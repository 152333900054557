import {useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import AddFinancialYearModal from '../../../financial-year-form/AddFinancialYearModal'
import CloseFinancialYearModal from '../../../financial-year-form/CloseFinancialYearModal'

const FinancialYearListToolbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isYearModalOpen, setIsYearModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }
  // console.log('isModalOpen', isModalOpen)
  const openYearModal = () => {
    setIsYearModalOpen(true)
  }

  const closeYearModal = () => {
    setIsYearModalOpen(false)
  }
  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()

  // const {onDownload} = useDownloadExcel({
  //   currentTableRef: tableRef.current,
  //   filename: `fn_Report_${fileName}`,
  //   sheet: 'Users',
  // })
  return (
    <>
      <div className='d-flex justify-content-end' data-kt-financialyear-table-toolbar='base'>
        {/* begin::Export */}
        {/* <button type='button' className='btn btn-light-primary me-3'>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Export
        </button> */}

        {/* begin::Add FinancialYear */}
        <button type='button' className='btn btn-primary me-3' onClick={openModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Add Financial Year
        </button>
        <AddFinancialYearModal show={isModalOpen} onHide={closeModal} />

        <button type='button' className='btn btn-success me-3' onClick={openYearModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Close Financial Year
        </button>
        <CloseFinancialYearModal show={isYearModalOpen} onHide={closeYearModal} />
      </div>
    </>
  )
}

export {FinancialYearListToolbar}
