import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'

import {getToken} from '../../../auth'

const API_URL = process.env.REACT_APP_API_URLS

var AuthVerify = getToken.call(this)

const getReport = async (data: any) => {
  console.log('api-data', data)
  const res = await axios.post(`${API_URL}reports/purchase-report`, data, getToken())
  console.log('api-res', res)
  return res
}
const supplierDropdown = async () => {
  const res = await axios.get(`${API_URL}purchase/get-supplier`, getToken())
  console.log('res-purchase/get-supplier-drop-api', res)
  return res
}
const productDropdown = async () => {
  const res = await axios.get(`${API_URL}purchase/get-product`, getToken())
  console.log('res-purchase/get-supplier-drop-api', res)
  return res
}

export {getReport,supplierDropdown,productDropdown}
