import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

import {AddSupplier} from './supplier-form'
import {EditSupplierWrapper} from './supplier-form/EditSupplierWrapper'
import {SupplierListWrapper} from './supplier-list/SupplierList'

const supplierBreadcrumbs: Array<PageLink> = [
  {
    title: 'Supplier Management',
    path: '/supplier',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SuppliersPage = () => {
  const navigate = useNavigate()
  function goBack() {
    navigate(-1)
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={supplierBreadcrumbs}>Supplier list</PageTitle>
              <SupplierListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
      <Route
        path='/add'
        element={
          <>
            {' '}
            <PageTitle breadcrumbs={supplierBreadcrumbs}>Supplier Add</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <AddSupplier />
          </>
        }
      />
      <Route
        path='/edit/:id'
        element={
          <>
            <PageTitle breadcrumbs={supplierBreadcrumbs}>Supplier Edit</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <EditSupplierWrapper />
          </>
        }
      />
    </Routes>
  )
}

export default SuppliersPage
