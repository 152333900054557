import axios, {AxiosResponse} from 'axios'
import {Department, DepartmentQueryResponse} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//Department API URL
const CREATE_DEPARTMENT_URL = `${API_URL}department/add`
const GET_DEPARTMENT_URL = `${API_URL}department/query`
const UPDATE_DEPARTMENT_URL = `${API_URL}department/update`
const DELETE_DEPARTMENT_URL = `${API_URL}department/delete`
const VIEW_DEPARTMENT_URL = `${API_URL}department/view/`

var AuthVerify = getToken.call(this)

//Department LIST
const getDepartment = (query: string, type: any): Promise<DepartmentQueryResponse> => {
  return axios
    .post(`${GET_DEPARTMENT_URL}?${query}`, {type}, AuthVerify)
    .then((response: AxiosResponse<DepartmentQueryResponse>) => {
      console.log('GET_DEPARTMENT_URL', response.data)
      return response.data
    })
}

//Department ADD
const createDepartment = (data: Department): Promise<Department | undefined> => {
  console.log('department-add', data)
  return axios.post(CREATE_DEPARTMENT_URL, data, getToken()).then((response: any) => response.data)
}

//Department Update
const updateDepartment = (data: any, id: any) => {
  console.log('Department-update', data)
  return axios.put(`${UPDATE_DEPARTMENT_URL}/${id}`, data, getToken()).then((response) => {
    return response.data
  })
}
//Department VEIW
const viewDepartment = (id: any) => {
  return axios.get(`${VIEW_DEPARTMENT_URL}/${id}`, getToken()).then((response) => {
    console.log('view-DEPARTMENT', response.data)
    return response.data
  })
}

//Department DELETE
const deleteDepartmentById = (id: any) => {
  return axios.post(`${DELETE_DEPARTMENT_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

export {getDepartment, createDepartment, updateDepartment, deleteDepartmentById, viewDepartment}
