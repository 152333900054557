// @ts-nocheck
import {Column} from 'react-table'
import {OpeningbalanceInfoCell} from './OpeningbalanceInfoCell'
import {OpeningbalanceTwoStepsCell} from './OpeningbalanceTwoStepsCell'
import {OpeningbalanceActionsCell} from './OpeningbalanceActionsCell'
import {OpeningbalanceCustomHeader} from './OpeningbalanceCustomHeader'
import {Openingbalance} from '../../core/_models'

const OpeningbalanceColumns: ReadonlyArray<Column<Openingbalance>> = [
  {
    Header: (props) => (
      <OpeningbalanceCustomHeader tableProps={props} title='S.No' className='min-w-55px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <OpeningbalanceCustomHeader
        tableProps={props}
        title='Financial Year'
        className='min-w-75px'
      />
    ),
    accessor: 'financialyear.financialYear',
  },
  {
    Header: (props) => (
      <OpeningbalanceCustomHeader tableProps={props} title='Date' className='min-w-105px' />
    ),
    accessor: 'openingDate',
  },
  {
    Header: (props) => (
      <OpeningbalanceCustomHeader tableProps={props} title='Account Name' className='min-w-105px' />
    ),
    accessor: 'coa.AccountName',
  },
  {
    Header: (props) => (
      <OpeningbalanceCustomHeader tableProps={props} title='Debit' className='min-w-75px' />
    ),
    accessor: 'debit',
  },
  {
    Header: (props) => (
      <OpeningbalanceCustomHeader tableProps={props} title='Credit' className='min-w-75px' />
    ),
    accessor: 'credit',
  },
  {
    Header: (props) => (
      <OpeningbalanceCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <OpeningbalanceActionsCell openingbalance={props.data[props.row.index]} />
    ),
  },
]

export {OpeningbalanceColumns}
