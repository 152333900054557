import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {SalesOrderListHeader} from './components/header/SalesOrderListHeader'

import {KTCard} from '../../../../../_metronic/helpers'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
import {useContext} from 'react'
import {SalesOrderTable} from './table/SalesOrdertable'

const SalesOrderList = () => {
  return (
    <>
      <KTCard>
        <SalesOrderListHeader />
        <SalesOrderTable />
      </KTCard>
    </>
  )
}
const SalesOrderMain = () => {
  const {show, message, type} = useContext(AlertContext)
  return (
    <div className='row'>
      {show && <AlertBox type={type === true ? 'success' : 'error'} message={message} />}
      <div className='col-lg-12'>
        <SalesOrderList />
      </div>
    </div>
  )
}
const SalesOrderListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <SalesOrderMain />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {SalesOrderListWrapper}
