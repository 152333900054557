import {ID, Response} from '../../../../../../_metronic/helpers'
export type OrderCategory = {
  id?: ID
  primaryId?: number
  
  name?: string
  description?: string

  message?: string
  status?: string
  createdBy?: number
}

export type OrderCategoryQueryResponse = Response<Array<OrderCategory>>

export const initialOrderCategory: OrderCategory = {
  description: '',
  name: '',


  createdBy: 0,
}
export type UpdateOrderCategory = {
  id?: ID
  primaryId?: number
  
  name?: string
  description?: string
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateOrderCategoryQueryResponse = Response<Array<UpdateOrderCategory>>

export const initialUpdateOrderCategory: UpdateOrderCategory = {
  description: '',
  name: '',

}
