// @ts-nocheck
import {Column} from 'react-table'

import {BankActionsCell} from './BankActionsCell'
import {BankCustomHeader} from './BankCustomHeader'
import {Bank} from '../../core/_models'

const BankColumns: ReadonlyArray<Column<Bank>> = [
  {
    Header: (props) => <BankCustomHeader tableProps={props} title='S.No' className='min-w-55px' />,
    accessor: 'serialNo',
  },

  {
    Header: (props) => (
      <BankCustomHeader tableProps={props} title='Bank Name' className='min-w-125px' />
    ),
    accessor: 'name',
  },

  {
    Header: (props) => (
      <BankCustomHeader tableProps={props} title='A/C Name' className='min-w-105px' />
    ),
    accessor: 'accountName',
  },

  {
    Header: (props) => (
      <BankCustomHeader tableProps={props} title='A/C Number' className='min-w-105px' />
    ),
    accessor: 'accountNumber',
  },

  {
    Header: (props) => (
      <BankCustomHeader tableProps={props} title='Branch' className='min-w-105px' />
    ),
    accessor: 'branch',
  },

  {
    Header: (props) => (
      <BankCustomHeader tableProps={props} title='IFSC Code' className='min-w-105px' />
    ),
    accessor: 'ifscCode',
  },

  {
    Header: (props) => (
      <BankCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <BankActionsCell bank={props.data[props.row.index]} />,
  },
]

export {BankColumns}
