import {ID, Response} from '../../../../../../_metronic/helpers'
export type Supplier = {
  id?: ID
  primaryId?: number
  name: string
  mobileNumber: string
  phoneNumber: string
  email: string
  address: string
  zipCode: string
  fax: string
  taxId: string
  message?: string
  status?: string
  createdBy?: number
}

export type SupplierQueryResponse = Response<Array<Supplier>>

export const initialSupplier: Supplier = {
  name: '',
  email: '',
  phoneNumber: '',
  mobileNumber: '',
  address: '',
  fax: '',
  taxId: '',
  zipCode: '',
  createdBy: 0,
}
export type UpdateSupplier = {
  id?: ID
  primaryId?: number
  name: string
  email: string
  phoneNumber: string
  address: string
  fax: string
  taxId: string
  mobileNumber: string
  zipCode: string
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateSupplierQueryResponse = Response<Array<UpdateSupplier>>

export const initialUpdateSupplier: UpdateSupplier = {
  name: '',
  email: '',
  phoneNumber: '',
  mobileNumber: '',
  address: '',
  fax: '',
  taxId: '',
  zipCode: '',
}
