import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewSalesOrder} from '../sales-order-list/core/_requests'
import EditSalesOrder from './EditSalesOrder'


export const EditSalesOrderWrapper = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewSalesOrder(id).then((res) => {
      setData(res.data)
      console.log('viewSalesOrderDetail', res.data)
    })
  }, [])
  if (data) {
    return <EditSalesOrder />
  }
  return null
}
