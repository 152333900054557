import {useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import AddPriorityModal from '../../../priority-form/AddPriorityModal'


const PriorityListToolbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)


  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()

  // const {onDownload} = useDownloadExcel({
  //   currentTableRef: tableRef.current,
  //   filename: `fn_Report_${fileName}`,
  //   sheet: 'Users',
  // })
  return (
    <>
      <div className='d-flex justify-content-end' data-kt-priority-table-toolbar='base'>
        {/* begin::Export */}
        {/* <button type='button' className='btn btn-light-primary me-3'>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Export
        </button> */}

        {/* begin::Add Priority */}
        <button type='button' className='btn btn-primary me-3' onClick={openModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Add Priority
        </button>
        <AddPriorityModal show={isModalOpen} onHide={closeModal} />

    
      </div>
    </>
  )
}

export {PriorityListToolbar}
