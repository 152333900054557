import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {SizeListHeader} from './components/header/SizeListHeader'
import {SizeTable} from './table/SizeTable'
// import {SizeEditModal} from './Size-Edit-Modal/SizeEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useContext} from 'react'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'

const SizeList = () => {
  const {itemIdForUpdate} = useListView()
  const {show, message, type} = useContext(AlertContext)
  return (
    <>
      <KTCard>
        {show && <AlertBox type={type === true ? 'success' : 'error'} message={message} />}
        <SizeListHeader />
        <SizeTable />
      </KTCard>
    </>
  )
}

const SizeListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <SizeList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {SizeListWrapper}
