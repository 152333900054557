import React, {useContext, useEffect, useState} from 'react'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {updateProductUnit} from '../unit-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {MyContext} from '../unit-list/ContextFile'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useAuth} from '../../../auth'

export interface ProductUnitDetail {
  name: string
  updatedBy: any
}

export let initialValues: ProductUnitDetail = {
  name: '',
  updatedBy: '',
}
const productUnitDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Product Unit is required'),
})

const ProductUnitDetails: React.FC = () => {
  const {currentUser} = useAuth()
  const {showAlert} = useContext(AlertContext)
  const {state, setState} = useContext(MyContext)
  const initialValues = state

  const [data, setData] = useState(state)

  useEffect(() => {
    setData(state)
    formik.setFieldValue('name', state?.name)
    formik.setFieldValue('primaryId', state?.primaryId)
  }, [state])

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: productUnitDetailsSchema,
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedBy = currentUser?.userId // Replace 'updatedBy' with the actual key in currentUser
      const updatedData = {...data, ...values, updatedBy}
      updateProductUnit(updatedData, state.primaryId).then((res) => {
        setData(initialValues)
        navigate('/unit', {replace: true})
        showAlert(res?.message, res?.status)
      })
    },
    enableReinitialize: true,
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Edit Product Unit </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/** Company Info */}
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Product Unit</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Product Unit'
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Update Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProductUnitDetails
