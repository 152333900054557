// @ts-nocheck
import React, {useState} from 'react'
import {Column} from 'react-table'
import {AttendanceActionsCell} from './AttendanceActionsCell'
import {AttendanceCustomHeader} from './AttendanceCustomHeader'
import {Attendance} from '../../core/_models'
import {Modal, Button} from 'react-bootstrap'
import {AttendanceInfoCell} from './AttendanceInfoCell'

const AttendanceColumns: ReadonlyArray<Column<Attendance>> = [
  {
    Header: (props) => (
      <AttendanceCustomHeader tableProps={props} title='S.No' className='min-w-40px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <AttendanceCustomHeader tableProps={props} title='Employee Id' className='min-w-75px' />
    ),
    accessor: 'employee.employeeId',
  },

  {
    Header: (props) => (
      <AttendanceCustomHeader tableProps={props} title='Employee Name' className='min-w-125px' />
    ),
    accessor: 'employee.employeeName',
  },
  {
    Header: (props) => (
      <AttendanceCustomHeader tableProps={props} title='Date' className='min-w-125px' />
    ),
    accessor: 'date',
  },
  {
    Header: (props) => (
      <AttendanceCustomHeader tableProps={props} title='Check-In' className='min-w-95px' />
    ),
    accessor: 'checkIn',
  },
  {
    Header: (props) => (
      <AttendanceCustomHeader tableProps={props} title='Check-Out' className='min-w-125px' />
    ),
    accessor: 'checkout',
    Cell: ({...props}) => <AttendanceInfoCell attendance={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <AttendanceCustomHeader
        tableProps={props}
        title='Stay Time (Hours)'
        className='min-w-85px'
      />
    ),
    accessor: 'totalHours',
  },

  {
    Header: (props) => (
      <AttendanceCustomHeader tableProps={props} title='OT Hours' className='min-w-55px' />
    ),
    accessor: 'otHours',
    Cell: ({ value }) => value || '-',
  },

  
  {
    Header: (props) => (
      <AttendanceCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <AttendanceActionsCell attendance={props.data[props.row.index]} />,
  },
]

export {AttendanceColumns}
