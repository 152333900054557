// @ts-nocheck
import {Column} from 'react-table'

import {OrderCategoryActionsCell} from './OrderCategoryActionsCell'
import {OrderCategoryCustomHeader} from './OrderCategoryCustomHeader'
import {OrderCategory} from '../../core/_models'

const OrderCategoryColumns: ReadonlyArray<Column<OrderCategory>> = [
  {
    Header: (props) => <OrderCategoryCustomHeader tableProps={props} title='S.No' className='min-w-85px' />,
    accessor: 'serialNo',
  },

  {
    Header: (props) => (
      <OrderCategoryCustomHeader tableProps={props} title='Category Name' className='min-w-125px' />
    ),
    accessor: 'name',
  },

  {
    Header: (props) => (
      <OrderCategoryCustomHeader tableProps={props} title='Description' className='min-w-125px' />
    ),
    accessor: 'description',
  },

  {
    Header: (props) => (
      <OrderCategoryCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <OrderCategoryActionsCell orderCategory={props.data[props.row.index]} />,
  },
]

export {OrderCategoryColumns}
