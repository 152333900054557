import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {AttendanceListWrapper} from './attendance-list/AttendanceList'
import {AddAttendance} from './attendance-form'
import {EditAttendanceWrapper} from './attendance-form/EditAttendanceWrapper'

const attendanceBreadcrumbs: Array<PageLink> = [
  {
    title: 'Attendance Management',
    path: '/attendance',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AttendancePage = () => {
  const navigate = useNavigate()
  function goBack() {
    navigate(-1)
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={attendanceBreadcrumbs}>Attendance</PageTitle>

              <AttendanceListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
      <Route
        path='/add'
        element={
          <>
            <PageTitle breadcrumbs={attendanceBreadcrumbs}>Attendance Add</PageTitle>

            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <AddAttendance />
          </>
        }
      />
      <Route
        path='/edit/:id'
        element={
          <>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <PageTitle breadcrumbs={attendanceBreadcrumbs}>Attendance Edit</PageTitle>
            <EditAttendanceWrapper />
          </>
        }
      />
    </Routes>
  )
}

export default AttendancePage
