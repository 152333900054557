// @ts-nocheck
import {Column} from 'react-table'
import {PriorityActionsCell} from './PriorityActionsCell'
import {PriorityCustomHeader} from './PriorityCustomHeader'
import {Priority} from '../../core/_models'

const PriorityColumns: ReadonlyArray<Column<Priority>> = [
  {
    Header: (props) => (
      <PriorityCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
    ),
    accessor: 'serialNo',
  },
 
  
  {
    Header: (props) => (
      <PriorityCustomHeader tableProps={props} title='name' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  

 
  {
    Header: (props) => (
      <PriorityCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <PriorityActionsCell priority={props.data[props.row.index]} />,
  },
]

export {PriorityColumns}
