import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {SalarygenerateListHeader} from './components/header/SalarygenerateListHeader'
import {SalarygenerateTable} from './table/SalarygenerateTable'
// import {SalarygenerateEditModal} from './Salarygenerate-Edit-Modal/SalarygenerateEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import { AlertContext } from '../../../../../_metronic/layout/components/alert-context'
import { useContext } from 'react'
import { AlertBox } from '../../../../../_metronic/layout/components/alert-message'

const SalarygenerateList = () => {
  const {itemIdForUpdate} = useListView()
  const {show, message, type} = useContext(AlertContext)
  return (
    <>
      <KTCard>
      {show && <AlertBox type={type === true ? 'success' : 'error'} message={message} />}
        <SalarygenerateListHeader />
        <SalarygenerateTable />
      </KTCard>
      
    </>
  )
}

const SalarygenerateListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <SalarygenerateList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {SalarygenerateListWrapper}
