import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewSales} from '../sales-list/core/_requests'
import EditSales from './EditSales'

export const EditSalesWrapper = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewSales(id).then((res) => {
      setData(res.data)
    })
  }, [])
  if (data) {
    return <EditSales />
  }
  return null
}
