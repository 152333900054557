// @ts-nocheck
import {Column} from 'react-table'

import {PurchaseActionsCell} from './PurchaseActionsCell'
import {PurchaseCustomHeader} from './PurchaseCustomHeader'
import {Purchase} from '../../core/_models'

const PurchaseColumns: ReadonlyArray<Column<Purchase>> = [
  {
    Header: (props) => (
      <PurchaseCustomHeader tableProps={props} title='S.No' className='min-w-55px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <PurchaseCustomHeader tableProps={props} title='Supplier Name' className='min-w-125px' />
    ),
    accessor: 'supplier.supplierName',
  },

  {
    Header: (props) => (
      <PurchaseCustomHeader tableProps={props} title='Purchase Date' className='min-w-85px' />
    ),
    accessor: 'purchaseDate',
  },
  {
    Header: (props) => (
      <PurchaseCustomHeader tableProps={props} title='Amount' className='min-w-75px' />
    ),
    accessor: 'amount',
  },
  {
    Header: (props) => (
      <PurchaseCustomHeader tableProps={props} title='Quantity' className='min-w-75px' />
    ),
    accessor: 'quantity',
  },
  {
    Header: (props) => (
      <PurchaseCustomHeader tableProps={props} title='Tax' className='min-w-75px' />
    ),
    accessor: 'tax',
  },
  {
    Header: (props) => (
      <PurchaseCustomHeader tableProps={props} title='Grand Total' className='min-w-75px' />
    ),
    accessor: 'grandTotal',
  },
  {
    Header: (props) => (
      <PurchaseCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <PurchaseActionsCell purchase={props.data[props.row.index]} />,
  },
]

export {PurchaseColumns}
