import axios, {AxiosResponse} from 'axios'

import {Priority, PriorityQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_PRIORITY_URL = `${API_URL}priority/add`
const GET_PRIORITY_URL = `${API_URL}priority/query`
const VIEW_PRIORITY_URL = `${API_URL}priority/view`
const UPDATE_PRIORITY_URL = `${API_URL}priority/update`
const DELETE_PRIORITY_URL = `${API_URL}priority/delete`


//AuthVerify
var AuthVerify = getToken.call(this)

//Priority LIST
const getPriority = (query: string): Promise<PriorityQueryResponse> => {
  return axios
    .post(`${GET_PRIORITY_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<PriorityQueryResponse>) => {
      console.log('get-Priority==>', response.data)
      return response.data
    })
}

//Priority ADD
const createPriority = (data: any): Promise<Priority | undefined> => {
  return axios
    .post(CREATE_PRIORITY_URL, data, getToken())
    .then((response: any) => response.data)
}

//Priority VEIW
const viewPriority = (id: any) => {
  return axios.get(`${VIEW_PRIORITY_URL}/${id}`, getToken()).then((response) => {
    console.log('view-Priority', response.data)
    return response.data
  })
}

//Priority UPDATE
const updatePriority = (data: any, id: any) => {
  console.log('reqData=update-Priority', data)
  return axios.put(`${UPDATE_PRIORITY_URL}/${id}`, data, getToken()).then((response) => {
    console.log('updatemp-Priority', response.data)
    return response.data
  })
}

//Priority DELETE
const deletePriorityById = (id: any) => {
  return axios.post(`${DELETE_PRIORITY_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

export {
  getPriority,
  createPriority,
  viewPriority,
  updatePriority,
  deletePriorityById,
 
}
