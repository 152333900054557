import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewSku} from '../sku-list/core/_requests'
import {EditSku} from '../sku-form/EditSku'

export const EditSkuWrapper = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewSku(id).then((res) => {
      setData(res.data)
      console.log('viewSkuDetail', res.data)
    })
  }, [])
  if (data) {
    return <EditSku initialValues={data} />
  }
  return null
}
