import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PurchaseReturnListHeader} from './components/header/PurchaseReturnListHeader'

import {KTCard} from '../../../../../_metronic/helpers'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
import {useContext} from 'react'
import {PurchaseReturnTable} from './table/PurchaseReturntable'

const PurchaseReturnList = () => {
  return (
    <>
      <KTCard>
        <PurchaseReturnListHeader />
        <PurchaseReturnTable />
      </KTCard>
    </>
  )
}
const PurchaseReturnMain = () => {
  const {show, message, type} = useContext(AlertContext)
  return (
    <div className='row'>
      {show && <AlertBox type={type === true ? 'success' : 'error'} message={message} />}
      <div className='col-lg-12'>
        <PurchaseReturnList />
      </div>
    </div>
  )
}
const PurchaseReturnListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PurchaseReturnMain />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PurchaseReturnListWrapper}
