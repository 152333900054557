import {CashadjustmentListHeader} from './components/header/CashadjustmentListHeader'
import {CashadjustmentTable} from './table/CashadjustmentTable'
import {KTCard} from '../../../../../_metronic/helpers'
import {AddCashadjustment, EditCashadjustment} from '../cash-adjustment-form'
import {useContext} from 'react'
import {MyContext} from './ContextFile'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ContextProvider} from '../../role/role-list/ContextProvider'

// import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
// import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
const CashadjustmentAdd = () => {
  const {state, setState} = useContext(MyContext)

  return (
    <>
      <KTCard>{state.editclicked === true ? <EditCashadjustment /> : <AddCashadjustment />}</KTCard>
    </>
  )
}

const CashadjustmentList = () => {
  return (
    <>
      <KTCard>
        <CashadjustmentListHeader />
        <CashadjustmentTable />
      </KTCard>
    </>
  )
}

const CashadjustmentMain = () => {
  // const {show, message, type} = useContext(AlertContext)
  return (
    <div className='row'>
      {/* {show && <AlertBox type={type === '1' ? 'success' : 'error'} message={message} />} */}
      <div className='col-lg-12'>
        <CashadjustmentAdd />
      </div>
      {/* <div className='col-lg-7'>
        <CashadjustmentList />
      </div> */}
    </div>
  )
}

const CashadjustmentListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ContextProvider>
          <CashadjustmentMain />
        </ContextProvider>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CashadjustmentListWrapper}
