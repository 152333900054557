// @ts-nocheck
import {Column} from 'react-table'
import {VoucherInfoCell} from './VoucherInfoCell'
import {VoucherTwoStepsCell} from './VoucherTwoStepsCell'
import {VoucherActionsCell} from './VoucherActionsCell'
import {VoucherCustomHeader} from './VoucherCustomHeader'
import {Voucher} from '../../core/_models'

const VoucherColumns: ReadonlyArray<Column<Voucher>> = [
  {
    Header: (props) => (
      <VoucherCustomHeader tableProps={props} title='S.No' className='min-w-55px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <VoucherCustomHeader tableProps={props} title='Voucher Number' className='min-w-95px' />
    ),
    accessor: 'voucherNumber',
  },
  
  {
    Header: (props) => (
      <VoucherCustomHeader tableProps={props} title='Date' className='min-w-125px' />
    ),
    accessor: 'voucherDate',
  },

  {
    Header: (props) => (
      <VoucherCustomHeader tableProps={props} title='Account name' className='min-w-95px' />
    ),
    accessor: 'reverseAccount.headName',
  },
 
  {
    Header: (props) => (
      <VoucherCustomHeader tableProps={props} title='Debit' className='min-w-85px' />
    ),
    accessor: 'debit',
  },
  {
    Header: (props) => (
      <VoucherCustomHeader tableProps={props} title='Credit' className='min-w-85px' />
    ),
    accessor: 'credit',
  },

  {
    Header: (props) => (
      <VoucherCustomHeader
        tableProps={props}
        title='Reverse Account Name'
        className='min-w-125px'
      />
    ),
    accessor: 'coa.headName',
  },
  
  {
    Header: (props) => (
      <VoucherCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <VoucherActionsCell voucher={props.data[props.row.index]} />,
  },
]

export {VoucherColumns}
