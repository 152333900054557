import React, {useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {Purchase, initialPurchase as initialValues} from '../purchase-list/core/_models'
import {
  createPurchase,
  getSupplier,
  getProduct,
  getPayment,
  getSku,
  getSize,getUnit,
  getColor,getMaterial
} from '../purchase-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../purchase-list/core/QueryResponseProvider'

// Validation schema
const purchaseDetailsSchema = Yup.object().shape({
  purchaseDate: Yup.string().required('Purchase Date is required'), 
  // supplierName: Yup.string().required('Supplier Name is required'), 
  // purchaseType: Yup.string().required('Purchase Date is required'), 
  purchaseDetail: Yup.array().of(
    Yup.object().shape({
      // amount: Yup.string().required('Amount is required'),
      // quantity: Yup.string().required('Quantity is required'),
      // skuId: Yup.string().required('SKU is required'), 
      // unitId: Yup.string().required('Unit is required'),
    })
  ),
})
// Define a Product typ
interface Product {
  id: number
  name: string
  price: number
  tax: {
    percentage: number
  }
}
interface Material {
  id: number
  name: string
  price: number
  tax: {
    percentage: number
  }
}
const PurchaseDetails = () => {
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()
  const [supplier, setSupplier] = useState([])
  const [product, setProduct] = useState([])
  const [payment, setPayment] = useState([])
  const [sku, setSku] = useState([])
  const [size, setSize] = useState([])
  const [unit, setUnit] = useState([])
  const [color, setColor] = useState([])
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Purchase>(initialValues)
  console.log('initialValues', initialValues)
  const navigate = useNavigate()
  const [productList, setProductList] = useState<Product[]>([])
  const [material, setMaterialList] = useState<Material[]>([])
  const [selectedPurchaseType, setSelectedPurchaseType] = useState('');

  useEffect(() => {
    getSupplier().then((res) => {
      setSupplier(res?.data?.data)
    })
  }, [])

  useEffect(() => {
    getProduct().then((res) => {

      setProduct(res?.data?.data)
      setProductList(res?.data?.data)
   
    })
  }, [])
  useEffect(() => {
    getMaterial().then((res) => {
      setMaterialList(res?.data?.data)
    
    })
  }, [])

  useEffect(() => {
    getPayment().then((res) => {
      setPayment(res?.data?.data)
    })
  }, [])
  useEffect(() => {
    getSku().then((res) => {
      setSku(res?.data?.data)
    })
  }, [])
  useEffect(() => {
    getSize().then((res) => {
      setSize(res?.data?.data)
    })
  }, [])
  useEffect(() => {
    getColor().then((res) => {
      setColor(res?.data?.data)
    })
  }, [])
  useEffect(() => {
    getUnit().then((res) => {
      setUnit(res?.data?.data)
    })
  }, [])
  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={purchaseDetailsSchema}
        onSubmit={(values, {resetForm}) => {
          // Calculate the Grand Total, Tax, and Discount, Quantity, Paid Amount
          const grandTotal = values.purchaseDetail
            .reduce(
              (total, purchaseDetail) =>
                total +
                ((parseFloat(purchaseDetail.quantity) || 0) *
                  (parseFloat(purchaseDetail.amount) || 0) -
                  (parseFloat(purchaseDetail.discountValue) || 0) +
                  (parseFloat(purchaseDetail.taxValue) || 0)),
              0
            )
            .toFixed(2)

          const tax = values.purchaseDetail
            .reduce(
              (total, purchaseDetail) => total + parseFloat(purchaseDetail.taxValue || '0'),
              0
            )
            .toFixed(2)

          const discount = values.purchaseDetail
            .reduce(
              (total, purchaseDetail) => total + parseFloat(purchaseDetail.discountValue || '0'),
              0
            )
            .toFixed(2)
          const amount = values.purchaseDetail
            .reduce((total, purchaseDetail) => total + parseFloat(purchaseDetail.amount || '0'), 0)
            .toFixed(2)
          const quantity = values.purchaseDetail
            .reduce(
              (total, purchaseDetail) => total + parseFloat(purchaseDetail.quantity || '0'),
              0
            )
            .toFixed(2)

          // Update the 'values' object with the calculated values
          const updatedValues = {
            ...values,
         
            quantity,
            amount,
            grandTotal,
            tax,
            discount,
            purchase: values.purchaseDetail,
            createdBy: currentUser?.userId,
          }

          // Now you can submit the updated 'values' to the createPurchase function
          createPurchase(updatedValues)
            .then((res) => {
              // Handle the response
              console.log('purchase', res?.message)
              resetForm({
                ...initialValues,
                values: {...initialValues},
              })
              setData(initialValues)
              navigate('/purchase', {replace: true})
              refetch()
              setLoading(false)
              showAlert(res?.message, res?.status)
            })
            .catch((error) => {
              console.error('Error creating purchase:', error)
            })
            .finally(() => {
              setLoading(false)
            })
        }}
      >
        {({values, setFieldValue}) => (
          <Form>
            <div
              className='card-header border-0'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Add Purchase</h3>
              </div>
            </div>
            <div id='kt_account_profile_details'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Supplier </label>'
                      <Field
                        as='select'
                        name='supplierId'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Supplier Name'
                      >
                        <option value=''>Select Supplier</option>
                        {supplier.map((item: any, i: any) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.supplierName}
                            </option>
                          )
                        })}
                      </Field>
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Purchase Date</label>
                      <Field
                        name='purchaseDate'
                        type='date'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Date'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='purchaseDate'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Details</label>
                      <Field
                        as='textarea'
                        name='description'
                        className='form-control form-control-lg form-control-solid'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='description'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <div className='form-group row'>
                        <div className='col-11 col-form-label'>
                          <br />
                          <br />
                          <br />

                          <div className='mb-8'>
                            <div className='form-check form-check-custom form-check-solid form-check-lg'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='isCreditCheckbox'
                                name='isCredit'
                                checked={values.isCredit}
                                onChange={(e) => setFieldValue('isCredit', e.target.checked)}
                              />
                              <label className='fw-bold ps-2 fs-6' htmlFor='flexCheckboxLg'>
                                Is Credit
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <ErrorMessage
                        className='fieldError'
                        name='isCredit'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                   {/* Purchase Type */}
      <div className='col-lg-6 fv-row'>
        <label className='col-form-label required fw-bold fs-6'>Purchase Product / Material </label>
        <Field
          as='select'
          name='purchaseType'
          className='form-control form-control-lg form-control-solid'
          onChange={(e:any) => {
            const type = e.target.value;
            setSelectedPurchaseType(type);
            setFieldValue('purchaseType', type);
          }}
        >
                   <option value='0'>Select Type</option>  
          <option value='Product'>Product</option>
          <option value='Material'>Material</option>
        </Field>
      </div>
                  </div>
              </div>

            
              <div className='row mb-6 card-footer d-flex justify-content'>
                <div className='table-container'>
                  <FieldArray
                    name='purchaseDetail'
                    render={(arrayHelpers) => {
                      console.log('arrayHelpers', values, arrayHelpers)
                      return (
                        <div>
                          {values.purchaseDetail && values.purchaseDetail.length > 0
                            ? values.purchaseDetail.map((purchaseDetail, index) => (
                                <div key={index} className='row mb-2 position-relative addon-group'>
                                  {selectedPurchaseType === 'Product' && (
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>
                                      Product
                                    </label>

                                    <Field
                                      as='select'
                                      name={`purchaseDetail[${index}].productId`}
                                      className='form-control form-control-lg form-control-solid'
                                      onChange={(e: any) => {
                                        const selectedProductId = e.target.value
                                        const selectedProductData = productList.find(
                                          (item: any) => item.id === parseInt(selectedProductId, 10)
                                        )

                                        if (selectedProductData) {
                                          const amount = selectedProductData.price || 0
                                          const taxPercentage =
                                            selectedProductData.tax?.percentage || 0

                                          // Update form values
                                          setFieldValue(
                                            `purchaseDetail[${index}].productId`,
                                            selectedProductId
                                          )
                                          setFieldValue(
                                            `purchaseDetail[${index}].amount`,
                                            amount.toString()
                                          )
                                          setFieldValue(
                                            `purchaseDetail[${index}].tax`,
                                            taxPercentage.toString()
                                          )

                                          // Recalculate tax value and discount value based on the new amount and tax
                                          const quantity =
                                            parseFloat(values.purchaseDetail[index].quantity) || 0
                                          const discount =
                                            parseFloat(values.purchaseDetail[index].discount) || 0

                                          const discountValue = (amount * discount) / 100
                                          const taxValue = (amount * taxPercentage) / 100
                                          const newTotal = (
                                            amount * quantity +
                                            taxValue -
                                            discountValue
                                          ).toFixed(2)

                                          // Update form values
                                          setFieldValue(
                                            `purchaseDetail[${index}].taxValue`,
                                            taxValue.toFixed(2)
                                          )
                                          setFieldValue(
                                            `purchaseDetail[${index}].discountValue`,
                                            discountValue.toFixed(2)
                                          )
                                          setFieldValue(`purchaseDetail[${index}].total`, newTotal)
                                        } else {
                                          // Update form values when no product is selected
                                          setFieldValue(`purchaseDetail[${index}].productId`, '')
                                          setFieldValue(`purchaseDetail[${index}].amount`, '')
                                          setFieldValue(`purchaseDetail[${index}].tax`, '')
                                          setFieldValue(`purchaseDetail[${index}].taxValue`, '')
                                          setFieldValue(
                                            `purchaseDetail[${index}].discountValue`,
                                            ''
                                          )
                                          setFieldValue(`purchaseDetail[${index}].total`, '')
                                        }
                                      }}
                                    >
                                      <option value=''>Select Product</option>
                                      {product.map((item: any, i: any) => (
                                        <option key={i} value={item.id}>
                                          {item.productName}
                                        </option>
                                      ))}
                                    </Field>

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`purchaseDetail[${index}].productId`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                      )}
                                       {selectedPurchaseType === 'Material' && (
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>
                                     Material
                                    </label>

                                    <Field
                                      as='select'
                                      name={`purchaseDetail[${index}].productId`}
                                      className='form-control form-control-lg form-control-solid'
                                      onChange={(e: any) => {
                                        const selectedMaterialId = e.target.value
                                        const selectedMaterialData = material.find(
                                          (item: any) => item.id === parseInt(selectedMaterialId, 10)
                                        )

                                        if (selectedMaterialData) {
                                          const amount = selectedMaterialData.price || 0
                                          const taxPercentage =
                                          selectedMaterialData.tax?.percentage || 0
                                          // Update form values
                                          setFieldValue(
                                            `purchaseDetail[${index}].productId`,
                                            selectedMaterialId
                                          )
                                          setFieldValue(
                                            `purchaseDetail[${index}].amount`,
                                            amount.toString()
                                          )
                                          setFieldValue(
                                            `purchaseDetail[${index}].tax`,
                                            taxPercentage.toString()
                                          )

                                          // Recalculate tax value and discount value based on the new amount and tax
                                          const quantity =
                                            parseFloat(values.purchaseDetail[index].quantity) || 0
                                          const discount =
                                            parseFloat(values.purchaseDetail[index].discount) || 0

                                          const discountValue = (amount * discount) / 100
                                          const taxValue = (amount * taxPercentage) / 100
                                          const newTotal = (
                                            amount * quantity +
                                            taxValue -
                                            discountValue
                                          ).toFixed(2)

                                          // Update form values
                                          setFieldValue(
                                            `purchaseDetail[${index}].taxValue`,
                                            taxValue.toFixed(2)
                                          )
                                          setFieldValue(
                                            `purchaseDetail[${index}].discountValue`,
                                            discountValue.toFixed(2)
                                          )
                                          setFieldValue(`purchaseDetail[${index}].total`, newTotal)
                                        } else {
                                          // Update form values when no product is selected
                                          setFieldValue(`purchaseDetail[${index}].productId`, '')
                                          setFieldValue(`purchaseDetail[${index}].amount`, '')
                                          setFieldValue(`purchaseDetail[${index}].tax`, '')
                                          setFieldValue(`purchaseDetail[${index}].taxValue`, '')
                                          setFieldValue(
                                            `purchaseDetail[${index}].discountValue`,
                                            ''
                                          )
                                          setFieldValue(`purchaseDetail[${index}].total`, '')
                                        }
                                      }}
                                    >
                                      <option value=''>Select Material</option>
                                      {material.map((item: any, i: any) => (
                                        <option key={i} value={item.id}>
                                          {item.materialName}
                                        </option>
                                      ))}
                                    </Field>

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`purchaseDetail[${index}].productId`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                     )}
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>
                                    SKU
                                    </label>

                                    <Field
                                      as='select'
                                      name={`purchaseDetail[${index}].skuId`}
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='SKU Name'
                                    >
                                      <option value=''>Select SKU</option>
                                      {sku.map((item: any, i: any) => {
                                        return (
                                          <option key={i} value={item.id}>
                                            {item.skuName}
                                          </option>
                                        )
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`purchaseDetail[${index}].skuId`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>
                                      Style Number
                                    </label>
                                    <Field
                                      type='text'
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Style Number'
                                      name={`purchaseDetail[${index}].styleNumber`}
                                    />
                                    <ErrorMessage
                                      className='fieldError'
                                      name={`purchaseDetail[${index}].styleNumber`}
                                      render={(msg) => <div className='fieldError'>{msg}</div>}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>
                                      Color
                                    </label>

                                    <Field
                                      as='select'
                                      name={`purchaseDetail[${index}].colorId`}
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Color'
                                    >
                                      <option value=''>Select Color</option>
                                      {color.map((item: any, i: any) => {
                                        return (
                                          <option key={i} value={item.id}>
                                            {item.colorName}
                                          </option>
                                        )
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`purchaseDetail[${index}].colorId`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                <div className={`col-md-2 mb-2 mb-md-0 ${selectedPurchaseType === 'Material' ? 'd-none' : ''}`}>
  <label className='form-label fw-bold fs-6'>
    Size
  </label>

  <Field
    as='select'
    name={`purchaseDetail[${index}].sizeId`}
    className='form-control form-control-lg form-control-solid'
    placeholder='Size'
  >
    <option value=''>Select Size</option>
    {size.map((item: any, i: any) => (
      <option key={i} value={item.id}>
        {item.sizeName}
      </option>
    ))}
  </Field>

  <ErrorMessage
    className='fieldError'
    name={`purchaseDetail[${index}].sizeId`}
    render={(msg) => (
      <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>{msg}</div>
      </div>
    )}
  />
</div>

                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>Quantity</label>
                                    <Field
                                      type='text'
                                      name={`purchaseDetail[${index}].quantity`}
                                      className='form-control form-control-lg form-control-solid'
                                      onChange={(e: any) => {
                                        const newQuantity = parseFloat(e.target.value) || 0
                                        const selectedProductData = productList.find(
                                          (item: any) =>
                                            item.id ===
                                            parseInt(values.purchaseDetail[index].productId, 10)
                                        )

                                        if (selectedProductData) {
                                          const amount = selectedProductData.price || 0
                                          const taxPercentage =
                                            selectedProductData.tax?.percentage || 0
                                          const discount =
                                            parseFloat(values.purchaseDetail[index].discount) || 0

                                          // Recalculate values based on the new quantity
                                          const discountValue =
                                            (amount * discount * newQuantity) / 100
                                          const taxValue =
                                            (amount * taxPercentage * newQuantity) / 100
                                          const newTotal = (
                                            amount * newQuantity +
                                            taxValue -
                                            discountValue
                                          ).toFixed(2)

                                          // Update form values
                                          setFieldValue(
                                            `purchaseDetail[${index}].quantity`,
                                            newQuantity.toString()
                                          )
                                          setFieldValue(
                                            `purchaseDetail[${index}].taxValue`,
                                            taxValue.toString()
                                          )
                                          setFieldValue(
                                            `purchaseDetail[${index}].discountValue`,
                                            discountValue.toString()
                                          )
                                          setFieldValue(
                                            `purchaseDetail[${index}].total`,
                                            newTotal.toString()
                                          )
                                        }
                                      }}
                                    />
                                        <ErrorMessage
                                      className='fieldError'
                                      name={`purchaseDetail[${index}].quantity`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />

                                  
                                  </div>
                              
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>
                                 Unit 
                                    </label>

                                    <Field
                                      as='select'
                                      name={`purchaseDetail[${index}].unitId`}
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='SKU Name'
                                    >
                                      <option value=''>Select Unit</option>
                                      {unit.map((item: any, i: any) => {
                                        return (
                                          <option key={i} value={item.id}>
                                            {item.unitName}
                                          </option>
                                        )
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`purchaseDetail[${index}].unitId`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>
                                      Amount
                                    </label>

                                    <Field
                                      type='text'
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Amount'
                                      name={`purchaseDetail[${index}].amount`}
                                      onChange={(e: any) => {
                                        // Calculate new values based on changes in 'amount'
                                        const newAmount = parseFloat(e.target.value) || 0
                                        const quantity =
                                          parseFloat(values.purchaseDetail[index].quantity) || 0
                                        const discount =
                                          parseFloat(values.purchaseDetail[index].discount) || 0
                                        const tax =
                                          parseFloat(values.purchaseDetail[index].tax) || 0
                                        const originalTotal =
                                          parseFloat(values.purchaseDetail[index].total) || 0

                                        const discountValue = (newAmount * discount) / 100
                                        const taxValue = (newAmount * tax) / 100
                                        const newTotal = (
                                          originalTotal +
                                          newAmount -
                                          values.purchaseDetail[index].amount
                                        ).toFixed(2)

                                        // Update form values
                                        setFieldValue(
                                          `purchaseDetail[${index}].amount`,
                                          e.target.value
                                        )
                                        setFieldValue(`purchaseDetail[${index}].total`, newTotal)
                                        setFieldValue(
                                          `purchaseDetail[${index}].discountValue`,
                                          discountValue.toFixed(2)
                                        )
                                        setFieldValue(
                                          `purchaseDetail[${index}].taxValue`,
                                          taxValue.toFixed(2)
                                        )
                                      }}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>Tax %</label>

                                    <Field
                                      type='text'
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Tax %'
                                      name={`purchaseDetail[${index}].tax`}
                                      onChange={(e: any) => {
                                        const amount =
                                          parseFloat(values.purchaseDetail[index].amount) || 0
                                        const quantity =
                                          parseFloat(values.purchaseDetail[index].quantity) || 0
                                        const discount =
                                          parseFloat(values.purchaseDetail[index].discount) || 0
                                        const tax = parseFloat(e.target.value) || 0

                                        const discountValue = (amount * discount) / 100
                                        const taxValue = (amount * tax) / 100
                                        const newTotal = (
                                          amount * quantity -
                                          discountValue +
                                          taxValue
                                        ).toFixed(2)

                                        // Update tax, discountValue, and total in the form values
                                        setFieldValue(
                                          `purchaseDetail[${index}].tax`,
                                          e.target.value
                                        )
                                        setFieldValue(
                                          `purchaseDetail[${index}].discountValue`,
                                          discountValue.toFixed(2)
                                        )
                                        setFieldValue(
                                          `purchaseDetail[${index}].taxValue`,
                                          taxValue.toFixed(2)
                                        )
                                        setFieldValue(`purchaseDetail[${index}].total`, newTotal)
                                      }}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>Tax Value</label>
                                    <Field
                                      type='text'
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Tax Value'
                                      name={`purchaseDetail[${index}].taxValue`}
                                      readOnly
                                    />
                                    <ErrorMessage
                                      className='fieldError'
                                      name={`purchaseDetail[${index}].taxValue`}
                                      render={(msg) => <div className='fieldError'>{msg}</div>}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>Discount %</label>
                                    <Field
                                      type='text'
                                      className='form-control form-control-lg form-control-solid'
                                      name={`purchaseDetail[${index}].discount`}
                                      onChange={(e: any) => {
                                        const discount = parseFloat(e.target.value) || 0
                                        const amount =
                                          parseFloat(values.purchaseDetail[index].amount) || 0
                                        const quantity =
                                          parseFloat(values.purchaseDetail[index].quantity) || 0
                                        const tax =
                                          parseFloat(values.purchaseDetail[index].tax) || 0

                                        const discountValue = (amount * discount) / 100
                                        const taxValue = (amount * tax) / 100
                                        const newTotal = (
                                          amount * quantity -
                                          discountValue +
                                          taxValue
                                        ).toFixed(2)

                                        setFieldValue(
                                          `purchaseDetail[${index}].discount`,
                                          discount.toString()
                                        )
                                        setFieldValue(
                                          `purchaseDetail[${index}].discountValue`,
                                          discountValue.toFixed(2)
                                        )
                                        setFieldValue(
                                          `purchaseDetail[${index}].taxValue`,
                                          taxValue.toFixed(2)
                                        )
                                        setFieldValue(`purchaseDetail[${index}].total`, newTotal)
                                      }}
                                    />

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`purchaseDetail[${index}].discount`}
                                      render={(msg) => <div className='fieldError'>{msg}</div>}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>
                                      Discount Value
                                    </label>
                                    <Field
                                      type='text'
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Discount Value'
                                      name={`purchaseDetail[${index}].discountValue`}
                                    />
                                    <ErrorMessage
                                      className='fieldError'
                                      name={`purchaseDetail[${index}].discountValue`}
                                      render={(msg) => <div className='fieldError'>{msg}</div>}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>Total</label>
                                    <Field
                                      type='text'
                                      name={`purchaseDetail[${index}].total`}
                                      className='form-control form-control-lg form-control-solid'
                                      onChange={(e: any) => {
                                        const newTotal = parseFloat(e.target.value) || 0
                                        const quantity =
                                          parseFloat(values.purchaseDetail[index].quantity) || 0
                                        const amount =
                                          parseFloat(values.purchaseDetail[index].amount) || 0
                                        const discount =
                                          parseFloat(values.purchaseDetail[index].discount) || 0
                                        const tax =
                                          parseFloat(values.purchaseDetail[index].tax) || 0

                                        // Perform calculations based on changes in 'total'
                                        const discountValue = (amount * discount) / 100
                                        const taxValue = (amount * tax) / 100

                                        // Calculate the new amount based on the new total
                                        const newAmount =
                                          (newTotal + discountValue - taxValue) / quantity

                                        // Update form values
                                        setFieldValue(
                                          `purchaseDetail[${index}].amount`,
                                          newAmount.toFixed(2)
                                        )
                                        setFieldValue(
                                          `purchaseDetail[${index}].discountValue`,
                                          discountValue.toFixed(2)
                                        )
                                        setFieldValue(
                                          `purchaseDetail[${index}].taxValue`,
                                          taxValue.toFixed(2)
                                        )
                                        setFieldValue(
                                          `purchaseDetail[${index}].total`,
                                          newTotal.toFixed(2)
                                        )
                                        // ... (perform other calculations and update fields)
                                      }}
                                    />

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`purchaseDetail[${index}].total`}
                                      render={(msg) => <div className='fieldError'>{msg}</div>}
                                    />
                                  </div>
                                  {index === 0 ? (
                                    <div className='col-md-2 mb-2 mb-md-0'>
                                      <label className='form-label mt-14'></label>
                                      <Link to='#' className='add-btn'>
                                        <div
                                          onClick={() =>
                                            arrayHelpers.push({
                                              productId: 0,
                                              skuId: 0,
                                              sizeId: 0,
                                              colorId: 0,
                                              unitId: 0,
                                              styleNumber: '',
                                              amount: '',
                                              tax: '',
                                              taxValue: '',
                                              discount: '',
                                              discountValue: '',
                                              total: '',
                                              quantity: '',
                                            })
                                          }
                                          className='btn btn-primary btn-sm'
                                        >
                                          Add Purchase
                                        </div>
                                      </Link>
                                    </div>
                                  ) : (
                                    <div className='col-md-2 mb-2 mb-md-0'>
                                      <label className='form-label mt-14'></label>
                                      <Link to='#' className='add-btn'>
                                        <div
                                          onClick={() => arrayHelpers.remove(index)}
                                          className='btn btn-danger btn-sm'
                                        >
                                          Remove
                                        </div>{' '}
                                      </Link>
                                    </div>
                                  )}
                                  <br />
                                  <br /> <br />
                                  <br /> <br />
                                  <hr />
                                </div>
                              ))
                            : null}
                        </div>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Total Calculations fields */}
            <div className='form-group row justify-content-end py-6 px-9'>
              <label className='form-label fw-bold fs-6 col-form-label col-lg-2 text-right col-sm-12'>
                Total Tax
              </label>
              <div className='col-lg-2 col-md-10 col-sm-12'>
                <Field
                  type='text'
                  className='form-control'
                  name='tax'
                  value={values.purchaseDetail
                    .reduce(
                      (total, purchaseDetail) => total + parseFloat(purchaseDetail.taxValue || '0'),
                      0
                    )
                    .toFixed(2)}
                />
              </div>
            </div>
            <div className='form-group row justify-content-end py-6 px-9'>
              <label className='form-label fw-bold fs-6 col-form-label col-lg-2 text-right col-sm-12'>
                Total Discount
              </label>
              <div className='col-lg-2 col-md-10 col-sm-12'>
                <Field
                  type='text'
                  className='form-control'
                  name='discount'
                  value={values.purchaseDetail
                    .reduce(
                      (total, purchaseDetail) =>
                        total + parseFloat(purchaseDetail.discountValue || '0'),
                      0
                    )
                    .toFixed(2)}
                />
              </div>
            </div>
            <div className='form-group row justify-content-end py-6 px-9'>
              <label className='form-label fw-bold fs-6 col-form-label col-lg-2 text-right col-sm-12'>
                Grand Total
              </label>
              <div className='col-lg-2 col-md-10 col-sm-12'>
                <Field
                  type='text'
                  className='form-control'
                  name='grandTotal'
                  value={values.purchaseDetail
                    .reduce(
                      (total, purchaseDetail) =>
                        total +
                        ((parseFloat(purchaseDetail.quantity) || 0) *
                          (parseFloat(purchaseDetail.amount) || 0) -
                          (parseFloat(purchaseDetail.discountValue) || 0) +
                          (parseFloat(purchaseDetail.taxValue) || 0)),
                      0
                    )
                    .toFixed(2)}
                />
              </div>
            </div>
            <div className='form-group row justify-content-end py-6 px-9'>
              <label className='form-label fw-bold fs-6 col-form-label col-lg-2 text-right col-sm-12'>
                Paid Amount
              </label>
              <div className='col-lg-2 col-md-10 col-sm-12'>
                <Field
                  type='text'
                  className='form-control'
                  name='paidAmount'
                  value={values.purchaseDetail
                    .reduce(
                      (total, purchaseDetail) =>
                        total +
                        ((parseFloat(purchaseDetail.quantity) || 0) *
                          (parseFloat(purchaseDetail.amount) || 0) -
                          (parseFloat(purchaseDetail.discountValue) || 0) +
                          (parseFloat(purchaseDetail.taxValue) || 0)),
                      0
                    )
                    .toFixed(2)}
                  onChange={(e: any) => {
                    // Handle changes to the "Paid Amount" field here
                    const newValue = parseFloat(e.target.value) || 0
                    setFieldValue('paidAmount', newValue)
                  }}
                />
              </div>
            </div>
            {/* payment type add */}
            <div className='row mb-6 card-footer d-flex justify-content'>
              <div className='table-container'>
                <FieldArray
                  name='paymentHistory'
                  render={(arrayHelpers) => {
                    return (
                      <div>
                        {values.paymentHistory && values.paymentHistory.length > 0
                          ? values.paymentHistory.map((paymentHistory, index) => (
                              <div key={index} className='row mb-2 position-relative addon-group'>
                                <div className='col-md-2 mb-2 mb-md-0'>
                                  <label className='form-label required fw-bold fs-6'>
                                    Payment Type
                                  </label>
                                  <Field
                                    as='select'
                                    name={`paymentHistory[${index}].coaId`}
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Payment Type'
                                  >
                                    <option value=''>Select Payment Type</option>
                                    {payment.map((item: any, i: any) => {
                                      return (
                                        <option key={i} value={item.id}>
                                          {item.headName}
                                        </option>
                                      )
                                    })}
                                  </Field>

                                  <ErrorMessage
                                    className='fieldError'
                                    name={`paymentHistory[${index}].coaId`}
                                    render={(msg) => (
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{msg}</div>
                                      </div>
                                    )}
                                  />
                                </div>
                                <div className='col-md-2 mb-2 mb-md-0'>
                                  <label className='form-label fw-bold fs-6'>Paid Amount</label>
                                  <Field
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Paid Amount'
                                    name={`paymentHistory[${index}].amount`}
                                  />
                                  <ErrorMessage
                                    className='fieldError'
                                    name={`paymentHistory[${index}].amount`}
                                    render={(msg) => <div className='fieldError'>{msg}</div>}
                                  />
                                </div>{' '}
                                {index === 0 ? (
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label mt-14'></label>
                                    <Link to='#' className='add-btn'>
                                      <div
                                        onClick={() =>
                                          arrayHelpers.push({
                                            coaId: '',
                                            amount: '',
                                          })
                                        }
                                        className='btn btn-primary btn-sm'
                                      >
                                        Add Payment
                                      </div>
                                    </Link>
                                  </div>
                                ) : (
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label mt-14'></label>
                                    <Link to='#' className='add-btn'>
                                      <div
                                        onClick={() => arrayHelpers.remove(index)}
                                        className='btn btn-danger btn-sm'
                                      >
                                        Remove
                                      </div>{' '}
                                    </Link>
                                  </div>
                                )}
                                <br />
                                <br /> <br />
                                <br /> <br />
                                <hr />
                              </div>
                            ))
                          : null}
                      </div>
                    )
                  }}
                />
              </div>
            </div>
            {/* payment type add */}
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading ? 'Save Changes' : 'Please wait...'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PurchaseDetails
