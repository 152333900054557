import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Attendance, AttendanceQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_ATTENDANCE_URL = `${API_URL}/attendance/add`
const GET_ATTENDANCE_URL = `${API_URL}/attendance/query`
const VIEW_ATTENDANCE_URL = `${API_URL}/attendance/view`
const UPDATE_ATTENDANCE_URL = `${API_URL}/attendance/update`
const DELETE_ATTENDANCE_URL = `${API_URL}/attendance/delete`
const GET_EMPLOYEE_URL = `${API_URL}/custom/get-employee`
const GET_BULK_INSERT_URL = `${API_URL}/attendance/bulk-insert`

//AuthVerify
var AuthVerify = getToken.call(this)

//Attendance LIST
const getAttendance = (query: string): Promise<AttendanceQueryResponse> => {
  return axios
    .get(`${GET_ATTENDANCE_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<AttendanceQueryResponse>) => {
      console.log('getemp==>', response.data)
      return response.data
    })
}

//Attendance ADD
const createAttendance = (data: any): Promise<Attendance | undefined> => {
  console.log('attdata-api', data)
  return axios.post(CREATE_ATTENDANCE_URL, data, getToken()).then((response: any) => response.data)
}

//Attendance VEIW
const viewAttendance = (id: any) => {
  return axios.get(`${VIEW_ATTENDANCE_URL}/${id}`, getToken()).then((response) => {
    console.log('viewemp', response.data)
    return response.data
  })
}

//Attendance UPDATE
const updateAttendance = (data: any, id: any) => {
  console.log('reqData=update--apii', data)
  return axios.put(`${UPDATE_ATTENDANCE_URL}/${id}`, data, getToken()).then((response) => {
    console.log('updatemp--api -res', response.data)
    return response.data
  })
}

//Attendance DELETE
const deleteAttendanceById = (id: any) => {
  return axios.post(`${DELETE_ATTENDANCE_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

//GET Employee
const getEmployee = async () => {
  const res = await axios.get(`${GET_EMPLOYEE_URL}`, getToken())
  console.log('reeee', res)
  return res
}
const bulkCreate = async (data: FormData) => {
  const res = await axios.post(`${GET_BULK_INSERT_URL}`, data, getToken())
  console.log('bulk create-api', res)
  return res
}
export {
  getAttendance,
  createAttendance,
  viewAttendance,
  updateAttendance,
  deleteAttendanceById,
  getEmployee,
  bulkCreate,
}
