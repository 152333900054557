// @ts-nocheck
import {Column} from 'react-table'
import {CustomerActionsCell} from './CustomerActionsCell'
import {CustomerCustomHeader} from './CustomerCustomHeader'
import {Customer} from '../../core/_models'

const CustomerColumns: ReadonlyArray<Column<Customer>> = [
  {
    Header: (props) => (
      <CustomerCustomHeader tableProps={props} title='S.No' className='min-w-55px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <CustomerCustomHeader tableProps={props} title='Customer Name' className='min-w-125px' />
    ),
    accessor: 'name',
  },

  {
    Header: (props) => (
      <CustomerCustomHeader tableProps={props} title='Mobile Number' className='min-w-105px' />
    ),
    accessor: 'mobileNumber',
  },
 

  {
    Header: (props) => (
      <CustomerCustomHeader tableProps={props} title='Email Address' className='min-w-105px' />
    ),
    accessor: 'email',
  },
  {
    Header: (props) => (
      <CustomerCustomHeader tableProps={props} title='Address' className='min-w-125px' />
    ),
    accessor: 'address',
  },
  {
    Header: (props) => (
      <CustomerCustomHeader tableProps={props} title='Zip code' className='min-w-85px' />
    ),
    accessor: 'zipCode',
  },

  {
    Header: (props) => (
      <CustomerCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CustomerActionsCell customer={props.data[props.row.index]} />,
  },
]

export {CustomerColumns}
