import axios, {AxiosResponse} from 'axios'
import {Product, ProductQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_PRODUCT_URL = `${API_URL}product/add`
const GET_PRODUCT_URL = `${API_URL}product/query`
const VIEW_PRODUCT_URL = `${API_URL}product/view`
const UPDATE_PRODUCT_URL = `${API_URL}product/update`
const DELETE_PRODUCT_URL = `${API_URL}product/delete`
const GET_UNIT_URL = `${API_URL}custom/get-unit`
const GET_TAX_URL = `${API_URL}custom/get-tax`
const GET_CATEGORY_URL = `${API_URL}custom/get-product-category`

//AuthVerify
var AuthVerify = getToken.call(this)

//Product LIST
const getProduct = (query: string): Promise<ProductQueryResponse> => {
  return axios
    .post(`${GET_PRODUCT_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<ProductQueryResponse>) => {
      console.log('getProduct==>', response.data)
      return response.data
    })
}

//Product ADD
const createProduct = (data: any): Promise<Product | undefined> => {
  return axios.post(CREATE_PRODUCT_URL, data, getToken()).then((response: any) => response.data)
}

//Product VEIW
const viewProduct = (id: any) => {
  return axios.get(`${VIEW_PRODUCT_URL}/${id}`, getToken()).then((response) => {
    console.log('view-Product', response.data)
    return response.data
  })
}

//Product UPDATE
const updateProduct = (data: any, id: any) => {
  console.log('product=update', data)
  return axios.put(`${UPDATE_PRODUCT_URL}/${id}`, data, getToken()).then((response) => {
    console.log('update-Product', response.data)
    return response.data
  })
}

//Product DELETE
const deleteProductById = (id: any) => {
  return axios.post(`${DELETE_PRODUCT_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}
//GET unit
const getUnit = async () => {
  const res = await axios.get(`${GET_UNIT_URL}`, getToken())
  console.log('UNIT', res)
  return res
}
//GET tax
const getTax = async () => {
  const res = await axios.get(`${GET_TAX_URL}`, getToken())
  console.log('Tax', res)
  return res
}
//GET Category
const getCategory = async () => {
  const res = await axios.get(`${GET_CATEGORY_URL}`, getToken())
  console.log('Category-api', res)
  return res
}
export {getProduct, createProduct, viewProduct, updateProduct, deleteProductById, getUnit,getCategory, getTax}
