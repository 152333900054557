// @ts-nocheck
import {Column} from 'react-table'

import {DepartmentActionsCell} from './DepartmentActionsCell'
import {DepartmentCustomHeader} from './DepartmentCustomHeader'
import {Department} from '../../core/_models'

const departmentColumns: ReadonlyArray<Column<Department>> = [
  {
    Header: (props) => (
      <DepartmentCustomHeader tableProps={props} title='S.No' className='min-w-65px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <DepartmentCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'name',
  },

  {
    Header: (props) => (
      <DepartmentCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <DepartmentActionsCell department={props.data[props.row.index]} />,
  },
]

export {departmentColumns}
