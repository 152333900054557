import React, {FC, useContext, useEffect, useState} from 'react'
import {
  getLedgerAccounts,
  createCOA,
  getChildHead,
  deleteCoaById,
  updateCoa,
} from '../core/_request'
import {useAuth} from '../../../auth'
import {useNavigate} from 'react-router-dom'
import {Modal, Button} from 'react-bootstrap'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'

// TreeNode component
interface TreeNodeProps {
  label: string
  children?: React.ReactNode
  isExpanded?: boolean
  onClick?: () => void
  headCode: string
  headName: string
  parentHeadCode: string
  id: any
  onSelect: (data: {headCode: string; headName: string; parentHeadCode: string; id: any}) => void
}

const TreeNode: React.FC<TreeNodeProps> = ({
  label,
  children,
  isExpanded,
  onClick,
  headCode,
  headName,
  parentHeadCode,
  id,
  onSelect,
}) => {
  const [expanded, setExpanded] = useState(!!isExpanded)
  const {currentUser} = useAuth()
  const toggleExpansion = () => {
    setExpanded(!expanded)
  }

  const handleNodeClick = () => {
    onSelect({headCode, headName, parentHeadCode, id})
    toggleExpansion()
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={onClick ? onClick : handleNodeClick}
      >
        <span
          style={{
            marginRight: '10px',
            padding: '5px',
            display: 'inline-block',
            width: '20px',
          }}
          onClick={handleNodeClick}
        >
          <i
            style={{fontSize: '17px', color: '#d4ac0d'}}
            className={expanded ? 'fa fa-folder-open' : 'fa fa-folder'}
            aria-hidden='true'
          ></i>
        </span>
        {label}
      </div>
      <div
        style={{
          marginLeft: '20px',
          borderLeft: '2px solid rgb(30 30 45 / 29%)',
          paddingLeft: '10px',
          display: expanded ? 'block' : 'none',
        }}
      >
        {children}
      </div>
    </div>
  )
}

const addChartOfAccountsEntry = (data: {
  headCode: string
  headName: string
  parentHeadCode: string
}) => {
  return createCOA(data)
}

const AddLedgerAccounts: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [treeData, setTreeData] = useState([])
  const [selectedNode, setSelectedNode] = useState({
    headCode: '',
    headName: '',
    parentHeadCode: '',
    id: '',
  })
  const [isSubmitMode, setIsSubmitMode] = useState(false)

  useEffect(() => {
    getLedgerAccounts().then((res: any) => {
      setTreeData(res?.data)
    })
  }, [])

  const handleNodeSelect = (data: {
    headCode: string
    headName: string
    parentHeadCode: string
    id: any
  }) => {
    setSelectedNode(data)
    setIsSubmitMode(false)
  }

  const handleAddButtonClick = () => {
    // Get the selected node's children
    getChildHead(selectedNode.headCode)
      .then((res: any) => {
        const existingHeadCodes = res?.data.map((item: any) => item.headCode)
        console.log('existingHeadCodes:', existingHeadCodes)
        // Log values before adding a new node
        console.log('Values before adding a new node:')
        console.log('headCode:', selectedNode.headCode)
        console.log('headName:', selectedNode.headName)
        console.log('parentHeadCode:', selectedNode.parentHeadCode)

        let newHeadCode

        if (existingHeadCodes && existingHeadCodes.length > 0) {
          // If children exist, calculate the next head code
          const nextHeadCode = calculateNextHeadCode(existingHeadCodes)
          console.log('Next Head Code (Auto-incremented):', nextHeadCode)
          newHeadCode = nextHeadCode
        } else {
          // If no children, create a new node with an incremented head code
          newHeadCode = `${selectedNode.headCode}01`
          console.log('New Head Code:', newHeadCode)
        }

        // Update the selected node state
        setSelectedNode({
          headCode: newHeadCode,
          headName: '',
          parentHeadCode: selectedNode.headCode,
          id: selectedNode.id,
        })

        // Switch to submit mode
        setIsSubmitMode(true)
      })
      .catch((error: any) => {
        // Handle error if needed
        console.error('Error fetching child head:', error)
      })
  }

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    console.log('Submitting form with data:', {
      headCode: selectedNode.headCode,
      headName: selectedNode.headName,
      parentHeadCode: selectedNode.parentHeadCode,
    })

    // Add the chart of accounts entry
    addChartOfAccountsEntry({
      headCode: selectedNode.headCode,
      headName: selectedNode.headName,
      parentHeadCode: selectedNode.parentHeadCode,
    })
      .then((response: any) => {
        // Handle the response as needed
        console.log('COA created successfully:', response)

        // Refresh the tree data after creating the new node
        console.log('Refreshing tree data...')
        getLedgerAccounts().then((res: any) => {
          console.log('Tree data after refresh:', res?.data)
          setTreeData(res?.data)
        })

        // Additional actions or state updates if needed

        // Reset the selected node state and switch back to add mode
        console.log('Resetting selected node state...')
        setSelectedNode({
          headCode: '',
          headName: '',
          parentHeadCode: '',
          id: '',
        })
        setIsSubmitMode(false)
      })
      .catch((error: any) => {
        // Handle the error
        console.error('Error creating COA:', error)
        // Additional error handling or state updates if needed
      })
  }
  console.log('selectedNode.id', selectedNode.id)

  const deleteItem = () =>
    deleteCoaById(selectedNode.id).then((res) => {
      console.log('delete', res)
      setShow(false)
      showAlert(res?.message, res?.status)
      navigate('/ledger-accounts')
    })

  const handleShow = () => {
    setShow(true)
  }
  const headName = selectedNode.headName
  const data = {headName}
  const updateItem = () =>
    updateCoa(selectedNode.id, data).then((res) => {
      console.log('update', res)
      setShow(false)
      showAlert(res?.message, res?.status)
      navigate('/ledger-accounts')
    })
  const calculateNextHeadCode = (existingHeadCodes: string[]) => {
    console.log('Existing Head Codes for Auto-increment:', existingHeadCodes)

    const highestNumber = Math.max(...existingHeadCodes.map((code) => parseInt(code.slice(-2), 10)))
    console.log('Highest Number for Auto-increment:', highestNumber)

    const nextNumber = highestNumber + 1
    console.log('Next Number for Auto-increment:', nextNumber)

    const nextHeadCode = nextNumber.toString().padStart(2, '0')
    console.log('Next Head Code (Auto-incremented):', nextHeadCode)

    return `${selectedNode.headCode}${nextHeadCode}`
  }
  const {show, message, type} = useContext(AlertContext)
  const renderTreeNodes = (nodes: any) => {
    if (!nodes || nodes.length === 0) {
      return null
    }
    return nodes.map((node: any) => (
      <TreeNode
        key={node.id}
        label={node.headName}
        headCode={node.headCode}
        headName={node.headName}
        parentHeadCode={node.parentHeadCode}
        id={node.id}
        onSelect={handleNodeSelect}
      >
        {renderTreeNodes(node.category)}
      </TreeNode>
    ))
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Chart Of Account </h3>
        </div>
      </div>

      <div className='card-body border-top p-9'>
        {show && <AlertBox type={type === true ? 'success' : 'error'} message={message} />}
        <div className='row' id='kt_account_profile_details'>
          <div className='col-md-6'>
            {treeData.length > 0 ? (
              <TreeNode
                label='Chart of Account'
                headCode=''
                headName=''
                parentHeadCode='0'
                id='0'
                onSelect={handleNodeSelect}
              >
                {renderTreeNodes(treeData)}
              </TreeNode>
            ) : (
              <p>Loading...</p>
            )}
          </div>
          {/* Design */}
          <div className='col-md-6'>
            <form onSubmit={handleFormSubmit}>
              <label htmlFor='headCode'>Head Code:{selectedNode.parentHeadCode}</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Generated Automatically'
                value={selectedNode.headCode}
                readOnly
                id='headCode'
              />
              <br />
              <label htmlFor='headName'>Head Name:</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Head Name'
                value={selectedNode.headName}
                onChange={(e) => {
                  console.log('Input Value:', e.target.value)
                  setSelectedNode({...selectedNode, headName: e.target.value})
                }}
                id='headName'
              />

              <br />
              <label htmlFor='parentHeadCode'>Parent Head Code:</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Generated from Selection'
                value={selectedNode.parentHeadCode}
                readOnly
                id='parentHeadCode'
              />
              <div className='mt-10'>
                {!isSubmitMode && (
                  <button
                    type='button'
                    className='btn btn-success btn-sm'
                    style={{marginRight: '20px'}}
                    onClick={handleAddButtonClick}
                  >
                    Add
                  </button>
                )}

                {isSubmitMode && (
                  <>
                    <button
                      type='submit'
                      className='btn btn-success btn-sm'
                      style={{marginRight: '10px'}}
                    >
                      Submit
                    </button>
                  </>
                )}
                <>
                  {' '}
                  <button
                    type='button'
                    className='btn btn-primary btn-sm me-3'
                    onClick={updateItem}
                  >
                    Update
                  </button>
                </>
                <>
                  {' '}
                  <button type='button' className='btn btn-danger btn-sm' onClick={handleShow}>
                    Delete
                  </button>
                </>
              </div>
            </form>
          </div>
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete "{selectedNode.headCode}"?</Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                Not Now
              </Button>
              <Button variant='primary' onClick={deleteItem}>
                Yes, Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default AddLedgerAccounts
