// @ts-nocheck
import {Column} from 'react-table'
import {FinancialyearActionsCell} from './FinancialyearActionsCell'
import {FinancialyearCustomHeader} from './FinancialyearCustomHeader'
import {Financialyear} from '../../core/_models'

const FinancialyearColumns: ReadonlyArray<Column<Financialyear>> = [
  {
    Header: (props) => (
      <FinancialyearCustomHeader tableProps={props} title='S.No' className='min-w-55px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <FinancialyearCustomHeader tableProps={props} title='Year' className='min-w-75px' />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <FinancialyearCustomHeader tableProps={props} title='Start Year' className='min-w-105px' />
    ),
    accessor: 'startDate',
  },
  {
    Header: (props) => (
      <FinancialyearCustomHeader tableProps={props} title='End year' className='min-w-105px' />
    ),
    accessor: 'endDate',
  },

  {
    Header: (props) => (
      <FinancialyearCustomHeader
        tableProps={props}
        title='Status'
        className='min-w-125px'
      />
    ),
    accessor: 'financialYearStatus',
    Cell: ({value}) => (
      <button
        style={{
          backgroundColor: value === 0 ? 'lightcoral' : 'lightgreen',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        {value === 0 ? 'Inactive' : 'Active'}
      </button>
    ),
  },
  {
    Header: (props) => (
      <FinancialyearCustomHeader
        tableProps={props}
        title='Close / Open Status'
        className='min-w-125px'
      />
    ),
    accessor: 'isCloseReq',
    Cell: ({value}) => (
      <button
        style={{
          backgroundColor: value === 0 ? 'lightcoral' : 'lightgreen',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        {value === 0 ? 'Open' : 'Closed'}
      </button>
    ),
  },
  {
    Header: (props) => (
      <FinancialyearCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <FinancialyearActionsCell financialyear={props.data[props.row.index]} />,
  },
]

export {FinancialyearColumns}
