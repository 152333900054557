import {ID, Response} from '../../../../../../_metronic/helpers'
export type SalesReturn = {
  id?: ID
  primaryId?: number
  purchaseId: any
  salesReturnId: number
  description: string
  date: any
  total: number
  salesReturnDetail: [
    {
      productId: number
      amount: any
      total: string
      quantity: string
      createdBy?: number
    }
  ]
  message?: string
  status?: string
}

export type SalesReturnQueryResponse = Response<Array<SalesReturn>>

export const initialSalesReturn: SalesReturn = {
  purchaseId: '',
  salesReturnId: 0,
  description: '',
  date: '',
  total: 0,
  salesReturnDetail: [
    {
      productId: 0,
      amount: '',
      quantity: '',
      total: '',
    },
  ],
}
export type UpdateSalesReturn = {
  id?: ID
  primaryId?: number
  purchaseId: any
  salesReturnId: number
  description: string
  date: any
  total: any
  salesReturnDetail: [
    {
      productId: number
      amount: any
      total: string
      quantity: string
      createdBy?: number
    }
  ]
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateSalesReturnQueryResponse = Response<Array<UpdateSalesReturn>>

export const initialUpdateSalesReturn: UpdateSalesReturn = {
  purchaseId: '',
  salesReturnId: 0,
  description: '',
  date: '',
  total: '',
  salesReturnDetail: [
    {
      productId: 0,
      amount: '',
      total: '',
      quantity: '',
    },
  ],
}
