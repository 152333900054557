import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Salaryadvance, SalaryadvanceQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_Salaryadvance_URL = `${API_URL}salary/salary-advance/add`
const POST_Salaryadvance_URL = `${API_URL}salary/salary-advance/query/`
const VIEW_Salaryadvance_URL = `${API_URL}salary/salary-advance/view/`
const UPDATE_Salaryadvance_URL = `${API_URL}salary/salary-advance/update/`
const DELETE_Salaryadvance_URL = `${API_URL}salary/salary-advance/delete/`
const GET_EMPLOYEE_URL = `${API_URL}custom/get-employee`

//AuthVerify
var AuthVerify = getToken.call(this)

//Attendance LIST
const getSalaryadvance = (query: string): Promise<SalaryadvanceQueryResponse> => {
  return axios
    .post(`${POST_Salaryadvance_URL}?${query}`)
    .then((response: AxiosResponse<SalaryadvanceQueryResponse>) => {
      console.log('getemp==>', response.data)
      return response.data
    })
}
//Salaryadvance ADD
const createSalaryadvance = (data: any): Promise<Salaryadvance | undefined> => {
  return axios
    .post(CREATE_Salaryadvance_URL, data, getToken())
    .then((response: any) => response.data)
}

//Salaryadvance VEIW
const viewSalaryadvance = (id: any) => {
  return axios.get(`${VIEW_Salaryadvance_URL}/${id}`, getToken()).then((response) => {
    console.log('view-salaryad', response.data)
    return response.data
  })
}

//Salaryadvance UPDATE
const updateSalaryadvance = (data: any, id: any) => {
  console.log('reqData=salaryad-update', data)
  return axios.put(`${UPDATE_Salaryadvance_URL}/${id}`, data, getToken()).then((response) => {
    console.log('update-salaryad', response.data)
    return response.data
  })
}

//Salaryadvance DELETE
const deleteSalaryadvanceById = (id: any) => {
  return axios.post(`${DELETE_Salaryadvance_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

//GET Employee
const getEmployee = async () => {
  const res = await axios.get(`${GET_EMPLOYEE_URL}`, getToken())
  console.log('salaryad-get', res)
  return res
}

export {
  getSalaryadvance,
  createSalaryadvance,
  viewSalaryadvance,
  updateSalaryadvance,
  deleteSalaryadvanceById,
  getEmployee,
}
