import {useListView} from '../../core/ListViewProvider'
import {SkuListSearchComponent} from './SkuListSearchComponent'
import {SkuListToolbar} from './SkuListToolbar'

const SkuListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <SkuListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <SkuListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {SkuListHeader}
