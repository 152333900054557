import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {ProductUnitListWrapper} from './unit-list/ProductUnitList'
import {useContext, useEffect} from 'react'
import {AlertContext} from '../../../../_metronic/layout/components/alert-context'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Product Unit Management',
    path: '/unit',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProductUnitPage = () => {
  const {hideAlert} = useContext(AlertContext)
  useEffect(() => {
    hideAlert(false)
  }, [])
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Product Unit</PageTitle>
              <ProductUnitListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default ProductUnitPage
