import React, {useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {SalesOrder, initialSalesOrder as initialValues} from '../sales-order-list/core/_models'
import {useNavigate} from 'react-router-dom'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../sales-order-list/core/QueryResponseProvider'
import {
  createSalesOrder,
  getColor,
  getCustomer,
  getPriority,
  getProduct,
  getSize,
  getUnit,
} from '../sales-order-list/core/_requests'

// Validation schema
const salesOrderDetailsSchema = Yup.object().shape({
  // SalesDate: Yup.string().required('Sales Date is required'),
  salesOrderDetail: Yup.array().of(
    Yup.object().shape({
      // amount: Yup.string().required('Amount is required'),
      // quantity: Yup.string().required('Quantity is required'),
    })
  ),
})
// Define a Product type
interface Product {
  id: number
  productName: string
  styleNumber: string
  price: number
  productcategoryId: number
  tax: {
    taxName: string
    percentage: number
  }
  productcategory: {
    productCategoryName: string
  }
  unit: {
    id: number
    unitName: string
  }
}

const SalesOrderDetails = () => {
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()
  const [customer, setCustomer] = useState([])
  const [product, setProduct] = useState([])
  const [sku, setSku] = useState([])
  const [size, setSize] = useState([])
  const [unit, setUnit] = useState([])
  const [color, setColor] = useState([])
  const [priority, setPriority] = useState([])
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<SalesOrder>(initialValues)
  console.log('initialValues', initialValues)
  const navigate = useNavigate()
  const [productList, setProductList] = useState<Product[]>([])
  const [isCredit, setIsCredit] = useState<boolean>(false) // Initial value can be true or false based on your requirements
  const handleManualClick = () => {
    console.log('Before click - isCredit:', isCredit)
    setIsCredit(!isCredit)
    console.log('After click - isCredit:', isCredit)
  }

  useEffect(() => {
    getCustomer().then((res) => {
      setCustomer(res?.data?.data)
    })
  }, [])

  useEffect(() => {
    getProduct().then((res) => {
      setProduct(res?.data?.data)
      setProductList(res?.data?.data)
    })
  }, [])

  useEffect(() => {
    getSize().then((res) => {
      setSize(res?.data?.data)
    })
  }, [])
  useEffect(() => {
    getColor().then((res) => {
      setColor(res?.data?.data)
    })
  }, [])
  useEffect(() => {
    getUnit().then((res) => {
      setUnit(res?.data?.data)
    })
  }, [])

  useEffect(() => {
    getPriority().then((res) => {
      setPriority(res?.data?.data)
    })
  }, [])
  return (
    <div className='card mb-5 mb-xl-10'>
   <Formik
  initialValues={initialValues}
  enableReinitialize={true}
  validationSchema={salesOrderDetailsSchema}
  onSubmit={(values, { resetForm }) => {
    // Calculate the Grand Total, Tax, and Discount, Quantity, Paid Amount
    const totalTax = values.salesOrderDetail
      .reduce((total, detail) => total + parseFloat(String(detail.taxValue)), 0)
      .toFixed(2);
    const totalQuantity = values.salesOrderDetail.reduce(
      (total, detail) => total + parseFloat(String(detail.quantity)), // Convert quantity to a number
      0
    );
    const totalAmount = values.salesOrderDetail
      .reduce((total, detail) => total + parseFloat(String(detail.total)), 0)
      .toFixed(2);
    const updatedValues = {
      ...values,
      isCredit,
      totalQuantity,
      totalAmount,
      totalTax,
      sales: values.salesOrderDetail,
      createdBy: currentUser?.userId,
    };

    createSalesOrder(updatedValues)
      .then((res) => {
        resetForm({
          ...initialValues,
        });
        setData(initialValues);
        navigate('/sales-order', { replace: true });
        refetch();
        setLoading(false);
        showAlert(res?.message, res?.status);
      })
      .catch((error) => {
        console.error('Error creating sales:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }}
>

        {({values, setFieldValue}) => (
          <Form>
            <div
              className='card-header border-0'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Add Sales Order</h3>
              </div>
            </div>
            <div id='kt_account_profile_details'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Customer </label>
                      <Field
                        as='select'
                        name='customerId'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Customer Name'
                      >
                        <option value=''>Select customer</option>
                        {customer.map((item: any, i: any) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.customerName}
                            </option>
                          )
                        })}
                      </Field>
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Sales Date</label>
                      <Field
                        name='date'
                        type='date'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Date'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='date'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Details</label>
                      <Field
                        as='textarea'
                        name='description'
                        className='form-control form-control-lg form-control-solid'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='description'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <div className='form-group row'>
                        <div className='col-11 col-form-label'>
                          <br />
                          <br />
                          <br />

                          <div className='mb-8'>
                            <div className='form-check form-check-custom form-check-solid form-check-lg'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='isCreditCheckbox'
                                name='isCredit'
                                checked={values.isCredit}
                                onChange={(e) => setFieldValue('isCredit', e.target.checked)}
                              />

                              <label className='fw-bold ps-2 fs-6' htmlFor='flexCheckboxLg'>
                                Is Credit
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <ErrorMessage
                        className='fieldError'
                        name='isCredit'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='row'></div>
              </div>
              <div className='row mb-6 card-footer d-flex justify-content'>
                <div className='table-container'>
                  <FieldArray
                    name='salesOrderDetail'
                    render={(arrayHelpers) => {
                      console.log('arrayHelpers', values, arrayHelpers)
                      return (
                        <div>
                          {values.salesOrderDetail && values.salesOrderDetail.length > 0
                            ? values.salesOrderDetail.map((salesOrderDetail, index) => (
                                <div key={index} className='row mb-2 position-relative addon-group'>
                                  {/* {selectedSalesType === 'Product' && ( */}
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>
                                      Product
                                    </label>

                                    <Field
                                      as='select'
                                      name={`salesOrderDetail[${index}].productId`}
                                      className='form-control form-control-lg form-control-solid'
                                      onChange={(e: any) => {
                                        const selectedProductId = e.target.value
                                        const selectedProductData = productList.find(
                                          (item: any) => item.id === parseInt(selectedProductId, 10)
                                        )

                                        if (selectedProductData) {
                                          const styleNumber = selectedProductData.styleNumber
                                          const amount = selectedProductData.price || 0
                                          const productcategoryId =
                                            selectedProductData.productcategory?.productCategoryName
                                          const unitId = selectedProductData.unit?.unitName
                                          const taxPercentage =
                                            selectedProductData.tax?.percentage || 0

                                          // Update form values
                                          setFieldValue(
                                            `salesOrderDetail[${index}].productId`,
                                            selectedProductId
                                          )
                                          setFieldValue(
                                            `salesOrderDetail[${index}].styleNumber`,
                                            styleNumber
                                          )
                                          setFieldValue(
                                            `salesOrderDetail[${index}].productcategoryId`,
                                            productcategoryId
                                          )
                                          setFieldValue(`salesOrderDetail[${index}].unitId`, unitId)
                                          setFieldValue(
                                            `salesOrderDetail[${index}].amount`,
                                            amount.toString()
                                          )
                                          setFieldValue(
                                            `salesOrderDetail[${index}].taxId`,
                                            taxPercentage.toString()
                                          )

                                          // Recalculate tax value and  value based on the new amount and tax
                                          const quantity = values.salesOrderDetail[index].quantity

                                          const taxValue = (amount * taxPercentage) / 100
                                          const newTotal = (amount * quantity + taxValue).toFixed(2)

                                          // Update form values
                                          setFieldValue(
                                            `salesOrderDetail[${index}].taxValue`,
                                            taxValue.toFixed(2)
                                          )

                                          setFieldValue(
                                            `salesOrderDetail[${index}].total`,
                                            newTotal
                                          )
                                        } else {
                                          // Update form values when no product is selected
                                          setFieldValue(`salesOrderDetail[${index}].productId`, '')
                                          setFieldValue(
                                            `salesOrderDetail[${index}].styleNumber`,
                                            ''
                                          )
                                          setFieldValue(`salesOrderDetail[${index}].unitId`, '')
                                          setFieldValue(
                                            `salesOrderDetail[${index}].productcategoryId`,
                                            ''
                                          )
                                          setFieldValue(`salesOrderDetail[${index}].amount`, '')
                                          setFieldValue(`salesOrderDetail[${index}].taxId`, '')
                                          setFieldValue(`salesOrderDetail[${index}].taxValue`, '')
                                          setFieldValue(`salesOrderDetail[${index}].total`, '')
                                        }
                                      }}
                                    >
                                      <option value=''>Select Product</option>
                                      {product.map((item: any, i: any) => (
                                        <option key={i} value={item.id}>
                                          {item.productName} - {item.styleNumber}
                                        </option>
                                      ))}
                                    </Field>

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`salesOrderDetail[${index}].productId`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>Style Number</label>
                                    <Field
                                      type='text'
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Style Number'
                                      name={`salesOrderDetail[${index}].styleNumber`}
                                    />
                                    <ErrorMessage
                                      className='fieldError'
                                      name={`salesOrderDetail[${index}].styleNumber`}
                                      render={(msg) => <div className='fieldError'>{msg}</div>}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>
                                      Color
                                    </label>

                                    <Field
                                      as='select'
                                      name={`salesOrderDetail[${index}].colorId`}
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Color'
                                    >
                                      <option value=''>Select Color</option>
                                      {color.map((item: any, i: any) => {
                                        return (
                                          <option key={i} value={item.id}>
                                            {item.colorName}
                                          </option>
                                        )
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`salesOrderDetail[${index}].colorId`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>Size</label>
                                    <Field
                                      as='select'
                                      name={`salesOrderDetail[${index}].sizeId`}
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Size'
                                    >
                                      <option value=''>Select Size</option>
                                      {size.map((item: any, i: any) => (
                                        <option key={i} value={item.id}>
                                          {item.sizeName}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      className='fieldError'
                                      name={`salesOrderDetail[${index}].sizeId`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>
                                      Priority
                                    </label>

                                    <Field
                                      as='select'
                                      name={`salesOrderDetail[${index}].priorityId`}
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Priority'
                                    >
                                      <option value=''>Select Priority</option>
                                      {priority.map((item: any, i: any) => {
                                        return (
                                          <option key={i} value={item.id}>
                                            {item.priorityName}
                                          </option>
                                        )
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`salesOrderDetail[${index}].priorityId`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>Category</label>

                                    <Field
                                      type='text'
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Type'
                                      name={`salesOrderDetail[${index}].productcategoryId`}
                                    />

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`salesOrderDetail[${index}].productcategoryId`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>Quantity</label>
                                    <Field
                                      type='text'
                                      name={`salesOrderDetail[${index}].quantity`}
                                      className='form-control form-control-lg form-control-solid'
                                      onChange={(e: any) => {
                                        const newQuantity = parseFloat(e.target.value) || 0
                                        const selectedProductData = productList.find(
                                          (item: any) =>
                                            item.id ===
                                            parseInt(
                                              values.salesOrderDetail[index].productId.toString(),
                                              10
                                            )
                                        )
                                        if (selectedProductData) {
                                          const amount = selectedProductData.price || 0
                                          const taxPercentage =
                                            selectedProductData.tax?.percentage || 0

                                          const taxValue =
                                            (amount * taxPercentage * newQuantity) / 100
                                          const newTotal = (
                                            amount * newQuantity +
                                            taxValue
                                          ).toFixed(2)

                                          // Update form values
                                          setFieldValue(
                                            `salesOrderDetail[${index}].quantity`,
                                            newQuantity.toString()
                                          )
                                          setFieldValue(
                                            `salesOrderDetail[${index}].taxValue`,
                                            taxValue.toString()
                                          )
                                          setFieldValue(
                                            `salesOrderDetail[${index}].total`,
                                            newTotal.toString()
                                          )
                                        }
                                      }}
                                    />

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`salesOrderDetail[${index}].quantity`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>Unit</label>

                                    <Field
                                      type='text'
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Unit'
                                      name={`salesOrderDetail[${index}].unitId`}
                                    />

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`salesOrderDetail[${index}].unitId`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>
                                      Amount
                                    </label>

                                    <Field
                                      type='text'
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Amount'
                                      name={`salesOrderDetail[${index}].amount`}
                                      onChange={(e: any) => {
                                        // Calculate new values based on changes in 'amount'
                                        const newAmount = parseFloat(e.target.value)
                                        const quantity = values.salesOrderDetail[index].quantity

                                        const tax = values.salesOrderDetail[index].taxId
                                        const originalTotal = values.salesOrderDetail[index].total
                                        const taxValue = (newAmount * tax) / 100
                                        const newTotal = (
                                          originalTotal +
                                          newAmount -
                                          values.salesOrderDetail[index].amount
                                        ).toFixed(2)

                                        // Update form values
                                        setFieldValue(
                                          `salesOrderDetail[${index}].amount`,
                                          e.target.value
                                        )
                                        setFieldValue(`salesOrderDetail[${index}].total`, newTotal)

                                        setFieldValue(
                                          `salesOrderDetail[${index}].taxValue`,
                                          taxValue.toFixed(2)
                                        )
                                      }}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>Tax %</label>

                                    <Field
                                      type='text'
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Tax %'
                                      name={`salesOrderDetail[${index}].taxId`}
                                      onChange={(e: any) => {
                                        const amount = values.salesOrderDetail[index].amount
                                        const quantity = values.salesOrderDetail[index].quantity

                                        const taxId = e.target.value
                                        const taxValue = (amount * taxId) / 100
                                        const newTotal = (amount * quantity - taxValue).toFixed(2)

                                        // Update tax, , and total in the form values
                                        setFieldValue(
                                          `salesOrderDetail[${index}].taxId`,
                                          e.target.value
                                        )

                                        setFieldValue(
                                          `salesOrderDetail[${index}].taxValue`,
                                          taxValue.toFixed(2)
                                        )
                                        setFieldValue(`salesOrderDetail[${index}].total`, newTotal)
                                      }}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>Tax Value</label>
                                    <Field
                                      type='text'
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Tax Value'
                                      name={`salesOrderDetail[${index}].taxValue`}
                                      readOnly
                                    />
                                    <ErrorMessage
                                      className='fieldError'
                                      name={`salesOrderDetail[${index}].taxValue`}
                                      render={(msg) => <div className='fieldError'>{msg}</div>}
                                    />
                                  </div>
                                  <div className='col-md-2 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>Total</label>
                                    <Field
                                      type='text'
                                      name={`salesOrderDetail[${index}].total`}
                                      className='form-control form-control-lg form-control-solid'
                                      onChange={(e: any) => {
                                        const newTotal = parseFloat(e.target.value) || 0
                                        const quantity = values.salesOrderDetail[index].quantity
                                        const amount = values.salesOrderDetail[index].amount
                                        const taxId = values.salesOrderDetail[index].taxId
                                        const taxValue = (amount * taxId) / 100

                                        // Calculate the new amount based on the new total
                                        const newAmount = (newTotal - taxValue) / quantity

                                        // Update form values
                                        setFieldValue(
                                          `salesOrderDetail[${index}].amount`,
                                          newAmount.toFixed(2)
                                        )

                                        setFieldValue(
                                          `salesOrderDetail[${index}].taxValue`,
                                          taxValue.toFixed(2)
                                        )
                                        setFieldValue(
                                          `salesOrderDetail[${index}].total`,
                                          newTotal.toFixed(2)
                                        )
                                      }}
                                    />

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`salesOrderDetail[${index}].total`}
                                      render={(msg) => <div className='fieldError'>{msg}</div>}
                                    />
                                  </div>
                                  {index === 0 ? (
                                    <div className='col-md-2 mb-2 mb-md-0'>
                                      <label className='form-label mt-14'></label>
                                      <Link to='#' className='add-btn'>
                                        <div
                                          onClick={() =>
                                            arrayHelpers.push({
                                              productId: 0,
                                              styleNumber: '',
                                              colorId: 0,
                                              sizeId: 0,
                                              unitId: 0,
                                              amount: 0,
                                              taxId: 0,
                                              taxValue: 0,
                                              total: 0,
                                              quantity: 0,
                                              productcategoryId: 0,
                                              orderstatusId: 0,
                                              priorityId: 0,
                                            })
                                          }
                                          className='btn btn-primary btn-sm'
                                        >
                                          Add Order
                                        </div>
                                      </Link>
                                    </div>
                                  ) : (
                                    <div className='col-md-2 mb-2 mb-md-0'>
                                      <label className='form-label mt-14'></label>
                                      <Link to='#' className='add-btn'>
                                        <div
                                          onClick={() => arrayHelpers.remove(index)}
                                          className='btn btn-danger btn-sm'
                                        >
                                          Remove
                                        </div>{' '}
                                      </Link>
                                    </div>
                                  )}
                                  <br />
                                  <br /> <br />
                                  <br /> <br />
                                  <hr />
                                </div>
                              ))
                            : null}
                        </div>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Total Calculations fields */}
            <div className='form-group row justify-content-end py-6 px-9'>
              <label className='form-label fw-bold fs-6 col-form-label col-lg-2 text-right col-sm-12'>
                Total Quantity
              </label>
              <div className='col-lg-2 col-md-10 col-sm-12'>
                <Field
                  type='text'
                  className='form-control'
                  name='totalQuantity'
                  value={values.salesOrderDetail.reduce(
                    (total, detail) => total + parseFloat(String(detail.quantity)),
                    0
                  ).toString()}
                  
                  
                  readOnly
                />
              </div>
            </div>

            <div className='form-group row justify-content-end py-6 px-9'>
              <label className='form-label fw-bold fs-6 col-form-label col-lg-2 text-right col-sm-12'>
                Total Tax
              </label>
              <div className='col-lg-2 col-md-10 col-sm-12'>
                <Field
                  type='text'
                  className='form-control'
                  name='tax'
                  value={values.salesOrderDetail
                    .reduce(
                      (total, salesOrderDetail) => total + parseFloat(String(salesOrderDetail.taxValue)),
                      0
                    )
                    .toFixed(2)}
                  readOnly
                />
              </div>
            </div>

            <div className='form-group row justify-content-end py-6 px-9'>
              <label className='form-label fw-bold fs-6 col-form-label col-lg-2 text-right col-sm-12'>
                Total Amount
              </label>
              <div className='col-lg-2 col-md-10 col-sm-12'>
                <Field
                  type='text'
                  className='form-control'
                  name='totalAmount'
                  value={values.salesOrderDetail
                    .reduce(
                      (total, salesOrderDetail) => total + parseFloat(String(salesOrderDetail.total)),
                      0
                    )
                    .toFixed(2)}
                  readOnly
                />
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading ? 'Save Changes' : 'Please wait...'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
export default SalesOrderDetails
