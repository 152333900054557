import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewAttendance} from '../attendance-list/core/_requests'
import {EditAttendance} from '../attendance-form/EditAttendance'

export const EditAttendanceWrapper = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewAttendance(id).then((res) => {
      setData(res.data)
      console.log('viewAttendanceDetail', res.data)
    })
  }, [])
  if (data) {
    return <EditAttendance initialValues={data} />
  }
  return null
}
