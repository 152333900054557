import axios, {AxiosResponse} from 'axios'

import {Financialyear, FinancialyearQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_FINANCIALYEAR_URL = `${API_URL}financial-year/add`
const GET_FINANCIALYEAR_URL = `${API_URL}financial-year/query`
const VIEW_FINANCIALYEAR_URL = `${API_URL}financial-year/view`
const UPDATE_FINANCIALYEAR_URL = `${API_URL}financial-year/update`
const DELETE_FINANCIALYEAR_URL = `${API_URL}financial-year/delete`
const ADD_CLOSE_FINANCIALYEAR_URL = `${API_URL}financial-year/closing-financial-year`
const GET_YEAR_FINANCIALYEAR_URL = `${API_URL}custom/get-financial-year`

//AuthVerify
var AuthVerify = getToken.call(this)

//Financialyear LIST
const getFinancialyear = (query: string): Promise<FinancialyearQueryResponse> => {
  return axios
    .post(`${GET_FINANCIALYEAR_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<FinancialyearQueryResponse>) => {
      console.log('get-Financialyear==>', response.data)
      return response.data
    })
}

//Financialyear ADD
const createFinancialyear = (data: any): Promise<Financialyear | undefined> => {
  return axios
    .post(CREATE_FINANCIALYEAR_URL, data, getToken())
    .then((response: any) => response.data)
}

//Financialyear VEIW
const viewFinancialyear = (id: any) => {
  return axios.get(`${VIEW_FINANCIALYEAR_URL}/${id}`, getToken()).then((response) => {
    console.log('view-Financialyear', response.data)
    return response.data
  })
}

//Financialyear UPDATE
const updateFinancialyear = (data: any, id: any) => {
  console.log('reqData=update-Financialyear', data)
  return axios.put(`${UPDATE_FINANCIALYEAR_URL}/${id}`, data, getToken()).then((response) => {
    console.log('updatemp-Financialyear', response.data)
    return response.data
  })
}

//Financialyear DELETE
const deleteFinancialyearById = (id: any) => {
  return axios.post(`${DELETE_FINANCIALYEAR_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}
//Close Financialyear ADD
const createCloseFinancialyear = (data: any): Promise<Financialyear | undefined> => {
  return axios
    .post(ADD_CLOSE_FINANCIALYEAR_URL, data, getToken())
    .then((response: any) => response.data)
}
//GET Close Financial Year

const getCloseFinancialYear = async () => {
  const res = await axios.get(`${GET_YEAR_FINANCIALYEAR_URL}`, getToken())
  console.log('getCloseFinancialYear-reeee', res)
  return res
}
export {
  getFinancialyear,
  createFinancialyear,
  viewFinancialyear,
  updateFinancialyear,
  deleteFinancialyearById,
  createCloseFinancialyear,
  getCloseFinancialYear,
}
