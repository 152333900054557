// @ts-nocheck
import {Column} from 'react-table'

import {ProductUnitActionsCell} from './ProductUnitActionsCell'
import {ProductUnitCustomHeader} from './ProductUnitCustomHeader'
import {ProductUnit} from '../../core/_models'

const productUnitColumns: ReadonlyArray<Column<ProductUnit>> = [
  {
    Header: (props) => (
      <ProductUnitCustomHeader tableProps={props} title='S.No' className='min-w-55px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <ProductUnitCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'name',
  },

  {
    Header: (props) => (
      <ProductUnitCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <ProductUnitActionsCell productUnit={props.data[props.row.index]} />,
  },
]

export {productUnitColumns}
