import React, {useState, useContext, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createProductUnit} from '../unit-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../unit-list/core/QueryResponseProvider'
import {setRoleAccess} from '../../role-access/role-setting-list/core/_requests'
import {ToastContainer, toast} from 'react-toastify'

//INTERFACE FOR ProductUnit
export interface ProductUnitDetail {
  name: string
  branchId: number | undefined
}

//INITIAL VALUES FOR ProductUnit
export let initialValues: ProductUnitDetail = {
  name: '',
  branchId: 0,
}

//VALIDATION USING YUP LIBRARY
const productUnitDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Product Unit is required'),
})

//FUNCTION START
const ProductUnitDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<ProductUnitDetail>(initialValues)
  const navigate = useNavigate()
  console.log('deparment-details-data=>', data)
  //CALL API FOR PAGE ACCESS USING ROLE ID AND MODULE ID
  const {currentUser} = useAuth()
  const [roleAccesses, setRoleAccesses] = useState<any>([])
  useEffect(() => {
    setRoleAccess(currentUser?.rolesId, 2).then((res: any) => {
      var resultRoleAccess = res.data?.Data[0]
      setRoleAccesses(resultRoleAccess)
    })
  }, [])

  const [loading, setLoading] = useState(false)
  const formik = useFormik<ProductUnitDetail>({
    initialValues,
    validationSchema: productUnitDetailsSchema,
    onSubmit: (values, {resetForm}) => {
      
        setLoading(true)
        const updatedData = Object.assign(data, values)
        updatedData.branchId = currentUser?.branchId
        createProductUnit(updatedData).then((res: any) => {
          initialValues = {
            name: '',
            branchId: 0,
          }
          resetForm({
            ...initialValues,
            values: {...initialValues},
          })
          setData(initialValues)
          navigate('/unit', {replace: true})
          refetch()
          setLoading(false)
          showAlert(res?.message, res?.status)
        })
      
    },
    enableReinitialize: true,
  })
  console.log('dep-formik', formik)
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Product Unit </h3>
        </div>
      </div>
      <ToastContainer />
      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/** Company Info */}
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Product Unit </label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Product Unit'
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProductUnitDetails
