import axios, {AxiosResponse} from 'axios'
import {Supplier, SupplierQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_SUPPLIER_URL = `${API_URL}supplier/add`
const GET_SUPPLIER_URL = `${API_URL}supplier/query/`
const VIEW_SUPPLIER_URL = `${API_URL}supplier/view/`
const UPDATE_SUPPLIER_URL = `${API_URL}supplier/update`
const DELETE_SUPPLIER_URL = `${API_URL}supplier/delete`

//AuthVerify
var AuthVerify = getToken.call(this)

//Supplier LIST
const getSupplier = (query: string): Promise<SupplierQueryResponse> => {
  return axios
    .get(`${GET_SUPPLIER_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<SupplierQueryResponse>) => {
      console.log('getSupplier==>', response.data)
      return response.data
    })
}

//Supplier ADD
const createSupplier = (data: any): Promise<Supplier | undefined> => {
  return axios.post(CREATE_SUPPLIER_URL, data, getToken()).then((response: any) => response.data)
}

//Supplier VEIW
const viewSupplier = (id: any) => {
  return axios.get(`${VIEW_SUPPLIER_URL}/${id}`, getToken()).then((response) => {
    console.log('view-SUPPLIER', response.data)
    return response.data
  })
}

//Supplier UPDATE
const updateSupplier = (data: any, id: any) => {
  console.log('reqData=update', data)
  return axios.put(`${UPDATE_SUPPLIER_URL}/${id}`, data, getToken()).then((response) => {
    console.log('update-SUPPLIER', response.data)
    return response.data
  })
}

//Supplier DELETE
const deleteSupplierById = (id: any) => {
  return axios.post(`${DELETE_SUPPLIER_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

export {getSupplier, createSupplier, viewSupplier, updateSupplier, deleteSupplierById}
