import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {CustomerListWrapper} from './customer-list/CustomerList'
import {AddCustomer} from './customer-form'
import {EditCustomerWrapper} from './customer-form/EditCustomerWrapper'

const CustomerBreadcrumbs: Array<PageLink> = [
  {
    title: 'Customer Management',
    path: '/customer',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CustomerPage = () => {
  const navigate = useNavigate()
  function goBack() {
    navigate(-1)
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={CustomerBreadcrumbs}>Customer</PageTitle>
              <CustomerListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
      <Route
        path='/add'
        element={
          <>
            <PageTitle breadcrumbs={CustomerBreadcrumbs}>Customer Add</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <AddCustomer />
          </>
        }
      />
      <Route
        path='/edit/:id'
        element={
          <>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <PageTitle breadcrumbs={CustomerBreadcrumbs}>Customer Edit</PageTitle>
            <EditCustomerWrapper />
          </>
        }
      />
    </Routes>
  )
}

export default CustomerPage
