import axios from 'axios' // Importing axios library and AxiosResponse type
import {getToken} from '../../../../auth' // Importing getToken function'

// SETTING API URL FROM ENVIRONMENT VARIABLE
const API_URL = process.env.REACT_APP_API_URLS
// CONSTRUCTING ROLE_URL USING API_URL
const ROLE_URL = `${API_URL}/role-access`

//var AuthVerify = getToken.call(this)
// FUNCTION TO GET ALL ROLES BASED ON CLIENTID
const getRole = async (branchId: any) => {
  const res = await axios.get(`${ROLE_URL}/get-role/${branchId}`, getToken())
  return res
}
// FUNCTION TO GET ALL MODULES
const getSubmodules = async () => {
  const res = await axios.get(`${ROLE_URL}/get-submodule`, getToken())
  return res
}

// FUNCTION TO CREATE ACCESS FOR A PARTICULAR ROLE
const createRoleAccess = async (data: any) => {
  const res = await axios.post(`${ROLE_URL}/create-role-access`, {data}, getToken())
  console.log('createroleaccess=>', res)
  return res
}

// FUNCTION TO GET EXISTING PERMISSIONS FOR A ROLE
const getRoleAccess = async (roleId: any) => {
  const res = await axios.put(`${ROLE_URL}/existing-permissions/${roleId}`, getToken())
  console.log('getroleaccess=>', res)
  return res
}

// FUNCTION TO GET ROLE-WISE SIDEBAR MENU
const getrolewisesidemenu = async (roleId: any) => {
  const res = await axios.get(`${ROLE_URL}/sidebar-modules/${roleId}`, getToken())
  return res
}

// FUNCTION TO SET PAGE ACCESS FOR ALL BUTTONS
const setRoleAccess = (rolesId: any, submodulesId: any) => {
  
  return axios
    .post(`${ROLE_URL}/role-access-list`, {rolesId, submodulesId}, getToken())
    .then((response) => {
      console.log("response",response)
      return response
    })
}

// EXPORTING ALL FUNCTIONS TO BE USED IN OTHER COMPONENTS
export {getRole, getSubmodules, createRoleAccess, getRoleAccess, getrolewisesidemenu, setRoleAccess}
