import axios, {AxiosResponse} from 'axios'
import {PaymentMethod, PaymentMethodQueryResponse} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//PaymentMethod API URL
const CREATE_PAYMENTMETHOD_URL = `${API_URL}payment-method/add`
const GET_PAYMENTMETHOD_URL = `${API_URL}payment-method/query`
const UPDATE_PAYMENTMETHOD_URL = `${API_URL}payment-method/update`
const DELETE_PAYMENTMETHOD_URL = `${API_URL}payment-method/delete`
const VIEW_PAYMENTMETHOD_URL = `${API_URL}payment-method/view/`

var AuthVerify = getToken.call(this)

//PaymentMethod LIST
const getPaymentMethod = (query: string, type: any): Promise<PaymentMethodQueryResponse> => {
  return axios
    .post(`${GET_PAYMENTMETHOD_URL}?${query}`, {type}, AuthVerify)
    .then((response: AxiosResponse<PaymentMethodQueryResponse>) => {
      console.log('PaymentMethod-LIST', response.data)
      return response.data
    })
}

//PaymentMethod ADD
const createPaymentMethod = (data: PaymentMethod): Promise<PaymentMethod | undefined> => {
  console.log('PaymentMethod-add', data)
  return axios
    .post(CREATE_PAYMENTMETHOD_URL, data, getToken())
    .then((response: any) => response.data)
}

//PaymentMethod Update
const updatePaymentMethod = (data: any, id: any) => {
  console.log('PaymentMethod-update', data)
  return axios.put(`${UPDATE_PAYMENTMETHOD_URL}/${id}`, data, getToken()).then((response) => {
    return response.data
  })
}
//PaymentMethod VEIW
const viewPaymentMethod = (id: any) => {
  return axios.get(`${VIEW_PAYMENTMETHOD_URL}/${id}`, getToken()).then((response) => {
    console.log('view-PaymentMethod', response.data)
    return response.data
  })
}

//PaymentMethod DELETE
const deletePaymentMethodById = (id: any) => {
  return axios.post(`${DELETE_PAYMENTMETHOD_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

export {
  getPaymentMethod,
  createPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethodById,
  viewPaymentMethod,
}
