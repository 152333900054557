/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {deleteAttendanceById} from '../../core/_requests'
import {Attendance} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
import {Modal, Button} from 'react-bootstrap'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
import {setRoleAccess} from '../../../../role-access/role-setting-list/core/_requests'
import React from 'react'
import {useAuth} from '../../../../../auth'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type Props = {
  attendance: Attendance
}

const AttendanceActionsCell: FC<Props> = ({attendance}) => {
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)

  const editAttendanceDetail = () => {
    navigate('/attendance/edit/' + attendance.primaryId)
  }

  // const {state, setState} = React.useContext(MyContext)
  //CALL API FOR PAGE ACCESS USING ROLE ID AND MODULE ID
  const {currentUser} = useAuth()
  // const [roleAccesses, setRoleAccesses] = useState<any>([])
  // useEffect(() => {
  //   setRoleAccess(currentUser?.rolesId, 4).then((res: any) => {
  //     var resultRoleAccess = res?.data?.data
  //     setRoleAccesses(resultRoleAccess)
  //   })
  // }, [])
  const deleteItem = () =>
    deleteAttendanceById(attendance.primaryId).then((res) => {
      console.log('delete', res)
      setShow(false)
      showAlert(res?.message, res?.status)
      navigate('/attendance')
    })
  const handleShow = () => {
    setShow(true)
  }

  return (
    <>
      <ToastContainer />
      {/* Edit Button */}
      <div className='d-flex justify-content-end flex-shrink-0'>
        {/* {roleAccesses?.edit === 1 ? ( */}
        <a
          onClick={editAttendanceDetail}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a>
        {/* ) : (
          ''
        )} */}
        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleShow}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete "{attendance.employeeId}"?</Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
    </>
  )
}

export {AttendanceActionsCell}
