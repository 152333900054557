import axios, {AxiosResponse} from 'axios'

import {getToken} from '../../../auth'

const API_URL = process.env.REACT_APP_API_URLS

var AuthVerify = getToken.call(this)

const employeeDropdown = async () => {
  const res = await axios.get(`${API_URL}custom/get-employee`, getToken())

  return res
}

const getReport = async (data: any) => {
  const res = await axios.post(`${API_URL}reports/attendance`, data, getToken())
  return res
}
export {employeeDropdown, getReport}
