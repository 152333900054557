import React, {useContext, useEffect, useState} from 'react'

import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createSalaryadvance, getEmployee} from '../salary-advance-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../salary-advance-list/core/QueryResponseProvider'
import {
  Salaryadvance,
  initialSalaryadvance as initialValues,
} from '../salary-advance-list/core/_models'

//Validation
const SalaryadvanceDetailsSchema = Yup.object().shape({
  employeeId: Yup.string().required('Employee name is required'),
  amount: Yup.string().required('Amount is required'),
  salaryMonth: Yup.string().required('Salary Month is required'),
  releaseDate: Yup.string().required('Release Date is required'),
})

// Function Starts
const SalaryadvanceDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Salaryadvance>(initialValues)
  const navigate = useNavigate()

  // Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()

  const [loading, setLoading] = useState(false)
  // Formik Starts
  const formik = useFormik<Salaryadvance>({
    initialValues: {
      ...initialValues,
      releaseDate: new Date().toISOString().split('T')[0], // Set the current date as the initial value
      salaryMonth: new Date().toISOString().substr(0, 7), // Set the current month and year as the initial value
    },
    validationSchema: SalaryadvanceDetailsSchema,

    // Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      updatedData.createdBy = currentUser?.userId
      createSalaryadvance(updatedData).then((res: any) => {
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/salary-advance', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.message, res?.status)
        console.log('salary-advance-messageadd', res?.status)
      })
    },
    enableReinitialize: true,
  })

  // Get employee using type
  const [employee, setEmployee] = useState<any>([])
  useEffect(() => {
    getEmployee().then((res: any) => {
      setEmployee(res?.data?.data)
    })
  }, [])
  console.log('salary-advance-formik', formik)

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Salary Advance </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Release Date</label>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Release Date'
                    {...formik.getFieldProps('releaseDate')}
                  />
                  {formik.touched.releaseDate && formik.errors.releaseDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.releaseDate}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Employee Name</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Employee Name'
                    {...formik.getFieldProps('employeeId')}
                  >
                    <option value=''>Select Employee Name</option>
                    {employee.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.employeeName}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.employeeId && formik.errors.employeeId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.employeeId}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Amount</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Amount'
                    {...formik.getFieldProps('amount')}
                  />
                  {formik.touched.amount && formik.errors.amount && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.amount}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Salary Month</label>
                  <input
                    type='month' // Use type "month" to display the month and year picker
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Salary Month'
                    {...formik.getFieldProps('salaryMonth')}
                    min='1900-01' // Set a minimum date as needed (January 1900 in this case)
                    max='2100-12' // Set a maximum date as needed (December 2100 in this case)
                  />

                  {formik.touched.salaryMonth && formik.errors.salaryMonth && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.salaryMonth}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SalaryadvanceDetails
