import React, {FC, useContext, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {updateBank} from '../bank-list/core/_requests'
import {UpdateBank} from '../bank-list/core/_models'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'

const bankDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Bank name is required'),
  accountName: Yup.string().required('Account Name is required'),
  accountNumber: Yup.string().required('Account Number is required'),
  branch: Yup.string().required('Branch is required'),
  ifscCode: Yup.string().required('IFSC Code is required'),
})

type Props = {
  initialValues: UpdateBank
}
export const EditBank: FC<Props> = ({initialValues}) => {
  const [data, setData] = useState<UpdateBank>(initialValues)
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {id} = useParams()

  const [loading, setLoading] = useState(false)

  //get type
  const {currentUser} = useAuth()

  const type = currentUser?.branchId
  //end get type
  const formik = useFormik<UpdateBank>({
    initialValues,
    validationSchema: bankDetailsSchema,

    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedBy = currentUser?.userId // Replace 'updatedBy' with the actual key in currentUser
      const updatedData = {...data, ...values, updatedBy}
      updateBank(updatedData, id).then((res) => {
        setData(initialValues)
        navigate('/bank', {replace: true})
        showAlert(res?.message, res?.status)
      })
    },
  })

  console.log('bank-edit', formik)
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Edit Bank </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'></div>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9'>
          <div className='row mb-6'>
            <div className='row'>
              {/* <!-- Bank Name --> */}
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Bank Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Bank Name'
                  id='bankName'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>

              {/* <!-- A/C Name --> */}
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>A/C Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='A/C Name'
                  id='accountName'
                  {...formik.getFieldProps('accountName')}
                />
                {formik.touched.accountName && formik.errors.accountName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.accountName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row'>
              {/* <!-- A/C Number --> */}
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>A/C Number </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='A/C Number'
                  id='accountNumber'
                  {...formik.getFieldProps('accountNumber')}
                />
                {formik.touched.accountNumber && formik.errors.accountNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.accountNumber}</div>
                  </div>
                )}
              </div>

              {/* <!-- Branch --> */}
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Branch</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Branch'
                  id='branch'
                  {...formik.getFieldProps('branch')}
                />
                {formik.touched.branch && formik.errors.branch && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.branch}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row'>
              {/* <!-- Signature Picture --> */}
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>IFSC Code</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  id='signaturePicture'
                  {...formik.getFieldProps('ifscCode')}
                />
                {formik.touched.ifscCode && formik.errors.ifscCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.ifscCode}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Save Changes'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
export default EditBank
