import {ID, Response} from '../../../../../../_metronic/helpers'
export type Salaryadvance = {
  id?: ID
  primaryId?: number
  employeeId?: string
  salaryMonth?: string
  amount?: string
  releaseDate?: string
  message?: string
  status?: string
  createdBy?: number
}

export type SalaryadvanceQueryResponse = Response<Array<Salaryadvance>>

export const initialSalaryadvance: Salaryadvance = {
  employeeId: '',
  salaryMonth: '',
  amount: '',
  releaseDate: '',

  createdBy: 0,
}
export type UpdateSalaryadvance = {
  id?: ID
  primaryId?: number
  employeeId?: string
  salaryMonth?: string
  amount?: string
  releaseDate?: string
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateSalaryadvanceQueryResponse = Response<Array<UpdateSalaryadvance>>

export const initialUpdateSalaryadvance: UpdateSalaryadvance = {
  employeeId: '',
  salaryMonth: '',
  amount: '',
  releaseDate: '',
}
