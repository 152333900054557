import {ID, Response} from '../../../../../../_metronic/helpers'
export type PurchaseReturn = {
  id?: ID
  primaryId?: number
  purchaseId: any
  purchaseReturnId: number
  description: string
  date: any
  total: number
  purchaseReturnDetail: [
    {
      productId: number
      amount: any
      quantity: string
      total: string
      createdBy?: number
    }
  ]
  message?: string
  status?: string
}

export type PurchaseReturnQueryResponse = Response<Array<PurchaseReturn>>

export const initialPurchaseReturn: PurchaseReturn = {
  purchaseId: '',
  purchaseReturnId: 0,
  description: '',
  date: '',
  total: 0,
  purchaseReturnDetail: [
    {
      productId: 0,
      amount: '',
      total: '',
      quantity: '',
    },
  ],
}
export type UpdatePurchaseReturn = {
  id?: ID
  primaryId?: number
  purchaseId: any
  purchaseReturnId: number
  description: string
  date: any
  total: number
  purchaseReturnDetail: [
    {
      productId: number
      amount: any
      quantity: string
      total: string
      createdBy?: number
    }
  ]
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdatePurchaseReturnQueryResponse = Response<Array<UpdatePurchaseReturn>>

export const initialUpdatePurchaseReturn: UpdatePurchaseReturn = {
  purchaseId: '',
  purchaseReturnId: 0,
  description: '',
  date: '',
  total: 0,
  purchaseReturnDetail: [
    {
      productId: 0,
      amount: '',
      total: '',
      quantity: '',
    },
  ],
}
