import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'

const PurchaseReturnListToolbar = () => {
  const navigate = useNavigate()
  const addPurchaseReturnPage = () => {
    navigate('/purchase-return/add')
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={addPurchaseReturnPage}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Purchase Return
      </button>
      {/* end::Add user */}
    </div>
  )
}

export {PurchaseReturnListToolbar}
