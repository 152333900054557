import axios, {AxiosResponse} from 'axios'
import {Purchase, PurchaseQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_PURCHASE_URL = `${API_URL}purchase/add`
const GET_PURCHASE_URL = `${API_URL}purchase/query`
const VIEW_PURCHASE_URL = `${API_URL}purchase/view`
const UPDATE_PURCHASE_URL = `${API_URL}purchase/update`
const DELETE_PURCHASE_URL = `${API_URL}purchase/delete`
const GET_SUPPLIER_URL = `${API_URL}custom/get-supplier`
const GET_PRODUCT_URL = `${API_URL}custom/get-product`
const GET_PAYMENT_URL = `${API_URL}custom/get-payment`
const GET_SKU_URL = `${API_URL}custom/get-sku`
const GET_SIZE_URL = `${API_URL}custom/get-size`
const GET_COLOR_URL = `${API_URL}custom/get-color`
const GET_MATERIAL_URL = `${API_URL}custom/get-material`
const GET_UNIT_URL = `${API_URL}custom/get-unit`
//AuthVerify
var AuthVerify = getToken.call(this)

//Purchase LIST
const getPurchase = (query: string): Promise<PurchaseQueryResponse> => {
  return axios
    .post(`${GET_PURCHASE_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<PurchaseQueryResponse>) => {
      console.log('getPurchase==>', response.data)
      return response.data
    })
}

//Purchase ADD

const createPurchase = (data: any): Promise<Purchase | undefined> => {
  // Make the POST request to create a purchase
  console.log('api - req -add==>', data)
  return axios.post(CREATE_PURCHASE_URL, data, getToken()).then((response: any) => {
    console.log('createPurchase Response:api', response.data) // Moved the console.log here
    return response.data
  })
}

//Purchase VEIW
const viewPurchase = (id: any) => {
  return axios.get(`${VIEW_PURCHASE_URL}/${id}`, getToken()).then((response) => {
    console.log('view-Purchase-api', response.data)
    return response.data
  })
}

//Purchase UPDATE
const updatePurchase = (data: any, id: any) => {
  console.log('Purchase-reqData=update-api', data)
  return axios.put(`${UPDATE_PURCHASE_URL}/${id}`, data, getToken()).then((response) => {
    console.log('update-Purchase-API', response.data)
    return response.data
  })
}

//Purchase DELETE
const deletePurchaseById = (id: any) => {
  return axios.post(`${DELETE_PURCHASE_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}
//GET ledger
const getSupplier = async () => {
  const res = await axios.get(`${GET_SUPPLIER_URL}`, getToken())
  console.log('getSupplier', res)
  return res
}
//GET Product
const getProduct = async () => {
  const res = await axios.get(`${GET_PRODUCT_URL}`, getToken())
  console.log('get-getProduct', res)
  return res
}
//GET Material
const getMaterial = async () => {
  const res = await axios.get(`${GET_MATERIAL_URL}`, getToken())
  console.log('get-Material-api', res)
  return res
}
//GET Payment
const getPayment = async () => {
  const res = await axios.get(`${GET_PAYMENT_URL}`, getToken())
  console.log('getPayment', res)
  return res
}
//GET sku
const getSku = async () => {
  const res = await axios.get(`${GET_SKU_URL}`, getToken())
  console.log('get-sku-api', res)
  return res
}
//GET Size
const getSize = async () => {
  const res = await axios.get(`${GET_SIZE_URL}`, getToken())
  console.log('get-GET_SIZE_URL-api', res)
  return res
}
//GET Size
const getColor = async () => {
  const res = await axios.get(`${GET_COLOR_URL}`, getToken())
  console.log('get-getColor-api', res)
  return res
}
//GET Unit
const getUnit = async () => {
  const res = await axios.get(`${GET_UNIT_URL}`, getToken())
  console.log('get-getUnit-api', res)
  return res
}
export {
  getPurchase,
  createPurchase,
  viewPurchase,
  updatePurchase,
  deletePurchaseById,
  getSupplier,
  getProduct,
  getPayment,getUnit,
  getSku,getSize,getColor,getMaterial,
}
