import {ID, Response} from '../../../../../../_metronic/helpers'
export type Customer = {
  id?: ID
  primaryId?: number
  name?: string
  mobileNumber?: string
  phoneNumber?: string
  email?: string
  address?: string
  zipCode?: string
  fax?: string
  taxId?: string
  message?: string
  status?: string
  createdBy?: number
}

export type CustomerQueryResponse = Response<Array<Customer>>

export const initialCustomer: Customer = {
  name: '',
  email: '',
  phoneNumber: '',
  mobileNumber: '',
  address: '',
  fax: '',
  taxId: '',
  zipCode: '',
  createdBy: 0,
}
export type UpdateCustomer = {
  id?: ID
  primaryId?: number
  name?: string
  email?: string
  phoneNumber?: string
  address?: string
  fax?: string
  taxId?: string
  mobileNumber?: string
  zipCode?: string
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateCustomerQueryResponse = Response<Array<UpdateCustomer>>

export const initialUpdateCustomer: UpdateCustomer = {
  name: '',
  email: '',
  phoneNumber: '',
  mobileNumber: '',
  address: '',
  fax: '',
  taxId: '',
  zipCode: '',
}
