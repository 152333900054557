import {useListView} from '../../core/ListViewProvider'
import {FinancialYearListToolbar} from './FinancialyearListToolbar'
import {FinancialyearListGrouping} from './FinancialyearListGrouping'
import {FinancialyearListSearchComponent} from './FinancialyearListSearchComponent'

const FinancialyearListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <FinancialyearListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <FinancialyearListGrouping /> : <FinancialYearListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {FinancialyearListHeader}
