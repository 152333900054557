import React, {useState, useContext, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createBankreconciliation} from '../bank-reconciliation-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {useQueryResponse} from '../bank-reconciliation-list/core/QueryResponseProvider'

export interface IProfileDetails {
  createdBy: string | undefined
}

export let initialValues: IProfileDetails = {
  createdBy: '',
}
const bankreconciliationDetailsSchema = Yup.object().shape({})

const BankreconciliationDetails: React.FC = () => {
  // const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const navigate = useNavigate()

  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: bankreconciliationDetailsSchema,
    onSubmit: (values, {resetForm}) => {
      setLoading(true)

      const updatedData = Object.assign(data, values)

      //updatedData.createdBy = currentUser?.userId
      // createBankreconciliation (updatedData).then((res: any) => {
      //   console.log('Alertres', res)
      //   initialValues = {
      //     name: '',

      //     createdBy: '',
      //   }
      //   resetForm({
      //     ...initialValues,
      //     values: {...initialValues},
      //   })
      //   setData(initialValues)
      //   navigate('/bank-reconciliation', {replace: true})
      //   refetch()
      //   setLoading(false)
      //   showAlert(res?.Message, res?.Code)
      // })
    },
    enableReinitialize: true,
  })
  // console.log(data?.profileImage+"prof");
  const [Bankreconciliationaccess, setbankreconciliationaccess] = useState<any>([])
  // useEffect(() => {
  //   getExCRUD(currentUser?.BankreconciliationId, 2).then((res: any) => {
  //     var crudres = res.data?.Data[0]
  //     setBankreconciliationaccess(crudres)
  //   })
  // }, [])
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'> Bank Reconciliation </h3>
        </div>
      </div>
      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>From Date</label>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Start Date'
                    id='inchage'
                  />
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>To Date</label>
                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Date'
                    id='inchage'
                  />
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Bank Name</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Bank Name'
                  >
                    <option value=''>Select a Bank Name</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Find'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>{' '}
    </div>
  )
}

export default BankreconciliationDetails
