import React, {FC, useContext, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {getEmployee, updateAttendance} from '../attendance-list/core/_requests'
import {UpdateAttendance} from '../attendance-list/core/_models'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'


const attendanceDetailsSchema = Yup.object().shape({
  employeeId: Yup.string().required('Attendance  name is required'),
  date: Yup.string().required('Date is required'),
  checkIn: Yup.string().required('Signin Time is required'),
  checkout: Yup.string().required('SignOut Time is required'),
})

type Props = {
  initialValues: UpdateAttendance
}
export const EditAttendance: FC<Props> = ({initialValues}) => {
  const [data, setData] = useState<UpdateAttendance>(initialValues)
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {id} = useParams()
  const [employee, setEmployee] = useState<any>([])

  const [loading, setLoading] = useState(false)

  //get type
  const {currentUser} = useAuth()

  const type = currentUser?.branchId
  //end get type
  const formik = useFormik<UpdateAttendance>({
    initialValues,
    validationSchema: attendanceDetailsSchema,

    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedBy = currentUser?.userId // Replace 'updatedBy' with the actual key in currentUser
      const updatedData = {...data, ...values, updatedBy}
      updateAttendance(updatedData, id).then((res) => {
        setData(initialValues)
        navigate('/attendance', {replace: true})
        showAlert(res?.message, res?.status)
      })
    },
  })
  useEffect(() => {
    getEmployee().then((res: any) => {
      setEmployee(res?.data?.data)
    })
  }, [])
  console.log('for', formik)
  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='card mb-5 mb-xl-10 p-9'>
        <div className='row mb-6'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <label className='col-form-label required fw-bold fs-6'>Employee Name</label>
              <select
                className='form-control form-control-lg form-control-solid'
                placeholder='Employee Name'
                {...formik.getFieldProps('employeeId')}
              >
                <option value=''>Select Employee Name</option>
                {employee.map((item: any, i: any) => {
                  return (
                    <option key={i} value={item.id}>
                      {item.employeeName}
                    </option>
                  )
                })}
              </select>
              {formik.touched.employeeId && formik.errors.employeeId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.employeeId}</div>
                </div>
              )}
            </div>
            <div className='col-lg-6 fv-row'>
              <label className='col-form-label required fw-bold fs-6'>Date</label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Date'
                id='date'
                {...formik.getFieldProps('date')}
              />
              {formik.touched.date && formik.errors.date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'></div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row mb-6'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <label className='col-form-label required fw-bold fs-6'>Check In</label>
              <input
                type='time'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Signin'
                id='checkIn'
                {...formik.getFieldProps('checkIn')}
              />
              {formik.touched.checkIn && formik.errors.checkIn && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'></div>
                </div>
              )}
            </div>
            <div className='col-lg-6 fv-row'>
              <label className='col-form-label required fw-bold fs-6'>Check Out</label>
              <input
                type='time'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Check out Time'
                id='checkIn'
                {...formik.getFieldProps('checkout')}
              />
              {formik.touched.checkout && formik.errors.checkout && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'></div>
                </div>
              )}
            </div>

          </div>
          <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>OT Hours</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter OT Hours'
                    id='otHours'
                    {...formik.getFieldProps('otHours')}
                  />
                  {formik.touched.otHours && formik.errors.otHours && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'></div>
                    </div>
                  )}
                </div>
                {/* <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>OT Amount</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter OT Amount'
                    id='otAmount'
                    {...formik.getFieldProps('otAmount')}
                  />
                  {formik.touched.otAmount && formik.errors.otAmount && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'></div>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button type='submit' className='btn btn-primary' disabled={loading}>
          {!loading && 'Save Changes'}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
export default EditAttendance
