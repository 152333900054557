import {ID, Response} from '../../../../../../_metronic/helpers'
export type Bank = {
  id?: ID
  primaryId?: number
  accountName?: string
  name?: string
  accountNumber?: string
  branch?: string
  ifscCode?: string
  message?: string
  status?: string
  createdBy?: number
}

export type BankQueryResponse = Response<Array<Bank>>

export const initialBank: Bank = {
  accountName: '',
  name: '',
  accountNumber: '',
  branch: '',
  ifscCode: '',
  createdBy: 0,
}
export type UpdateBank = {
  id?: ID
  primaryId?: number
  accountName?: string
  name?: string
  accountNumber?: string
  branch?: string
  ifscCode?: string
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateBankQueryResponse = Response<Array<UpdateBank>>

export const initialUpdateBank: UpdateBank = {
  accountName: '',
  name: '',
  accountNumber: '',
  branch: '',
  ifscCode: '',
}
