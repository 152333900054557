import {ID, Response} from '../../../../../../_metronic/helpers'
export type Priority = {
  id?: ID
  primaryId?: number
  name?: string
 
 
  message?: string
  status?: string
  createdBy?: number
}

export type PriorityQueryResponse = Response<Array<Priority>>

export const initialPriority: Priority = {
  name: '',

  
  createdBy: 0,
}
export type UpdatePriority = {
  id?: ID
  primaryId?: number
  name?: string
 
 
  status?: string
  updatedBy?: number
}

export type UpdatePriorityQueryResponse = Response<Array<UpdatePriority>>

export const initialUpdatePriority: UpdatePriority = {
  name: '',
 
  
}
