import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {StockListHeader} from './components/header/StockListHeader'

import {KTCard} from '../../../../../_metronic/helpers'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
import {useContext} from 'react'
import {StockTable} from './table/Stocktable'

const StockList = () => {
  return (
    <>
      <KTCard>
        <StockListHeader />
        <StockTable />
      </KTCard>
    </>
  )
}
const StockMain = () => {
  const {show, message, type} = useContext(AlertContext)
  return (
    <div className='row'>
      {show && <AlertBox type={type === true ? 'success' : 'error'} message={message} />}
      <div className='col-lg-12'>
        <StockList />
      </div>
    </div>
  )
}
const StockListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <StockMain />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {StockListWrapper}
