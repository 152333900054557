import {useEffect, useRef, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {KTCardBody, toAbsoluteUrl} from '../../../../../../_metronic/helpers'

// import {getReport} from '../_requests'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select, {Theme} from 'react-select'
import {useDownloadExcel} from 'react-export-table-to-excel'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {getReport, headNameDropdown} from '../_requests'
import {useAuth} from '../../../../auth'
const ReportContent = () => {
  const tableRef = useRef(null)
  const [cli, setCli] = useState<any>([])
  const {currentUser} = useAuth()
  const [pro, setPro] = useState<any>([])
  const [report, setReport] = useState<any>([])

  const [dateRange, setDateRange] = useState([null, null])
  const [cliId, setcliId] = useState(0)
  const [OCcli, setOCcli] = useState('')
  const [id, setid] = useState(0)
  const [OCpro, setOCOCpro] = useState('')
  const [ledgerId, setLedgerId] = useState(0) // Initial value can be set accordingly

  const [data, setdata] = useState('')
  const [OCFy, setFy] = useState('')
  const [OCDate, setOCDate] = useState('')
  const [startDate, endDate] = dateRange
  const [loading, setLoading] = useState(false)
  const [btnExcelDisable, setbtnExcelDisable] = useState(true)
  const [btnClearDisable, setbtnClearDisable] = useState(true)

  useEffect(() => {
    headNameDropdown().then((res: any) => {
      //setCli(res.data.Data)
      const mappedOptions = res.data.data.map((pro: {id: any; headName: any}) => ({
        value: pro.id,
        label: pro.headName,
      }))
      console.log('optionsWithAll- console.logres-data', res.data)
      console.log('optionsWithAll- console.log-data', data)
      console.log('optionsWithAll- console.log-mappedOptions', mappedOptions)

      const allOption = {value: 0, label: 'All'}
      const optionsWithAll = [allOption, ...mappedOptions]
      console.log('optionsWithAll', optionsWithAll)
      setPro(optionsWithAll)
    })
  }, [])

  const [selectedOption, setSelectedOption] = useState(null)
  const [proselectedOption, setProSelectedOption] = useState(null)
  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption)
    var text = selectedOption?.label
    setOCcli(text)
    setcliId(selectedOption?.value)
  }

  const handleProjectSelectChange = (proselectedOption: any) => {
    setProSelectedOption(proselectedOption)
    var text = proselectedOption?.label
    setOCOCpro(text)
    setLedgerId(proselectedOption?.value)
    // fetchCustomer(proselectedOption?.value)
  }
  const {mode} = useThemeMode()
  //dropdown
  if (mode === 'dark') {
    var customStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        borderColor: '#393944',

        boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',
        '&:hover': {
          color: 'red',
        },
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
        color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: 5,
        borderRadius: 5,
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: 'var(--kt-input-solid-color)',
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-input-solid-color)',
      }),
    }
  } else {
    var customStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        borderColor: '#c5c5c5',

        boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
        color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: 5,
        borderRadius: 5,
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: 'var(--kt-input-solid-color)',
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: '#c5c5c5',
      }),
    }
  }

  const GeneralLedgerReport = () => {
    setLoading(true)

    var Daterange = (document.getElementById('Daterange') as HTMLInputElement).value
    const dateRange = Daterange.split(' - ')

    const fromDate = dateRange[0].split('/').reverse().join('-')
    const toDate = dateRange[1].split('/').reverse().join('-')

    const financialYearId = currentUser?.financialYearId

    const data = {financialYearId, fromDate, toDate, ledgerId}
    console.log('fromDate-toDate', financialYearId, toDate, fromDate)
    getReport(data).then((resRep) => {
      console.log('data-form ', data)
      setLoading(false)

      setReport(resRep.data.data)
      // Enable or disable the buttons as needed based on the new data.
      setbtnExcelDisable(Object.keys(resRep.data.data).length === 0)
      setbtnClearDisable(Object.keys(resRep.data.data).length === 0)
    })

    setOCDate(`${fromDate} - ${toDate}`)
    setFy(`${currentUser?.financial}`)
  }
  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()

  const clearReport = () => {
    setReport([])
    setFy('')
    setProSelectedOption(null)
    setDateRange([null, null])
    setOCDate('')
    setbtnExcelDisable(true)
    setbtnClearDisable(true)
  }

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `GeneralLedger_Report_${fileName}`,
    sheet: 'Users',
  })
  return (
    <>
      <div className='card '>
        {/* begin::Header */}
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>General Ledger Report</h3>
          </div>
          <div className='col-lg-2 pt-5'>
            <button
              className='btn btn-danger col-lg-12'
              disabled={btnExcelDisable}
              onClick={onDownload}
            >
              {' '}
              Export{' '}
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='row mb-10'>
            <div className='col-lg-4'>
              <label className=' col-form-label  fw-bold fs-6'>Filter By Transaction Head</label>

              <Select
                className='my-react-select-container'
                classNamePrefix='my-react-select'
                value={proselectedOption}
                onChange={handleProjectSelectChange}
                options={pro}
                isSearchable
                // onInputChange={handleInputChange}
                styles={customStyles}
              />
            </div>

            <div className='col-lg-4'>
              <label className=' col-form-label  fw-bold fs-6'>Filter By Date Range</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid'
                id='Daterange'
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update: any) => {
                  setDateRange(update)
                }}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
              />
            </div>

            <div className='col-lg-2 pt-12'>
              <button onClick={GeneralLedgerReport} className='btn btn-primary col-lg-12'>
                Search
              </button>
            </div>
            <div className='col-lg-2 pt-12'>
              <button
                onClick={clearReport}
                className='btn btn-info col-lg-12'
                disabled={btnClearDisable}
              >
                Clear
              </button>
            </div>
          </div>

          <div className='table-responsive'>
            {/* begin::Table */}

            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
              ref={tableRef}
            >
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                  <th className='min-w-50px' colSpan={6}>
                    {' '}
                    Financial Year : {OCFy}
                  </th>
                  <th className='min-w-120px' colSpan={5}>
                    DATE : {OCDate}
                  </th>
                </tr>
                <tr>
                  <td colSpan={11}></td>{' '}
                </tr>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-50px'> S. No</th>
                  <th className='min-w-140px'> Date</th>
                  <th className='min-w-140px'> Voucher No</th>
                  <th className='min-w-120px'> Voucher Type </th>

                  <th className='min-w-100px'> Remark </th>
                  <th className='min-w-100px'> Debit</th>
                  <th className='min-w-100px'> Credit </th>
                  <th className='min-w-100px'> Reverse Account Name </th>
                  <th className='min-w-100px'> Payment Type </th>
                </tr>
              </thead>
              {loading && (
                <tbody>
                  <tr>
                    <td colSpan={11} style={{textAlign: 'center'}}>
                      {' '}
                      Please wait...{' '}
                    </td>
                  </tr>
                </tbody>
              )}
              {!loading && (
                <tbody>
                  {report?.map((item: any, i: any) => {
                    return (
                      <tr key={i}>
                        <td> {i + 1}</td>

                        <td> {item.voucherDate}</td>

                        <td> {item.voucherNumber} </td>
                        <td> {item.voucherType} </td>
                        <td> {item.ledgerComment} </td>
                        <td> {item.debit} </td>
                        <td> {item.credit} </td>
                        <td> {item.reverseAccount.headName} </td>
                        <td> {item.coa.headName} </td>
                      </tr>
                    )
                  })}

                  {report.length > 0 ? (
                    <tr>
                      <td colSpan={9}> </td>
                      <th> TOTAL </th>
                      <th>
                        {' '}
                        {report?.reduce((a: any, v: any) => (a = a + parseInt(v.amount)), 0)}
                      </th>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={11} style={{textAlign: 'center'}}>
                        {' '}
                        No Data{' '}
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
              {/* end::Table body */}
            </table>

            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {ReportContent}
