import React, {FC, useContext, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {
  approveSalarygenerate,
  updateSalarygenerate,
  viewApprovalList,
} from '../salary-generate-list/core/_requests'
import {UpdateSalarygenerate} from '../salary-generate-list/core/_models'
import {ErrorMessage} from 'formik'
type Props = {
  initialValues: UpdateSalarygenerate
}

export const ApproveList: FC<Props> = ({initialValues}) => {
  const [data, setData] = useState<UpdateSalarygenerate>(initialValues)
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {id} = useParams()
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const [datalist, setDataList] = useState<any[]>([])
  console.log('datalist-view', datalist)
  console.log('data-edit page', data)
  useEffect(() => {
    viewApprovalList(id).then((res) => {
      console.log('viewApprovalList', res.data)
      setDataList(res.data)
    })
  }, [])
  console.log('datalist-view', datalist)
  const calculateTotalOtAmount = (totalOtHours: number, otAmount: number) => {
    return totalOtHours * otAmount
  }
  const calculateBonusAmount = (bonusAmount: number, netSalary: number) => {
    return bonusAmount + netSalary
  }
  const handleApprove = async () => {
    setLoading(true)

    const approvedBy = currentUser?.userId // Assuming userId is a number
    const approvedStatus = '1' // Assuming it needs to be a string
    const approvedDate = new Date().toISOString().split('T')[0] // Format as "yyyy-MM-dd"

    console.log('Before API call:', {approvedBy, approvedStatus, approvedDate})

    const approvalData = {
      approvedBy,
      approvedStatus,
      approvedDate,
    }
    console.log('Before API call:approvalData', approvalData)
    try {
      // Set data to the API
      const res = await approveSalarygenerate(approvalData, id)

      console.log('API Response:', res)
      console.log('mes', res?.data?.message)

      // Only handle the success response, no need to update the state
      navigate('/salary-generate', {replace: true})
      showAlert(res?.message, res?.status)
    } catch (error) {
      // Handle error
      console.error('Error:', error)
    } finally {
      setLoading(false)
    }
  }
  const handleUpdate = async (id: any) => {
    setLoading(true)
    try {
      const updatedData = datalist.map((item) => ({
        ...item,
        updatedBy: currentUser?.userId,
      }))
      console.log('Updated Data:', updatedData)

      // Assuming updateSalarygenerate expects id as a URL parameter
      const response = await updateSalarygenerate({salaries: updatedData}, id)
      navigate('/salary-generate', {replace: true})
      showAlert(response?.message, response?.status)
      console.log('API Response:', response.data)
      // showAlert('Data updated successfully', 'success');
    } catch (error) {
      console.error('Error updating data:', error)
      showAlert('Failed to update data', 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleSalaryAmountChange = (value: string, index: number) => {
    const newData = [...datalist]
    newData[index].salaryAmount = value
    setDataList(newData)
  }
  const handleOtHoursChange = (value: string, index: number) => {
    const newData = [...datalist]
    newData[index].totalOtHours = value
    setDataList(newData)
  }
  const handleOtAmountChange = (value: string, index: number) => {
    const newData = [...datalist]
    newData[index].otAmount = value
    setDataList(newData)
  }
  const handleTotalOtAmountChange = (value: string, index: number) => {
    const newData = [...datalist]
    newData[index].totalOtAmount = value
    setDataList(newData)
  }

  const handleAdvanceChange = (value: string, index: number) => {
    const newData = [...datalist]
    newData[index].advance = value
    setDataList(newData)
  }
  const handleLeaveDeductChange = (value: string, index: number) => {
    const newData = [...datalist]
    newData[index].leaveDeduct = value
    setDataList(newData)
  }
  const handleLoanDeductChange = (value: string, index: number) => {
    const newData = [...datalist]
    newData[index].loanDeduct = value
    setDataList(newData)
  }
  const handleBonusTypeChange = (value: string, index: number) => {
    const newData = [...datalist]
    newData[index].bonusType = value
    setDataList(newData)
  }

  const handleBonusAmountChange = (value: string, index: number) => {
    const newData = [...datalist]
    newData[index].bonusAmount = value

    // Calculate netSalary by adding salaryAmount, bonusAmount, totalOtAmount, and otAmount
    const salaryAmount = parseFloat(newData[index].salaryAmount) || 0 // Parse as number or default to 0
    const bonusAmount = parseFloat(value) || 0 // Parse as number or default to 0
    const totalOtAmount = parseFloat(newData[index].totalOtAmount) || 0 // Parse as number or default to 0
    const netSalary =
      salaryAmount +
      bonusAmount +
      totalOtAmount -
      parseFloat(newData[index].leaveDeduct || 0) -
      parseFloat(newData[index].advance || 0) -
      parseFloat(newData[index].loanDeduct || 0)
    newData[index].netSalary = isNaN(netSalary) ? 'NaN' : netSalary.toString() // Check for NaN

    setDataList(newData)
  }

  const handleNetSalaryChange = (value: string, index: number) => {
    const newData = [...datalist]
    newData[index].netSalary = value
    setDataList(newData)
  }
  const handleInputChange = (value: string, index: number, field: string) => {
    const newData = [...datalist]
    newData[index][field] = value

    // Calculate total OT amount if either totalOtHours or otAmount changes
    if (field === 'totalOtHours' || field === 'otAmount') {
      const totalOtHours = parseFloat(newData[index].totalOtHours)
      const otAmount = parseFloat(newData[index].otAmount)
      newData[index].totalOtAmount = calculateTotalOtAmount(totalOtHours, otAmount)
    }

    // Calculate Net Salary if any of the relevant fields change
    if (
      field === 'salaryAmount' ||
      field === 'bonusAmount' ||
      field === 'totalOtAmount' ||
      field === 'totalOtHours' ||
      field === 'leaveDeduct' ||
      field === 'advance' ||
      field === 'loanDeduct'
    ) {
      const salaryAmount = parseFloat(newData[index].salaryAmount) || 0
      const bonusAmount = parseFloat(newData[index].bonusAmount) || 0
      const totalOtAmount = parseFloat(newData[index].totalOtAmount) || 0
      const leaveDeduct = parseFloat(newData[index].leaveDeduct) || 0
      const advance = parseFloat(newData[index].advance) || 0
      const loanDeduct = parseFloat(newData[index].loanDeduct) || 0

      const netSalary =
        salaryAmount + bonusAmount + totalOtAmount - leaveDeduct - advance - loanDeduct

      newData[index].netSalary = isNaN(netSalary) ? 'NaN' : netSalary.toString()
    }

    setDataList(newData)
  }

  return (
    <>
      <div className='tab-content' id='myTabContent'>
        <div className='mb-xl-10 tab-pane fade active show' id='kt_tab_overview' role='tabpanel'>
          <div className='card mb-3 mb-xl-10'>
            <div className='card-title text-center m-0 mt-9'>
              <h3 className='fw-bolder m-0 me-2'>
                Payroll posting sheet for -
                <b className='my-6' style={{color: 'red'}}>
                  {/* {data.salarysheet.month} */}
                </b>
                <br />
                <br />
                (Not Approved)
              </h3>
            </div>
            <div className='tab-content' id='myTabContent'>
              <div
                className='mb-xl-10 tab-pane fade active show'
                id='kt_tab_overview'
                role='tabpanel'
              >
                <div className='card mb-5 mb-xl-10'>
                  <div className='card-header cursor-pointer'></div>
                  <div className='card-body p-9'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>Employee Id</th>
                          <th>Employee Name</th>
                          <th>Salary Amount</th>
                          <th>OT Hours</th>
                          <th>OT Amount</th>
                          <th>Total OT</th>
                          <th>Leave </th>
                          <th>Advance</th>
                          <th>Loan Amount</th>
                          <th>Bonus Type</th>
                          <th>Bonus Amount</th>
                          <th>Net Salary</th>
                        </tr>
                      </thead>

                      <tbody>
                        {datalist.map((item: any, index: number) => (
                          <tr key={index}>
                            <td>{item.employee.employeeId}</td>
                            <td>{item.employee.employeeName}</td>

                            <td>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid d-flex mb-3 mb-lg-0'
                                value={item.salaryAmount}
                                onChange={(e) => handleSalaryAmountChange(e.target.value, index)}
                              />
                            </td>
                            <td>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid d-flex mb-3 mb-lg-0'
                                value={item.totalOtHours}
                                onChange={(e) =>
                                  handleInputChange(e.target.value, index, 'totalOtHours')
                                }
                              />
                            </td>
                            <td>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid d-flex mb-3 mb-lg-0'
                                value={item.otAmount}
                                onChange={(e) =>
                                  handleInputChange(e.target.value, index, 'otAmount')
                                }
                              />
                            </td>
                            <td>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid d-flex mb-3 mb-lg-0'
                                value={item.totalOtAmount}
                                readOnly // Making it read-only because it's a calculated field
                              />
                            </td>
                            <td>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid d-flex mb-3 mb-lg-0'
                                value={item.leaveDeduct}
                                onChange={(e) => handleLeaveDeductChange(e.target.value, index)}
                              />
                            </td>
                            <td>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid d-flex mb-3 mb-lg-0'
                                value={item.advance}
                                onChange={(e) => handleAdvanceChange(e.target.value, index)}
                              />
                            </td>
                            <td>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid d-flex mb-3 mb-lg-0'
                                value={item.loanDeduct}
                                onChange={(e) => handleLoanDeductChange(e.target.value, index)}
                              />
                            </td>
                            <td>
                              <select
                                className='form-select form-select-lg form-select-solid d-flex mb-3 mb-lg-0'
                                value={item.bonusType}
                                onChange={(e) => handleBonusTypeChange(e.target.value, index)}
                              >
                                <option value='Yearly bonus'>Yearly Bonus</option>
                                <option value='Monthly Bonus'>Monthly Bonus</option>
                                <option value='Performance Bonus'>Performance Bonus</option>
                              </select>
                            </td>
                            <td>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid d-flex mb-3 mb-lg-0'
                                value={item.bonusAmount}
                                onChange={(e) => handleBonusAmountChange(e.target.value, index)}
                              />
                            </td>
                            <td>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid d-flex mb-3 mb-lg-0'
                                value={item.netSalary}
                                onChange={(e) => handleNetSalaryChange(e.target.value, index)}
                              />
                            </td>
                            <tr>
                              <td colSpan={12}>
                                <hr />
                              </td>
                            </tr>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>{' '}
          </div>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button
          type='button'
          className='btn btn-success me-3'
          onClick={() => handleUpdate(id)} // Use an arrow function to pass the id parameter
          disabled={loading}
        >
          {!loading ? 'Update' : 'Updating...'}
        </button>

        <button
          type='button'
          className='btn btn-primary'
          onClick={handleApprove}
          disabled={loading}
        >
          {!loading ? 'Approve' : 'Please wait...'}
        </button>
      </div>
    </>
  )
}

export default ApproveList
