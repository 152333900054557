import {ID, Response} from '../../../../../../_metronic/helpers'
export type Purchase = {
  id?: ID
  primaryId?: number
  purchaseDate: any
  amount: any
  grandTotal: any
  tax: any
  paidAmount: any
  discount: any
  description: string
  supplierId: number
  quantity: any
  purchaseType: any
  isCredit: boolean
  purchaseDetail: [
    {
      productId: any
      styleNumber: any
      colorId: any
      sizeId: any
      unitId: any
      skuId: any
      amount: any
      tax: any
      taxValue: any
      discount: any
      discountValue: any
      total: any
      quantity: any
    }
  ]
  paymentHistory: [
    {
      coaId: number
      amount: string
    }
  ]
  createdBy: number
  message?: string
  status?: number
}

export type PurchaseQueryResponse = Response<Array<Purchase>>

export const initialPurchase: Purchase = {
  purchaseDate: '',
  purchaseType: '',
  description: '',
  supplierId: 0,
  isCredit: false,
  purchaseDetail: [
    {
      productId: '',
      styleNumber: '',
      colorId: '',
      sizeId: 0,
      unitId: '',
      skuId: '',
      amount: '',
      tax: '',
      taxValue: 0,
      discount: '',
      discountValue: 0,
      total: '',
      quantity: '',
    },
  ],
  amount: '',
  grandTotal: 0,
  tax: 0,
  paidAmount: 0,
  discount: 0,
  quantity: 0,
  paymentHistory: [
    {
      coaId: 0,
      amount: '',
    },
  ],
  createdBy: 0,
}
export type UpdatePurchase = {
  id?: ID
  primaryId?: number
  purchaseDate: any
  purchaseType: any
  amount: any
  grandTotal: any
  tax: any
  paidAmount: any
  discount: any
  description: string
  supplierId: string

  isCredit: boolean
  purchaseDetail: [
    {
      productId: string
      styleNumber: any
      colorId: any
      sizeId: any
      unitId: any
      skuId: string
      amount: any
      tax: any
      taxValue: any
      discount: any
      discountValue: any
      total: any
      quantity: string
    }
  ]
  paymentHistory: [
    {
      coaId: string
      amount: any
    }
  ]
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdatePurchaseQueryResponse = Response<Array<UpdatePurchase>>

export const initialUpdatePurchase: UpdatePurchase = {
  purchaseDate: '',
  purchaseType: '',
  amount: '',
  grandTotal: '',
  tax: '',
  paidAmount: '',
  discount: '',
  description: '',
  supplierId: '',

  isCredit: false,
  purchaseDetail: [
    {
      productId: '',
      styleNumber: '',
      colorId: '',
      sizeId: 0,
      unitId: '',
      skuId: '',
      amount: '',
      tax: '',
      taxValue: '',
      discount: '',
      discountValue: '',
      total: '',
      quantity: '',
    },
  ],
  paymentHistory: [
    {
      coaId: '',
      amount: '',
    },
  ],
}
