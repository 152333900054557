import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {SalarygenerateListWrapper} from './salary-generate-list/SalarygenerateList'

import {EditSalarygenerateWrapper} from './salary-generate-form/EditSalarygenerateWrapper'
import ApproveList from './salary-generate-form/EditSalaryGenerate'


const SalarygenerateBreadcrumbs: Array<PageLink> = [
  {
    title: 'Salary Generate Management',
    path: '/salary-generate',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SalarygeneratesPage = () => {
  const navigate = useNavigate()
  function goBack() {
    navigate(-1)
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={SalarygenerateBreadcrumbs}>Salary Generate list</PageTitle>
              <SalarygenerateListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />

      <Route
        path='/edit/:id'
        element={
          <>
            <PageTitle breadcrumbs={SalarygenerateBreadcrumbs}>Salary Generate Edit</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <EditSalarygenerateWrapper />
            {/* <ApproveList /> */}
          </>
        }
      />
    </Routes>
  )
}
export default SalarygeneratesPage
