import {Modal} from 'react-bootstrap'
import React, {useContext, useState, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  createCloseFinancialyear,
  getCloseFinancialYear,
} from '../financial-year-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../financial-year-list/core/QueryResponseProvider'
import {
  Financialyear,
  initialFinancialyear as initialValues,
} from '../financial-year-list/core/_models'

// Validation
const FinancialYearDetailsSchema = Yup.object().shape({
  closeYearId: Yup.string().required('Name is required'),
})

interface modalProps {
  show: boolean
  onHide: () => void
}

const CloseFinancialYearModal: React.FC<modalProps> = ({show, onHide}) => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Financialyear>(initialValues)
  const navigate = useNavigate()

  // Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()
  const type = currentUser?.id
  const [loading, setLoading] = useState(false)

  // formik Starts
  const formik = useFormik<Financialyear>({
    initialValues: {
      ...initialValues,
    },
    validationSchema: FinancialYearDetailsSchema,

    // Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      createCloseFinancialyear(updatedData).then((res: any) => {
        console.log('financial-year-Alertres', res?.message)
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/financial-year', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.message, res?.status)
      })
    },
    enableReinitialize: true,
  })
  // Get Close Financial Year using type
  const [closeYear, setcloseYear] = useState<any>([])
  useEffect(() => {
    getCloseFinancialYear().then((res: any) => {
      setcloseYear(res?.data?.data)
    })
  }, [])

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Close Financial Year</Modal.Title>
      </Modal.Header>
      <form
        id='kt_modal_add_financialyear_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <Modal.Body>
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_add_financialyear_form'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_financialyear_header'
            data-kt-scroll-wrappers='#kt_modal_add_financialyear_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg- fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Year</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Close Year'
                    {...formik.getFieldProps('closeYearId')}
                  >
                    <option value=''>Select a Close Year</option>
                    {closeYear.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.closeYearId && formik.errors.closeYearId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.closeYearId}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* end::Scroll */}
        <Modal.Footer>
          <div className='card-footer d-flex justify-content-end py-6 px-9 '>
            <button type='submit' className='btn btn-primary me-3' disabled={loading}>
              {!loading ? (
                'Closing'
              ) : (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button
              type='button'
              className='btn btn-danger me-2 '
              disabled={loading}
              onClick={onHide}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default CloseFinancialYearModal
