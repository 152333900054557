import axios, {AxiosResponse} from 'axios'
import {Process, ProcessQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_PROCESS_URL = `${API_URL}process/add`
const GET_PROCESS_URL = `${API_URL}process/query`
const VIEW_PROCESS_URL = `${API_URL}process/view`
const UPDATE_PROCESS_URL = `${API_URL}process/update`
const DELETE_PROCESS_URL = `${API_URL}process/delete`
const GET_SALESORDER_URL = `${API_URL}custom/get-sales-order`
const GET_MATERIAL_URL = `${API_URL}custom/get-material`
const GET_MACHINE_URL = `${API_URL}custom/get-machines`
const GET_ORDERSTATUS_URL = `${API_URL}custom/get-process-status`
const GET_PROCESSTYPE_URL = `${API_URL}custom/get-process-type`
const GET_PRIORITY_URL = `${API_URL}custom/get-priority`
//AuthVerify
var AuthVerify = getToken.call(this)

//Process LIST
const getProcess = (query: string): Promise<ProcessQueryResponse> => {
  return axios
    .post(`${GET_PROCESS_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<ProcessQueryResponse>) => {
      // console.log('getProcess==>', response.data)
      return response.data
    })
}

//Process ADD
const createProcess = (data: any): Promise<Process | undefined> => {
  return axios.post(CREATE_PROCESS_URL, data, getToken()).then((response: any) => response.data)
}

//Process VEIW
const viewProcess = (id: any) => {
  return axios.get(`${VIEW_PROCESS_URL}/${id}`, getToken()).then((response) => {
    // console.log('view-Process', response.data)
    return response.data
  })
}

//Process UPDATE
const updateProcess = (data: any, id: any) => {
  console.log('Process=update', data)
  return axios.put(`${UPDATE_PROCESS_URL}/${id}`, data, getToken()).then((response) => {
    // console.log('update-Process', response.data)
    return response.data
  })
}

//Process DELETE
const deleteProcessById = (id: any) => {
  return axios.post(`${DELETE_PROCESS_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}
//GET Type
const getSalesOrder = async () => {
  const res = await axios.get(`${GET_SALESORDER_URL}`, getToken())
  console.log('getSalesOrder-api', res)
  return res
}
//GET material
const getMaterial = async () => {
  const res = await axios.get(`${GET_MATERIAL_URL}`, getToken())
  console.log('Material-api', res)
  return res
}
//GET Machine
const getMachine = async () => {
  const res = await axios.get(`${GET_MACHINE_URL}`, getToken())
  // console.log('getMachine-api', res)
  return res
}
//GET Process Status
const getProcessStatus = async () => {
  const res = await axios.get(`${GET_ORDERSTATUS_URL}`, getToken())
  // console.log('GET_ORDERSTATUS_URL-api', res)
  return res
}
//GET Process Status
const getProcessType = async () => {
  const res = await axios.get(`${GET_PROCESSTYPE_URL}`, getToken())
  // console.log('GET_PROCESSTYPE_URL-api', res)
  return res
}
//GET Process Status
const getPriority = async () => {
  const res = await axios.get(`${GET_PRIORITY_URL}`, getToken())
  // console.log('getPriority-api', res)
  return res
}
export {getProcess, createProcess, viewProcess, updateProcess, deleteProcessById, getSalesOrder, getMaterial,getMachine,getProcessStatus,getProcessType,getPriority}
