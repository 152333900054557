// @ts-nocheck
import {Column} from 'react-table'

import {SkuActionsCell} from './SkuActionsCell'
import {SkuCustomHeader} from './SkuCustomHeader'
import {Sku} from '../../core/_models'

const SkuColumns: ReadonlyArray<Column<Sku>> = [
  {
    Header: (props) => <SkuCustomHeader tableProps={props} title='S.No' className='min-w-55px' />,
    accessor: 'serialNo',
  },

  {
    Header: (props) => (
      <SkuCustomHeader tableProps={props} title='Sku Name' className='min-w-125px' />
    ),
    accessor: 'name',
  },

  {
    Header: (props) => (
      <SkuCustomHeader tableProps={props} title='Description' className='min-w-125px' />
    ),
    accessor: 'description',
  },

  {
    Header: (props) => (
      <SkuCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <SkuActionsCell sku={props.data[props.row.index]} />,
  },
]

export {SkuColumns}
