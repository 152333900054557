import {useListView} from '../../core/ListViewProvider'
import {ColorListToolbar} from './ColorListToolbar'
import {ColorListGrouping} from './ColorListGrouping'
import {ColorListSearchComponent} from './ColorListSearchComponent'

const ColorListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ColorListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ColorListGrouping /> : <ColorListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ColorListHeader}
