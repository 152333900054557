import {ID, Response} from '../../../../../../_metronic/helpers'
export type Employee = {
  id?: ID
  primaryId?: number
  rolesId?: number
  departmentsId?: number
  name?: string
  email?: string
  contactNumber?: string
  address?: string
  password?: string
  profile?: any
  paymentType?: string
  paymentRate?: string
  bloodGroup?: string
  profileNew?: any
  fileName?: any
  message?: string
  status?: string
  createdBy?: number
}

export type EmployeeQueryResponse = Response<Array<Employee>>

export const initialEmployee: Employee = {
  rolesId: 0,
  departmentsId: 0,
  name: '',
  email: '',
  contactNumber: '',
  address: '',
  password: '',
  profile: '',
  paymentType: '',
  paymentRate: '',
  bloodGroup: '',
  createdBy: 0,
}
export type UpdateEmployee = {
  id?: ID
  primaryId?: number
  rolesId?: number
  departmentsId?: number
  name?: string
  email?: string
  contactNumber?: string
  address?: string
  password?: string
  profile?: any
  profileNew?: any
  profileImage?: any
  fileName?: any
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateEmployeeQueryResponse = Response<Array<UpdateEmployee>>

export const initialUpdateEmployee: UpdateEmployee = {
  rolesId: 0,
  departmentsId: 0,
  name: '',
  email: '',
  contactNumber: '',
  address: '',
  password: '',
  profile: '',
}
