import {useListView} from '../../core/ListViewProvider'
import {PurchaseListSearchComponent} from './PurchaseListSearchComponent'
import {PurchaseListToolbar} from './PurchaseListToolbar'

const PurchaseListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <PurchaseListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <PurchaseListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PurchaseListHeader}
