import axios, {AxiosResponse} from 'axios'

import {Color, ColorQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_COLOR_URL = `${API_URL}color/add`
const GET_COLOR_URL = `${API_URL}color/query`
const VIEW_COLOR_URL = `${API_URL}color/view`
const UPDATE_COLOR_URL = `${API_URL}color/update`
const DELETE_COLOR_URL = `${API_URL}color/delete`


//AuthVerify
var AuthVerify = getToken.call(this)

//Color LIST
const getColor = (query: string): Promise<ColorQueryResponse> => {
  return axios
    .post(`${GET_COLOR_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<ColorQueryResponse>) => {
      console.log('get-Color==>', response.data)
      return response.data
    })
}

//Color ADD
const createColor = (data: any): Promise<Color | undefined> => {
  return axios
    .post(CREATE_COLOR_URL, data, getToken())
    .then((response: any) => response.data)
}

//Color VEIW
const viewColor = (id: any) => {
  return axios.get(`${VIEW_COLOR_URL}/${id}`, getToken()).then((response) => {
    console.log('view-Color', response.data)
    return response.data
  })
}

//Color UPDATE
const updateColor = (data: any, id: any) => {
  console.log('reqData=update-Color', data)
  return axios.put(`${UPDATE_COLOR_URL}/${id}`, data, getToken()).then((response) => {
    console.log('updatemp-Color', response.data)
    return response.data
  })
}

//Color DELETE
const deleteColorById = (id: any) => {
  return axios.post(`${DELETE_COLOR_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

export {
  getColor,
  createColor,
  viewColor,
  updateColor,
  deleteColorById,
 
}
