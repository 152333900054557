import axios, {AxiosResponse} from 'axios'
import {Customer, CustomerQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_CUSTOMER_URL = `${API_URL}customer/add`
const GET_CUSTOMER_URL = `${API_URL}customer/query/`
const VIEW_CUSTOMER_URL = `${API_URL}customer/view/`
const UPDATE_CUSTOMER_URL = `${API_URL}customer/update`
const DELETE_CUSTOMER_URL = `${API_URL}customer/delete`

//AuthVerify
var AuthVerify = getToken.call(this)

//Customer LIST
const getCustomer = (query: string): Promise<CustomerQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMER_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<CustomerQueryResponse>) => {
      console.log('getCustomer==>', response.data)
      return response.data
    })
}

//Customer ADD
const createCustomer = (data: any): Promise<Customer | undefined> => {
  return axios.post(CREATE_CUSTOMER_URL, data, getToken()).then((response: any) => response.data)
}

//Customer VEIW
const viewCustomer = (id: any) => {
  return axios.get(`${VIEW_CUSTOMER_URL}/${id}`, getToken()).then((response) => {
    console.log('view-Customer', response.data)
    return response.data
  })
}

//Customer UPDATE
const updateCustomer = (data: any, id: any) => {
  console.log('reqData=update', data)
  return axios.put(`${UPDATE_CUSTOMER_URL}/${id}`, data, getToken()).then((response) => {
    console.log('update-Customer', response.data)
    return response.data
  })
}

//Customer DELETE
const deleteCustomerById = (id: any) => {
  return axios.post(`${DELETE_CUSTOMER_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

export {getCustomer, createCustomer, viewCustomer, updateCustomer, deleteCustomerById}
