import {ID, Response} from '../../../../../../_metronic/helpers'
export type Color = {
  id?: ID
  primaryId?: number
  name?: string
 
 
  message?: string
  status?: string
  createdBy?: number
}

export type ColorQueryResponse = Response<Array<Color>>

export const initialColor: Color = {
  name: '',

  
  createdBy: 0,
}
export type UpdateColor = {
  id?: ID
  primaryId?: number
  name?: string
 
 
  status?: string
  updatedBy?: number
}

export type UpdateColorQueryResponse = Response<Array<UpdateColor>>

export const initialUpdateColor: UpdateColor = {
  name: '',
 
  
}
