import {useListView} from '../../core/ListViewProvider'
import {BankListSearchComponent} from './BankListSearchComponent'
import {BankListToolbar} from './BankListToolbar'

const BankListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <BankListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <BankListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {BankListHeader}
