import axios, {AxiosResponse} from 'axios'
import {PurchaseReturn, PurchaseReturnQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_PurchaseReturn_URL = `${API_URL}purchase-return/add`
const GET_PurchaseReturn_URL = `${API_URL}purchase-return/query/`
const VIEW_PurchaseReturn_URL = `${API_URL}purchase-return/view/`
const UPDATE_PurchaseReturn_URL = `${API_URL}purchase-return/update`
const DELETE_PurchaseReturn_URL = `${API_URL}purchase-return/delete`
const GET_PRODUCT_URL = `${API_URL}custom/get-product`
const GET_PURCHASE_URL = `${API_URL}custom/get-purchase`


//AuthVerify
var AuthVerify = getToken.call(this)

//PurchaseReturn LIST
const getPurchaseReturn = (query: string): Promise<PurchaseReturnQueryResponse> => {
  return axios
    .post(`${GET_PurchaseReturn_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<PurchaseReturnQueryResponse>) => {
      console.log('getPurchaseReturn==>', response.data)
      return response.data
    })
}

//PurchaseReturn ADD
const createPurchaseReturn = (data: any): Promise<PurchaseReturn | undefined> => {
  // Make the POST request to create a purchase
  console.log('createPurchaseReturn Response:API data', data) // Moved the console.log here
  return axios.post(CREATE_PurchaseReturn_URL, data, getToken()).then((response: any) => {
    console.log('createPurchaseReturn Response:API', response.data) // Moved the console.log here
    return response.data
  })
}
//PurchaseReturn VEIW
const viewPurchaseReturn = (id: any) => {
  return axios.get(`${VIEW_PurchaseReturn_URL}/${id}`, getToken()).then((response) => {
    console.log('view-PurchaseReturn', response.data)
    return response.data
  })
}

//PurchaseReturn UPDATE
const updatePurchaseReturn = (data: any, id: any) => {
  console.log('reqData=update', data)
  return axios.put(`${UPDATE_PurchaseReturn_URL}/${id}`, data, getToken()).then((response) => {
    console.log('update-PurchaseReturn', response.data)
    return response.data
  })
}

//PurchaseReturn DELETE
const deletePurchaseReturnById = (id: any) => {
  return axios.post(`${DELETE_PurchaseReturn_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}
//GET Product
const getProduct = async () => {
  const res = await axios.get(`${GET_PRODUCT_URL}`, getToken())
  console.log('get-getProduct', res)
  return res
}
//GET Product
const getPurchase = async () => {
  const res = await axios.get(`${GET_PURCHASE_URL}`, getToken())
  console.log('get-getPurchase--API', res)
  return res
}
export {
  getPurchaseReturn,
  createPurchaseReturn,
  viewPurchaseReturn,
  updatePurchaseReturn,
  deletePurchaseReturnById,
  getProduct,
  getPurchase,
}
