import React, {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createProcessType} from '../process-type-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../process-type-list/core/QueryResponseProvider'
import {ProcessType, initialProcessType as initialValues} from '../process-type-list/core/_models'
//Validation
const ProcessTypeDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Process Type name is required'),
})

//Function Starts
const ProcessTypeDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<ProcessType>(initialValues)
  const navigate = useNavigate()

  //Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()
  // const type = currentUser?.branchId
  const [loading, setLoading] = useState(false)
  //formik Starts
  const formik = useFormik<ProcessType>({
    initialValues,
    validationSchema: ProcessTypeDetailsSchema,

    //Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      createProcessType(updatedData).then((res: any) => {
        console.log('Alertres', res?.message)
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/process-type', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.message, res?.status)
      })
    },
    enableReinitialize: true,
  })

  console.log('attance-formik', formik)
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Process Type </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Process Type Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Process Type Name'
                    id='name'
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Description</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Process Description'
                    id='description'
                    {...formik.getFieldProps('description')}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.description}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-3 px-6'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProcessTypeDetails
