// @ts-nocheck
import {Column} from 'react-table'
import {SalesReturnActionsCell} from './SalesReturnActionsCell'
import {SalesReturnCustomHeader} from './SalesReturnCustomHeader'
import {SalesReturn} from '../../core/_models'

const SalesReturnColumns: ReadonlyArray<Column<SalesReturn>> = [
  {
    Header: (props) => (
      <SalesReturnCustomHeader tableProps={props} title='S.No' className='min-w-55px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <SalesReturnCustomHeader
        tableProps={props}
        title='Sales Return INV'
        className='min-w-105px'
      />
    ),
    accessor: 'salesReturnId',
  },

  {
    Header: (props) => (
      <SalesReturnCustomHeader tableProps={props} title='Sales INV' className='min-w-105px' />
    ),
    accessor: 'salesId',
  },
  {
    Header: (props) => (
      <SalesReturnCustomHeader tableProps={props} title='Date' className='min-w-105px' />
    ),
    accessor: 'date',
  },

  {
    Header: (props) => (
      <SalesReturnCustomHeader tableProps={props} title='Total' className='min-w-105px' />
    ),
    accessor: 'total',
  },

  {
    Header: (props) => (
      <SalesReturnCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <SalesReturnActionsCell salesReturn={props.data[props.row.index]} />,
  },
]

export {SalesReturnColumns}
