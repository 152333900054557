/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {deleteSkuById} from '../../core/_requests'
import {Sku} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
import {Modal, Button} from 'react-bootstrap'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
type Props = {
  sku: Sku
}

const SkuActionsCell: FC<Props> = ({sku}) => {
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)

  const editSkuDetail = () => {
    navigate('/sku/edit/' + sku.primaryId)
  }
  const deleteItem = () =>
    deleteSkuById(sku.primaryId).then((res) => {
      console.log('delete', res)
      setShow(false)
      showAlert(res?.message, res?.status)
      navigate('/sku')
    })
  const handleShow = () => {
    setShow(true)
  }
  return (
    <>
      {/* Edit Button */}
      <div className='d-flex justify-content-end flex-shrink-0'>
        <a
          onClick={editSkuDetail}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a>

        {/* Delete Button */}
        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleShow}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete "{sku.primaryId}"?</Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
    </>
  )
}

export {SkuActionsCell}
