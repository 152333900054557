import React, {useEffect, useRef, useState} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useDownloadExcel} from 'react-export-table-to-excel'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {getReport} from '../_requests'
import {useAuth} from '../../../../auth'

const ReportContent = () => {
  const tableRef = useRef(null)
  const [report, setReport] = useState<ReportData[]>([])
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null])

  const [totalExpenses, setTotalExpenses] = useState<number>(0)
  const [totalIncome, setTotalIncome] = useState<number>(0)
  const [totalLiabilities, setTotalLiabilities] = useState<number>(0)
  const [totalAssets, setTotalAssets] = useState<number>(0)

  const [OCDate, setOCDate] = useState('')
  const [loading, setLoading] = useState(false)
  const [btnExcelDisable, setBtnExcelDisable] = useState(true)
  const [btnClearDisable, setBtnClearDisable] = useState(true)
  const tableRefDebit = useRef(null)
  const tableRefCredit = useRef(null)
  const {mode} = useThemeMode()

  interface ReportData {
    coaId: number
    debit: string
    credit: string
    coa: {
      headName: string
      headCode: string
      parentHeadName: string
      headLevel: number
    }
  }

  const BalanceSheetReport = () => {
    setLoading(true)
    const Daterange = (document.getElementById('Daterange') as HTMLInputElement).value
    const dateRange = Daterange.split(' - ')
    const fromDate = dateRange[0].split('/').reverse().join('-')
    const toDate = dateRange[1].split('/').reverse().join('-')
    const data = {fromDate, toDate}

    console.log('Sending API request with data:', data)

    getReport(data).then((resRep: any) => {
      console.log('Received API response:', resRep)

      const {Income, Expenses, Assets, Liabilities} = resRep.data.data

      // Extract and set the Income data
      const incomeData = Income || []
      setTotalIncome(
        incomeData.reduce((total: any, item: any) => total + parseFloat(item.credit || '0'), 0)
      )

      // Extract and set the Expenses data
      const expensesData = Expenses || []
      setTotalExpenses(
        expensesData.reduce((total: any, item: any) => total + parseFloat(item.debit || '0'), 0)
      )

      // Extract and set the Assets data
      const assetsData = Assets || []
      setTotalAssets(
        assetsData.reduce((total: any, item: any) => total + parseFloat(item.credit || '0'), 0)
      )

      // Extract and set the Liabilities data
      const liabilitiesData = Liabilities || []
      setTotalLiabilities(
        liabilitiesData.reduce((total: any, item: any) => total + parseFloat(item.debit || '0'), 0)
      )

      // Combine all data and set the report state
      setReport([...incomeData, ...expensesData, ...assetsData, ...liabilitiesData])

      // Set the OCDate based on the selected date range
      setOCDate(`${fromDate} - ${toDate}`)
      setLoading(false)

      // Enable or disable the buttons as needed based on the new data.
      setBtnExcelDisable(Object.keys(resRep.data.data).length === 0)
      setBtnClearDisable(Object.keys(resRep.data.data).length === 0)
    })
  }

  const calculateTotalDebit = () => {
    return report.reduce((total, item) => {
      return total + parseFloat(item.debit || '0')
    }, 0)
  }

  const calculateTotalCredit = () => {
    return report.reduce((total, item) => {
      return total + parseFloat(item.credit || '0')
    }, 0)
  }

  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()

  const clearReport = () => {
    setReport([])
    setDateRange([null, null])
    setOCDate('')
    setBtnExcelDisable(true)
    setBtnClearDisable(true)
    setTotalExpenses(0)
    setTotalIncome(0)
    setTotalLiabilities(0)
    setTotalAssets(0)
  }

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRefDebit && tableRefCredit.current,
    filename: `BalanceSheet_Report_${fileName}`,
    sheet: 'Users',
  })

  return (
    <>
      <div className='card'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Balance Sheet Report</h3>
          </div>
          <div className='col-lg-2 pt-5'>
            <button
              className='btn btn-danger col-lg-12'
              disabled={btnExcelDisable}
              onClick={onDownload}
            >
              Export
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='row mb-10'>
            <div className='col-lg-4'></div>

            <div className='col-lg-4'>
              <label className='col-form-label fw-bold fs-6'>Filter By Date Range</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid'
                id='Daterange'
                selectsRange={true}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                onChange={(update) => {
                  setDateRange(update as [Date | null, Date | null])
                }}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
              />
            </div>

            <div className='col-lg-2 pt-12'>
              <button onClick={BalanceSheetReport} className='btn btn-primary col-lg-12'>
                Search
              </button>
            </div>
            <div className='col-lg-2 pt-12'>
              <button
                onClick={clearReport}
                className='btn btn-info col-lg-12'
                disabled={btnClearDisable}
              >
                Clear
              </button>
            </div>
          </div>

          <div className='table-responsive'>
            <div className='row'>
              <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                <th colSpan={2} className='min-w-120px'>
                  DATE: {OCDate}
                </th>
              </tr>
              <br />
              <br />
              {/* Debit Table */}
              <div className='col-md-6'>
                <table
                  className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
                  ref={tableRefDebit}
                >
                  <thead>
                    <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                      <th className='min-w-50px'>S. No</th>
                      <th className='min-w-120px'>Particulars</th>
                      <th className='min-w-120px'>Debit</th>
                    </tr>
                    <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                      <th colSpan={2} className='min-w-120px'></th>
                    </tr>
                  </thead>

                  <tbody>
                    <h5 className='fw-bolder m-0'>Income</h5>

                    {report.map((item, i) => (
                      //  console.log('item', item)
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.coa.headName}</td>
                        <td>{item.credit}</td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>

                      <td>
                        <h5>Total Income:</h5>
                      </td>
                      <td>{totalIncome}</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <h5 className='fw-bolder m-0'>Liabilities</h5>

                    {report.map((item, i) => (
                      //  console.log('item', item)
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.coa.headName}</td>
                        <td>{item.credit}</td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>

                      <td>
                        <h5>Total Liabilities:</h5>
                      </td>
                      <td>{totalLiabilities}</td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>
                        <h5>Total Debit:</h5>
                      </td>
                      <td>{calculateTotalDebit()}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Credit Table */}
              <div className='col-md-6'>
                <table
                  className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
                  ref={tableRefCredit}
                >
                  <thead>
                    <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                      <th className='min-w-50px'>S. No</th>
                      <th className='min-w-120px'>Particulars</th>
                      <th className='min-w-120px'>Credit</th>
                    </tr>
                    <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                      <th colSpan={2} className='min-w-120px'></th>
                    </tr>
                  </thead>

                  <tbody>
                    <h5 className='fw-bolder m-0'>Expenses</h5>
                    {report.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.coa.headName}</td>
                        <td>{item.debit}</td>
                        {/* <td>{item.credit}</td> */}
                      </tr>
                    ))}

                    <tr>
                      <td></td>
                      <td>
                        <h5>Total Expenses:</h5>
                      </td>
                      <td>{totalExpenses}</td>
                      {/* <td>Total Income: </td>
                  <td>{totalIncome}</td> */}
                    </tr>
                  </tbody>
                  <tbody>
                    <h5 className='fw-bolder m-0'>Assets</h5>
                    {report.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.coa.headName}</td>
                        <td>{item.debit}</td>
                        {/* <td>{item.credit}</td> */}
                      </tr>
                    ))}

                    <tr>
                      <td></td>
                      <td>
                        <h5>Total Assets:</h5>
                      </td>
                      <td>{totalAssets}</td>
                      {/* <td>Total Income: </td>
                  <td>{totalIncome}</td> */}
                    </tr>

                    <tr>
                      <td></td>
                      <td>
                        <h5>Total Credit:</h5>
                      </td>
                      <td>{calculateTotalCredit()}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ReportContent}
