import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {FinancialyearListHeader} from './components/header/FinancialyearListHeader'
import {FinancialyearTable} from './table/FinancialyearTable'
// import {FinancialYearEditModal} from './FinancialYear-Edit-Modal/FinancialYearEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useContext} from 'react'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'

const FinancialYearList = () => {
  const {itemIdForUpdate} = useListView()
  const {show, message, type} = useContext(AlertContext)
  return (
    <>
      <KTCard>
        {show && <AlertBox type={type === true ? 'success' : 'error'} message={message} />}
        <FinancialyearListHeader />
        <FinancialyearTable />
      </KTCard>
    </>
  )
}

const FinancialYearListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <FinancialYearList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {FinancialYearListWrapper}
