import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewProcess} from '../process-list/core/_requests'
import EditProcess from './EditProcess'


export const EditProcessWrapper = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewProcess(id).then((res) => {
      setData(res.data)
      console.log('viewProcessDetail', res.data)
    })
  }, [])
  if (data) {
    return <EditProcess />
    // initialValues={data}
  }
  return null
}
