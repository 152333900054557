/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik, FormikProps} from 'formik'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {deleteProductCategoryById, updateProductCategory} from '../../core/_requests'
import {ProductCategory, UpdateProductCategory} from '../../core/_models'
import {useNavigate, useParams} from 'react-router-dom'
import {Modal, Button, Form} from 'react-bootstrap'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
import {useAuth} from '../../../../../auth'
import EditProductCategoryModal from '../../../product-category-form/EditProductCategoryModal'


type Props = {
  productCategory: ProductCategory
}

const ProductCategoryActionsCell: FC<Props> = ({productCategory}) => {

  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false)
 
  const handleClose = () => setShow(false)

  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const deleteItem = () =>
    deleteProductCategoryById(productCategory.primaryId).then((res) => {
      console.log('delete', res)
      setShow(false)
      showAlert(res?.message, res?.status)
      navigate('/product-category')
    })
  const handleShow = () => {
    setShow(true)
  }
  return (
    <>
      {/* Edit Button */}
      <div className='d-flex justify-content-center flex-shrink-0'>
        <a
         onClick={openModal}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
         
        </a>
        <EditProductCategoryModal show={isModalOpen} onHide={closeModal} productCategory={productCategory} />
        
        {/* Delete Button */}
        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleShow}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>
    
      {/* Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete "{productCategory.primaryId}"?</Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
    </>
  )
}

export {ProductCategoryActionsCell}
