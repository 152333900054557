// @ts-nocheck
import {Column} from 'react-table'

import {ProcessTypeActionsCell} from './ProcessTypeActionsCell'
import {ProcessTypeCustomHeader} from './ProcessTypeCustomHeader'
import {ProcessType} from '../../core/_models'

const ProcessTypeColumns: ReadonlyArray<Column<ProcessType>> = [
  {
    Header: (props) => (
      <ProcessTypeCustomHeader tableProps={props} title='S.No' className='min-w-85px' />
    ),
    accessor: 'serialNo',
  },

  {
    Header: (props) => (
      <ProcessTypeCustomHeader
        tableProps={props}
        title='Process Type Name'
        className='min-w-125px'
      />
    ),
    accessor: 'name',
  },

  {
    Header: (props) => (
      <ProcessTypeCustomHeader tableProps={props} title='Description' className='min-w-125px' />
    ),
    accessor: 'description',
  },

  {
    Header: (props) => (
      <ProcessTypeCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <ProcessTypeActionsCell processType={props.data[props.row.index]} />,
  },
]

export {ProcessTypeColumns}
