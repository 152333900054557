import {useListView} from '../../core/ListViewProvider'
import {PriorityListToolbar} from './PriorityListToolbar'
import {PriorityListGrouping} from './PriorityListGrouping'
import {PriorityListSearchComponent} from './PriorityListSearchComponent'

const PriorityListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <PriorityListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <PriorityListGrouping /> : <PriorityListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {PriorityListHeader}
