import {useListView} from '../../core/ListViewProvider'
import {ProcessTypeListSearchComponent} from './ProcessTypeListSearchComponent'
import {ProcessTypeListToolbar} from './ProcessTypeListToolbar'

const ProcessTypeListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ProcessTypeListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <ProcessTypeListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ProcessTypeListHeader}
