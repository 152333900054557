import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Sku, SkuQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_SKU_URL = `${API_URL}sku/add`
const GET_SKU_URL = `${API_URL}sku/query`
const VIEW_SKU_URL = `${API_URL}sku/view`
const UPDATE_SKU_URL = `${API_URL}sku/update`
const DELETE_SKU_URL = `${API_URL}sku/delete`

//AuthVerify
var AuthVerify = getToken.call(this)

//Sku LIST
const getSku = (query: string): Promise<SkuQueryResponse> => {
  return axios
    .post(`${GET_SKU_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<SkuQueryResponse>) => {
      console.log('get-Sku==>', response.data)
      return response.data
    })
}

//Sku ADD
const createSku = (data: any): Promise<Sku | undefined> => {
  return axios.post(CREATE_SKU_URL, data, getToken()).then((response: any) => response.data)
}

//Sku VEIW
const viewSku = (id: any) => {
  return axios.get(`${VIEW_SKU_URL}/${id}`, getToken()).then((response) => {
    console.log('view-Sku', response.data)
    return response.data
  })
}

//Sku UPDATE
const updateSku = (data: any, id: any) => {
  console.log('reqData=update-Sku', data)
  return axios.put(`${UPDATE_SKU_URL}/${id}`, data, getToken()).then((response) => {
    console.log('updatemp-Sku', response.data)
    return response.data
  })
}

//Sku DELETE
const deleteSkuById = (id: any) => {
  return axios.post(`${DELETE_SKU_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

export {getSku, createSku, viewSku, updateSku, deleteSkuById}
