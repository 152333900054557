import {ID, Response} from '../../../../../../_metronic/helpers'
export type Size = {
  id?: ID
  primaryId?: number
  name?: string
  type?: string
 
  message?: string
  status?: string
  createdBy?: number
}

export type SizeQueryResponse = Response<Array<Size>>

export const initialSize: Size = {
  name: '',
  type: '',
  
  createdBy: 0,
}
export type UpdateSize = {
  id?: ID
  primaryId?: number
  name?: string
  type?: string
 
  status?: string
  updatedBy?: number
}

export type UpdateSizeQueryResponse = Response<Array<UpdateSize>>

export const initialUpdateSize: UpdateSize = {
  name: '',
  type: '',
  
}
