import {Button, Modal} from 'react-bootstrap'
import React, {useContext, useState} from 'react'
import * as Yup from 'yup'
import {FormikProps, useFormik} from 'formik'
import {updateSize} from '../size-list/core/_requests'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../size-list/core/QueryResponseProvider'
import { UpdateSize, Size } from '../size-list/core/_models'


// Validation
const SizeDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),

  
})
type Props = {
  size: Size
}
type modalProps = {
  show: boolean;
  onHide: () => void;
  size: Size; // Add Size to the modalProps type
};
const SizeModal: React.FC<modalProps> = ({ show, onHide, size }) => {
 
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Size>(size)
  const navigate = useNavigate()
  const {id} = useParams()
  // Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)

  console.log("size",size)
  const formik: FormikProps<Size> = useFormik<Size>({
    initialValues: size,
    
    validationSchema: SizeDetailsSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const updatedBy = currentUser?.userId;
      const updatedData = { ...data, ...values, updatedBy };
      updateSize(updatedData, size.primaryId).then((res) => {
        // Set the updated data to the state
        setData(updatedData);
        navigate('/size', { replace: true });
        showAlert(res?.message, res?.status);
      });
    },
  });
  console.log('Initial values:', formik.initialValues);
  console.log('Initial values:formik', formik);
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Size</Modal.Title>
      </Modal.Header>
      <form
        id='kt_modal_add_size_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <Modal.Body>
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_add_size_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_size_header'
            data-kt-scroll-wrappers='#kt_modal_add_size_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className='row mb-6'>
           
           <div className='row'>
             <div className='col-lg- fv-row'>
               <label className='col-form-label required fw-bold fs-6'>Name</label>
               <input
                 type='text'
                 className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                 placeholder='Enter Size'
                 {...formik.getFieldProps('name')}
               />
               {formik.touched.name && formik.errors.name && (
                 <div className='fv-plugins-message-container'>
                   <div className='fv-help-block'>{formik.errors.name}</div>
                 </div>
               )}
             </div>
           </div>
         </div>
    
          </div>
        </Modal.Body>
        {/* end::Scroll */}
        <Modal.Footer>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary me-3' disabled={loading}>
              {!loading ? (
                'Save Changes'
              ) : (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button
              type='button'
              className='btn btn-danger me-2 '
              disabled={loading}
              onClick={onHide}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default SizeModal
