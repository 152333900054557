import React, {FC, useContext, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {getEmployee, updateSalaryadvance} from '../salary-advance-list/core/_requests'
import {UpdateSalaryadvance} from '../salary-advance-list/core/_models'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'

const SalaryadvanceDetailsSchema = Yup.object().shape({
  employeeId: Yup.string().required('Employee name is required'),
  amount: Yup.string().required('Amount is required'),
  salaryMonth: Yup.string().required('Salary Month is required'),
  releaseDate: Yup.string().required('Release Date is required'),
})
type Props = {
  initialValues: UpdateSalaryadvance
}
export const EditSalaryadvance: FC<Props> = ({initialValues}) => {
  const [data, setData] = useState<UpdateSalaryadvance>(initialValues)
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {id} = useParams()
  const [employee, setEmployee] = useState<any>([])

  const [loading, setLoading] = useState(false)

  //get type
  const {currentUser} = useAuth()

  const type = currentUser?.branchId
  //end get type
  const formik = useFormik<UpdateSalaryadvance>({
    initialValues,
    validationSchema: SalaryadvanceDetailsSchema,

    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedBy = currentUser?.userId // Replace 'updatedBy' with the actual key in currentUser
      const updatedData = {...data, ...values, updatedBy}
      updateSalaryadvance(updatedData, id).then((res) => {
        console.log('mes', res?.data?.message)
        setData(initialValues)
        navigate('/salary-advance', {replace: true})
        showAlert(res?.message, res?.status)
      })
    },
  })
  useEffect(() => {
    getEmployee().then((res: any) => {
      setEmployee(res?.data?.data)
    })
  }, [])
  console.log('ini', initialValues.releaseDate)
  console.log('for', formik)
  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='card mb-5 mb-xl-10 p-9'>
        <div className='row mb-6'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <label className='col-form-label required fw-bold fs-6'>Release Date</label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Release Date'
                {...formik.getFieldProps('releaseDate')}
              />
              {formik.touched.releaseDate && formik.errors.releaseDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.releaseDate}</div>
                </div>
              )}
            </div>

            <div className='col-lg-6 fv-row'>
              <label className='col-form-label required fw-bold fs-6'>Employee Name</label>
              <select
                className='form-control form-control-lg form-control-solid'
                placeholder='Employee Name'
                {...formik.getFieldProps('employeeId')}
              >
                <option value=''>Select Employee Name</option>
                {employee.map((item: any, i: any) => {
                  return (
                    <option key={i} value={item.id}>
                      {item.employeeName}
                    </option>
                  )
                })}
              </select>
              {formik.touched.employeeId && formik.errors.employeeId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.employeeId}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row mb-6'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <label className='col-form-label required fw-bold fs-6'>Amount</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Amount'
                {...formik.getFieldProps('amount')}
              />
              {formik.touched.amount && formik.errors.amount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.amount}</div>
                </div>
              )}
            </div>
            <div className='col-lg-6 fv-row'>
              <label className='col-form-label required fw-bold fs-6'>Salary Month</label>
              <input
                type='month' // Use type "month" to display the month and year picker
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Salary Month'
                {...formik.getFieldProps('salaryMonth')}
                min='1900-01' // Set a minimum date as needed (January 1900 in this case)
                max='2100-12' // Set a maximum date as needed (December 2100 in this case)
              />

              {formik.touched.salaryMonth && formik.errors.salaryMonth && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.salaryMonth}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button type='submit' className='btn btn-primary' disabled={loading}>
          {!loading && 'Save Changes'}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
export default EditSalaryadvance
