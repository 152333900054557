import React, {useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import {useAuth} from '../../../auth'
import {Process, initialProcess as initialValues} from '../process-list/core/_models'
import {
  createProcess,
  getSalesOrder,
  getProcessType,
  getPriority,
  getMaterial,
  getMachine,
  getProcessStatus,
} from '../process-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../process-list/core/QueryResponseProvider'

// Validation schema
const processDetailsSchema = Yup.object().shape({
  // date: Yup.date().required('Process Date is required'),
  processDetail: Yup.array().of(
    Yup.object().shape({
      // processtypeId: Yup.number().required('Process Type is required'),
    })
  ),
})
// Define a Sales Order
interface SalesOrder {
  id: number
  orderId: string
  invoiceNumber: string
  customerId: number
  totalQuantity: number
  totalDiscount: number
  totalTax: number
  totalAmount: number
  paidAmount: number
  description: string
  isCredit: boolean

  date: any
  customer: {
    customerName: number
  }

  salesorderdetails: {
    productId: number
    styleNumber: string
    unitId: string
    sizeId: number
    colorId: number
    quantity: number
    amount: number
    taxId: number
    taxValue: number
    discount: number
    discountValue: number
    total: number
    product: {
      id: number
      productName: string
    }
    priority: {
      id: number
      priorityName: string
    }
    size: {
      id: number
      sizeName: string
    }
    color: {
      id: number
      colorName: string
    }
    orderstatus: {
      id: number
      orderstatusName: string
    }
    ordercategory: {
      id: number
      ordercategoryName: string
    }
  }
}
const ProcessDetails = () => {
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Process>(initialValues)
  const navigate = useNavigate()
  const [salesOrder, setSalesOrder] = useState<SalesOrder[]>([])
  const [material, setMaterialList] = useState([])
  const [orderIdForModal, setOrderIdForModal] = useState(null) // State to manage orderId for the modal
  const [showModal, setShowModal] = useState(false) // State to manage modal visibility
  const [orderStatusId, setOrderStatusId] = useState('')
  const [processType, setProcessType] = useState([])
  const [status, setProcessStatus] = useState([])
  const [priority, setPriority] = useState([])
  const [machine, setMachine] = useState([])
  const [showProcessDetailFields, setShowProcessDetailFields] = useState(false)
  useEffect(() => {
    getMaterial().then((res) => {
      setMaterialList(res?.data?.data)
    })
  }, [])
  useEffect(() => {
    getProcessType().then((res) => {
      setProcessType(res?.data?.data)
    })
  }, [])
  useEffect(() => {
    getMachine().then((res) => {   
      setMachine(res?.data?.data)})
  }, [])
  useEffect(() => {
    getPriority().then((res) => {
      setPriority(res?.data?.data)
    })
  }, [])
  useEffect(() => {
    getProcessStatus().then((res) => {
      setProcessStatus(res?.data?.data)
    })
  }, [])

  useEffect(() => {
    getSalesOrder().then((res) => {
      console.log('getSalesOrder', res)
      console.log('getSalesOrder', res?.data?.data)

      setSalesOrder(res?.data?.data)
    })
  }, [])

  const handleOrderSelection = (selectedOrderId: any, setFieldValue: any, values: any) => {
    // Find the selected order data
    const selectedOrderData = salesOrder.find(
      (item: any) => item.id === parseInt(selectedOrderId, 10)
    )

    if (selectedOrderData) {
      setFieldValue('customerName', selectedOrderData.customer.customerName)
      setFieldValue('orderDate', selectedOrderData.date)
      // Check if salesorderdetails exists and is an array
      if (Array.isArray(selectedOrderData.salesorderdetails)) {
        // Extract sales order details
        const orderDetails = selectedOrderData.salesorderdetails.map((detail: any) => ({
          styleNumber: detail.styleNumber,
          quantity: detail.quantity,
          amount: detail.amount,
          total: detail.total,
          unitId: detail.unitId,
          product: {
            productName: detail.product.productName,
          },
          color: {
            colorName: detail.color.colorName,
          },
          size: {
            sizeName: detail.size.sizeName,
          },
          priority: {
            priorityName: detail.priority.priorityName,
          },
          // orderstatus: detail.orderstatus.orderstatusName,
        }))

        // Update formik values with the new sales order details
        setFieldValue('salesorderdetails', orderDetails)
      }
    }
  }



// Modify the click handler for the "Process" button to toggle the visibility
const handleProcessButtonClick = () => {
  setShowProcessDetailFields(!showProcessDetailFields);
};
  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        initialValues={{
          ...initialValues,
          salesorderdetails: [],


        }}
        enableReinitialize={true}
        validationSchema={processDetailsSchema}
        onSubmit={(values, {resetForm}) => {
          const updatedValues = {
            ...values,
            process: values.processDetail,
          }

          createProcess(updatedValues)
            .then((res) => {
              resetForm({
                ...initialValues,
              })
              setData(initialValues)
              navigate('/process', {replace: true})
              refetch()
              setLoading(false)
              showAlert(res?.message, res?.status)
            })
            .catch((error) => {
              console.error('Error creating process:', error)
            })
            .finally(() => {
              setLoading(false)
            })
        }}
      >
        {({values, setFieldValue}) => (
          <Form>
            <div id='kt_account_profile_details'>
              <div className='card-body border-top p-9'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>
                    Order Id / Style Number{' '}
                  </label>
                  <Field
                    as='select'
                    name='orderId'
                    className='form-control form-control-lg form-control-solid '
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      const selectedOrderId = e.target.value
                      handleOrderSelection(selectedOrderId, setFieldValue, values)
                    }}
                  >
                    <option value=''>Select Order</option>
                    {salesOrder.map((item: any) => (
                      <option key={item.id} value={item.id}>
                        {item.orderId}
                      </option>
                    ))}
                  </Field>
                </div>
                <br />
                <br />
                <div className='row mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Order Name </label>'
                      <Field
                        name='customerName'
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Customer Name'
                      />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Order Date</label>
                      <Field
                        name='orderDate'
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Date'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='date'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Process Date</label>
                      <Field
                        name='date'
                        type='date'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Date'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='date'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Details</label>
                      <Field
                        as='textarea'
                        name='description'
                        className='form-control form-control-lg form-control-solid'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='description'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6 card-footer d-flex justify-content'>
                <div className='table-container'>
                  <div className='table-container'>
                    <FieldArray
                      name='salesorderdetails'
                      render={(arrayHelpers) => (
                        <table className='table'>
                          <thead>
                            <tr>
                              <th>Style Number</th>
                              <th>Product</th>
                              <th>Color</th>
                              <th>Size</th>
                              <th>Quantity</th>
                              <th>Amount</th>
                              <th>Total</th>
                              <th>Priority</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.salesorderdetails.map((salesorderdetail, index) => (
                              <tr key={index}>
                                <td>
                                  <Field
                                    name={`salesorderdetails[${index}].styleNumber`}
                                    className='form-control'
                                    type='text'
                                    placeholder='Style Number'
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`salesorderdetails[${index}].product.productName`}
                                    className='form-control'
                                    type='text'
                                    placeholder='Product Name'
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`salesorderdetails[${index}].color.colorName`}
                                    className='form-control'
                                    type='text'
                                    placeholder='Color'
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`salesorderdetails[${index}].size.sizeName`}
                                    className='form-control'
                                    type='text'
                                    placeholder='Size'
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`salesorderdetails[${index}].quantity`}
                                    className='form-control'
                                    type='text'
                                    placeholder='Quantity'
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`salesorderdetails[${index}].amount`}
                                    className='form-control'
                                    type='text'
                                    placeholder='Amount'
                                  />
                                </td>

                                <td>
                                  <Field
                                    name={`salesorderdetails[${index}].total`}
                                    className='form-control'
                                    type='text'
                                    placeholder='Total'
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`salesorderdetails[${index}].priority.priorityName`}
                                    className='form-control'
                                    type='text'
                                    placeholder='Priority'
                                  />
                                </td>
                                <td>
                                  {/* Process button */}
                                  <button
                  type='button'
                  onClick={handleProcessButtonClick}
                  className='btn btn-primary btn-sm'
                >
                  {showProcessDetailFields ? 'Hide Process ' : 'Show Process '}
                </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>  
{/* process flow add start */}
{showProcessDetailFields && (
            <div className='row mb-6 card-footer d-flex justify-content'>
              <div className='table-container'>
                <FieldArray
                  name='processDetail'
                  render={(arrayHelpers) => (
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>Process Type</th>
                          <th>Material</th>
                          <th>Machine</th>
                          <th>Priority</th>
                          <th>Process Time</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.processDetail.map((processDetail, index) => (
                          <tr key={index}>
                            <td>
                              <Field
                                as='select'
                                name={`processDetail[${index}].processtypeId`}
                                className='form-control'
                              >
                                <option value=''>Select Type</option>
                                {processType.map((item: any, i: any) => (
                                  <option key={i} value={item.id}>
                                    {item.processTypeName}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className='fieldError'
                                name={`processDetail[${index}].processtypeId`}
                                component='div'
                              />
                            </td>
                            <td>
                              <Field
                                as='select'
                                name={`processDetail[${index}].materialId`}
                                className='form-control'
                              >
                                <option value=''>Select Material</option>
                                {material.map((item: any, i: any) => (
                                  <option key={i} value={item.id}>
                                    {item.materialName}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className='fieldError'
                                name={`processDetail[${index}].materialId`}
                                component='div'
                              />
                            </td>
                            <td>
                              <Field
                                as='select'
                                name={`processDetail[${index}].machineId`}
                                className='form-control'
                              >
                                <option value=''>Select Machine</option>
                                {machine.map((item: any, i: any) => (
                                  <option key={i} value={item.id}>
                                    {item.headName}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className='fieldError'
                                name={`processDetail[${index}].machineId`}
                                component='div'
                              />
                            </td>
                            <td>
                              <Field
                                as='select'
                                name={`processDetail[${index}].priorityId`}
                                className='form-control'
                              >
                                <option value=''>Select Priority</option>
                                {priority.map((item: any, i: any) => (
                                  <option key={i} value={item.id}>
                                    {item.priorityName}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className='fieldError'
                                name={`processDetail[${index}].priorityId`}
                                component='div'
                              />
                            </td>
                            <td>
                              <Field
                                type='time'
                                className='form-control'
                                name={`processDetail[${index}].processTime`}
                              />
                              <ErrorMessage
                                className='fieldError'
                                name={`processDetail[${index}].processTime`}
                                component='div'
                              />
                            </td>
                            <td>
                              {index === 0 ? (
                                <button
                                  type='button'
                                  onClick={() => arrayHelpers.push({})}
                                  className='btn btn-primary btn-sm'
                                >
                                  Add
                                </button>
                              ) : (
                                <button
                                  type='button'
                                  onClick={() => arrayHelpers.remove(index)}
                                  className='btn btn-danger btn-sm'
                                >
                                  Remove
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                />
              </div>
            </div>    )}
            {/* process flow add End */}
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading ? 'Save Changes' : 'Please wait...'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ProcessDetails
