import axios, {AxiosResponse} from 'axios'
import {SalesReturn, SalesReturnQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_SalesReturn_URL = `${API_URL}sales-return/add`
const GET_SalesReturn_URL = `${API_URL}sales-return/query/`
const VIEW_SalesReturn_URL = `${API_URL}sales-return/view/`
const UPDATE_SalesReturn_URL = `${API_URL}sales-return/update`
const DELETE_SalesReturn_URL = `${API_URL}sales-return/delete`
const GET_PRODUCT_URL = `${API_URL}custom/get-product`
const GET_SALES_URL = `${API_URL}custom/get-sales`
const GET_SKU_URL = `${API_URL}custom/get-sku`
//AuthVerify
var AuthVerify = getToken.call(this)

//SalesReturn LIST
const getSalesReturn = (query: string): Promise<SalesReturnQueryResponse> => {
  return axios
    .post(`${GET_SalesReturn_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<SalesReturnQueryResponse>) => {
      console.log('getSalesReturn==>', response.data)
      return response.data
    })
}

//SalesReturn ADD
const createSalesReturn = (data: any): Promise<SalesReturn | undefined> => {
  return axios.post(CREATE_SalesReturn_URL, data, getToken()).then((response: any) => response.data)
}

//SalesReturn VEIW
const viewSalesReturn = (id: any) => {
  return axios.get(`${VIEW_SalesReturn_URL}/${id}`, getToken()).then((response) => {
    console.log('view-SalesReturn---api', response.data)
    return response.data
  })
}

//SalesReturn UPDATE
const updateSalesReturn = (data: any, id: any) => {
  console.log('reqData=update-api', data)
  return axios.put(`${UPDATE_SalesReturn_URL}/${id}`, data, getToken()).then((response) => {
    console.log('update-SalesReturn-api', response.data)
    return response.data
  })
}

//SalesReturn DELETE
const deleteSalesReturnById = (id: any) => {
  return axios.post(`${DELETE_SalesReturn_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}
//GET Product
const getProduct = async () => {
  const res = await axios.get(`${GET_PRODUCT_URL}`, getToken())
  console.log('get-getProduct', res)
  return res
}
//GET sales
const getSales = async () => {
  const res = await axios.get(`${GET_SALES_URL}`, getToken())
  console.log('get-getSales-api', res)
  return res
}
//GET SKU
const getSku = async () => {
  const res = await axios.get(`${GET_SKU_URL}`, getToken())
  console.log('sku-api', res)
  return res
}
export {
  getSalesReturn,
  createSalesReturn,
  viewSalesReturn,
  updateSalesReturn,
  deleteSalesReturnById,
  getProduct,
  getSales,
  getSku,

}
