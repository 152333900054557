import axios, {AxiosResponse} from 'axios'
import {Openingbalance, OpeningbalanceQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_OPENINGBALANCE_URL = `${API_URL}opening-balance/add`
const GET_OPENINGBALANCE_URL = `${API_URL}opening-balance/query`
const VIEW_OPENINGBALANCE_URL = `${API_URL}opening-balance/view`
const UPDATE_OPENINGBALANCE_URL = `${API_URL}opening-balance/update`
const DELETE_OPENINGBALANCE_URL = `${API_URL}opening-balance/delete`
const GET_ACCOUNTNAME_URL = `${API_URL}custom/get-ledger`
const GET_YEAR_FINANCIALYEAR_URL = `${API_URL}custom/get-financial-year`
//AuthVerify
var AuthVerify = getToken.call(this)

//Openingbalance LIST
const getOpeningbalance = (query: string): Promise<OpeningbalanceQueryResponse> => {
  return axios
    .post(`${GET_OPENINGBALANCE_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<OpeningbalanceQueryResponse>) => {
      console.log('getOpeningbalance==>', response.data)
      return response.data
    })
}

const createOpeningbalance = (data: any): Promise<Openingbalance | undefined> => {
  console.log('add==Openingbalance==>', data)
  return axios
    .post(CREATE_OPENINGBALANCE_URL, data, getToken())
    .then((response: AxiosResponse<Openingbalance>) => response.data) // Specify the response type
    .catch((error) => {
      console.error('Error creating Openingbalance:', error)
      return undefined // Return undefined in case of an error
    })
}

//Openingbalance VEIW
const viewOpeningbalance = (id: any) => {
  return axios.get(`${VIEW_OPENINGBALANCE_URL}/${id}`, getToken()).then((response) => {
    console.log('view-Openingbalance', response.data)
    return response.data
  })
}

//Openingbalance UPDATE
const updateOpeningbalance = (data: any, id: any) => {
  console.log('reqData=update-api', data)
  return axios.put(`${UPDATE_OPENINGBALANCE_URL}/${id}`, data, getToken()).then((response) => {
    console.log('update-Openingbalance-api', response.data)
    return response.data
  })
}

//Openingbalance DELETE
const deleteOpeningbalanceById = (id: any) => {
  return axios.post(`${DELETE_OPENINGBALANCE_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}
//GET ledger
const getLedger = async () => {
  const res = await axios.get(`${GET_ACCOUNTNAME_URL}`, getToken())
  // console.log('ledger--get', res)
  return res
}
//GET Financial Year

const getCloseFinancialYear = async () => {
  const res = await axios.get(`${GET_YEAR_FINANCIALYEAR_URL}`, getToken())
  // console.log('getCloseFinancialYear-reeee', res)
  return res
}
export {
  getOpeningbalance,
  createOpeningbalance,
  viewOpeningbalance,
  updateOpeningbalance,
  deleteOpeningbalanceById,
  getLedger,
  getCloseFinancialYear,
}
