import {ID, Response} from '../../../../../../_metronic/helpers'
export type ProductCategory = {
  id?: ID
  primaryId?: number
  name?: string
  description?: string
 
  message?: string
  status?: string
  createdBy?: number
}

export type ProductCategoryQueryResponse = Response<Array<ProductCategory>>

export const initialProductCategory: ProductCategory = {
  name: '',
  description: '',
  
  createdBy: 0,
}
export type UpdateProductCategory = {
  id?: ID
  primaryId?: number
  name?: string
  description?: string
 
  status?: string
  updatedBy?: number
}

export type UpdateProductCategoryQueryResponse = Response<Array<UpdateProductCategory>>

export const initialUpdateProductCategory: UpdateProductCategory = {
  name: '',
  description: '',
  
}
