import axios, {AxiosResponse} from 'axios'
import {SalesOrder, SalesOrderQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_SALESORDER_URL = `${API_URL}sales-order/add`
const GET_SALESORDER_URL = `${API_URL}sales-order/query`
const VIEW_SALESORDER_URL = `${API_URL}sales-order/view`
const UPDATE_SALESORDER_URL = `${API_URL}sales-order/update`
const DELETE_SALESORDER_URL = `${API_URL}sales-order/delete`
const GET_ORDERCATEGORY_URL = `${API_URL}custom/get-ordercategory`
const GET_PRODUCT_URL = `${API_URL}custom/get-product`
const GET_CUSTOMER_URL = `${API_URL}custom/get-customer`
const GET_COLOR_URL = `${API_URL}custom/get-color`
const GET_SIZE_URL = `${API_URL}custom/get-size`
const GET_UNIT_URL = `${API_URL}custom/get-unit`
const GET_SKU_URL = `${API_URL}custom/get-sku`
const GET_PAYMENT_URL = `${API_URL}custom/get-payment`
const GET_PRIORITY_URL = `${API_URL}custom/get-priority`
//AuthVerify
var AuthVerify = getToken.call(this)

//SalesOrder LIST
const getSalesOrder = (query: string): Promise<SalesOrderQueryResponse> => {
  return axios
    .post(`${GET_SALESORDER_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<SalesOrderQueryResponse>) => {
      console.log('getSalesOrder==>', response.data)
      return response.data
    })
}

//SalesOrder ADD
const createSalesOrder = (data: any): Promise<SalesOrder | undefined> => {
  return axios.post(CREATE_SALESORDER_URL, data, getToken()).then((response: any) => response.data)
}

//SalesOrder VEIW
const viewSalesOrder = (id: any) => {
  return axios.get(`${VIEW_SALESORDER_URL}/${id}`, getToken()).then((response) => {
    console.log('view-SalesOrder', response.data)
    return response.data
  })
}

//SalesOrder UPDATE
const updateSalesOrder = (data: any, id: any) => {
  console.log('SalesOrder=update', data)
  return axios.put(`${UPDATE_SALESORDER_URL}/${id}`, data, getToken()).then((response) => {
    console.log('update-SalesOrder', response.data)
    return response.data
  })
}

//SalesOrder DELETE
const deleteSalesOrderById = (id: any) => {
  return axios.post(`${DELETE_SALESORDER_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}
//GET Order Category
const getOrderCategory = async () => {
  const res = await axios.get(`${GET_ORDERCATEGORY_URL}`, getToken())
  console.log('type-api', res)
  return res
}
//GET Product
const getProduct = async () => {
  const res = await axios.get(`${GET_PRODUCT_URL}`, getToken())
  console.log('getProduct-api', res)
  return res
}
//GETCustomer
const getCustomer = async () => {
  const res = await axios.get(`${GET_CUSTOMER_URL}`, getToken())
  console.log('getCustomer-api', res)
  return res
}
//getUnit
const getUnit = async () => {
  const res = await axios.get(`${GET_UNIT_URL}`, getToken())
  console.log('getUnit-api', res)
  return res
}
//getColor
const getColor = async () => {
  const res = await axios.get(`${GET_COLOR_URL}`, getToken())
  console.log('getColor-api', res)
  return res
}
//getSize
const getSize = async () => {
  const res = await axios.get(`${GET_SIZE_URL}`, getToken())
  console.log('getSize-api', res)
  return res
}
//getPayment
const getPayment = async () => {
  const res = await axios.get(`${GET_PAYMENT_URL}`, getToken())
  console.log('getPayment-api', res)
  return res
}
//getPriority
const getPriority = async () => {
  const res = await axios.get(`${GET_PRIORITY_URL}`, getToken())
  console.log('GET_PRIORITY_URL-api', res)
  return res
}


export {getSalesOrder, createSalesOrder, viewSalesOrder, updateSalesOrder, deleteSalesOrderById, getOrderCategory, 
  getUnit,getProduct,getCustomer,getSize,getColor,getPayment,getPriority}
