// @ts-nocheck
import {Column} from 'react-table'
import {EmployeeInfoCell} from './EmployeeInfoCell'
import {EmployeeTwoStepsCell} from './EmployeeTwoStepsCell'
import {EmployeeActionsCell} from './EmployeeActionsCell'
import {EmployeeCustomHeader} from './EmployeeCustomHeader'
import {Employee} from '../../core/_models'

const EmployeeColumns: ReadonlyArray<Column<Employee>> = [
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='S.No' className='min-w-45px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='ID' className='min-w-85px' />
    ),
    accessor: 'employeeId',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <EmployeeInfoCell employees={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Role' className='min-w-105px' />
    ),
    accessor: 'role.roleName',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Department' className='min-w-105px' />
    ),
    accessor: 'department.departmentName',
  },
  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Mobile' className='min-w-85px' />
    ),
    accessor: 'contactNumber',
  },

  {
    Header: (props) => (
      <EmployeeCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <EmployeeActionsCell employees={props.data[props.row.index]} />,
  },
]

export {EmployeeColumns}
