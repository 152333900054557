import {DepartmentListHeader} from './components/header/DepartmentListHeader'
import {DepartmentTable} from './table/DepartmentTable'
import {KTCard} from '../../../../../_metronic/helpers'
import {AddDepartment, EditDepartment} from '../department-form'
import {useContext} from 'react'
import {MyContext} from './ContextFile'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ContextProvider} from './ContextProvider'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
const DepartmentAdd = () => {
  const {state, setState} = useContext(MyContext)

  return (
    <>
      <KTCard>{state.editclicked === true ? <EditDepartment /> : <AddDepartment />}</KTCard>
    </>
  )
}

const DepartmentList = () => {
  return (
    <>
      <KTCard>
        <DepartmentListHeader />
        <DepartmentTable />
      </KTCard>
    </>
  )
}

const DepartmentMain = () => {
  const {show, message, type} = useContext(AlertContext)
  return (
    <div className='row'>
      {show && <AlertBox type={type === true ? 'success' : 'error'} message={message} />}
      <div className='col-lg-6'>
        <DepartmentAdd />
      </div>
      <div className='col-lg-6'>
        <DepartmentList />
      </div>
    </div>
  )
}

const DepartmentListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ContextProvider>
          <DepartmentMain />
        </ContextProvider>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {DepartmentListWrapper}
