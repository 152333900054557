// @ts-nocheck
import {Column} from 'react-table'
import {SalesOrderInfoCell} from './SalesOrderInfoCell'
import {SalesOrderTwoStepsCell} from './SalesOrderTwoStepsCell'
import {SalesOrderActionsCell} from './SalesOrderActionsCell'
import {SalesOrderCustomHeader} from './SalesOrderCustomHeader'
import {SalesOrder} from '../../core/_models'

const SalesOrderColumns: ReadonlyArray<Column<SalesOrder>> = [
  {
    Header: (props) => (
      <SalesOrderCustomHeader tableProps={props} title='S.No' className='min-w-85px' />
    ),
    accessor: 'serialNo',
  },

  {
    Header: (props) => (
      <SalesOrderCustomHeader tableProps={props} title='Order Id' className='min-w-125px' />
    ),
    accessor: 'orderId',
  },
 
  {
    Header: (props) => (
      <SalesOrderCustomHeader tableProps={props} title='Customer Name' className='min-w-125px' />
    ),
    accessor: 'customer.customerName',
  },
  {
    Header: (props) => (
      <SalesOrderCustomHeader tableProps={props} title='Date' className='min-w-125px' />
    ),
    accessor: 'date',
  },
  {
    Header: (props) => (
      <SalesOrderCustomHeader tableProps={props} title='Total Quantity' className='min-w-125px' />
    ),
    accessor: 'totalQuantity',
  },
  {
    Header: (props) => (
      <SalesOrderCustomHeader tableProps={props} title='Total Amount' className='min-w-125px' />
    ),
    accessor: 'totalAmount',
  },
 
  // {
  //   Header: (props) => (
  //     <SalesOrderCustomHeader tableProps={props} title='Quantity' className='min-w-125px' />
  //   ),
  //   accessor: 'quantity',
  // },
  // {
  //   Header: (props) => (
  //     <SalesOrderCustomHeader tableProps={props} title='Priority' className='min-w-125px' />
  //   ),
  //   accessor: 'priority',
  // },
  {
    Header: (props) => (
      <SalesOrderCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <SalesOrderActionsCell salesOrder={props.data[props.row.index]} />,
  },
]

export {SalesOrderColumns}
