import axios, {AxiosResponse} from 'axios'

import {ProductCategory, ProductCategoryQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_ProductCategory_URL = `${API_URL}product-category/add`
const GET_ProductCategory_URL = `${API_URL}product-category/query`
const VIEW_ProductCategory_URL = `${API_URL}product-category/view`
const UPDATE_ProductCategory_URL = `${API_URL}product-category/update`
const DELETE_ProductCategory_URL = `${API_URL}product-category/delete`


//AuthVerify
var AuthVerify = getToken.call(this)

//ProductCategory LIST
const getProductCategory = (query: string): Promise<ProductCategoryQueryResponse> => {
  return axios
    .post(`${GET_ProductCategory_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<ProductCategoryQueryResponse>) => {
      console.log('get-ProductCategory==>', response.data)
      return response.data
    })
}

//ProductCategory ADD
const createProductCategory = (data: any): Promise<ProductCategory | undefined> => {
  return axios
    .post(CREATE_ProductCategory_URL, data, getToken())
    .then((response: any) => response.data)
}

//ProductCategory VEIW
const viewProductCategory = (id: any) => {
  return axios.get(`${VIEW_ProductCategory_URL}/${id}`, getToken()).then((response) => {
    console.log('view-ProductCategory', response.data)
    return response.data
  })
}

//ProductCategory UPDATE
const updateProductCategory = (data: any, id: any) => {
  console.log('reqData=update-ProductCategory', data)
  return axios.put(`${UPDATE_ProductCategory_URL}/${id}`, data, getToken()).then((response) => {
    console.log('updatemp-ProductCategory', response.data)
    return response.data
  })
}

//ProductCategory DELETE
const deleteProductCategoryById = (id: any) => {
  return axios.post(`${DELETE_ProductCategory_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

export {
  getProductCategory,
  createProductCategory,
  viewProductCategory,
  updateProductCategory,
  deleteProductCategoryById,
 
}
