import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {ProcessType, ProcessTypeQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_TYPE_URL = `${API_URL}process-type/add`
const GET_TYPE_URL = `${API_URL}process-type/query`
const VIEW_TYPE_URL = `${API_URL}process-type/view`
const UPDATE_TYPE_URL = `${API_URL}process-type/update`
const DELETE_TYPE_URL = `${API_URL}process-type/delete`

//AuthVerify
var AuthVerify = getToken.call(this)

//ProcessType LIST
const getProcessType = (query: string): Promise<ProcessTypeQueryResponse> => {
  return axios
    .post(`${GET_TYPE_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<ProcessTypeQueryResponse>) => {
      console.log('get-ProcessType==>', response.data)
      return response.data
    })
}

//ProcessType ADD
const createProcessType = (data: any): Promise<ProcessType | undefined> => {
  return axios.post(CREATE_TYPE_URL, data, getToken()).then((response: any) => response.data)
}

//ProcessType VEIW
const viewProcessType = (id: any) => {
  return axios.get(`${VIEW_TYPE_URL}/${id}`, getToken()).then((response) => {
    console.log('view-ProcessType', response.data)
    return response.data
  })
}

//ProcessType UPDATE
const updateProcessType = (data: any, id: any) => {
  console.log('reqData=update-ProcessType', data)
  return axios.put(`${UPDATE_TYPE_URL}/${id}`, data, getToken()).then((response) => {
    console.log('updatemp-ProcessType', response.data)
    return response.data
  })
}

//ProcessType DELETE
const deleteProcessTypeById = (id: any) => {
  return axios.post(`${DELETE_TYPE_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

export {getProcessType, createProcessType, viewProcessType, updateProcessType, deleteProcessTypeById}
