import React, {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {UpdateCustomer} from '../customer-list/core/_models'
import {updateCustomer} from '../customer-list/core/_requests'

const CustomerDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Customer Name is required'),
  email: Yup.string().required('Email Address is required'),
  mobileNumber: Yup.string().required('Mobile No is required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
  address: Yup.string().required('Address is required'),
  fax: Yup.string().required('Fax is required'),
  zipCode: Yup.string().required('Zip Code is required'),
  taxId: Yup.string().required('Tax No is required'),
})

type Props = {
  initialValues: UpdateCustomer
}
export const EditCustomer: FC<Props> = ({initialValues}) => {
  const [data, setData] = useState<UpdateCustomer>(initialValues)
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {id} = useParams()
  const [employee, setEmployee] = useState<any>([])

  const [loading, setLoading] = useState(false)

  //get type
  const {currentUser} = useAuth()

  const type = currentUser?.branchId
  //end get type
  const formik = useFormik<UpdateCustomer>({
    initialValues,
    validationSchema: CustomerDetailsSchema,

    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedBy = currentUser?.userId // Replace 'updatedBy' with the actual key in currentUser
      const updatedData = {...data, ...values, updatedBy}
      updateCustomer(updatedData, id).then((res) => {
        setData(initialValues)
        navigate('/customer', {replace: true})
        showAlert(res?.message, res?.status)
      })
    },
  })

  console.log('Customer-edit-formik', formik)

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Edit Customer </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Customer Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Customer Name'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Mobile Number</label>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Mobile Number'
                  {...formik.getFieldProps('mobileNumber')}
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.mobileNumber}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Phone Number</label>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Phone Number'
                  id='phone'
                  {...formik.getFieldProps('phoneNumber')}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phoneNumber}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Email Address</label>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Email Address'
                  id='email'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Address</label>
                <textarea
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Address'
                  id='address1'
                  {...formik.getFieldProps('address')}
                ></textarea>
                {formik.touched.address && formik.errors.address && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.address}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Zip Code</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Zip Code'
                  id='zipCode'
                  {...formik.getFieldProps('zipCode')}
                />
                {formik.touched.zipCode && formik.errors.zipCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.zipCode}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Fax</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Fax'
                  id='fax'
                  {...formik.getFieldProps('fax')}
                />
                {formik.touched.fax && formik.errors.fax && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.fax}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Tax Id</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Tax Id'
                  id='taxId'
                  {...formik.getFieldProps('taxId')}
                />
                {formik.touched.taxId && formik.errors.taxId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.taxId}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading ? (
                'Save Changes'
              ) : (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditCustomer
