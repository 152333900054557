import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {SalesReturnListHeader} from './components/header/SalesReturnListHeader'

import {KTCard} from '../../../../../_metronic/helpers'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
import {useContext} from 'react'
import {SalesReturnTable} from './table/SalesReturntable'

const SalesReturnList = () => {
  return (
    <>
      <KTCard>
        <SalesReturnListHeader />
        <SalesReturnTable />
      </KTCard>
    </>
  )
}
const SalesReturnMain = () => {
  const {show, message, type} = useContext(AlertContext)
  return (
    <div className='row'>
      {show && <AlertBox type={type === true ? 'success' : 'error'} message={message} />}
      <div className='col-lg-12'>
        <SalesReturnList />
      </div>
    </div>
  )
}
const SalesReturnListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <SalesReturnMain />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {SalesReturnListWrapper}
