import axios from 'axios'
import {getToken} from '../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//LedgerAccounts API URL

const GET_CHART_OF_ACCOUNT_URL = `${API_URL}chart-of-account/coa`
const ADD_CHART_OF_ACCOUNT_URL = `${API_URL}chart-of-account/add`
const DELETE_CHART_OF_ACCOUNT_URL = `${API_URL}chart-of-account/delete`
const UPDATE_CHART_OF_ACCOUNT_URL = `${API_URL}chart-of-account/update`
const VIEW_CHART_OF_ACCOUNT_URL = `${API_URL}chart-of-account/view`
const GET_CHILD_HEAD_URL = `${API_URL}chart-of-account/get-child`

//AuthVerify
var AuthVerify = getToken.call(this)

//LedgerAccounts LIST
const getLedgerAccounts = () => {
  return axios.get(`${GET_CHART_OF_ACCOUNT_URL}`, AuthVerify).then((response) => {
    console.log('getLedgerAccounts==>', response.data)
    return response.data
  })
}

const createCOA = (data: any) => {
  console.log('Sending data to server-API:', data)
  return axios
    .post(ADD_CHART_OF_ACCOUNT_URL, data, getToken())
    .then((response: any) => {
      console.log('COA creation response:API', response.data)
      return response.data
    })
    .catch((error) => {
      console.error('Error creating COA:API', error.response.data)
      throw error // Re-throw the error to propagate it to the caller
    })
}

const getChildHead = (headName: any) => {
  return axios.get(`${GET_CHILD_HEAD_URL}/${headName}`, AuthVerify).then((response) => {
    console.log('getChild==>', response.data)
    return response.data
  })
}
//COA VEIW
const viewCoa = (id: any) => {
  return axios.get(`${VIEW_CHART_OF_ACCOUNT_URL}/${id}`, getToken()).then((response) => {
    console.log('view-Bank', response.data)
    return response.data
  })
}

//coa UPDATE
const updateCoa = (id: any, data: any) => {
  console.log('reqData=update-Bank', data)
  return axios.put(`${UPDATE_CHART_OF_ACCOUNT_URL}/${id}`, data, getToken()).then((response) => {
    console.log('updatemp-Bank', response.data)
    return response.data
  })
}

//coa DELETE
const deleteCoaById = (id: any) => {
  console.log('id', id)
  return axios.post(`${DELETE_CHART_OF_ACCOUNT_URL}/${id}`, AuthVerify).then((response) => {
    console.log('iresponse.datad', response.data)
    return response.data
  })
}
export {getLedgerAccounts, createCOA, getChildHead, viewCoa, updateCoa, deleteCoaById}
