import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewStock} from '../stock-list/core/_requests'
import {EditStock} from './EditStock'

export const EditStockWrapper = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewStock(id).then((res) => {
      setData(res.data)
      console.log('viewStockDetail', res.data)
    })
  }, [])
  if (data) {
    return <EditStock initialValues={data} />
  }
  return null
}
