// @ts-nocheck
import {Column} from 'react-table'
import {ProductCategoryActionsCell} from './ProductCategoryActionsCell'
import {ProductCategoryCustomHeader} from './ProductCategoryCustomHeader'
import {ProductCategory} from '../../core/_models'

const ProductCategoryColumns: ReadonlyArray<Column<ProductCategory>> = [
  {
    Header: (props) => (
      <ProductCategoryCustomHeader tableProps={props} title='S.No' className='min-w-85px' />
    ),
    accessor: 'serialNo',
  },

  
  {
    Header: (props) => (
      <ProductCategoryCustomHeader tableProps={props} title='name' className='min-w-105px' />
    ),
    accessor: 'name',
  },
  

  {
    Header: (props) => (
      <ProductCategoryCustomHeader
        tableProps={props}
        title='Actions'
        className='text-center min-w-130px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <ProductCategoryActionsCell productCategory={props.data[props.row.index]} />,
  },
]

export {ProductCategoryColumns}
