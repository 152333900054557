import {ID, Response} from '../../../../../../_metronic/helpers'
export type Tax = {
  id?: ID
  primaryId?: number
  category?: string
  name?: string
  percentage?: string

  message?: string
  status?: string
  createdBy?: number
}

export type TaxQueryResponse = Response<Array<Tax>>

export const initialTax: Tax = {
  category: '',
  name: '',
  percentage: '',

  createdBy: 0,
}
export type UpdateTax = {
  id?: ID
  primaryId?: number
  category?: string
  name?: string
  percentage?: string
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateTaxQueryResponse = Response<Array<UpdateTax>>

export const initialUpdateTax: UpdateTax = {
  category: '',
  name: '',
  percentage: '',
}
