import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Tax, TaxQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_TAX_URL = `${API_URL}tax/add`
const GET_TAX_URL = `${API_URL}tax/query`
const VIEW_TAX_URL = `${API_URL}tax/view`
const UPDATE_TAX_URL = `${API_URL}tax/update`
const DELETE_TAX_URL = `${API_URL}tax/delete`

//AuthVerify
var AuthVerify = getToken.call(this)

//Tax LIST
const getTax = (query: string): Promise<TaxQueryResponse> => {
  return axios
    .post(`${GET_TAX_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<TaxQueryResponse>) => {
      console.log('get-Tax==>', response.data)
      return response.data
    })
}

//Tax ADD
const createTax = (data: any): Promise<Tax | undefined> => {
  return axios.post(CREATE_TAX_URL, data, getToken()).then((response: any) => response.data)
}

//Tax VEIW
const viewTax = (id: any) => {
  return axios.get(`${VIEW_TAX_URL}/${id}`, getToken()).then((response) => {
    console.log('view-Tax', response.data)
    return response.data
  })
}

//Tax UPDATE
const updateTax = (data: any, id: any) => {
  console.log('reqData=update-Tax', data)
  return axios.put(`${UPDATE_TAX_URL}/${id}`, data, getToken()).then((response) => {
    console.log('updatemp-Tax', response.data)
    return response.data
  })
}

//Tax DELETE
const deleteTaxById = (id: any) => {
  return axios.post(`${DELETE_TAX_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

export {getTax, createTax, viewTax, updateTax, deleteTaxById}
