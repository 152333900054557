import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
// import {CashadjustmentListWrapper} from './Cashadjustmentlist/CashadjustmentList'
import {useContext, useEffect} from 'react'
import {CashadjustmentListWrapper} from './cash-adjustment-list/CashadjustmentList'
// import { AlertContext } from '../../../../_metronic/layout/components/alert-context'

const cashadjustmentBreadcrumbs: Array<PageLink> = [
  {
    title: 'Cash Adjustment Management',
    path: '/cash-adjustment',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CashadjustmentPage = () => {
  // const {hideAlert} = useContext(AlertContext)
  // useEffect(() => {
  //   hideAlert(false)
  // }, [])
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={cashadjustmentBreadcrumbs}>
                Cashadjustment Management
              </PageTitle>
              <CashadjustmentListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default CashadjustmentPage
