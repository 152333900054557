import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewOrderCategory} from '../order-category-list/core/_requests'
import {EditOrderCategory} from '../order-category-form/EditOrderCategory'

export const EditOrderCategoryWrapper = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewOrderCategory(id).then((res) => {
      setData(res.data)
      console.log('viewOrderCategoryDetail', res.data)
    })
  }, [])
  if (data) {
    return <EditOrderCategory initialValues={data} />
  }
  return null
}
