import {Button, Modal} from 'react-bootstrap'
import React, {useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createSalarygenerate} from '../salary-generate-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../salary-generate-list/core/QueryResponseProvider'
import {
  Salarygenerate,
  initialSalarygenerate as initialValues,
} from '../salary-generate-list/core/_models'

//Validation
const SalarygenerateDetailsSchema = Yup.object().shape({
  month: Yup.string().required('Salary Month is required'),
})

interface modalProps {
  show: boolean
  onHide: () => void
}

const SalaryGenerateModal: React.FC<modalProps> = ({show, onHide}) => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Salarygenerate>(initialValues)
  const navigate = useNavigate()

  //Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()
  // const type = currentUser?.branchId
  const [loading, setLoading] = useState(false)

  // Set initial value for "month" input to today's year and month (e.g., '2023-10')
  const initialMonthValue = new Date().toISOString().slice(0, 7)

  // Update the initialValues object
  const initialValuesWithMonth = {
    ...initialValues,
    month: initialMonthValue,
  }

  // formik Starts
  const formik = useFormik<Salarygenerate>({
    initialValues: initialValuesWithMonth, // Use updated initial values
    validationSchema: SalarygenerateDetailsSchema,

    // Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      updatedData.createdBy = currentUser?.userId
      createSalarygenerate(updatedData).then((res: any) => {
        console.log('Alertres', res?.message)
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/salary-generate', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.message, res?.status)
      })
    },
    enableReinitialize: true,
  })

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Salary Generate</Modal.Title>
      </Modal.Header>
      <form
        id='kt_modal_add_financialyear_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <Modal.Body>
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_add_financialyear_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_financialyear_header'
            data-kt-scroll-wrappers='#kt_modal_add_financialyear_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className='row'>
              <div className='col-lg-12 fv-row'>
                <label className='col-form-label required fw-bold mb-3 mb-lg-0 py-6 px-9 d-flex'>
                  Year
                </label>
                <input
                  type='month'
                  className='form-control form-control-lg form-control-solid d-flex mb-3 mb-lg-0'
                  placeholder='Enter Salary Month'
                  {...formik.getFieldProps('month')}
                  min='1900-01'
                  max='2100-12'
                />
                {formik.touched.month && formik.errors.month && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.month}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary me-3' disabled={loading}>
              {!loading ? (
                'Save Changes'
              ) : (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button
              type='button'
              className='btn btn-danger me-2 '
              disabled={loading}
              onClick={onHide}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default SalaryGenerateModal
