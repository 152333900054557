/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Employee} from '../../core/_models'
import Avatar from 'react-avatar'

type Props = {
  employees: Employee
}

const EmployeeInfoCell: FC<Props> = ({employees}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {/* {employees.profile ? (
          <div className='symbol-label'>
            <img
              src={toAbsoluteUrl(`/media/${employees.profile}`)}
              alt={employees.name}
              className='w-100'
            />
          </div>
        ) : ( */}
        <Avatar size='40' round='20px' maxInitials={2} name={employees.name}></Avatar>
        {/* )} */}
      </a>
    </div>
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {employees.name}
      </a>
      <span>{employees.email}</span>
    </div>
  </div>
)

export {EmployeeInfoCell}
