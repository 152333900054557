import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {SalesOrderListWrapper} from './sales-order-list/SalesOrderList'
import {AddSalesOrder} from './sales-order-form'
import {EditSalesOrderWrapper} from './sales-order-form/EditSalesOrderWrapper'

const SalesOrderBreadcrumbs: Array<PageLink> = [
  {
    title: 'Sales Order Management',
    path: '/sales-order',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SalesOrderPage = () => {
  const navigate = useNavigate()
  function goBack() {
    navigate(-1)
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={SalesOrderBreadcrumbs}>Sales Order</PageTitle>
              <SalesOrderListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
      <Route
        path='/add'
        element={
          <>
            <PageTitle breadcrumbs={SalesOrderBreadcrumbs}>Sales Order Add</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <AddSalesOrder />
          </>
        }
      />
      <Route
        path='/edit/:id'
        element={
          <>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <PageTitle breadcrumbs={SalesOrderBreadcrumbs}>Sales Order Edit</PageTitle>
            <EditSalesOrderWrapper />
          </>
        }
      />
    </Routes>
  )
}

export default SalesOrderPage
