import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewMaterial} from '../material-list/core/_requests'
import {EditMaterial} from '../material-form/EditMaterial'

export const EditMaterialWrapper = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewMaterial(id).then((res) => {
      setData(res.data)
      console.log('viewMaterialDetail', res.data)
    })
  }, [])
  if (data) {
    return <EditMaterial initialValues={data} />
  }
  return null
}
