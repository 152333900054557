import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'

import {getToken} from '../../../auth'

const API_URL = process.env.REACT_APP_API_URLS

var AuthVerify = getToken.call(this)

const getReport = async (data: any) => {
  console.log('api-data', data)
  const res = await axios.post(`${API_URL}reports/trial-balance`, data, getToken())
  console.log('api-res', res)
  return res
}
export {getReport}
