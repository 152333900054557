import {useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import AddVoucherApprovalModal from '../../../voucher-approval-form/AddVoucherApprovalModal'
import CloseVoucherApprovalModal from '../../../voucher-approval-form/CloseVoucherApprovalModal'

const VoucherApprovalListToolbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isYearModalOpen, setIsYearModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }
  // console.log('isModalOpen', isModalOpen)
  const openYearModal = () => {
    setIsYearModalOpen(true)
  }

  const closeYearModal = () => {
    setIsYearModalOpen(false)
  }
  console.log('isYearModalOpen', isYearModalOpen)
  return (
    <>
      <div className='d-flex justify-content-end' data-kt-voucherapproval-table-toolbar='base'>
        {/* begin::Export */}
        <button type='button' className='btn btn-light-primary me-3'>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Export
        </button>

        {/* begin::Add VoucherApproval */}
        {/* <button type='button' className='btn btn-primary me-3' onClick={openModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Approval All
        </button> */}
        <AddVoucherApprovalModal show={isModalOpen} onHide={closeModal} />

        {/* <button type='button' className='btn btn-success me-3' onClick={openYearModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Approval All
          <CloseVoucherApprovalModal show={isYearModalOpen} onHideClose={closeYearModal} />
        </button> */}
      </div>
    </>
  )
}

export {VoucherApprovalListToolbar}
