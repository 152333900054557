import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Cashadjustment, CashadjustmentsQueryResponse} from './_models'
// import {getToken} from '../../../../auth/core/AuthHelpers'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//Cashadjustment API URL
const CREATE_Cash_adjustment_URL = `${API_URL}/cash-adjustment/add`
const GET_Cash_adjustment_URL = `${API_URL}/cash-adjustment/query`
const UPDATE_Cash_adjustment_URL = `${API_URL}/cash-adjustment/update`
const DELETE_Cash_adjustment_URL = `${API_URL}/cash-adjustment/delete`

// var AuthVerify = getToken.call(this)

//Cash_adjustment LIST
const getCashadjustment = (query: string): Promise<CashadjustmentsQueryResponse> => {
  return axios
    .post(
      `${GET_Cash_adjustment_URL}?${query}`
      // AuthVerify
    )
    .then((response: AxiosResponse<CashadjustmentsQueryResponse>) => {
      return response.data
    })
}

//Cash_adjustment ADD
const createCashadjustment = (
  Cashadjustmentdata: Cashadjustment
): Promise<Cashadjustment | undefined> => {
  return axios
    .post(
      CREATE_Cash_adjustment_URL,
      Cashadjustmentdata
      //getToken()
    )
    .then((response: any) => response.data)
}

//Cash_adjustment UPDATE
const updateCashadjustment = (name: any, id: any, updatedBy: any) => {
  return axios
    .put(
      `${UPDATE_Cash_adjustment_URL}/${id}`,
      {name, updatedBy}
      //getToken()
    )
    .then((response) => {
      return response.data
    })
}

//Cash_adjustment DELETE
const deleteCashadjustmentById = (id: any) => {
  return axios
    .post(
      `${DELETE_Cash_adjustment_URL}/${id}`
      //AuthVerify
    )
    .then((response) => {
      return response.data
    })
}

export {getCashadjustment, createCashadjustment, updateCashadjustment, deleteCashadjustmentById}
