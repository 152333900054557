import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {PaymentMethodListWrapper} from './payment-method-list/PaymentMethodList'
import {useContext, useEffect} from 'react'
import {AlertContext} from '../../../../_metronic/layout/components/alert-context'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Payment Method Management',
    path: '/payment-method',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PaymentMethodPage = () => {
  const {hideAlert} = useContext(AlertContext)
  useEffect(() => {
    hideAlert(false)
  }, [])
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Payment Method</PageTitle>
              <PaymentMethodListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default PaymentMethodPage
