import axios, {AxiosResponse} from 'axios'
import {Voucher, VoucherQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_VOUCHER_URL = `${API_URL}voucher/add`
const GET_VOUCHER_URL = `${API_URL}voucher/query`
const VIEW_VOUCHER_URL = `${API_URL}voucher/view`
const UPDATE_VOUCHER_URL = `${API_URL}voucher/update`
const DELETE_VOUCHER_URL = `${API_URL}voucher/delete`
const GET_ACCOUNTNAME_URL = `${API_URL}voucher/get-ledger`
const GET_PAYMENT_URL = `${API_URL}voucher/get-payment`

//AuthVerify
var AuthVerify = getToken.call(this)

//Voucher LIST
const getVoucher = (query: string): Promise<VoucherQueryResponse> => {
  return axios
    .post(`${GET_VOUCHER_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<VoucherQueryResponse>) => {
      console.log('getVoucher-list==>', response.data)
      return response.data
    })
}

//Voucher ADD
const createVoucher = (data: any): Promise<Voucher | undefined> => {
  return axios.post(CREATE_VOUCHER_URL, data, getToken()).then((response: any) => response.data)
}

//Voucher VEIW
const viewVoucher = (voucherNumber: any) => {
  console.log('view-voucherNumber', voucherNumber)
  return axios.get(`${VIEW_VOUCHER_URL}/${voucherNumber}`, getToken()).then((response) => {
    console.log('view-Voucher', response.data)
    return response.data
  })
}

//Voucher UPDATE
const updateVoucher = (data: any, id: any) => {
  console.log('Voucher-reqData=update', data)
  return axios.put(`${UPDATE_VOUCHER_URL}/${id}`, data, getToken()).then((response) => {
    console.log('update-Voucher', response.data)
    return response.data
  })
}

//Voucher DELETE
const deleteVoucherById = (id: any) => {
  return axios.post(`${DELETE_VOUCHER_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}
//GET Ledger
const getLedger = async () => {
  const res = await axios.get(`${GET_ACCOUNTNAME_URL}`, getToken())

  return res
}
//GET Payment
const getPayment = async () => {
  const res = await axios.get(`${GET_PAYMENT_URL}`, getToken())

  return res
}

export {
  getVoucher,
  createVoucher,
  viewVoucher,
  updateVoucher,
  deleteVoucherById,
  getLedger,
  getPayment,
}
