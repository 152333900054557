// @ts-nocheck
import {Column} from 'react-table'
import {ColorActionsCell} from './ColorActionsCell'
import {ColorCustomHeader} from './ColorCustomHeader'
import {Color} from '../../core/_models'

const ColorColumns: ReadonlyArray<Column<Color>> = [
  {
    Header: (props) => (
      <ColorCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
    ),
    accessor: 'serialNo',
  },
 
  
  {
    Header: (props) => (
      <ColorCustomHeader tableProps={props} title='name' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  

 
  {
    Header: (props) => (
      <ColorCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <ColorActionsCell color={props.data[props.row.index]} />,
  },
]

export {ColorColumns}
