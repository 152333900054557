import {ID, Response} from '../../../../../../_metronic/helpers'
export type Stock = {
  id?: ID
  primaryId?: number
  skuId:string,
          productId:string,
          value:string,
          date:any,
          inwardQuantity:string,
          outwardQuantity:string,
          currentStock:string,
  message?: string
  status?: string
  createdBy?: number
}

export type StockQueryResponse = Response<Array<Stock>>

export const initialStock: Stock = {
  skuId:'',
  productId:'',
  value:'',
  date:'',
  inwardQuantity:'',
  outwardQuantity:'',
  currentStock:'',
  createdBy: 0,
}
export type UpdateStock = {
  id?: ID
  primaryId?: number
  skuId:string,
          productId:string,
          value:string,
          date:any,
          inwardQuantity:string,
          outwardQuantity:string,
          currentStock:string,
  sku: [
    {
      name: string
      description: string
    }
  ]
  product: [
    {
      name: string
      description: string
    }
  ]
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateStockQueryResponse = Response<Array<UpdateStock>>

export const initialUpdateStock: UpdateStock = {
  skuId:'',
  productId:'',
  value:'',
  date:'',
  inwardQuantity:'',
  outwardQuantity:'',
  currentStock:'',
  sku: [
    {
      name: '',
      description: '',
    },
  ],
  product: [
    {
      name: '',
      description: '',
    },
  ],
}
