// @ts-nocheck
import {Column} from 'react-table'
import {PurchaseReturnActionsCell} from './PurchaseReturnActionsCell'
import {PurchaseReturnCustomHeader} from './PurchaseReturnCustomHeader'
import {PurchaseReturn} from '../../core/_models'

const PurchaseReturnColumns: ReadonlyArray<Column<PurchaseReturn>> = [
  {
    Header: (props) => (
      <PurchaseReturnCustomHeader tableProps={props} title='S.No' className='min-w-55px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <PurchaseReturnCustomHeader
        tableProps={props}
        title='Purchase INV '
        className='min-w-105px'
      />
    ),
    accessor: 'purchaseId',
  },
  {
    Header: (props) => (
      <PurchaseReturnCustomHeader
        tableProps={props}
        title='Purchase Return INV'
        className='min-w-105px'
      />
    ),
    accessor: 'purchaseReturnId',
  },

  {
    Header: (props) => (
      <PurchaseReturnCustomHeader tableProps={props} title='Date' className='min-w-105px' />
    ),
    accessor: 'date',
  },
  {
    Header: (props) => (
      <PurchaseReturnCustomHeader tableProps={props} title='Total' className='min-w-85px' />
    ),
    accessor: 'total',
  },

  {
    Header: (props) => (
      <PurchaseReturnCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <PurchaseReturnActionsCell purchaseReturn={props.data[props.row.index]} />
    ),
  },
]

export {PurchaseReturnColumns}
