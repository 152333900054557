import {FC, useContext, useState} from 'react' // Import useState
import {Attendance} from '../../core/_models'
import Avatar from 'react-avatar'
import {Button, Modal} from 'react-bootstrap'
import {updateAttendance} from '../../core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../../auth'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'

import {useQueryResponse} from '../../core/QueryResponseProvider'
type Props = {
  attendance: Attendance
}

const AttendanceInfoCell: FC<Props> = ({attendance}) => {
  console.log('attendance-modal', attendance)
  const [isModalOpen, setModalOpen] = useState(false)
  const [checkoutTime, setCheckoutTime] = useState('')
  const [otHours, setOtHours] = useState('')
  const [loading, setLoading] = useState(false)
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const {refetch} = useQueryResponse()
  const handleCheckoutClick = () => {
    setCheckoutTime('') // Clear any previous checkout time
    setOtHours('') // Clear any previous checkout time
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const dataCheckout = {
    // employeeId: attendance.primaryId,

    date: attendance.date,
    checkIn: attendance.checkIn,
    checkout: checkoutTime,
    otHours: otHours,
    updatedBy: currentUser?.userId,
  }
  console.log('dataCheckout-const', dataCheckout)
  const handleCheckoutSubmit = () => {
    setLoading(true)
    console.log('dataCheckout', dataCheckout)
    console.log('attendance.primaryId', attendance.primaryId)
    updateAttendance(dataCheckout, attendance.primaryId)
      .then((res) => {
        refetch()
        setLoading(false)
        navigate('/attendance', {replace: true})
        refetch()
        showAlert(res?.message, res?.status)
        refetch()
        // Refresh the page after a successful update
        // window.location.reload()
      })
      .catch((error) => {
        // Handle error here
        setLoading(false)
        console.error('Error:', error)
      })
  }

  return (
    <div>
      {/* Check-Out button */}
      {attendance.checkout ? (
        // Show checkout time if it exists
        <span>{attendance.checkout}</span>
      ) : (
        // Show "Check-Out" button if checkout is empty
        <button className='btn btn-success btn-sm' onClick={handleCheckoutClick}>
          Check-Out
        </button>
      )}

      {/* Modal */}
      <Modal show={isModalOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Check-Out</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Checkout Time</label>
          <br /> <br />
          <input
            type='time'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            value={checkoutTime}
            onChange={(e) => setCheckoutTime(e.target.value)}
          />
          <label>OT Hours (Optional) </label>
          <br /> <br />
          <input
            type='number'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            value={otHours}
            onChange={(e) => setOtHours(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleCheckoutSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export {AttendanceInfoCell}
