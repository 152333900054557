/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik, FormikProps} from 'formik'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {deleteVoucherApprovalById, approveVoucherApproval} from '../../core/_requests'
import {VoucherApproval, UpdateVoucherApproval} from '../../core/_models'
import {useNavigate, useParams} from 'react-router-dom'
import {Modal, Button, Form} from 'react-bootstrap'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
import {useAuth} from '../../../../../auth'

const VoucherApprovalDetailsSchema = Yup.object().shape({
  // name: Yup.string().required('Name is required'),
})
type Props = {
  voucherapproval: VoucherApproval
}

const VoucherApprovalActionsCell: FC<Props> = ({voucherapproval}) => {
  console.log('voucherapproval', voucherapproval)
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false)
  const [editshowModal, seteditShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleEditClose = () => seteditShow(false)
  const [data, setData] = useState<UpdateVoucherApproval>(voucherapproval)
  const {id} = useParams()
  const [loading, setLoading] = useState(false)

  //get type
  const {currentUser} = useAuth()
  const formik: FormikProps<UpdateVoucherApproval> = useFormik<UpdateVoucherApproval>({
    initialValues: voucherapproval,
    validationSchema: VoucherApprovalDetailsSchema,
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedBy = currentUser?.userId
      const approvedBy = currentUser?.userId
      const updatedData = {...data, ...values, updatedBy, approvedBy}
      approveVoucherApproval(updatedData, voucherapproval.voucherNumber).then((res) => {
        setData(voucherapproval)
        navigate('/voucher-approval', {replace: true})
        showAlert(res?.message, res?.status)
        handleEditClose() // Close the modal after submission
      })
    },
  })

  const saveChanges = () => {
    formik.handleSubmit() // Trigger the Formik form submission
  }

  const editVoucherApprovalDetail = () => {
    seteditShow(true)
  }
  const deleteItem = () =>
    deleteVoucherApprovalById(voucherapproval.primaryId).then((res) => {
      console.log('delete', res)
      setShow(false)
      showAlert(res?.message, res?.status)
      navigate('/voucher-approval')
    })
  const handleShow = () => {
    setShow(true)
  }
  return (
    <>
      {/* Edit Button */}
      <div className='d-flex justify-content-end flex-shrink-0'>
        <a
          onClick={editVoucherApprovalDetail}
          className='btn btn-primary me-3 btn-active-color-primary btn-sm'
        >
          Approve
          {/* <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' /> */}
        </a>

        {/* Delete Button */}
        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleShow}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>
      <Modal show={editshowModal} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Voucher Approval</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            id='kt_modal_add_voucherapproval_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            {/* begin::Scroll */}
            <div
              className='d-flex flex-column scroll-y me-n7 pe-7'
              id='kt_modal_add_voucherapproval_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_voucherapproval_header'
              data-kt-scroll-wrappers='#kt_modal_add_voucherapproval_scroll'
              data-kt-scroll-offset='300px'
            >
              <div className='row mb-6'>
                <div className='row'>
                  <div className='col-lg- fv-row'>
                    <label className='col-form-label required fw-bold fs-6'>Approve Date</label>
                    <input
                      type='date'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Enter Date'
                      {...formik.getFieldProps('approvedDate')}
                    />
                    {formik.touched.approvedDate && formik.errors.approvedDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.approvedDate}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* end::Scroll */}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleEditClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={saveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete "{voucherapproval.primaryId}"?</Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
    </>
  )
}

export {VoucherApprovalActionsCell}
