import {ID, Response} from '../../../../../../_metronic/helpers'
export type Sku = {
  id?: ID
  primaryId?: number
  
  name?: string
  description?: string

  message?: string
  status?: string
  createdBy?: number
}

export type SkuQueryResponse = Response<Array<Sku>>

export const initialSku: Sku = {
  description: '',
  name: '',


  createdBy: 0,
}
export type UpdateSku = {
  id?: ID
  primaryId?: number
  
  name?: string
  description?: string
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateSkuQueryResponse = Response<Array<UpdateSku>>

export const initialUpdateSku: UpdateSku = {
  description: '',
  name: '',

}
