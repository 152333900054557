import React, {useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../sales-return-list/core/QueryResponseProvider'

import {SalesReturn, initialSalesReturn as initialValues} from '../sales-return-list/core/_models'
import {createSalesReturn, getProduct, getSales} from '../sales-return-list/core/_requests'

const SalesReturnDetailsSchema = Yup.object().shape({
  total: Yup.string().required('Total is required'),
  salesReturnDetail: Yup.array().of(
    Yup.object().shape({
      productId: Yup.string().required('Product Id is required'),
    })
  ),
})
// Define a Product type
interface Product {
  id: number
  name: string
  price: number
  tax: {
    percentage: number
  }
}

const SalesReturnDetails = () => {
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()
  const [sales, setSales] = useState([])
  const [product, setProduct] = useState([])
  const [financialyear, setFinancialYear] = useState([])
  const navigate = useNavigate()
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<SalesReturn>(initialValues)
  const [productList, setProductList] = useState<Product[]>([])
  function getCurrentDate() {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }
  useEffect(() => {
    // Fetch ledger data using useEffect
    getProduct().then((res) => {
      setProduct(res?.data?.data)
      setProductList(res?.data?.data)
    })
  }, [])
  useEffect(() => {
    // Fetch Purchase data using useEffect
    getSales().then((res) => {
      setSales(res?.data?.data)
    })
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={SalesReturnDetailsSchema}
        onSubmit={(values, {resetForm}) => {
          setLoading(true)
          // Calculate total based on salesReturnDetail
          const calculatedTotal = values.salesReturnDetail
            .reduce(
              (total, salesReturnDetail) =>
                total +
                (parseFloat(salesReturnDetail.quantity) || 0) *
                  (parseFloat(salesReturnDetail.amount) || 0),
              0
            )
            .toFixed(2)
          const updatedValues = {
            ...values,
            SalesReturn: values.salesReturnDetail,
            createdBy: currentUser?.userId,
            total: calculatedTotal,
          }
          createSalesReturn(updatedValues)
            .then((res: any) => {
              console.log('createSalesReturn-res', res?.message)
              resetForm({
                ...initialValues,
                values: {...initialValues},
              })
              setData(initialValues)
              navigate('/sales-return', {replace: true})
              refetch()
              setLoading(false)
              showAlert(res?.message, res?.status)
            })
            .catch((error) => {
              console.error('Error creating SalesReturn:', error)
            })
            .finally(() => {
              setLoading(false)
            })
        }}
      >
        {({values, setFieldValue}) => (
          <Form>
            <div
              className='card-header border-0'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Add Sales Return</h3>
              </div>
            </div>

            <div id='kt_account_profile_details'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Sales</label>
                      <Field
                        as='select'
                        name='salesId' // Add a unique name for the Field
                        className='form-control form-control-lg form-control-solid'
                        placeholder='sales Id '
                      >
                        <option value=''>Select Sales INV</option>
                        {sales.map((item: any, i: any) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.invoiceNumber}
                            </option>
                          )
                        })}
                      </Field>

                      <ErrorMessage
                        className='fieldError'
                        name='salesId'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Date</label>
                      <Field
                        name='date'
                        type='date'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Date'
                        defaultValue={getCurrentDate()}
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='date'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Description</label>
                      <Field
                        name='description'
                        type='test'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Description'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='description'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6 card-footer d-flex justify-content'>
                <div className='table-container'>
                  <FieldArray
                    name='salesReturnDetail'
                    render={(arrayHelpers) => {
                      return (
                        <div>
                          {values.salesReturnDetail && values.salesReturnDetail.length > 0
                            ? values.salesReturnDetail.map((salesReturnDetail, index) => (
                                <div key={index} className='row mb-2 position-relative addon-group'>
                                  <div className='col-md-3 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>
                                      Product Name
                                    </label>
                                    <Field
                                      as='select'
                                      name={`salesReturnDetail[${index}].productId`}
                                      className='form-control form-control-lg form-control-solid'
                                      onChange={(e: any) => {
                                        const selectedProductId = e.target.value
                                        const selectedProductData = productList.find(
                                          (item: any) => item.id === parseInt(selectedProductId, 10)
                                        )

                                        if (selectedProductData) {
                                          const amount = selectedProductData.price || 0

                                          // Update form values
                                          setFieldValue(
                                            `salesReturnDetail[${index}].productId`,
                                            selectedProductId
                                          )
                                          setFieldValue(
                                            `salesReturnDetail[${index}].amount`,
                                            amount.toString()
                                          )

                                          // Recalculate tax value and discount value based on the new amount and tax
                                          const quantity =
                                            parseFloat(values.salesReturnDetail[index].quantity) ||
                                            0

                                          const newTotal = (amount * quantity).toFixed(2)

                                          setFieldValue(
                                            `salesReturnDetail[${index}].total`,
                                            newTotal
                                          )
                                        } else {
                                          // Update form values when no product is selected
                                          setFieldValue(`salesReturnDetail[${index}].productId`, '')
                                          setFieldValue(`salesReturnDetail[${index}].amount`, '')

                                          setFieldValue(`salesReturnDetail[${index}].total`, '')
                                        }
                                      }}
                                    >
                                      <option value=''>Select Product Name</option>
                                      {product.map((item: any, i: any) => {
                                        return (
                                          <option key={i} value={item.id}>
                                            {item.productName}
                                          </option>
                                        )
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`salesReturnDetail[${index}].productId`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                  <div className='col-md-2'>
                                    <label className='form-label fw-bold fs-6'>Quantity</label>
                                    <Field
                                      type='text'
                                      name={`salesReturnDetail[${index}].quantity`}
                                      className='form-control form-control-lg form-control-solid'
                                      onChange={(e: any) => {
                                        const newQuantity = parseFloat(e.target.value) || 0
                                        const selectedProductData = productList.find(
                                          (item: any) =>
                                            item.id ===
                                            parseInt(
                                              String(values.salesReturnDetail[index].productId),
                                              10
                                            )
                                        )

                                        if (selectedProductData) {
                                          const amount = selectedProductData.price || 0

                                          // Recalculate values based on the new quantity

                                          const newTotal = (amount * newQuantity).toFixed(2)

                                          // Update form values
                                          setFieldValue(
                                            `salesReturnDetail[${index}].quantity`,
                                            newQuantity.toString()
                                          )

                                          setFieldValue(
                                            `salesReturnDetail[${index}].total`,
                                            newTotal.toString()
                                          )
                                        }
                                      }}
                                    />
                                    <ErrorMessage
                                      className='fieldError'
                                      name={`salesReturnDetail[${index}].quantity`}
                                      component='div'
                                    />
                                  </div>
                                  <div className='col-md-2'>
                                    <label className='form-label fw-bold fs-6'>Amount</label>
                                    <Field
                                      type='text'
                                      name={`salesReturnDetail[${index}].amount`}
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Amount'
                                      onChange={(e: any) => {
                                        // Calculate new values based on changes in 'amount'
                                        const newAmount = parseFloat(e.target.value) || 0
                                        const quantity =
                                          parseFloat(values.salesReturnDetail[index].quantity) || 0

                                        const originalTotal =
                                          parseFloat(values.salesReturnDetail[index].total) || 0

                                        const newTotal = (
                                          originalTotal +
                                          newAmount -
                                          values.salesReturnDetail[index].amount
                                        ).toFixed(2)

                                        // Update form values
                                        setFieldValue(
                                          `salesReturnDetail[${index}].amount`,
                                          e.target.value
                                        )
                                        setFieldValue(`salesReturnDetail[${index}].total`, newTotal)
                                      }}
                                    />
                                    <ErrorMessage
                                      className='fieldError'
                                      name={`salesReturnDetail[${index}].amount`}
                                      component='div'
                                    />
                                  </div>
                                  <div className='col-md-2'>
                                    <label className='form-label fw-bold fs-6'>Total</label>
                                    <Field
                                      type='text'
                                      name={`salesReturnDetail[${index}].total`}
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Total'
                                    />
                                    <ErrorMessage
                                      className='fieldError'
                                      name={`salesReturnDetail[${index}].total`}
                                      component='div'
                                    />
                                  </div>
                                  {index === 0 ? (
                                    <div className='col-md-2 mb-2 mb-md-0'>
                                      <label className='form-label mt-14'></label>
                                      <Link to='#'>
                                        <div
                                          onClick={() =>
                                            arrayHelpers.push({
                                              productId: '',
                                              amount: '',
                                              quantity: '',
                                              total: '',
                                            })
                                          }
                                          className='btn btn-primary'
                                        >
                                          Add New Field
                                        </div>
                                      </Link>
                                    </div>
                                  ) : (
                                    <div className='col-md-2 mb-2 mb-md-0'>
                                      <label className='form-label mt-14'></label>
                                      <Link to='#' className='add-btn'>
                                        <div
                                          onClick={() => arrayHelpers.remove(index)}
                                          className='btn btn-danger'
                                        >
                                          Remove
                                        </div>{' '}
                                      </Link>
                                    </div>
                                  )}
                                  <br />
                                  <br /> <br />
                                  <br /> <br />
                                  <hr />
                                </div>
                              ))
                            : null}
                        </div>
                      )
                    }}
                  />
                </div>
              </div>

              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Total</label>
                <Field
                  type='text'
                  className='form-control'
                  name='total'
                  value={values.salesReturnDetail
                    .reduce(
                      (total, salesReturnDetail) =>
                        total +
                        (parseFloat(salesReturnDetail.quantity) || 0) *
                          (parseFloat(salesReturnDetail.amount) || 0),
                      0
                    )
                    .toFixed(2)}
                />

                <ErrorMessage
                  className='fieldError'
                  name='total'
                  render={(msg) => (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{msg}</div>
                    </div>
                  )}
                />
              </div>
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading ? 'Save Changes' : 'Please wait...'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default SalesReturnDetails
