import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {viewApprovalList} from '../salary-generate-list/core/_requests'
import ApproveList from './EditSalaryGenerate'


export const EditSalarygenerateWrapper = () => {
  const {id} = useParams()
  const [data, setdata] = useState<any>(null)
  useEffect(() => {
    viewApprovalList(id).then((res) => {
      console.log('wrappppres-generate-res', res.data)
      console.log('wrapppp-data-generate', data)

      setdata(res.data)
    })
  }, [])
  if (data) {
    return <ApproveList initialValues={data} />
  }
  return null
}
