import {ID, Response} from '../../../../../../_metronic/helpers'
export type Openingbalance = {
  id?: ID
  primaryId?: number
  openingDate: any
  financialyearId: number
  openingBalance: [
    {
      coaId: number
      debit: string
      credit: string
      createdBy?: number
    }
  ]
  message?: string
  status?: string
}

export type OpeningbalanceQueryResponse = Response<Array<Openingbalance>>

export const initialOpeningbalance: Openingbalance = {
  openingDate: '',
  financialyearId: 0,
  openingBalance: [
    {
      coaId: 0,
      debit: '',
      credit: '',
    },
  ],
}
export type UpdateOpeningbalance = {
  id?: ID
  primaryId?: number
  openingDate?: string
  financialyearId: string

  coaId: string
  debit: string
  credit: string

  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateOpeningbalanceQueryResponse = Response<Array<UpdateOpeningbalance>>

export const initialUpdateOpeningbalance: UpdateOpeningbalance = {
  openingDate: '',
  financialyearId: '',

  coaId: '',
  debit: '',
  credit: '',
}
