import {useListView} from '../../core/ListViewProvider'
import {StockListSearchComponent} from './StockListSearchComponent'
import {StockListToolbar} from './StockListToolbar'

const StockListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <StockListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <StockListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {StockListHeader}
