// @ts-nocheck
import {Column} from 'react-table'
import {SizeActionsCell} from './SizeActionsCell'
import {SizeCustomHeader} from './SizeCustomHeader'
import {Size} from '../../core/_models'

const SizeColumns: ReadonlyArray<Column<Size>> = [
  {
    Header: (props) => (
      <SizeCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
    ),
    accessor: 'serialNo',
  },
 
  
  {
    Header: (props) => (
      <SizeCustomHeader tableProps={props} title='name' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  

  {
    Header: (props) => (
      <SizeCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <SizeActionsCell size={props.data[props.row.index]} />,
  },
]

export {SizeColumns}
