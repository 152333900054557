import {useEffect, useRef, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {KTCardBody, toAbsoluteUrl} from '../../../../../../_metronic/helpers'

// import {getReport} from '../_requests'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select, {Theme} from 'react-select'
import {useDownloadExcel} from 'react-export-table-to-excel'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {getReport} from '../_requests'
import {useAuth} from '../../../../auth'
const ReportContent = () => {
  const tableRef = useRef(null)
  const {currentUser} = useAuth()
  const [report, setReport] = useState<any>([])

  const [OCFy, setFy] = useState('')
  const [OCDate, setOCDate] = useState('')
  const [loading, setLoading] = useState(false)
  const [btnExcelDisable, setbtnExcelDisable] = useState(true)
  const [btnClearDisable, setbtnClearDisable] = useState(true)
  const [date, setDate] = useState<Date | null>(new Date()) // Set the initial state to the current date

  const {mode} = useThemeMode()

  //dropdown
  if (mode === 'dark') {
    var customStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        borderColor: '#393944',

        boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',
        '&:hover': {
          color: 'red',
        },
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
        color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: 5,
        borderRadius: 5,
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: 'var(--kt-input-solid-color)',
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-input-solid-color)',
      }),
    }
  } else {
    var customStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        borderColor: '#c5c5c5',

        boxShadow: state.isFocused ? '0 0 0 1px #009ef7' : 'none',
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#009ef7' : 'var(--kt-app-bg-color)',
        color: state.isFocused ? 'white' : 'var(--kt-input-solid-color)',
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'var(--kt-app-bg-color)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: 5,
        borderRadius: 5,
      }),
      placeholder: (provided: any, state: any) => ({
        ...provided,
        color: 'var(--kt-input-solid-color)',
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? 'var(--kt-input-solid-color)' : 'var(--kt-input-solid-color)',
      }),
      indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: '#c5c5c5',
      }),
    }
  }

  const DayBookReport = () => {
    setLoading(true)
    const selectedDate = date
      ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
      : ''
    const financialYear = currentUser?.financialYearId

    const data = {financialYear, date: selectedDate}
    getReport(data).then((resRep) => {
      console.log('data-getReport', data)
      setLoading(false)

      setReport(resRep.data.data)

      // Enable or disable the buttons as needed based on the new data.
      setbtnExcelDisable(Object.keys(resRep.data.data).length === 0)
      setbtnClearDisable(Object.keys(resRep.data.data).length === 0)

      // Avoid resetting the date here
      // setDate(null);

      setOCDate(selectedDate)
      setFy(`${currentUser?.financial}`)
    })
  }
  // Function to calculate the total of the "Debit" column
  const calculateDebitTotal = () => {
    if (report.length > 0) {
      return report
        .reduce((total: any, item: any) => total + parseFloat(item.debit || 0), 0)
        .toFixed(2)
    } else {
      return '0.00'
    }
  }

  // Function to calculate the total of the "Credit" column
  const calculateCreditTotal = () => {
    if (report.length > 0) {
      return report
        .reduce((total: any, item: any) => total + parseFloat(item.credit || 0), 0)
        .toFixed(2)
    } else {
      return '0.00'
    }
  }

  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()

  const clearReport = () => {
    setReport([])
    setFy('')
    setOCDate('')
    setDate(null) // Clear the date
    setbtnExcelDisable(true)
    setbtnClearDisable(true)
  }

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `DayBook_Report_${fileName}`,
    sheet: 'Users',
  })
  return (
    <>
      <div className='card '>
        {/* begin::Header */}
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Day Book Report</h3>
          </div>
          <div className='col-lg-2 pt-5'>
            <button
              className='btn btn-danger col-lg-12'
              disabled={btnExcelDisable}
              onClick={onDownload}
            >
              Export
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='row mb-10'>
            <div className='col-lg-4'></div>

            <div className='col-lg-4'>
              <label className=' col-form-label fw-bold fs-6'>Select Date</label>
              <DatePicker
                selected={date}
                onChange={(newDate) => setDate(newDate)}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
                className='form-control form-control-lg form-control-solid custom-datepicker'
                todayButton='Today'
              />
            </div>
            <div className='col-lg-2 pt-12'>
              <button onClick={DayBookReport} className='btn btn-primary col-lg-12'>
                Search
              </button>
            </div>
            <div className='col-lg-2 pt-12'>
              <button
                onClick={clearReport}
                className='btn btn-info col-lg-12'
                disabled={btnClearDisable}
              >
                Clear
              </button>
            </div>
          </div>

          <div className='table-responsive'>
            {/* begin::Table */}

            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
              ref={tableRef}
            >
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                  <th className='min-w-50px' colSpan={6}>
                    {' '}
                    Financial Year : {OCFy}
                  </th>
                  <th className='min-w-120px' colSpan={5}>
                    DATE : {OCDate}
                  </th>
                </tr>
                <tr>
                  <td colSpan={11}></td>{' '}
                </tr>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-50px'> S. No</th>
                  <th className='min-w-140px'> Date</th>
                  <th className='min-w-140px'> Voucher No</th>
                  <th className='min-w-120px'> Voucher Type </th>

                  <th className='min-w-100px'> Remark </th>
                  <th className='min-w-100px'> Debit</th>
                  <th className='min-w-100px'> Credit </th>
                  <th className='min-w-100px'> Reverse Account Name </th>
                  <th className='min-w-100px'> Payment Type </th>
                </tr>
              </thead>
              {loading && (
                <tbody>
                  <tr>
                    <td colSpan={11} style={{textAlign: 'center'}}>
                      {' '}
                      Please wait...{' '}
                    </td>
                  </tr>
                </tbody>
              )}
              {!loading && (
                <tbody>
                  {report?.map((item: any, i: any) => {
                    return (
                      <tr key={i}>
                        <td> {i + 1}</td>

                        <td> {item.voucherDate}</td>

                        <td> {item.voucherNumber} </td>
                        <td> {item.voucherType} </td>
                        <td> {item.ledgerComment} </td>
                        <td> {item.debit} </td>
                        <td> {item.credit} </td>
                        <td> {item.reverseAccount.headName} </td>
                        <td> {item.coa.headName} </td>
                      </tr>
                    )
                  })}

                  {report.length > 0 ? (
                    <tr>
                      <td colSpan={4}> </td>
                      <th>
                        <h4 className='fw-bolder m-0'>Totals</h4>
                      </th>
                      <th>{calculateDebitTotal()}</th>
                      <th>{calculateCreditTotal()}</th>
                      <td colSpan={2}></td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={11} style={{textAlign: 'center'}}>
                        {' '}
                        No Data{' '}
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
              {/* end::Table body */}
            </table>

            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {ReportContent}
