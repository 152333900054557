import {useListView} from '../../core/ListViewProvider'
import {PaymentMethodListSearchComponent} from './PaymentMethodListSearchComponent'

const PaymentMethodListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      {/* Search bar */}
      <PaymentMethodListSearchComponent />
    </div>
  )
}

export {PaymentMethodListHeader}
