import axios, {AxiosResponse} from 'axios'

import {VoucherApproval, VoucherApprovalQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_VOUCHERAPPROVAL_URL = `${API_URL}voucher-approval/add`
const GET_VOUCHERAPPROVAL_URL = `${API_URL}voucher/query`
const VIEW_VOUCHERAPPROVAL_URL = `${API_URL}voucher/view`
const UPDATE_VOUCHERAPPROVAL_URL = `${API_URL}voucher/approval`
const APPROVE_VOUCHERAPPROVAL_URL = `${API_URL}voucher/approval`
const DELETE_VOUCHERAPPROVAL_URL = `${API_URL}voucher-approval/delete`
const ADD_CLOSE_VOUCHERAPPROVAL_URL = `${API_URL}voucher-approval/view`
const GET_YEAR_VOUCHERAPPROVAL_URL = `${API_URL}/custom/get-financial-year`

//AuthVerify
var AuthVerify = getToken.call(this)

//VoucherApproval LIST
const getVoucherApproval = (query: string): Promise<VoucherApprovalQueryResponse> => {
  return axios
    .post(`${GET_VOUCHERAPPROVAL_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<VoucherApprovalQueryResponse>) => {
      console.log('get-VoucherApproval==>', response.data)
      return response.data
    })
}

//VoucherApproval ADD
const createVoucherApproval = (data: any): Promise<VoucherApproval | undefined> => {
  return axios
    .post(CREATE_VOUCHERAPPROVAL_URL, data, getToken())
    .then((response: any) => response.data)
}

//VoucherApproval VEIW
const viewVoucherApproval = (id: any) => {
  return axios.get(`${VIEW_VOUCHERAPPROVAL_URL}/${id}`, getToken()).then((response) => {
    console.log('view-VoucherApproval', response.data)
    return response.data
  })
}

//VoucherApproval UPDATE
const approveVoucherApproval = (updatedData: any, voucherNumber: any) => {
  console.log('reqData=update-VoucherApproval', updatedData)
  return axios
    .post(`${APPROVE_VOUCHERAPPROVAL_URL}/${voucherNumber}`, updatedData, getToken())
    .then((response) => {
      console.log('updatemp-VoucherApproval', response.data)
      return response.data
    })
}

//VoucherApproval DELETE
const deleteVoucherApprovalById = (id: any) => {
  return axios.post(`${DELETE_VOUCHERAPPROVAL_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}
//Close VoucherApproval ADD
const createCloseVoucherApproval = (data: any): Promise<VoucherApproval | undefined> => {
  return axios
    .post(ADD_CLOSE_VOUCHERAPPROVAL_URL, data, getToken())
    .then((response: any) => response.data)
}
//GET Close Financial Year

const getCloseVoucherApproval = async () => {
  const res = await axios.get(`${GET_YEAR_VOUCHERAPPROVAL_URL}`, getToken())
  console.log('getCloseVoucherApproval-reeee', res)
  return res
}
export {
  getVoucherApproval,
  createVoucherApproval,
  viewVoucherApproval,
  approveVoucherApproval,
  deleteVoucherApprovalById,
  createCloseVoucherApproval,
  getCloseVoucherApproval,
}
