import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {ClientDashboardWrapper} from '../pages/client-dashboard/DashboardWrapper'
import {useAuth} from '../modules/auth'
import AttendancePage from '../modules/apps/attendance/AttendancePage'
import BankPage from '../modules/apps/bank/BankPage'
import ProductPage from '../modules/apps/product/ProductPage'
import PurchasePage from '../modules/apps/purchase/PurchasePage'
import SalesPage from '../modules/apps/sales/SalesPage'
import OpeningbalancePage from '../modules/apps/opening-balance/OpeningbalancePage'
import BankreconciliationPage from '../modules/apps/bank-reconciliation/BankreconciliationPage'
import CashadjustmentPage from '../modules/apps/cash-adjustment/CashadjustmentPage'
import SupplierPage from '../modules/apps/supplier/SupplierPage'
import SalaryadvancePage from '../modules/apps/salary-advance/SalaryadvancePage'
import CustomerPage from '../modules/apps/customer/CustomerPage'
import EmployeePage from '../modules/apps/employee/EmployeePage'
import SalarygeneratePage from '../modules/apps/salary-generate/SalarygeneratePage'
import DepartmentPage from '../modules/apps/department/DepartmentPage'
import PaymentMethodPage from '../modules/apps/payment-method/PaymentMethodPage'
import RoleaccessPage from '../modules/apps/role-access/RoleaccessPage'
import ProductUnitPage from '../modules/apps/unit/ProductUnitPage'
import FinancialyearPage from '../modules/apps/financial-year/FinancialyearPage'
import VoucherPage from '../modules/apps/voucher/VoucherPage'
import LedgerAccountsPage from '../modules/apps/ledger-accounts/LedgerAccountsPage'
import PurchaseReturnPage from '../modules/apps/purchase-return/PurchaseReturnPage'
import SalesReturnPage from '../modules/apps/sales-return/SalesReturnPage'
import AttendanceReportPage from '../modules/apps/attendance-report/AttendanceReportPage'
import VoucherApprovalPage from '../modules/apps/voucher-approval/VoucherApprovalPage'
import GeneralLedgerReportPage from '../modules/apps/general-ledger/GeneralLedgerReportPage'
import BalanceSheetReportPage from '../modules/apps/balance-sheet/BalanceSheetReportPage'
import DayBookReportPage from '../modules/apps/day-book/DayBookReportPage'
import PlReportPage from '../modules/apps/pl-report/PlReportPage'
import TrialBalanceReportPage from '../modules/apps/trial-balance/TrialBalanceReportPage'
import TradingReportPage from '../modules/apps/trading-report/TradingReportPage'
import TaxPage from '../modules/apps/tax/TaxPage'
import StockPage from '../modules/apps/stock/StockPage'
import SkuPage from '../modules/apps/sku/SkuPage'
import StockReportPage from '../modules/apps/stock-report/StockReportPage'
import ClosingReportPage from '../modules/apps/closing-report/ClosingReportPage'
import PurchaseReportPage from '../modules/apps/purchase-report/PurchaseReportPage'
import SalesReportPage from '../modules/apps/sales-report/SalesReportPage'
import ProcessTypePage from '../modules/apps/process-type/ProcessTypePage'
import ProcessPage from '../modules/apps/process/ProcessPage'
import MaterialPage from '../modules/apps/material/MaterialPage'
import OrderCategorysPage from '../modules/apps/order-category/OrderCategoryPage'
import SalesOrderPage from '../modules/apps/sales-order/SalesOrderPage'
import SizePage from '../modules/apps/size/SizePage'
import ColorPage from '../modules/apps/color/ColorPage'
import ProductCategoryPage from '../modules/apps/product-category/ProductCategoryPage'
import PriorityPage from '../modules/apps/priority/PriorityPage'



const PrivateRoutes = () => {
  const RolePage = lazy(() => import('../modules/apps/role/RolePage'))

  const RoleAccessPage = lazy(() => import('../modules/apps/role-access/RoleaccessPage'))

  const {currentUser} = useAuth()
  var too = '/dashboard'
  if (currentUser?.branchId === 0) {
    var too = `/dashboard`
  }
  if (currentUser?.branchId === 2) {
    var too = `/dashboard`
  }
  return (
    <Suspense fallback={<SuspendedView />}>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to={too} />} />
          {/* Pages */}
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='dashboard' element={<ClientDashboardWrapper />} />
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />

          {/* Lazy Modules */}
          <Route
            path='ledger-accounts/*'
            element={
              <SuspendedView>
                <LedgerAccountsPage />
              </SuspendedView>
            }
          />
          <Route
            path='role/*'
            element={
              <SuspendedView>
                <RolePage />
              </SuspendedView>
            }
          />
          <Route
            path='page-access/*'
            element={
              <SuspendedView>
                <RoleaccessPage />
              </SuspendedView>
            }
          />
          <Route
            path='department/*'
            element={
              <SuspendedView>
                <DepartmentPage />
              </SuspendedView>
            }
          />
          <Route
            path='employee/*'
            element={
              <SuspendedView>
                <EmployeePage />
              </SuspendedView>
            }
          />

          <Route
            path='attendance/*'
            element={
              <SuspendedView>
                <AttendancePage />
              </SuspendedView>
            }
          />
          <Route
            path='salary-advance/*'
            element={
              <SuspendedView>
                <SalaryadvancePage />
              </SuspendedView>
            }
          />
          <Route
            path='salary-generate/*'
            element={
              <SuspendedView>
                <SalarygeneratePage />
              </SuspendedView>
            }
          />

          <Route
            path='financial-year/*'
            element={
              <SuspendedView>
                <FinancialyearPage />
              </SuspendedView>
            }
          />
          <Route
            path='opening-balance/*'
            element={
              <SuspendedView>
                <OpeningbalancePage />
              </SuspendedView>
            }
          />
          <Route
            path='voucher/*'
            element={
              <SuspendedView>
                <VoucherPage />
              </SuspendedView>
            }
          />
          <Route
            path='voucher-approval/*'
            element={
              <SuspendedView>
                <VoucherApprovalPage />
              </SuspendedView>
            }
          />
          <Route
            path='payment-method/*'
            element={
              <SuspendedView>
                <PaymentMethodPage />
              </SuspendedView>
            }
          />

          <Route
            path='bank-reconciliation/*'
            element={
              <SuspendedView>
                <BankreconciliationPage />
              </SuspendedView>
            }
          />
          <Route
            path='cash-adjustment/*'
            element={
              <SuspendedView>
                <CashadjustmentPage />
              </SuspendedView>
            }
          />
          <Route
            path='bank/*'
            element={
              <SuspendedView>
                <BankPage />
              </SuspendedView>
            }
          />
          <Route
            path='tax/*'
            element={
              <SuspendedView>
                <TaxPage />
              </SuspendedView>
            }
          />
          <Route
            path='customer/*'
            element={
              <SuspendedView>
                <CustomerPage />
              </SuspendedView>
            }
          />
          <Route
            path='supplier/*'
            element={
              <SuspendedView>
                <SupplierPage />
              </SuspendedView>
            }
          />
          <Route
            path='product/*'
            element={
              <SuspendedView>
                <ProductPage />
              </SuspendedView>
            }
          />
            <Route
            path='size/*'
            element={
              <SuspendedView>
                <SizePage />
              </SuspendedView>
            }
          />
           <Route
            path='color/*'
            element={
              <SuspendedView>
                <ColorPage />
              </SuspendedView>
            }
          />
            <Route
            path='product-category/*'
            element={
              <SuspendedView>
                <ProductCategoryPage />
              </SuspendedView>
            }
          />
          <Route
            path='unit/*'
            element={
              <SuspendedView>
                <ProductUnitPage />
              </SuspendedView>
            }
          />
          <Route
            path='sku/*'
            element={
              <SuspendedView>
                <SkuPage />
              </SuspendedView>
            }
          />
          <Route
            path='stock/*'
            element={
              <SuspendedView>
                <StockPage />
              </SuspendedView>
            }
          />
          <Route
            path='stock-report/*'
            element={
              <SuspendedView>
                <StockReportPage />
              </SuspendedView>
            }
          />
          <Route
            path='purchase/*'
            element={
              <SuspendedView>
                <PurchasePage />
              </SuspendedView>
            }
          />
          <Route
            path='purchase-return/*'
            element={
              <SuspendedView>
                <PurchaseReturnPage />
              </SuspendedView>
            }
          />
          <Route
            path='sales/*'
            element={
              <SuspendedView>
                <SalesPage />
              </SuspendedView>
            }
          />
          <Route
            path='sales-return/*'
            element={
              <SuspendedView>
                <SalesReturnPage />
              </SuspendedView>
            }
          />
          <Route
            path='attendance-report/*'
            element={
              <SuspendedView>
                <AttendanceReportPage />
              </SuspendedView>
            }
          />
          <Route
            path='general-ledger/*'
            element={
              <SuspendedView>
                <GeneralLedgerReportPage />
              </SuspendedView>
            }
          />
          <Route
            path='balance-sheet/*'
            element={
              <SuspendedView>
                <BalanceSheetReportPage />
              </SuspendedView>
            }
          />
          <Route
            path='day-book/*'
            element={
              <SuspendedView>
                <DayBookReportPage />
              </SuspendedView>
            }
          />
          <Route
            path='pl-report/*'
            element={
              <SuspendedView>
                <PlReportPage />
              </SuspendedView>
            }
          />
          <Route
            path='trial-balance/*'
            element={
              <SuspendedView>
                <TrialBalanceReportPage />
              </SuspendedView>
            }
          />
          <Route
            path='trading-report/*'
            element={
              <SuspendedView>
                <TradingReportPage />
              </SuspendedView>
            }
          />
          <Route
            path='closing-report/*'
            element={
              <SuspendedView>
                <ClosingReportPage />
              </SuspendedView>
            }
          />
          <Route
            path='purchase-report/*'
            element={
              <SuspendedView>
                <PurchaseReportPage />
              </SuspendedView>
            }
          />
          <Route
            path='sales-report/*'
            element={
              <SuspendedView>
                <SalesReportPage />
              </SuspendedView>
            }
          />
          <Route
            path='process-type/*'
            element={
              <SuspendedView>
                <ProcessTypePage />
              </SuspendedView>
            }
          />
          <Route
            path='process/*'
            element={
              <SuspendedView>
                <ProcessPage />
              </SuspendedView>
            }
          />
            <Route
            path='sales-order/*'
            element={
              <SuspendedView>
                <SalesOrderPage />
              </SuspendedView>
            }
          />
           <Route
            path='order-category/*'
            element={
              <SuspendedView>
                <OrderCategorysPage />
              </SuspendedView>
            }
          />
          <Route
            path='material/*'
            element={
              <SuspendedView>
                <MaterialPage />
              </SuspendedView>
            }
          />
           <Route
            path='priority/*'
            element={
              <SuspendedView>
                <PriorityPage />
              </SuspendedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

const SuspendedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
