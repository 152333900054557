import axios, {AxiosResponse} from 'axios'
import {Stock, StockQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_STOCK_URL = `${API_URL}stock/add`
const GET_STOCK_URL = `${API_URL}stock/query`
const VIEW_STOCK_URL = `${API_URL}stock/view`
const UPDATE_STOCK_URL = `${API_URL}stock/update`
const DELETE_STOCK_URL = `${API_URL}stock/delete`
const GET_SKU_URL = `${API_URL}custom/get-sku`
const GET_PRODUCT_URL = `${API_URL}custom/get-product`

//AuthVerify
var AuthVerify = getToken.call(this)

//Product LIST
const getStock = (query: string): Promise<StockQueryResponse> => {
  return axios
    .post(`${GET_STOCK_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<StockQueryResponse>) => {
      console.log('getProduct==>api', response.data)
      return response.data
    })
}

//Product ADD
const createStock = (data: any): Promise<Stock | undefined> => {
  return axios.post(CREATE_STOCK_URL, data, getToken()).then((response: any) => response.data)
}

//Product VEIW
const viewStock = (id: any) => {
  return axios.get(`${VIEW_STOCK_URL}/${id}`, getToken()).then((response) => {
    console.log('view-Stock-api', response.data)
    return response.data
  })
}

//Stock UPDATE
const updateStock = (data: any, id: any) => {
  console.log('Stock=update-api', data)
  return axios.put(`${UPDATE_STOCK_URL}/${id}`, data, getToken()).then((response) => {
    console.log('update-Stock-api', response.data)
    return response.data
  })
}

//Stock DELETE
const deleteStockById = (id: any) => {
  return axios.post(`${DELETE_STOCK_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}
//GET unit
const getSku = async () => {
  const res = await axios.get(`${GET_SKU_URL}`, getToken())
  console.log('sku-api', res)
  return res
}
//GET tax
const getProduct = async () => {
  const res = await axios.get(`${GET_PRODUCT_URL}`, getToken())
  console.log('setProduct-api', res)
  return res
}

export {getStock, createStock, viewStock, updateStock, deleteStockById, getSku, getProduct}
