import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

import {AddDebitVoucher, EditVoucher} from './voucher-form'
import {AddCreditVoucher} from './voucher-form'
import {AddContraVoucher} from './voucher-form'
import {AddJournalVoucher} from './voucher-form'
import {VoucherListWrapper} from '././voucher-list/VoucherList'
import {EditVoucherWrapper} from './voucher-form/EditVoucherWrapper'

const voucherBreadcrumbs: Array<PageLink> = [
  {
    title: 'Voucher Management',
    path: '/voucher',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const VoucherPage = () => {
  const navigate = useNavigate()
  function goBack() {
    navigate(-1)
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={voucherBreadcrumbs}>Voucher list</PageTitle>

              <VoucherListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
      <Route
        path='/add-debit-voucher'
        element={
          <>
            {' '}
            <PageTitle breadcrumbs={voucherBreadcrumbs}>Debit Voucher Add</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <AddDebitVoucher />
          </>
        }
      />
      <Route
        path='/add-credit-voucher'
        element={
          <>
            {' '}
            <PageTitle breadcrumbs={voucherBreadcrumbs}> Credit Voucher Add</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <AddCreditVoucher />
          </>
        }
      />
      <Route
        path='/add-contra-voucher'
        element={
          <>
            {' '}
            <PageTitle breadcrumbs={voucherBreadcrumbs}>Contra Voucher Add</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <AddContraVoucher />
          </>
        }
      />
      <Route
        path='/add-journal-voucher'
        element={
          <>
            {' '}
            <PageTitle breadcrumbs={voucherBreadcrumbs}> Journal Voucher Add</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <AddJournalVoucher />
          </>
        }
      />
      <Route
        // path='/edit/:id'
        path='/edit/:voucherNumber'
        element={
          <>
            <PageTitle breadcrumbs={voucherBreadcrumbs}>Voucher Edit</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <EditVoucherWrapper />
          </>
        }
      />
    </Routes>
  )
}

export default VoucherPage
