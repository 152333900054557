import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {AddSku} from './sku-form'
import {EditSkuWrapper} from './sku-form/EditSkuWrapper'
import {SkuListWrapper} from './sku-list/SkuList'
const skuBreadcrumbs: Array<PageLink> = [
  {
    title: 'Sku Management',
    path: '/sku',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SkusPage = () => {
  const navigate = useNavigate()
  function goBack() {
    navigate(-1)
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={skuBreadcrumbs}>Sku List</PageTitle>
              <SkuListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
      <Route
        path='/add'
        element={
          <>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <PageTitle breadcrumbs={skuBreadcrumbs}>Sku Add</PageTitle>
            <AddSku />
          </>
        }
      />
      <Route
        path='/edit/:id'
        element={
          <>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <PageTitle breadcrumbs={skuBreadcrumbs}>Sku Edit</PageTitle>
            <EditSkuWrapper />
          </>
        }
      />
    </Routes>
  )
}

export default SkusPage
