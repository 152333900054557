import {ID, Response} from '../../../../../../_metronic/helpers'
export type Voucher = {
  voucherNumber: string
  id?: ID
  primaryId?: number
  financialyearId: string
  voucherDate: any
  voucherType: string
  coaId: string
  narration: string
  chequeNumber: string
  chequeDate: any
  voucher: [
    {
      ledgerComment: string
      debit: string
      credit: string
      reverseAccountName: string
    }
  ]
  createdBy: ''
  message?: string
  status?: string
}

export type VoucherQueryResponse = Response<Array<Voucher>>

export const initialVoucher: Voucher = {
  voucherNumber: '',
  financialyearId: '',
  voucherDate: '',
  voucherType: '',
  coaId: '',
  narration: '',
  chequeNumber: '',
  chequeDate: '',
  voucher: [
    {
      ledgerComment: '',
      debit: '',
      credit: '',
      reverseAccountName: '',
    },
  ],
  createdBy: '',
}
export type UpdateVoucher = {
  id?: ID
  voucherNumber?: string

  primaryId?: number
  financialyearId: string
  voucherDate: any
  voucherType: string
  coaId: string
  narration: string
  chequeNumber: string
  chequeDate: any
  voucher: [
    {
      ledgerComment: string
      debit: string
      credit: string
      reverseAccountName: string
    }
  ]
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateVoucherQueryResponse = Response<Array<UpdateVoucher>>

export const initialUpdateVoucher: UpdateVoucher = {
  financialyearId: '',
  voucherNumber: '',
  voucherDate: '',
  voucherType: '',
  coaId: '',
  narration: '',
  chequeNumber: '',
  chequeDate: '',
  voucher: [
    {
      ledgerComment: '',
      debit: '',
      credit: '',
      reverseAccountName: '',
    },
  ],
}
