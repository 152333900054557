import {useListView} from '../../core/ListViewProvider'
import {SalarygenerateListToolbar} from './SalarygenerateListToolbar'
import {SalarygenerateListGrouping} from './SalarygenerateListGrouping'
import {SalarygenerateListSearchComponent} from './SalarygenerateListSearchComponent'

const SalarygenerateListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <SalarygenerateListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <SalarygenerateListGrouping /> : <SalarygenerateListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {SalarygenerateListHeader}
