import {ID, Response} from '../../../../../../_metronic/helpers'
export type ProcessType = {
  id?: ID
  primaryId?: number
  
  name?: string
  description?: string

  message?: string
  status?: string
  createdBy?: number
}

export type ProcessTypeQueryResponse = Response<Array<ProcessType>>

export const initialProcessType: ProcessType = {
  description: '',
  name: '',


  createdBy: 0,
}
export type UpdateProcessType = {
  id?: ID
  primaryId?: number
  
  name?: string
  description?: string
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateProcessTypeQueryResponse = Response<Array<UpdateProcessType>>

export const initialUpdateProcessType: UpdateProcessType = {
  description: '',
  name: '',

}
