import {Route, Routes, Outlet, Navigate, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {PurchaseListWrapper} from './purchase-list/PurchaseList'
import {AddPurchase, EditPurchase} from './purchase-form'

const PurchaseBreadcrumbs: Array<PageLink> = [
  {
    title: 'Purchase Management',
    path: '/purchase',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PurchasePage = () => {
  const navigate = useNavigate()
  function goBack() {
    navigate(-1)
  }
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={PurchaseBreadcrumbs}>Purchase</PageTitle>
              <PurchaseListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
      <Route
        path='/add'
        element={
          <>
            <PageTitle breadcrumbs={PurchaseBreadcrumbs}>Purchase Add</PageTitle>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <AddPurchase />
          </>
        }
      />
      <Route
        path='/edit/:id'
        element={
          <>
            <button
              onClick={goBack}
              style={{marginBottom: '15px'}}
              className='btn btn-sm btn-info px-6 align-self-center text-nowrap'
            >
              <i className='bi bi-reply fs-4 me-2'></i> Go Back{' '}
            </button>
            <PageTitle breadcrumbs={PurchaseBreadcrumbs}>Purchase Edit</PageTitle>
            <EditPurchase />
          </>
        }
      />
    </Routes>
  )
}

export default PurchasePage
