import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useEffect, useState} from 'react'
//import {getExCRUD} from '../../../../role-access/role-setting-list/core/_requests'
import {useAuth} from '../../../../../auth'

const VoucherListToolbar = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()

  const addDebitVoucherPage = () => {
    navigate('/voucher/add-debit-voucher')
  }
  const addCreditVoucherPage = () => {
    navigate('/voucher/add-credit-voucher')
  }
  const addContraVoucherPage = () => {
    navigate('/voucher/add-contra-voucher')
  }
  const addJournalVoucherPage = () => {
    navigate('/voucher/add-journal-voucher')
  }
  // const [roleaccess, setroleaccess] = useState<any>([])
  // useEffect(() =>
  // {
  //   getExCRUD(currentUser?.roleId, 3).then((res: any) => {
  //     var crudres = res.data?.Data[0]
  //     setroleaccess(crudres)
  //   })
  // }
  //   , [])
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Add user */}

      <button type='button' className='btn btn-primary my-3 me-1 btn-sm' onClick={addDebitVoucherPage}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Debit Voucher
      </button>

      <button type='button' className='btn btn-success my-3 me-1 btn-sm' onClick={addCreditVoucherPage}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Credit Voucher
      </button>
      <button type='button' className='btn btn-warning my-3 me-1 btn-sm' onClick={addContraVoucherPage}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Contra Voucher
      </button>
      <button type='button' className='btn btn-info my-3 btn-sm' onClick={addJournalVoucherPage}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Journal Voucher
      </button>

      {/* end::Add user */}
    </div>
  )
}

export {VoucherListToolbar}
