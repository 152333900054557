// @ts-nocheck
import {Column} from 'react-table'
import {VoucherApprovalActionsCell} from './VoucherApprovalActionsCell'
import {VoucherApprovalCustomHeader} from './VoucherApprovalCustomHeader'
import {VoucherApproval} from '../../core/_models'
const VoucherApprovalColumns: ReadonlyArray<Column<VoucherApproval>> = [
  {
    Header: (props) => (
      <VoucherApprovalCustomHeader tableProps={props} title='S.No' className='min-w-55px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <VoucherApprovalCustomHeader
        tableProps={props}
        title='Voucher Number'
        className='min-w-95px'
      />
    ),
    accessor: 'voucherNumber',
  },

  {
    Header: (props) => (
      <VoucherApprovalCustomHeader tableProps={props} title='Date' className='min-w-100px' />
    ),
    accessor: 'voucherDate',
  },

  {
    Header: (props) => (
      <VoucherApprovalCustomHeader
        tableProps={props}
        title='Account name'
        className='min-w-110px'
      />
    ),
    accessor: 'reverseAccount.headName',
  },

  {
    Header: (props) => (
      <VoucherApprovalCustomHeader tableProps={props} title='Debit' className='min-w-70px' />
    ),
    accessor: 'debit',
  },
  {
    Header: (props) => (
      <VoucherApprovalCustomHeader tableProps={props} title='Credit' className='min-w-70px' />
    ),
    accessor: 'credit',
  },

  {
    Header: (props) => (
      <VoucherApprovalCustomHeader
        tableProps={props}
        title='Reverse Account Name'
        className='min-w-110px'
      />
    ),
    accessor: 'coa.headName',
  },

  {
    Header: (props) => (
      <VoucherApprovalCustomHeader
        tableProps={props}
        title='Status'
        className='min-w-105px'
      />
    ),
    accessor: 'isApproved',
    Cell: ({value}) => (
      <button
        style={{
          backgroundColor: value === 0 ? 'lightcoral' : 'lightgreen',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        {value === 0 ? 'Pending' : 'Approved'}
      </button>
    ),
  },
  {
    Header: (props) => (
      <VoucherApprovalCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <VoucherApprovalActionsCell voucherapproval={props.data[props.row.index]} />
    ),
  },
]

export {VoucherApprovalColumns}
