import React, {useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {Voucher, initialVoucher as initialValues} from '../voucher-list/core/_models'
import {createVoucher, getLedger, getPayment} from '../voucher-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../voucher-list/core/QueryResponseProvider'
// Validation schema
const VoucherDetailsSchema = Yup.object().shape({
  voucherDate: Yup.string().required('Voucher Date is required'),
  coaId: Yup.string().required('COA ID is required'),
  voucher: Yup.array().of(
    Yup.object().shape({
      reverseAccountName: Yup.string().required('Reverse Account Name is required'),
    })
  ),
})

const VoucherDetails = () => {
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()
  const [ledger, setLedger] = useState([])
  const [payment, setPayment] = useState([])
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Voucher>(initialValues)

  const navigate = useNavigate()

  useEffect(() => {
    // Fetch ledger data using useEffect
    getLedger().then((res) => {
      setLedger(res?.data?.data)
    })
  }, [])
  useEffect(() => {
    // Fetch ledger data using useEffect
    getPayment().then((res) => {
      setPayment(res?.data?.data)
    })
  }, [])
  function getCurrentDate() {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={VoucherDetailsSchema}
        onSubmit={(values, {resetForm}) => {
          setLoading(true)
          const updatedValues = {
            ...values,
            voucher: values.voucher,
            financialyearId: currentUser?.financialYearId,
            createdBy: currentUser?.userId,
            voucherType: 4,
          }
          createVoucher(updatedValues)
            .then((res: any) => {
              console.log('Contra-voucher', res?.message)
              resetForm({
                ...initialValues,
                values: {...initialValues},
              })
              setData(initialValues)
              navigate('/voucher', {replace: true})
              refetch()
              setLoading(false)
              showAlert(res?.message, res?.status)
            })
            .catch((error) => {
              console.error('Error creating voucher:', error)
            })
            .finally(() => {
              setLoading(false)
            })
        }}
      >
        {({values}) => (
          <Form>
            <div
              className='card-header border-0'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Add Journal Voucher</h3>
              </div>
            </div>

            <div id='kt_account_profile_details'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Voucher Type</label>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Enter Voucher Type'
                        value='Journal Voucher'
                        readOnly
                      />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>
                        Reverse Account Head
                      </label>
                      <Field
                        as='select'
                        name='coaId'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Account Head'
                      >
                        <option value=''>Select A/C Head</option>
                        {payment.map((item: any, i: any) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.headName}
                            </option>
                          )
                        })}
                      </Field>

                      <ErrorMessage
                        className='fieldError'
                        name='coaId'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label required fw-bold fs-6'>Date</label>
                      <Field
                        name='voucherDate'
                        type='date'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Date'
                        defaultValue={getCurrentDate()}
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='voucherDate'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <label className='col-form-label fw-bold fs-6'>Remarks</label>
                      <Field
                        as='textarea'
                        name='narration'
                        className='form-control form-control-lg form-control-solid'
                      />
                      <ErrorMessage
                        className='fieldError'
                        name='narration'
                        render={(msg) => (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{msg}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6 card-footer d-flex justify-content'>
                <div className='table-container'>
                  <FieldArray
                    name='voucher'
                    render={(arrayHelpers) => {
                      return (
                        <div>
                          {values.voucher && values.voucher.length > 0
                            ? values.voucher.map((voucher, index) => (
                                <div key={index} className='row mb-2 position-relative addon-group'>
                                  <div className='col-md-3 mb-2 mb-md-0'>
                                    <label className='form-label required fw-bold fs-6'>
                                      Account Name
                                    </label>
                                    <Field
                                      as='select'
                                      name={`voucher[${index}].reverseAccountName`}
                                      className='form-control form-control-lg form-control-solid'
                                      placeholder='Account Name'
                                    >
                                      <option value=''>Select A/C Name</option>
                                      {ledger.map((item: any, i: any) => {
                                        return (
                                          <option key={i} value={item.id}>
                                            {item.headName}
                                          </option>
                                        )
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      className='fieldError'
                                      name={`voucher[${index}].reverseAccountName`}
                                      render={(msg) => (
                                        <div className='fv-plugins-message-container'>
                                          <div className='fv-help-block'>{msg}</div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                  <div className='col-md-3 mb-2 mb-md-0'>
                                    <label className='form-label fw-bold fs-6'>
                                      Ledger Comment
                                    </label>
                                    <Field
                                      type='text'
                                      className='form-control'
                                      name={`voucher[${index}].ledgerComment`}
                                    />
                                    <ErrorMessage
                                      className='fieldError'
                                      name={`voucher[${index}].ledgerComment`}
                                      render={(msg) => <div className='fieldError'>{msg}</div>}
                                    />
                                  </div>
                                  <div className='col-md-2'>
                                    <label className='form-label fw-bold fs-6'>Debit</label>
                                    <Field
                                      type='text'
                                      className='form-control'
                                      placeholder='Debit Amount'
                                      name={`voucher[${index}].debit`}
                                    />
                                    <ErrorMessage
                                      className='fieldError'
                                      name={`voucher[${index}].debit`}
                                      render={(msg) => <div className='fieldError'>{msg}</div>}
                                    />
                                  </div>
                                  <div className='col-md-2'>
                                    <label className='form-label fw-bold fs-6'>Credit</label>
                                    <Field
                                      type='text'
                                      className='form-control'
                                      placeholder='credit Amount'
                                      name={`voucher[${index}].credit`}
                                    />
                                    <ErrorMessage
                                      className='fieldError'
                                      name={`voucher[${index}].credit`}
                                      render={(msg) => <div className='fieldError'>{msg}</div>}
                                    />
                                  </div>
                                  {index === 0 ? (
                                    <div className='col-md-2 mb-2 mb-md-0'>
                                      <label className='form-label mt-14'></label>
                                      <Link to='#' className='add-btn'>
                                        <div
                                          onClick={() =>
                                            arrayHelpers.push({
                                              reverseAccountName: '',
                                              debit: '',
                                              credit: '',
                                              ledgerComment: '',
                                            })
                                          }
                                          className='btn btn-primary'
                                        >
                                          Add New Field
                                        </div>
                                      </Link>
                                    </div>
                                  ) : (
                                    <div className='col-md-2 mb-2 mb-md-0'>
                                      <label className='form-label mt-14'></label>
                                      <Link to='#' className='add-btn'>
                                        <div
                                          onClick={() => arrayHelpers.remove(index)}
                                          className='btn btn-danger'
                                        >
                                          Remove
                                        </div>
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              ))
                            : null}
                        </div>
                      )
                    }}
                  />
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading ? 'Save Changes' : 'Please wait...'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default VoucherDetails
