import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'

import {getToken} from '../../../auth'

const API_URL = process.env.REACT_APP_API_URLS

var AuthVerify = getToken.call(this)

const getReport = async (data: any) => {
  console.log('api-data', data)
  const res = await axios.post(`${API_URL}reports/sales-report`, data, getToken())
  console.log('api-res', res)
  return res
}
const customerDropdown = async () => {
  const res = await axios.get(`${API_URL}sales/get-customer`, getToken())
  console.log('res-customer/get-supplier-drop-api', res)
  return res
}
export {getReport,customerDropdown}
