import {useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import AddSalaryGenerateModal from '../../../salary-generate-form/AddSalaryGenerateModal'

const SalarygenerateListToolbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }
  console.log('isModalOpen', isModalOpen)
  return (
    <>
      <div className='d-flex justify-content-end' data-kt-salarygenerate-table-toolbar='base'>
        {/* begin::Export */}
        {/* <button type='button' className='btn btn-light-primary me-3'>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Export
        </button> */}

        {/* begin::Add SalaryGenerate */}
        <button type='button' className='btn btn-primary me-3' onClick={openModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Salary Generate
        </button>
        <AddSalaryGenerateModal show={isModalOpen} onHide={closeModal} />
      </div>
    </>
  )
}

export {SalarygenerateListToolbar}
