import React, {useEffect, useRef, useState} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useDownloadExcel} from 'react-export-table-to-excel'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {getReport} from '../_requests'
import {useAuth} from '../../../../auth'

const ReportContent = () => {
  const tableRef = useRef(null)
  const [report, setReport] = useState<ReportData[]>([])
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null])
  const [totalExpenses, setTotalExpenses] = useState<string>('')
  const [totalIncome, setTotalIncome] = useState<string>('')
  const [OCDate, setOCDate] = useState('')
  const [loading, setLoading] = useState(false)
  const [btnExcelDisable, setbtnExcelDisable] = useState(true)
  const [btnClearDisable, setbtnClearDisable] = useState(true)
  const [selectedOption, setSelectedOption] = useState(null)
  const [alertMessage, setAlertMessage] = useState<string | null>(null)

  const {mode} = useThemeMode()

  interface ReportData {
    coaId: number
    debit: string
    credit: string
    coa: {
      headName: string
      headCode: string
      parentHeadName: string
      headLevel: number
    }
  }

  const PlReport = () => {
    setLoading(true)

    // Get the value of the date range input
    const Daterange = (document.getElementById('Daterange') as HTMLInputElement).value

    // Check if the date range is not selected
    if (!Daterange) {
      // Set the alert message
      setAlertMessage('Please select a date range.')
      setLoading(false) // Set loading to false to indicate that the request is not in progress
      return // Exit the function if the date range is not selected
    }

    setAlertMessage('')

    const dateRange = Daterange.split(' - ')
    const fromDate = dateRange[0].split('/').reverse().join('-')
    const toDate = dateRange[1].split('/').reverse().join('-')
    const data = {fromDate, toDate}

    console.log('Sending API request with data:', data)

    getReport(data).then((resRep: any) => {
      console.log('Received API response:', resRep)

      // Extract and set the Expenses and Total Expenses
      const expensesData = resRep.data.data.Expenses
      const incomeData = resRep.data.data.Income
      const totalExpensesData = resRep.data.data.totalExpenses
      const totalIncomeData = resRep.data.data.totalIncome

      setReport(expensesData)
      setTotalExpenses(totalExpensesData)
      setTotalIncome(totalIncomeData)

      // Set the OCDate based on the selected date range
      setOCDate(`${fromDate} - ${toDate}`)
      setLoading(false)

      // Enable or disable the buttons as needed based on the new data.
      setbtnExcelDisable(Object.keys(resRep.data.data).length === 0)
      setbtnClearDisable(Object.keys(resRep.data.data).length === 0)
    })
  }

  var DatefileName = new Date()
  var fileName = `${DatefileName.getFullYear()}-${
    DatefileName.getMonth() + 1
  }-${DatefileName.getDate()}`

  const clearReport = () => {
    setReport([])
    setSelectedOption(null)
    setDateRange([null, null])
    setOCDate('')
    setbtnExcelDisable(true)
    setbtnClearDisable(true)
  }

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `ProfitLoss_Report_${fileName}`,
    sheet: 'Users',
  })

  return (
    <>
      <div className='card'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profit and Loss Report</h3>
          </div>
          <div className='col-lg-2 pt-5'>
            <button
              className='btn btn-danger col-lg-12'
              disabled={btnExcelDisable}
              onClick={onDownload}
            >
              Export
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='row mb-10'>
            <div className='col-lg-4'></div>

            <div className='col-lg-4'>
              <label className='col-form-label fw-bold fs-6'>Filter By Date Range</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid'
                id='Daterange'
                selectsRange={true}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                onChange={(update) => {
                  setDateRange(update as [Date | null, Date | null])
                }}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
              />
              {/* Display the alert message */}
              {alertMessage && <div className='text-danger'>{alertMessage}</div>}
            </div>

            <div className='col-lg-2 pt-12'>
              <button onClick={PlReport} className='btn btn-primary col-lg-12'>
                Search
              </button>
            </div>
            <div className='col-lg-2 pt-12'>
              <button
                onClick={clearReport}
                className='btn btn-info col-lg-12'
                disabled={btnClearDisable}
              >
                Clear
              </button>
            </div>
          </div>

          <div className='table-responsive'>
            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
              ref={tableRef}
            >
              <thead>
                <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                  <th className='min-w-120px' colSpan={6}>
                    DATE: {OCDate}
                  </th>
                </tr>
                <tr>
                  <td colSpan={11}></td>
                </tr>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-50px'>S. No</th>
                  <th className='min-w-120px'>Particulars</th>
                  <th className='min-w-120px'>Debit</th>
                  <th className='min-w-120px'>Credit</th>
                </tr>
              </thead>

              <tbody>
                <h5 className='fw-bolder m-0'>Income</h5>

                {report.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.coa.headName}</td>
                    <td>{item.credit}</td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td></td>

                  <td>
                    <h5>Total Income:</h5>
                  </td>
                  <td>{totalIncome}</td>
                </tr>
              </tbody>

              <tbody>
                <h5 className='fw-bolder m-0'>Expenses</h5>
                {report.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.coa.headName}</td>
                    <td>{item.debit}</td>
                  </tr>
                ))}

                <tr>
                  <td></td>
                  <td>
                    <h5>Total Expenses:</h5>
                  </td>
                  <td>{totalExpenses}</td>
                </tr>
              </tbody>
            </table>
            <div></div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ReportContent}
