import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
// import {BankreconciliationListWrapper} from './bankreconciliationlist/BankreconciliationList'
import {useContext, useEffect} from 'react'
import {BankreconciliationListWrapper} from './bank-reconciliation-list/BankreconciliationList'
// import { AlertContext } from '../../../../_metronic/layout/components/alert-context'

const bankreconciliationBreadcrumbs: Array<PageLink> = [
  {
    title: 'Bank Reconciliation Management',
    path: '/bank-reconciliation',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BankreconciliationPage = () => {
  // const {hideAlert} = useContext(AlertContext)
  // useEffect(() => {
  //   hideAlert(false)
  // }, [])
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={bankreconciliationBreadcrumbs}>
                Bank Reconciliation Management
              </PageTitle>
              <BankreconciliationListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default BankreconciliationPage
