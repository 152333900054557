import {PaymentMethodListHeader} from './components/header/PaymentMethodListHeader'
import {PaymentMethodTable} from './table/PaymentMethodTable'
import {KTCard} from '../../../../../_metronic/helpers'
import {AddPaymentMethod, EditPaymentMethod} from '../payment-method-form'
import {useContext} from 'react'
import {MyContext} from './ContextFile'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ContextProvider} from './ContextProvider'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
const PaymentMethodAdd = () => {
  const {state, setState} = useContext(MyContext)

  return (
    <>
      <KTCard>{state.editclicked === true ? <EditPaymentMethod /> : <AddPaymentMethod />}</KTCard>
    </>
  )
}

const PaymentMethodList = () => {
  return (
    <>
      <KTCard>
        <PaymentMethodListHeader />
        <PaymentMethodTable />
      </KTCard>
    </>
  )
}

const PaymentMethodMain = () => {
  const {show, message, type} = useContext(AlertContext)
  return (
    <div className='row'>
      {show && <AlertBox type={type === true ? 'success' : 'error'} message={message} />}
      <div className='col-lg-5'>
        <PaymentMethodAdd />
      </div>
      <div className='col-lg-7'>
        <PaymentMethodList />
      </div>
    </div>
  )
}

const PaymentMethodListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ContextProvider>
          <PaymentMethodMain />
        </ContextProvider>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PaymentMethodListWrapper}
