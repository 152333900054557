import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewOpeningbalance} from '../opening-balance-list/core/_requests'
import {EditOpeningBalance} from '../opening-balance-form/EditOpeningbalance'

export const EditOpeningBalanceWrapper = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewOpeningbalance(id).then((res) => {
      setData(res.data)
      console.log('viewOpeningBalanceDetail', res.data)
      console.log('viewOpeningBalanceDetail-data', data)
    })
  }, [])
  if (data) {
    return <EditOpeningBalance initialValues={data} />
  }
  return null
}
