import React, {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createSupplier} from '../supplier-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../supplier-list/core/QueryResponseProvider'
import {Supplier, initialSupplier as initialValues} from '../supplier-list/core/_models'

//Validation
const SupplierDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Supplier Name is required'),
  email: Yup.string().required('Email Address is required'),
  mobileNumber: Yup.string().required('Mobile No is required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
  address: Yup.string().required('Address is required'),
  fax: Yup.string().required('Fax is required'),
  zipCode: Yup.string().required('Zip Code is required'),
  taxId: Yup.string().required('Tax No is required'),
})

//Function Starts
const SupplierDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Supplier>(initialValues)
  const navigate = useNavigate()

  //Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()
  // const type = currentUser?.branchId
  const [loading, setLoading] = useState(false)
  //formik Starts
  const formik = useFormik<Supplier>({
    initialValues,
    validationSchema: SupplierDetailsSchema,

    //Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      updatedData.createdBy = currentUser?.userId
      createSupplier(updatedData).then((res: any) => {
        console.log('Alertres', res?.message)
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/supplier', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.message, res?.status)
      })
    },
    enableReinitialize: true,
  })

  console.log('Supplier-formik', formik)
  // ... (previous imports and code)

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Supplier </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Supplier Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Supplier Name'
                  id='supplierName'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Mobile Number</label>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Mobile Number'
                  {...formik.getFieldProps('mobileNumber')}
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.mobileNumber}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Phone Number</label>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Phone Number'
                  id='phone'
                  {...formik.getFieldProps('phoneNumber')}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phoneNumber}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Email Address</label>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Email Address'
                  id='email'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Address</label>
                <textarea
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Address'
                  id='address1'
                  {...formik.getFieldProps('address')}
                ></textarea>
                {formik.touched.address && formik.errors.address && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.address}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Zip Code</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Zip Code'
                  id='zipCode'
                  {...formik.getFieldProps('zipCode')}
                />
                {formik.touched.zipCode && formik.errors.zipCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.zipCode}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Fax</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Fax'
                  id='fax'
                  {...formik.getFieldProps('fax')}
                />
                {formik.touched.fax && formik.errors.fax && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.fax}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Tax Id</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Tax Id'
                  id='taxId'
                  {...formik.getFieldProps('taxId')}
                />
                {formik.touched.taxId && formik.errors.taxId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.taxId}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading ? (
                'Save Changes'
              ) : (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default SupplierDetails
