import {useListView} from '../../core/ListViewProvider'
import {SizeListToolbar} from './SizeListToolbar'
import {SizeListGrouping} from './SizeListGrouping'
import {SizeListSearchComponent} from './SizeListSearchComponent'

const SizeListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <SizeListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <SizeListGrouping /> : <SizeListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {SizeListHeader}
