import {ID, Response} from '../../../../../../_metronic/helpers'
export type Financialyear = {
  id?: ID
  primaryId?: number
  name?: string
  startDate?: string
  endDate?: string
  closeYearId?: string
  message?: string
  status?: string
  createdBy?: number
}

export type FinancialyearQueryResponse = Response<Array<Financialyear>>

export const initialFinancialyear: Financialyear = {
  name: '',
  startDate: '',
  endDate: '',
  closeYearId: '',
  createdBy: 0,
}
export type UpdateFinancialyear = {
  id?: ID
  primaryId?: number
  name?: string
  startDate?: string
  endDate?: string
  status?: string
  updatedBy?: number
}

export type UpdateFinancialyearQueryResponse = Response<Array<UpdateFinancialyear>>

export const initialUpdateFinancialyear: UpdateFinancialyear = {
  name: '',
  startDate: '',
  endDate: '',
}
