import {useEffect, useRef, useState} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select, {Theme} from 'react-select'
import {useDownloadExcel} from 'react-export-table-to-excel'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {getReport, customerDropdown} from '../_requests'
import {useAuth} from '../../../../auth'

const ReportContent = () => {
  const tableRef = useRef(null)
  const [cli, setCli] = useState<any>([])
  const {currentUser} = useAuth()

  const [report, setReport] = useState<any>([])

  const [dateRange, setDateRange] = useState([null, null])

  const [data, setdata] = useState('')
  const [OCFy, setFy] = useState('')
  const [OCDate, setOCDate] = useState('')
  const [startDate, endDate] = dateRange
  const [loading, setLoading] = useState(false)
  const [btnExcelDisable, setbtnExcelDisable] = useState(true)
  const [btnClearDisable, setbtnClearDisable] = useState(true)
  const [type, setType] = useState(1)
  const [selectedOption, setSelectedOption] = useState<{value: string | number} | null>(null)

  // Add a useEffect to fetch the list of sku on component mount
  useEffect(() => {
    // Define an async function to fetch the list of employees
    const fetchCustomers = async () => {
      try {
        const response = await customerDropdown() // Replace with your actual API call
        console.log('response-sku', response.data)
        const customerOptions = response.data.data.map((customer: any) => ({
          value: customer.id,
          label: customer.customerName,
        }))

        const allOption = {value: 0, label: 'All'}
        setCli([allOption, ...customerOptions])
      } catch (error) {
        console.error('Error fetching customer:', error)
      }
    }

    fetchCustomers()
  }, [])

  const StockReport = (type: any) => {
    setLoading(true)

    const Daterange = (document.getElementById('Daterange') as HTMLInputElement).value
    const dateRange = Daterange.split(' - ')

    const fromDate = dateRange[0] ? dateRange[0].split('/').reverse().join('-') : ''
    const toDate = dateRange[1] ? dateRange[1].split('/').reverse().join('-') : ''
    const selectedCustomer = selectedOption ? selectedOption.value : 0

    const data = {
      fromDate,
      toDate,
      customerId: selectedCustomer,
      type,
    }

    console.log('fromDate', fromDate)
    console.log('toDate', toDate)
    console.log('selectedCustomer', selectedCustomer)
    console.log('type', type)

    getReport(data).then((resRep) => {
      console.log('data-getReport', data)
      setLoading(false)

      setReport(resRep.data.data)

      // Enable or disable the buttons as needed based on the new data.
      setbtnExcelDisable(Object.keys(resRep.data.data).length === 0)
      setbtnClearDisable(Object.keys(resRep.data.data).length === 0)
    })

    setOCDate(`${fromDate} - ${toDate}`)
  }
  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()

  const clearReport = () => {
    setLoading(true)
    setTimeout(() => {
      setReport([])
      setSelectedOption(null)
      setDateRange([null, null])
      setOCDate('')
      setbtnExcelDisable(true)
      setbtnClearDisable(true)
      setLoading(false) // Set loading to false after clearing the data
    }, 100)
  }

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Sales_Report_${fileName}`,
    sheet: 'Users',
  })

  return (
    <>
      <div className='card '>
        {/* begin::Header */}
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Sales Report</h3>
          </div>
          <div className='col-lg-2 pt-5'>
            <button
              className='btn btn-danger col-lg-12'
              disabled={btnExcelDisable}
              onClick={onDownload}
            >
              Export
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='row mb-10'>
            <div className='col-lg-4'>
              <label className=' col-form-label  fw-bold fs-6'>Filter By Customer</label>

              <Select
                className='my-react-select-container'
                classNamePrefix='my-react-select'
                value={selectedOption}
                options={cli}
                isSearchable
                onChange={(selectedValue) => setSelectedOption(selectedValue)}
              />
            </div>

            <div className='col-lg-4'>
              <label className=' col-form-label  fw-bold fs-6'>Filter By Date Range</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid'
                id='Daterange'
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update: any) => {
                  setDateRange(update)
                }}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
              />
            </div>

            <div className='col-lg-2 pt-12'>
              <button onClick={() => StockReport(type)} className='btn btn-primary col-lg-12'>
                Search
              </button>
            </div>
            <div className='col-lg-2 pt-12'>
              <button
                onClick={clearReport}
                className='btn btn-info col-lg-12'
                disabled={btnClearDisable}
              >
                Clear
              </button>
            </div>
          </div>

          <div className='table-responsive'>
            {/* begin::Table */}

            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
              ref={tableRef}
            >
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                  <th className='min-w-50px' colSpan={4}>
                    Customer Name: {selectedOption ? (selectedOption as any).label : 'All'}
                  </th>

                  <th className='min-w-120px' colSpan={5}>
                    DATE : {OCDate}
                  </th>
                </tr>
                <tr>
                  <td colSpan={11}></td>{' '}
                </tr>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-50px'> S. NO</th>
                  <th className='min-w-140px'> CUSTOMER NAME</th>
                  <th className='min-w-140px'> DATE</th>
                  <th className='min-w-120px'> INVOICE NO </th>
                  <th className='min-w-140px'> QUANTITY </th>
                  <th className='min-w-100px'> TOTAL TAX </th>
                  <th className='min-w-100px'> TOTAL DISCOUNT </th>
                  <th className='min-w-100px'> TOTAL TOTAL </th>
                  <th className='min-w-100px'> PAID AMOUNT </th>
                </tr>
              </thead>
              {loading && (
                <tbody>
                  <tr>
                    <td colSpan={11} style={{textAlign: 'center'}}>
                      {' '}
                      No Data...{' '}
                    </td>
                  </tr>
                </tbody>
              )}
              {!loading && (
                <tbody>
                  {report?.map((item: any, i: any) => {
                    return (
                      <tr key={i}>
                        <td> {i + 1}</td>

                        <td> {item.customer.customerName}</td>

                        <td> {item.salesDate} </td>
                        <td> {item.invoiceNumber} </td>
                        <td> {item.quantity} </td>
                        <td> {item.tax} </td>
                        <td> {item.discount} </td>
                        <td> {item.grandTotal} </td>
                        <td> {item.paidAmount} </td>
                      </tr>
                    )
                  })}
                </tbody>
              )}
              {/* end::Table body */}
            </table>
            {!loading && report.length === 0 && (
              <tbody>
                <tr>
                  <td colSpan={11} style={{textAlign: 'center'}}>
                    No data available.
                  </td>
                </tr>
              </tbody>
            )}

            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {ReportContent}
