// @ts-nocheck
import {Column} from 'react-table'
import {StockInfoCell} from './StockInfoCell'
import {StockTwoStepsCell} from './StockTwoStepsCell'
import {StockActionsCell} from './StockActionsCell'
import {StockCustomHeader} from './StockCustomHeader'
import {Stock} from '../../core/_models'

const StockColumns: ReadonlyArray<Column<Stock>> = [
  {
    Header: (props) => (
      <StockCustomHeader tableProps={props} title='S.No' className='min-w-75px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <StockCustomHeader tableProps={props} title='Category' className='min-w-125px' />
    ),
    accessor: 'purchaseType',
    Cell: ({ value }) => (
      <label
        style={{
          backgroundColor: value === "Product" ? '#FFF4FF' : '#FFF4FF', // Both Product and Material have the same background color
          color: value === "Product" ? 'blue' : value === "Material" ? 'green' : value === "Product Sales" ? 'purple' : 'black', // Set the text color based on the value
          padding: '8px 12px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        {value === "Product" ? 'Product' : value === "Material" ? 'Material' : value === "Product Sales" ? 'Product sales' : ''}
      </label>
    ),
  },
  
  // {
  //   Header: (props) => (
  //     <StockCustomHeader tableProps={props} title='Category' className='min-w-105px' />
  //   ),
  //   accessor: 'purchaseType',
  //   Cell: ({ value }) => (
  //     <label
  //       style={{
  //         backgroundColor: value === "Product" ? '#FFF4FF' : '#FFF4FF', // Both Product and Material have the same background color
  //         color: value === "Product" ? 'blue' : 'green', // Text color is blue for Product and green for Material
  //         padding: '8px 12px',
  //         borderRadius: '5px',
  //         border: 'none',
  //         cursor: 'pointer',
  //       }}
  //     >
  //       {value === "Product" ? 'Product' : 'Material'}
  //     </label>
  //   ),
  // },
  
  

  {
    Header: (props) => (
      <StockCustomHeader tableProps={props} title='Date' className='min-w-125px' />
    ),
    accessor: 'date',
  },
  {
    Header: (props) => (
      <StockCustomHeader tableProps={props} title='SKU Name' className='min-w-165px' />
    ),
    accessor: 'sku.skuName',
  },
  {
    Header: (props) => (
      <StockCustomHeader tableProps={props} title='Size' className='min-w-115px' />
    ),
    accessor: 'size.sizeName',
    Cell: ({ value }) => (value ? value : "-"),
  },
  {
    Header: (props) => (
      <StockCustomHeader tableProps={props} title='Color' className='min-w-105px' />
    ),
    accessor: 'color.colorName',
  },


 
  {
    Header: (props) => (
      <StockCustomHeader tableProps={props} title='Unit' className='min-w-85px' />
    ),
    accessor: 'unit.unitName',
  },
  {
    Header: (props) => (
      <StockCustomHeader tableProps={props} title='Inward Quantity' className='min-w-105px' />
    ),
    accessor: 'inwardQuantity',
  },
  {
    Header: (props) => (
      <StockCustomHeader tableProps={props} title='Outward Quantity' className='min-w-105px' />
    ),
    accessor: 'outwardQuantity',
  },
  {
    Header: (props) => (
      <StockCustomHeader tableProps={props} title='Current Stock' className='min-w-105px' />
    ),
    accessor: 'currentStock',
  },
 
  // {
  //   Header: (props) => (
  //     <StockCustomHeader tableProps={props} title='Product Value' className='min-w-105px' />
  //   ),
  //   accessor: 'value',
  // },
  {
    Header: (props) => (
      <StockCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <StockActionsCell stock={props.data[props.row.index]} />,
  },
]

export {StockColumns}
