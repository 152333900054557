/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {SidebarMenuItem} from './SidebarMenuItem'
import {getrolewisesidemenu} from '../../../../../app/modules/apps/role-access/role-setting-list/core/_requests'
import {useAuth} from '../../../../../app/modules/auth'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const [sidemenu, setsideMenu] = useState<any>([])
  const {currentUser} = useAuth()
  const rolesId = currentUser?.rolesId

  useEffect(() => {
    getrolewisesidemenu(rolesId).then((res: any) => {
      const sidemenuItems = res.data.data
      const result = sidemenuItems.reduce((acc: any, item: any) => {
        const titleName = item.module.title.name
        const moduleName = item.module.name
        const moduleIcon = item.module.icon // Add module icon
        const moduleLink = item.module.link // Add module link
        const submodules = item.module.submodules.map((submodule: any) => ({
          name: submodule.name,
          link: submodule.link, // Add submodule link
        }))

        if (!acc[titleName]) {
          acc[titleName] = {}
        }

        if (!acc[titleName][moduleName]) {
          acc[titleName][moduleName] = {
            icon: moduleIcon,
            link: moduleLink,
            submodules: submodules,
          }
        } else {
          // Merge submodules and remove duplicates
          acc[titleName][moduleName].submodules = [
            ...new Map(
              [...acc[titleName][moduleName].submodules, ...submodules].map((item) => [
                item.name,
                item,
              ])
            ).values(),
          ]
        }

        return acc
      }, {})

      const restructuredData = Object.keys(result).map((title) => ({
        title: title,
        modules: Object.keys(result[title]).map((moduleName) => ({
          module: moduleName,
          icon: result[title][moduleName].icon, // Use module icon here
          link: result[title][moduleName].link, // Use module link here
          submodules: result[title][moduleName].submodules, // Use submodules here
        })),
      }))

      setsideMenu(restructuredData)
    })
  }, [])

  return (
    <>
      <div>
        {/* Render your menu items */}
        {sidemenu.map((titleItem: any, i: any) => (
          <div key={i}>
            <div className='menu-item'>
              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                  {titleItem.title}
                </span>
              </div>
            </div>
            {titleItem.modules.map((moduleItem: any, j: any) => (
              <SidebarMenuItemWithSub
                key={j}
                to={moduleItem.link} // Use module link here
                title={moduleItem.module}
                fontIcon={moduleItem.icon} // Use module icon here
                icon='/media/icons/duotune/communication/com006.svg'
              >
                {moduleItem.submodules.map((submoduleItem: any, k: any) => (
                  <SidebarMenuItem
                    key={k}
                    to={submoduleItem.link} // Use submodule link here
                    title={submoduleItem.name} // Extract 'name' from object
                    hasBullet={true}
                  />
                ))}
              </SidebarMenuItemWithSub>
            ))}
          </div>
        ))}
      </div>
    </>
  )
}

export {SidebarMenuMain}
