import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Bank, BankQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_BANK_URL = `${API_URL}bank/add`
const GET_BANK_URL = `${API_URL}bank/query`
const VIEW_BANK_URL = `${API_URL}bank/view`
const UPDATE_BANK_URL = `${API_URL}bank/update`
const DELETE_BANK_URL = `${API_URL}bank/delete`

//AuthVerify
var AuthVerify = getToken.call(this)

//Bank LIST
const getBank = (query: string): Promise<BankQueryResponse> => {
  return axios
    .get(`${GET_BANK_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<BankQueryResponse>) => {
      console.log('get-Bank==>', response.data)
      return response.data
    })
}

//Bank ADD
const createBank = (data: any): Promise<Bank | undefined> => {
  return axios.post(CREATE_BANK_URL, data, getToken()).then((response: any) => response.data)
}

//Bank VEIW
const viewBank = (id: any) => {
  return axios.get(`${VIEW_BANK_URL}/${id}`, getToken()).then((response) => {
    console.log('view-Bank', response.data)
    return response.data
  })
}

//Bank UPDATE
const updateBank = (data: any, id: any) => {
  console.log('reqData=update-Bank', data)
  return axios.put(`${UPDATE_BANK_URL}/${id}`, data, getToken()).then((response) => {
    console.log('updatemp-Bank', response.data)
    return response.data
  })
}

//Bank DELETE
const deleteBankById = (id: any) => {
  return axios.post(`${DELETE_BANK_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

export {getBank, createBank, viewBank, updateBank, deleteBankById}
