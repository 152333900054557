import axios, {AxiosResponse} from 'axios'
import {Sales, SalesQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_SALES_URL = `${API_URL}sales/add`
const GET_SALES_URL = `${API_URL}sales/query`
const VIEW_SALES_URL = `${API_URL}sales/view`
const UPDATE_SALES_URL = `${API_URL}sales/update`
const DELETE_SALES_URL = `${API_URL}sales/delete`
const GET_CUS_URL = `${API_URL}custom/get-customer`
const GET_PRODUCT_URL = `${API_URL}custom/get-product`
const GET_PAYMENT_URL = `${API_URL}custom/get-payment`
const GET_SKU_URL = `${API_URL}custom/get-sku`
const GET_SIZE_URL = `${API_URL}custom/get-size`
const GET_COLOR_URL = `${API_URL}custom/get-color`
const GET_MATERIAL_URL = `${API_URL}custom/get-material`
const GET_UNIT_URL = `${API_URL}custom/get-unit`
//AuthVerify
var AuthVerify = getToken.call(this)

//Sales LIST
const getSales = (query: string): Promise<SalesQueryResponse> => {
  return axios
    .post(`${GET_SALES_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<SalesQueryResponse>) => {
      console.log('getSales==>api', response.data)
      return response.data
    })
}

//Sales ADD
const createSales = (data: any): Promise<Sales | undefined> => {
  return axios.post(CREATE_SALES_URL, data, getToken()).then((response: any) => response.data)
}

//Sales VEIW
const viewSales = (id: any) => {
  return axios.get(`${VIEW_SALES_URL}/${id}`, getToken()).then((response) => {
    console.log('view-Sales', response.data)
    return response.data
  })
}

//Sales UPDATE
const updateSales = (data: any, id: any) => {
  console.log('Sales-reqData=update', data)
  return axios.put(`${UPDATE_SALES_URL}/${id}`, data, getToken()).then((response) => {
    console.log('update-Sales', response.data)
    return response.data
  })
}

//Sales DELETE
const deleteSalesById = (id: any) => {
  return axios.post(`${DELETE_SALES_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}
//GET Product
const getProduct = async () => {
  const res = await axios.get(`${GET_PRODUCT_URL}`, getToken())
  console.log('get-getProduct', res)
  return res
}
//GET Payment
const getPayment = async () => {
  const res = await axios.get(`${GET_PAYMENT_URL}`, getToken())
  console.log('getPayment', res)
  return res
}
//GET customer
const getCustomer = async () => {
  const res = await axios.get(`${GET_CUS_URL}`, getToken())
  console.log('rgetCustomer', res)
  return res
}
//GET SKU
const getSku = async () => {
  const res = await axios.get(`${GET_SKU_URL}`, getToken())
  console.log('sku-api', res)
  return res
}
//GET Material
const getMaterial = async () => {
  const res = await axios.get(`${GET_MATERIAL_URL}`, getToken())
  console.log('get-Material-api', res)
  return res
}
//GET Size
const getSize = async () => {
  const res = await axios.get(`${GET_SIZE_URL}`, getToken())
  console.log('get-GET_SIZE_URL-api', res)
  return res
}
//GET Size
const getColor = async () => {
  const res = await axios.get(`${GET_COLOR_URL}`, getToken())
  console.log('get-getColor-api', res)
  return res
}
//GET Unit
const getUnit = async () => {
  const res = await axios.get(`${GET_UNIT_URL}`, getToken())
  console.log('get-getUnit-api', res)
  return res
}
export {
  getSales,
  createSales,
  viewSales,
  updateSales,
  deleteSalesById,
  getProduct,
  getPayment,
  getCustomer,
  getSku,getSize,getColor,getMaterial,getUnit,
}
