import {ID, Response} from '../../../../../../_metronic/helpers'
export type Salarygenerate = {
  id?: ID
  primaryId?: any
  month?: string
  generatedDate?: string
  approvedDate?: any
  message?: string
  status?: string
  createdBy?: number
  generatedBy?: number
  approvedStatus?: number
  approvedBy?: number
}

export type SalarygenerateQueryResponse = Response<Array<Salarygenerate>>

export const initialSalarygenerate: Salarygenerate = {
  month: '',
  generatedDate: '',
  approvedDate: '',
  generatedBy: 0,
  approvedStatus: 0,
  approvedBy: 0,
  createdBy: 0,
}
export type UpdateSalarygenerate = {
  id?: ID
  primaryId?: any
employee:{
  employeeName:any
}
  employeeId?: any
  amount?: any
  month?: string
  releaseDate?:any
  releaseAmount?:any
  salaryMonth?:any
  generatedDate?: string
  salaryAmount?: any
  totalCount?: any
  loanDeduct?: any
  loanId?: any
  netSalary?: any
  generatedBy?: number
  salarysheet:{month?: string}
  message?: string
  status?: string
  updatedBy?: number
  approvedBy?: number
  approvedDate?: any
  approvedStatus?: any
}

export type UpdateSalarygenerateQueryResponse = Response<Array<UpdateSalarygenerate>>

export const initialUpdateSalarygenerate: UpdateSalarygenerate = {
  employee:{
    employeeName:''
  },
  month: '',
  generatedDate: '',
  generatedBy: 0,
  approvedBy: 0,
  approvedDate: '',
  approvedStatus: '',
  salarysheet:{month: ''}
}
