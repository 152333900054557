import React, {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createStock, getSku, getProduct} from '../stock-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../stock-list/core/QueryResponseProvider'
import {Stock, initialStock as initialValues} from '../stock-list/core/_models'
//Validation
const StockDetailsSchema = Yup.object().shape({
  skuId: Yup.string().required('Sku Name is required'),
  productId: Yup.string().required('Product Name is required'),
  value: Yup.string().required('Product value is required'),
  currentStock: Yup.string().required('Current Stock is required'),
})

//Function Starts
const StockDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Stock>(initialValues)
  const navigate = useNavigate()

  //Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()
  // const type = currentUser?.branchId
  const [loading, setLoading] = useState(false)
  //formik Starts
  const formik = useFormik<Stock>({
    initialValues,
    validationSchema: StockDetailsSchema,

    //Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      updatedData.createdBy = currentUser?.userId
      createStock(updatedData).then((res: any) => {
        console.log('stock', res?.message)
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/stock', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.message, res?.status)
      })
    },
    enableReinitialize: true,
  })
  // Get unit using type
  const [product, setProduct] = useState<any>([])
  const [sku, setSku] = useState<any>([])

  useEffect(() => {
    getSku().then((res: any) => {
      console.log('Unit-drop=>', res)
      setSku(res?.data?.data)
    })
  }, [])
  useEffect(() => {
    getProduct().then((res: any) => {
      console.log('getTax-drop=>', res)
      setProduct(res?.data?.data)
    })
  }, [])
  console.log('Stock-formik', formik)
  const handleCancel = () => {
    // Navigate to the previous page
    navigate(-1)
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Stock</h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>SKU Name</label>
                <select
                  className='form-control form-control-lg form-control-solid'
                  placeholder='SKU Name'
                  {...formik.getFieldProps('skuId')}
                >
                  <option value=''>Select SKU Name</option>
                  {sku.map((item: any, i: any) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.skuName}
                      </option>
                    )
                  })}
                </select>
              </div>

              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Product Name</label>
                <select
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Product Name'
                  {...formik.getFieldProps('productId')}
                >
                  <option value=''>Select Product Name</option>
                  {product.map((item: any, i: any) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.productName}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Date</label>
                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Date'
                  {...formik.getFieldProps('date')}
                />
                {formik.touched.date && formik.errors.date && (
                  <div className='fv-plugins-message-container'>
                    {/* <div className='fv-help-block'>{formik.errors.date}</div> */}
                  </div>
                )}
              </div>

              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Stock Price Value</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Stock Value'
                  {...formik.getFieldProps('value')}
                />
                {formik.touched.value && formik.errors.value && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.value}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Inward Quantity</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Inward Quantity'
                  {...formik.getFieldProps('inwardQuantity')}
                />
                {formik.touched.inwardQuantity && formik.errors.inwardQuantity && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.inwardQuantity}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Outward Quantity</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Outward Quantity Stock '
                  {...formik.getFieldProps('outwardQuantity')}
                />
                {formik.touched.outwardQuantity && formik.errors.outwardQuantity && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.outwardQuantity}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Current Stock</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Current Stock'
                  {...formik.getFieldProps('currentStock')}
                />
                {formik.touched.currentStock && formik.errors.currentStock && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.currentStock}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button type='button' className='btn btn-secondary ms-2' onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default StockDetails
