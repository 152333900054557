import {Button, Modal} from 'react-bootstrap'
import React, {useContext, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createVoucherApproval} from '../voucher-approval-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../voucher-approval-list/core/QueryResponseProvider'
import {
  VoucherApproval,
  initialVoucherApproval as initialValues,
} from '../voucher-approval-list/core/_models'

// Validation
const VoucherApprovalDetailsSchema = Yup.object().shape({
  //  name: Yup.string().required('Name is required'),
})

interface modalProps {
  show: boolean
  onHide: () => void
}

const VoucherApprovalModal: React.FC<modalProps> = ({show, onHide}) => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<VoucherApproval>(initialValues)
  const navigate = useNavigate()

  // Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)

  // Set initial values for "Start Date" and "End Date" in 'dd/MM/yyyy' format
  const initialStartDate = new Date().toLocaleDateString('en-GB') // Change to your desired initial date
  const initialEndDate = new Date().toLocaleDateString('en-GB') // Change to your desired initial date

  // formik Starts
  const formik = useFormik<VoucherApproval>({
    initialValues: {
      ...initialValues,
      approvedDate: initialStartDate,
    },
    validationSchema: VoucherApprovalDetailsSchema,

    // Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      createVoucherApproval(updatedData).then((res: any) => {
        console.log('voucher-approval-Alertres', res?.message)
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/voucher-approval', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.message, res?.status)
      })
    },
    enableReinitialize: true,
  })

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Approval All </Modal.Title>
      </Modal.Header>
      <form
        id='kt_modal_add_voucherapproval_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_voucherapproval_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_voucherapproval_header'
          data-kt-scroll-wrappers='#kt_modal_add_voucherapproval_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row mb-6'>
            <div className='row'>
              <div className='col-lg- fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Approve Date</label>
                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Date'
                  {...formik.getFieldProps('approvedDate')}
                />
                {formik.touched.approvedDate && formik.errors.approvedDate && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.approvedDate}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Amount</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Voucher Amount'
                  // {...formik.getFieldProps('startDate')}
                />
              </div>
            </div>
          </div>
        </div>
        {/* end::Scroll */}
      </form>
    </Modal>
  )
}

export default VoucherApprovalModal
