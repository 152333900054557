import {Button, Modal} from 'react-bootstrap'
import React, {useContext, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createPriority} from '../priority-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../priority-list/core/QueryResponseProvider'
import {
  Priority,
  initialPriority as initialValues,
} from '../priority-list/core/_models'

// Validation
const PriorityDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
 
  
})

interface modalProps {
  show: boolean
  onHide: () => void
}

const PriorityModal: React.FC<modalProps> = ({show, onHide}) => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Priority>(initialValues)
  const navigate = useNavigate()

  // Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)

  // Set initial values for "Start Date" and "End Date" in 'dd/MM/yyyy' format
  const initialStartDate = new Date().toLocaleDateString('en-GB') // Change to your desired initial date
  const initialEndDate = new Date().toLocaleDateString('en-GB') // Change to your desired initial date

  // formik Starts
  const formik = useFormik<Priority>({
    initialValues: {
      ...initialValues,
     
    },
    validationSchema: PriorityDetailsSchema,

    // Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      updatedData.createdBy = currentUser?.userId
      createPriority(updatedData).then((res: any) => {
        console.log('priority-Alertres', res?.message)
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/priority', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.message, res?.status)
      })
    },
    enableReinitialize: true,
  })

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Priority</Modal.Title>
      </Modal.Header>
      <form
        id='kt_modal_add_priority_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <Modal.Body>
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_add_priority_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_priority_header'
            data-kt-scroll-wrappers='#kt_modal_add_priority_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className='row mb-6'>
             
              <div className='row'>
                <div className='col-lg- fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Priority '
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
       
          </div>
        </Modal.Body>
        {/* end::Scroll */}
        <Modal.Footer>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary me-3' disabled={loading}>
              {!loading ? (
                'Save Changes'
              ) : (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button
              type='button'
              className='btn btn-danger me-2 '
              disabled={loading}
              onClick={onHide}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default PriorityModal
