// @ts-nocheck
import {Column} from 'react-table'
import {ProductInfoCell} from './ProductInfoCell'
import {ProductTwoStepsCell} from './ProductTwoStepsCell'
import {ProductActionsCell} from './ProductActionsCell'
import {ProductCustomHeader} from './ProductCustomHeader'
import {Product} from '../../core/_models'

const ProductColumns: ReadonlyArray<Column<Product>> = [
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='S.No' className='min-w-60px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Style Number' className='min-w-100px' />
    ),
    accessor: 'styleNumber',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Product Name' className='min-w-105px' />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Code' className='min-w-95px' />
    ),
    accessor: 'code',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Unit' className='min-w-95px' />
    ),
    accessor: 'unit.unitName',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Price' className='min-w-95px' />
    ),
    accessor: 'price',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Tax' className='min-w-105px' />
    ),
    accessor: 'tax.taxName',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Percentage' className='min-w-105px' />
    ),
    accessor: 'tax.percentage',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ProductActionsCell product={props.data[props.row.index]} />,
  },
]

export {ProductColumns}
