import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PriorityListHeader} from './components/header/PriorityListHeader'
import {PriorityTable} from './table/PriorityTable'
// import {PriorityEditModal} from './Priority-Edit-Modal/PriorityEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useContext} from 'react'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'

const PriorityList = () => {
  const {itemIdForUpdate} = useListView()
  const {show, message, type} = useContext(AlertContext)
  return (
    <>
      <KTCard>
        {show && <AlertBox type={type === true ? 'success' : 'error'} message={message} />}
        <PriorityListHeader />
        <PriorityTable />
      </KTCard>
    </>
  )
}

const PriorityListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PriorityList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PriorityListWrapper}
