// @ts-nocheck
import {Column} from 'react-table'
import {SupplierActionsCell} from './SupplierActionsCell'
import {SupplierCustomHeader} from './SupplierCustomHeader'
import {Supplier} from '../../core/_models'

const SupplierColumns: ReadonlyArray<Column<Supplier>> = [
  {
    Header: (props) => (
      <SupplierCustomHeader tableProps={props} title='S.No' className='min-w-55px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <SupplierCustomHeader tableProps={props} title='Supplier Name' className='min-w-125px' />
    ),
    accessor: 'name',
  },

  {
    Header: (props) => (
      <SupplierCustomHeader tableProps={props} title='Mobile Number' className='min-w-85px' />
    ),
    accessor: 'mobileNumber',
  },

  {
    Header: (props) => (
      <SupplierCustomHeader tableProps={props} title='Email Address' className='min-w-105px' />
    ),
    accessor: 'email',
  },
  {
    Header: (props) => (
      <SupplierCustomHeader tableProps={props} title='Address' className='min-w-125px' />
    ),
    accessor: 'address',
  },
  {
    Header: (props) => (
      <SupplierCustomHeader tableProps={props} title='Zip code' className='min-w-125px' />
    ),
    accessor: 'zipCode',
  },


  {
    Header: (props) => (
      <SupplierCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <SupplierActionsCell supplier={props.data[props.row.index]} />,
  },
]

export {SupplierColumns}
