import React, {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {
  updateOpeningbalance,
  getLedger,
  getCloseFinancialYear,
} from '../opening-balance-list/core/_requests'
import {UpdateOpeningbalance} from '../opening-balance-list/core/_models'

const OpeningBalanceDetailsSchema = Yup.object().shape({
  // address: Yup.string().required('Address is required'),
})

type Props = {
  initialValues: UpdateOpeningbalance
}
export const EditOpeningBalance: FC<Props> = ({initialValues}) => {
  const [data, setData] = useState<UpdateOpeningbalance>(initialValues)
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {id} = useParams()

  const [loading, setLoading] = useState(false)

  const {currentUser} = useAuth()
  const [ledger, setLedger] = useState([])
  const [financialyear, setFinancialYear] = useState([])

  const type = currentUser?.branchId
  //end get type
  const formik = useFormik<UpdateOpeningbalance>({
    initialValues,
    validationSchema: OpeningBalanceDetailsSchema,

    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedBy = currentUser?.userId // Replace 'updatedBy' with the actual key in currentUser
      const updatedData = {...data, ...values, updatedBy}
      updateOpeningbalance(updatedData, id).then((res) => {
        setData(initialValues)
        navigate('/opening-balance', {replace: true})
        showAlert(res?.message, res?.status)
      })
    },
  })
  useEffect(() => {
    getLedger()
      .then((res) => {
        setLedger(res?.data?.data)
      })
      .catch((error) => {
        console.error('Error fetching ledger:', error)
      })
  }, [])

  useEffect(() => {
    getCloseFinancialYear()
      .then((res) => {
        setFinancialYear(res?.data?.data)
      })
      .catch((error) => {
        console.error('Error fetching financial year:', error)
      })
  }, [])
  console.log('OpeningBalance-edit-formik', formik)

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Edit Opening Balance </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Financial Year</label>
                <select
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Financial Year'
                  {...formik.getFieldProps('financialyearId')}
                >
                  <option value=''>Select a Financial Year</option>
                  {financialyear.map((item: any, i: any) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
                {formik.touched.financialyearId && formik.errors.financialyearId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.financialyearId}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Date</label>
                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Date'
                  id='date'
                  {...formik.getFieldProps('openingDate')}
                />
                {formik.touched.openingDate && formik.errors.openingDate && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.openingDate}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Account Name</label>
                <select
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Account Name'
                  {...formik.getFieldProps('coaId')}
                >
                  <option value=''>Select a A/C Name</option>
                  {ledger.map((item: any, i: any) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.headName}
                      </option>
                    )
                  })}
                </select>
                {formik.touched.coaId && formik.errors.coaId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.coaId}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Debit</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Debit'
                  id='debit'
                  {...formik.getFieldProps('debit')}
                />
                {formik.touched.debit && formik.errors.debit && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.debit}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Credit</label>
                <textarea
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Credit'
                  {...formik.getFieldProps('credit')}
                ></textarea>
                {formik.touched.credit && formik.errors.credit && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.credit}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading ? (
                'Save Changes'
              ) : (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditOpeningBalance
