import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {FinancialYearListWrapper} from './financial-year-list/FinancialyearList'

const financialyearBreadcrumbs: Array<PageLink> = [
  {
    title: 'Financial Year Management',
    path: '/financial-year',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const FinancialyearPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={financialyearBreadcrumbs}>Financial Year list</PageTitle>
              <FinancialYearListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default FinancialyearPage
