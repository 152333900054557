import axios, {AxiosResponse} from 'axios'

import {Size, SizeQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_Size_URL = `${API_URL}size/add`
const GET_Size_URL = `${API_URL}size/query`
const VIEW_Size_URL = `${API_URL}size/view`
const UPDATE_Size_URL = `${API_URL}size/update`
const DELETE_Size_URL = `${API_URL}size/delete`


//AuthVerify
var AuthVerify = getToken.call(this)

//Size LIST
const getSize = (query: string): Promise<SizeQueryResponse> => {
  return axios
    .post(`${GET_Size_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<SizeQueryResponse>) => {
      console.log('get-Size==>', response.data)
      return response.data
    })
}

//Size ADD
const createSize = (data: any): Promise<Size | undefined> => {
  return axios
    .post(CREATE_Size_URL, data, getToken())
    .then((response: any) => response.data)
}

//Size VEIW
const viewSize = (id: any) => {
  return axios.get(`${VIEW_Size_URL}/${id}`, getToken()).then((response) => {
    console.log('view-Size', response.data)
    return response.data
  })
}

//Size UPDATE
const updateSize = (data: any, id: any) => {
  console.log('reqData=update-Size', data)
  return axios.put(`${UPDATE_Size_URL}/${id}`, data, getToken()).then((response) => {
    console.log('updatemp-Size', response.data)
    return response.data
  })
}

//Size DELETE
const deleteSizeById = (id: any) => {
  return axios.post(`${DELETE_Size_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

export {
  getSize,
  createSize,
  viewSize,
  updateSize,
  deleteSizeById,
 
}
