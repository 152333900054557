import React, {useContext, useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {bulkCreate} from '../../core/_requests'
import {Modal} from 'react-bootstrap'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
import {AxiosResponse} from 'axios'
interface Event<T = EventTarget> {
  target: T
}

const AttendanceListToolbar = () => {
  const {showAlert} = useContext(AlertContext)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const addAttendancePage = () => {
    navigate('/attendance/add')
  }

  // State to control the visibility of the modal
  const [showModal, setShowModal] = useState(false)

  // Function to handle modal show
  const handleShowModal = () => {
    setShowModal(true)
  }

  // Function to handle modal close
  const handleCloseModal = () => {
    setShowModal(false)
  }

  // State to track if the file has been submitted
  const [fileSubmitted, setFileSubmitted] = useState(false)

  // Function to handle save button click in the modal
  // Function to handle save button click in the modal
  const handleSave = () => {
    setLoading(true)

    // Check if inputFileRef and inputFileRef.current are not null
    if (inputFileRef && inputFileRef.current && inputFileRef.current.files) {
      const formData = new FormData()
      formData.append('excel', inputFileRef.current.files[0])

      bulkCreate(formData)
        .then((res) => {
          setLoading(false)
          navigate('/attendance/list', {replace: true})
          showAlert(res.data.message, res.data.status)
          setFileSubmitted(true) // Set fileSubmitted to true after submitting
        })
        .catch((error) => {
          // Handle error here
          setLoading(false)
        })
    } else {
      // Handle the case where inputFileRef or inputFileRef.current is null
      setLoading(false)
      console.error('inputFileRef or inputFileRef.current is null')
    }

    // Close the modal after saving or error handling
    handleCloseModal()
  }

  // Reference to the file input element
  const inputFileRef = React.createRef<HTMLInputElement>()

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <button type='button' className='btn btn-primary me-3' onClick={handleShowModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Bulk Create
      </button>

      {/* Add Attendance Button */}
      <button type='button' className='btn btn-primary' onClick={addAttendancePage}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Attendance
      </button>

      {/* Bulk Upload Modal */}
      {showModal && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Bulk Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* File Upload Input */}
            <div className='mb-3'>
              <label
                htmlFor='fileInput'
                className='col-form-label required fw-bold fs-6 form-label'
              >
                Upload File
              </label>
              <input type='file' className='form-control' id='fileInput' ref={inputFileRef} />
            </div>
            <br />
            {/* Download Button */}
            <a href='../assets/attendance.xlsx' download>
              <button type='button' className='btn btn-primary me-9'>
                Download Sample File
              </button>
            </a>
            {/* Save Button */}
            <button
              type='button'
              className={`btn btn-success  ${fileSubmitted ? 'd-none' : ''}`}
              onClick={handleSave}
            >
              Save
            </button>{' '}
            <br /> <br />
          </Modal.Body>
        </Modal>
      )}
      {/* End Bulk Upload Modal */}
    </div>
  )
}

export {AttendanceListToolbar}
