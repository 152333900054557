import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Bankreconciliation, BankreconciliationsQueryResponse} from './_models'
// import {getToken} from '../../../../auth/core/AuthHelpers'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//Bank_reconciliation API URL
const CREATE_Bank_reconciliation_URL = `${API_URL}/bank-reconciliation/add`
const GET_Bank_reconciliation_URL = `${API_URL}/bank-reconciliation/query`
const UPDATE_Bank_reconciliation_URL = `${API_URL}/bank-reconciliation/update`
const DELETE_Bank_reconciliation_URL = `${API_URL}/bank-reconciliation/delete`

// var AuthVerify = getToken.call(this)

//Bank_reconciliation LIST
const getBankreconciliation = (query: string): Promise<BankreconciliationsQueryResponse> => {
  return axios
    .post(
      `${GET_Bank_reconciliation_URL}?${query}`
      // AuthVerify
    )
    .then((response: AxiosResponse<BankreconciliationsQueryResponse>) => {
      return response.data
    })
}

//Bank_reconciliation ADD
const createBankreconciliation = (
  Bankreconciliationdata: Bankreconciliation
): Promise<Bankreconciliation | undefined> => {
  return axios
    .post(
      CREATE_Bank_reconciliation_URL,
      Bankreconciliationdata
      //getToken()
    )
    .then((response: any) => response.data)
}

//Bank_reconciliation UPDATE
const updateBankreconciliation = (name: any, id: any, updatedBy: any) => {
  return axios
    .put(
      `${UPDATE_Bank_reconciliation_URL}/${id}`,
      {name, updatedBy}
      //getToken()
    )
    .then((response) => {
      return response.data
    })
}

//Bank_reconciliation DELETE
const deleteBankreconciliationById = (id: any) => {
  return axios
    .post(
      `${DELETE_Bank_reconciliation_URL}/${id}`
      //AuthVerify
    )
    .then((response) => {
      return response.data
    })
}

export {
  getBankreconciliation,
  createBankreconciliation,
  updateBankreconciliation,
  deleteBankreconciliationById,
}
