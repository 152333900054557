import React, {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createMaterial, getUnit, getTax} from '../material-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../material-list/core/QueryResponseProvider'
import {Material, initialMaterial as initialValues} from '../material-list/core/_models'
//Validation
const MaterialDetailsSchema = Yup.object().shape({
  code: Yup.string().required('Barcode/QR-code is required'),
  name: Yup.string().required('Material Name is required'),
  unitId: Yup.string().required('Unit is required'),
  price: Yup.string().required('Price is required'),
})
// Define the type for your tax object
interface Tax {
  id: number
  taxName: string
  category: number
  percentage: string
}
//Function Starts
const MaterialDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Material>(initialValues)
  const navigate = useNavigate()

  //Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()
  // const type = currentUser?.branchId
  const [loading, setLoading] = useState(false)
  //formik Starts
  const formik = useFormik<Material>({
    initialValues,
    validationSchema: MaterialDetailsSchema,

    //Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      updatedData.createdBy = currentUser?.userId
      createMaterial(updatedData).then((res: any) => {
        console.log('material', res?.message)
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/material', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.message, res?.status)
      })
    },
    enableReinitialize: true,
  })
  // Get unit using type
  const [unit, setUnit] = useState<any>([])
  const [tax, setTax] = useState<any>([])
  const [selectedTax, setSelectedTax] = useState<Tax | null>(null)

  useEffect(() => {
    getUnit().then((res: any) => {
      console.log('Unit-drop=>', res)
      setUnit(res?.data?.data)
    })
  }, [])
  useEffect(() => {
    getTax().then((res: any) => {
      console.log('getTax-drop=>', res)
      setTax(res?.data?.data)
    })
  }, [])
  console.log('material-formik', formik)

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Material</h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Material Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Material Name'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Barcode/QR-code</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Barcode/QR-code'
                  {...formik.getFieldProps('code')}
                />
                {formik.touched.code && formik.errors.code && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.code}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Unit</label>
                <select
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Unit Name'
                  {...formik.getFieldProps('unitId')}
                >
                  <option value=''>Select Unit</option>
                  {unit.map((item: any, i: any) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.unitName}
                      </option>
                    )
                  })}
                </select>
                {formik.touched.unitId && formik.errors.unitId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.unitId}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Cost Price</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Cost Price'
                  {...formik.getFieldProps('price')}
                />
                {formik.touched.price && formik.errors.price && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.price}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Tax</label>
                <select
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Tax Name'
                  {...formik.getFieldProps('taxId')}
                  onChange={(event) => {
                    const selectedTaxId = event.target.value
                    const selectedTax = tax.find((item: any) => item.id === Number(selectedTaxId))
                    setSelectedTax(selectedTax)
                    formik.setFieldValue('taxId', selectedTaxId)
                  }}
                >
                  <option value=''>Select Tax</option>
                  {tax.map((item: Tax, i: number) => {
                    return (
                      <option key={i} value={item.id.toString()}>
                        {item.taxName}
                      </option>
                    )
                  })}
                </select>
                {formik.touched.taxId && formik.errors.taxId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.taxId}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Tax Percentage</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Tax Percentage'
                  value={selectedTax ? selectedTax.percentage : ''}
                  readOnly
                  name='taxId'
                />
                {formik.touched.taxId && formik.errors.taxId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.taxId}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading ? 'Save Changes' : 'Please wait...'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default MaterialDetails
