import {ID, Response} from '../../../../../../_metronic/helpers'
export type Sales = {
  id?: ID
  primaryId?: number
  salesDate: any
  amount: any
  grandTotal: any
  tax: any
  paidAmount: any
  discount: any
  salesType: any
  description: string
  customerId: number
  isCredit: boolean
  salesDetail: [
    {
      productId: string
      skuId: any
      styleNumber: any
      colorId: any
      sizeId: any
      unitId: any
      amount: any
      tax: any
      taxValue: any
      discount: any
      discountValue: any
      total: string
      quantity: string
    }
  ]
  paymentHistory: [
    {
      coaId: number
      amount: string
    }
  ]
  createdBy: number
  message?: string
  status?: number
}

export type SalesQueryResponse = Response<Array<Sales>>

export const initialSales: Sales = {
  salesDate: '',
  amount: '',
  grandTotal: '',
  tax: '',
  paidAmount: '',
  discount: '',
  description: '',
  salesType: 'Product Sales',
  customerId: 0,
  isCredit: false,
  salesDetail: [
    {
      productId: '',
      styleNumber: '',
      colorId: '',
      sizeId: 0,
      unitId: '',
      skuId: '',
      amount: '',
      tax: '',
      taxValue: 0,
      discount: '',
      discountValue: 0,
      total: '',
      quantity: '',
    },
  ],
  paymentHistory: [
    {
      coaId: 0,
      amount: '',
    },
  ],
  createdBy: 0,
}
export type UpdateSales = {
  id?: ID
  primaryId?: number
  salesDate: any
  amount: any
  grandTotal: any
  tax: any
  paidAmount: any
  discount: any
  salesType: any
  description: string
  customerId: number
  isCredit: boolean
  salesDetail: [
    {
      productId: string
      styleNumber: any
      colorId: any
      sizeId: any
      unitId: any
      skuId: any
      amount: string
      tax: any
      taxValue: any
      discount: any
      discountValue: any
      total: string
      quantity: string
    }
  ]
  paymentHistory: [
    {
      coaId: number
      amount: string
    }
  ]

  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateSalesQueryResponse = Response<Array<UpdateSales>>

export const initialUpdateSales: UpdateSales = {
  salesDate: '',
  amount: '',
  grandTotal: '',
  tax: '',
  paidAmount: '',
  discount: '',
  description: '',
  salesType: 'Product Sales',
  customerId: 0,
  isCredit: false,
  salesDetail: [
    {
      productId: '',
      styleNumber: '',
      colorId: '',
      sizeId: 0,
      unitId: '',
      skuId: '',
      amount: '',
      tax: '',
      taxValue: 0,
      discount: '',
      discountValue: 0,
      total: '',
      quantity: '',
    },
  ],
  paymentHistory: [
    {
      coaId: 0,
      amount: '',
    },
  ],
}
