import React, {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {UpdateMaterial} from '../material-list/core/_models'
import {updateMaterial, getUnit, getTax} from '../material-list/core/_requests'

const MaterialDetailsSchema = Yup.object().shape({
  code: Yup.string().required('Barcode/QR-code is required'),
  name: Yup.string().required('Material Name is required'),
  unitId: Yup.string().required('Unit is required'),
  price: Yup.string().required('Price is required'),
  taxId: Yup.string().required('Tax is required'), // Updated to match the form field name
})

// Define the type for your tax object
interface Tax {
  id: number
  taxName: string
  category: number
  percentage: string
}

type Props = {
  initialValues: UpdateMaterial
}

export const EditMaterial: FC<Props> = ({initialValues}) => {
  const [data, setData] = useState<UpdateMaterial>(initialValues)
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {id} = useParams()
  const [unit, setUnit] = useState<any>([])
  const [tax, setTax] = useState<any>([])

  const [loading, setLoading] = useState(false)

  // Initialize selectedTax with the initial tax data
  const [selectedTax, setSelectedTax] = useState<Tax | null>(
    tax.find((item: any) => item.id === Number(initialValues.taxId))
  )

  // Get the current user from your authentication context
  const {currentUser} = useAuth()
  const type = currentUser?.branchId

  const formik = useFormik<UpdateMaterial>({
    initialValues,
    validationSchema: MaterialDetailsSchema,

    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedBy = currentUser?.userId
      const updatedData = {...data, ...values, updatedBy}
      updateMaterial(updatedData, id).then((res) => {
        setData(initialValues)
        navigate('/material', {replace: true})
        showAlert(res?.message, res?.status)
      })
    },
  })

  useEffect(() => {
    getUnit().then((res: any) => {
      setUnit(res?.data?.data)
    })
  }, [])

  useEffect(() => {
    getTax().then((res: any) => {
      setTax(res?.data?.data)
    })
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Edit Material</h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Material Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Material Name'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Barcode/QR-code</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Barcode/QR-code'
                  {...formik.getFieldProps('code')}
                />
                {formik.touched.code && formik.errors.code && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.code}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Unit</label>
                <select
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Material Unit'
                  {...formik.getFieldProps('unitId')}
                >
                  <option value=''>Select Unit</option>
                  {unit.map((item: any, i: any) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.unitName}
                      </option>
                    )
                  })}
                </select>
                {formik.touched.unitId && formik.errors.unitId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.unitId}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Cost Price</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Cost Price'
                  {...formik.getFieldProps('price')}
                />
                {formik.touched.price && formik.errors.price && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.price}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Tax</label>
                <select
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Tax Name'
                  {...formik.getFieldProps('taxId')}
                  onChange={(event) => {
                    const selectedTaxId = event.target.value
                    const selectedTax = tax.find((item: any) => item.id === Number(selectedTaxId))
                    setSelectedTax(selectedTax)

                    // Update the tax percentage field in the formik values
                    formik.setFieldValue('taxId', selectedTaxId)
                    formik.setFieldValue('tax.percentage', selectedTax?.percentage || '')
                  }}
                >
                  <option value=''>Select Tax</option>
                  {tax.map((item: any, i: any) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.taxName}
                      </option>
                    )
                  })}
                </select>
                {formik.touched.taxId && formik.errors.taxId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.taxId}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Tax Percentage</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Tax Percentage'
                  {...formik.getFieldProps('tax.percentage')}
                />
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading ? 'Save Changes' : 'Please wait...'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditMaterial
