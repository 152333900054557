import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ColorListHeader} from './components/header/ColorListHeader'
import {ColorTable} from './table/ColorTable'
// import {ColorEditModal} from './Color-Edit-Modal/ColorEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useContext} from 'react'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'

const ColorList = () => {
  const {itemIdForUpdate} = useListView()
  const {show, message, type} = useContext(AlertContext)
  return (
    <>
      <KTCard>
        {show && <AlertBox type={type === true ? 'success' : 'error'} message={message} />}
        <ColorListHeader />
        <ColorTable />
      </KTCard>
    </>
  )
}

const ColorListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ColorList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ColorListWrapper}
