import axios, {AxiosResponse} from 'axios'
import {ProductUnit, ProductUnitQueryResponse} from './_models'
import {getToken} from '../../../../auth/core/AuthHelpers'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ProductUnit API URL
const CREATE_PRODUCTUNIT_URL = `${API_URL}unit/add`
const GET_PRODUCTUNIT_URL_URL = `${API_URL}unit/query`
const UPDATE_PRODUCTUNIT_URL_URL = `${API_URL}unit/update`
const DELETE_PRODUCTUNIT_URL_URL = `${API_URL}unit/delete`
const VIEW_PRODUCTUNIT_URL_URL = `${API_URL}unit/view`

var AuthVerify = getToken.call(this)

//ProductUnit LIST
const getProductUnit = (query: string, type: any): Promise<ProductUnitQueryResponse> => {
  return axios
    .post(`${GET_PRODUCTUNIT_URL_URL}?${query}`, {type}, AuthVerify)
    .then((response: AxiosResponse<ProductUnitQueryResponse>) => {
      console.log('ProductUnit-LIST', response.data)
      return response.data
    })
}

//ProductUnit ADD
const createProductUnit = (data: ProductUnit): Promise<ProductUnit | undefined> => {
  console.log('ProductUnit-add', data)
  return axios.post(CREATE_PRODUCTUNIT_URL, data, getToken()).then((response: any) => response.data)
}

//ProductUnit Update
const updateProductUnit = (data: any, id: any) => {
  console.log('ProductUnit-update', data)
  return axios.put(`${UPDATE_PRODUCTUNIT_URL_URL}/${id}`, data, getToken()).then((response) => {
    return response.data
  })
}
//ProductUnit VEIW
const viewProductUnit = (id: any) => {
  return axios.get(`${VIEW_PRODUCTUNIT_URL_URL}/${id}`, getToken()).then((response) => {
    console.log('view-ProductUnit', response.data)
    return response.data
  })
}

//ProductUnit DELETE
const deleteProductUnitById = (id: any) => {
  return axios.post(`${DELETE_PRODUCTUNIT_URL_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

export {
  getProductUnit,
  createProductUnit,
  updateProductUnit,
  deleteProductUnitById,
  viewProductUnit,
}
