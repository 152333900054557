import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewProcessType} from '../process-type-list/core/_requests'
import {EditProcessType} from '../process-type-form/EditProcessType'

export const EditProcessTypeWrapper = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewProcessType(id).then((res) => {
      setData(res.data)
      console.log('viewProcessTypeDetail', res.data)
    })
  }, [])
  if (data) {
    return <EditProcessType initialValues={data} />
  }
  return null
}
