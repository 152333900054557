import {useListView} from '../../core/ListViewProvider'
import {TaxListSearchComponent} from './TaxListSearchComponent'
import {TaxListToolbar} from './TaxListToolbar'

const TaxListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <TaxListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <TaxListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {TaxListHeader}
