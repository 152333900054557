import {useEffect, useRef, useState} from 'react'
import {KTCardBody, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useDownloadExcel} from 'react-export-table-to-excel'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {getReport} from '../_requests'
import {useAuth} from '../../../../auth'

const ReportContent = () => {
  const tableRef = useRef(null)
  const [cli, setCli] = useState<any>([])
  const {currentUser} = useAuth()

  const [report, setReport] = useState({
    totalPurchase: '',
    totalSales: '',
  })

  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [loading, setLoading] = useState(false)
  const [btnExcelDisable, setbtnExcelDisable] = useState(true)
  const [btnClearDisable, setbtnClearDisable] = useState(true)
  const [OCDate, setOCDate] = useState('')
  const [selectedOption, setSelectedOption] = useState(null)

  const {mode} = useThemeMode()

  const TradingReport = () => {
    setLoading(true)

    var Daterange = (document.getElementById('Daterange') as HTMLInputElement).value
    const dateRange = Daterange.split(' - ')

    const fromDate = dateRange[0].split('/').reverse().join('-')
    const toDate = dateRange[1].split('/').reverse().join('-')

    const data = {fromDate, toDate}
    console.log('data-form', data)
    getReport(data).then((resRep) => {
      console.log('Data received from API:', resRep.data)
      setReport(resRep.data.data)
      setLoading(false)
      // Enable or disable the buttons as needed based on the new data.
      setbtnExcelDisable(Object.keys(resRep.data.data).length === 0)
      setbtnClearDisable(Object.keys(resRep.data.data).length === 0)
    })

    setOCDate(`${fromDate} - ${toDate}`)
  }

  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()

  const clearReport = () => {
    setReport({
      totalPurchase: '',
      totalSales: '',
    })
    setSelectedOption(null)
    setDateRange([null, null])
    setOCDate('')
    setbtnExcelDisable(true)
    setbtnClearDisable(true)
  }

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Trading_Report_${fileName}`,
    sheet: 'Users',
  })

  return (
    <>
      <div className='card'>
        {/* begin::Header */}
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Trading Report</h3>
          </div>
          <div className='col-lg-2 pt-5'>
            <button
              className='btn btn-danger col-lg-12'
              disabled={btnExcelDisable}
              onClick={onDownload}
            >
              Export
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='row mb-10'>
            <div className='col-lg-4'></div>

            <div className='col-lg-4'>
              <label className='col-form-label fw-bold fs-6'>Filter By Date Range</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid'
                id='Daterange'
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update: any) => {
                  setDateRange(update)
                }}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
              />
            </div>

            <div className='col-lg-2 pt-12'>
              <button onClick={TradingReport} className='btn btn-primary col-lg-12'>
                Search
              </button>
            </div>
            <div className='col-lg-2 pt-12'>
              <button
                onClick={clearReport}
                className='btn btn-info col-lg-12'
                disabled={btnClearDisable}
              >
                Clear
              </button>
            </div>
          </div>

          <div className='table-responsive'>
            {/* begin::Table */}
            {loading ? (
              <p style={{textAlign: 'center'}}>Please wait...</p>
            ) : (
              <table
                className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
                ref={tableRef}
              >
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                    <th className='min-w-120px' colSpan={5}>
                      DATE: {OCDate}
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={11}></td>{' '}
                  </tr>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-140px'>Total Purchase (Taka - ৳)</th>
                    <th className='min-w-140px'>Total Sales (Taka - ৳)</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && (
                    <tr>
                      {/* <td>1</td> */}
                      <td>{report.totalPurchase}</td>
                      <td>{report.totalSales}</td>
                    </tr>
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
            )}
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {ReportContent}
