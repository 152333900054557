import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Salarygenerate, SalarygenerateQueryResponse} from './_models'
import {getToken} from '../../../../auth'
import {AnyAaaaRecord} from 'dns'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_SALARYGENERATE_URL = `${API_URL}salary/salary-generate`
const CREATE_FINDDUPLICATE_URL = `${API_URL}salary/salary-find-duplicate`
const GET_SALARYGENERATE_URL = `${API_URL}salary/salary-generate/query`
const VIEW_APPROVAL_SALARYGENERATE_URL = `${API_URL}salary/salary-approve/list`
const VIEW_SALARYGENERATE_URL = `${API_URL}salary/view-salary-generate`
const UPDATE_SALARYGENERATE_URL = `${API_URL}salary/salary-generate/update`
const DELETE_SALARYGENERATE_URL = `${API_URL}salary/delete-salary-generate`
const APPROVAL_SALARYGENERATE_URL = `${API_URL}salary/approve-salary-generate`

//AuthVerify
var AuthVerify = getToken.call(this)

//Salarygenerate LIST
const getSalarygenerate = (query: string): Promise<SalarygenerateQueryResponse> => {
  return axios
    .post(`${GET_SALARYGENERATE_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<SalarygenerateQueryResponse>) => {
      console.log('getemp==>', response.data)
      return response.data
    })
}

//Salarygenerate ADD
const createSalarygenerate = (data: any): Promise<Salarygenerate | undefined> => {
  return axios
    .post(CREATE_SALARYGENERATE_URL, data, getToken())
    .then((response: any) => response.data)
}
//Salary month Duplicate find
const checkDuplicateMonth = (month: any) => {
  return axios.post(`${CREATE_FINDDUPLICATE_URL}`, {month}, getToken()).then((response) => {
    return response.data
  })
}
//Salarygenerate Approval

const approveSalarygenerate = (data: any, id: any) => {
  console.log('salary apl-api', data)
  return axios.put(`${APPROVAL_SALARYGENERATE_URL}/${id}`, data, getToken()).then((response) => {
    console.log('Salary -aprroval-api res', response.data)
    return response.data
  })
}
//Salarygenerate VEIW  Approval
const viewApprovalList = ( id: any) => {
  return axios.put(`${VIEW_APPROVAL_SALARYGENERATE_URL}/${id}`, getToken()).then((response) => {
    console.log('viewemp-api', response.data)
    return response.data
  })
}

const viewSalarygenerate = (id: any) => {
  console.log('viewSalarygenerate-api-id', id)
  return axios.put(`${VIEW_SALARYGENERATE_URL}/${id}`, getToken()).then((response) => {
    console.log('viewSalarygenerate-api-resr', response.data)
    return response.data
  })
}

//Salarygenerate UPDATE
const updateSalarygenerate = (data: any, id: any) => {
  console.log('reqData-api', data)
  console.log('reqData-api-id', id)
  return axios.put(`${UPDATE_SALARYGENERATE_URL}/${id}`, data, getToken()).then((response) => {
    console.log('updatemp--api -res', response.data)
    return response.data
  })
}

//Salarygenerate DELETE
const deleteSalarygenerateById = (id: any) => {
  return axios.post(`${DELETE_SALARYGENERATE_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

export {
  getSalarygenerate,
  createSalarygenerate,
  checkDuplicateMonth,
  viewSalarygenerate,
  updateSalarygenerate,
  deleteSalarygenerateById,
  approveSalarygenerate,
  viewApprovalList,
}
