import {ID, Response} from '../../../../../../_metronic/helpers'
export type Product = {
  id?: ID
  primaryId?: number
  productcategoryId?: number
  code: string
  name: string
  unitId: string
  price: string
  taxId: string
  styleNumber: string

  message?: string
  status?: string
  createdBy?: number
}

export type ProductQueryResponse = Response<Array<Product>>

export const initialProduct: Product = {
  code: '',
  styleNumber: '',
  name: '',
  taxId: '',
  unitId: '',
  price: '',
  productcategoryId: 0,
  createdBy: 0,
}
export type UpdateProduct = {
  id?: ID
  primaryId?: number
  productcategoryId?: number
  code: string
  styleNumber: string
  name: string
  unitId: string
  price: string
  taxId: string
  tax: [
    {
      taxName: string
      percentage: string
    }
  ]
  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateProductQueryResponse = Response<Array<UpdateProduct>>

export const initialUpdateProduct: UpdateProduct = {
  code: '',
  styleNumber: '',
  productcategoryId:0,
  name: '',
  taxId: '',
  unitId: '',
  price: '',
  tax: [
    {
      taxName: '',
      percentage: '',
    },
  ],
}
