import {ID, Response} from '../../../../../../_metronic/helpers'
export type VoucherApproval = {
  id?: ID
  primaryId?: number

  approvedBy: any
  approvedDate?: string
  voucherNumber: any
  isApproved: any
  message?: string
  status?: string
  createdBy?: number
}

export type VoucherApprovalQueryResponse = Response<Array<VoucherApproval>>

export const initialVoucherApproval: VoucherApproval = {
  approvedBy: '',
  approvedDate: '',
  voucherNumber: '',
  isApproved: '',
  createdBy: 0,
}
export type UpdateVoucherApproval = {
  id?: ID
  primaryId?: number
  voucherNumber: any
  approvedBy: any
  approvedDate?: string
  isApproved?: string

  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateVoucherApprovalQueryResponse = Response<Array<UpdateVoucherApproval>>

export const initialUpdateVoucherApproval: UpdateVoucherApproval = {
  approvedBy: '',
  approvedDate: '',
  voucherNumber: '',
  isApproved: '',
}
