import {useListView} from '../../core/ListViewProvider'
import {SalesReturnListSearchComponent} from './SalesReturnListSearchComponent'
import {SalesReturnListToolbar} from './SalesReturnListToolbar'

const SalesReturnListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <SalesReturnListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <SalesReturnListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {SalesReturnListHeader}
