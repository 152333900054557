// @ts-nocheck
import {Column} from 'react-table'

import {TaxActionsCell} from './TaxActionsCell'
import {TaxCustomHeader} from './TaxCustomHeader'
import {Tax} from '../../core/_models'

const TaxColumns: ReadonlyArray<Column<Tax>> = [
  {
    Header: (props) => <TaxCustomHeader tableProps={props} title='S.No' className='min-w-55px' />,
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <TaxCustomHeader tableProps={props} title='Category' className='min-w-95px' />
    ),
    accessor: 'category',
  },
  {
    Header: (props) => (
      <TaxCustomHeader tableProps={props} title='Tax Name' className='min-w-125px' />
    ),
    accessor: 'name',
  },

  {
    Header: (props) => (
      <TaxCustomHeader tableProps={props} title='Percentage' className='min-w-55px' />
    ),
    accessor: 'percentage',
  },

  {
    Header: (props) => (
      <TaxCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <TaxActionsCell tax={props.data[props.row.index]} />,
  },
]

export {TaxColumns}
