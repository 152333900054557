import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {OrderCategory, OrderCategoryQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_CATEGORY_URL = `${API_URL}order-category/add`
const GET_CATEGORY_URL = `${API_URL}order-category/query`
const VIEW_CATEGORY_URL = `${API_URL}order-category/view`
const UPDATE_CATEGORY_URL = `${API_URL}order-category/update`
const DELETE_CATEGORY_URL = `${API_URL}order-category/delete`

//AuthVerify
var AuthVerify = getToken.call(this)

//OrderCategory LIST
const getOrderCategory = (query: string): Promise<OrderCategoryQueryResponse> => {
  return axios
    .post(`${GET_CATEGORY_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<OrderCategoryQueryResponse>) => {
      console.log('get-OrderCategory==>', response.data)
      return response.data
    })
}

//OrderCategory ADD
const createOrderCategory = (data: any): Promise<OrderCategory | undefined> => {
  return axios.post(CREATE_CATEGORY_URL, data, getToken()).then((response: any) => response.data)
}

//OrderCategory VEIW
const viewOrderCategory = (id: any) => {
  return axios.get(`${VIEW_CATEGORY_URL}/${id}`, getToken()).then((response) => {
    console.log('view-OrderCategory', response.data)
    return response.data
  })
}

//OrderCategory UPDATE
const updateOrderCategory = (data: any, id: any) => {
  console.log('reqData=update-OrderCategory', data)
  return axios.put(`${UPDATE_CATEGORY_URL}/${id}`, data, getToken()).then((response) => {
    console.log('updatemp-OrderCategory', response.data)
    return response.data
  })
}

//OrderCategory DELETE
const deleteOrderCategoryById = (id: any) => {
  return axios.post(`${DELETE_CATEGORY_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}

export {getOrderCategory, createOrderCategory, viewOrderCategory, updateOrderCategory, deleteOrderCategoryById}
