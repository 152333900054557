/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {deleteDepartmentById} from '../../core/_requests'
import {Department} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
import {Modal, Button} from 'react-bootstrap'
import {MyContext} from '../../ContextFile'
import React from 'react'
import {useAuth} from '../../../../../auth'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
import {setRoleAccess} from '../../../../role-access/role-setting-list/core/_requests'

type Props = {
  department: Department
}

const DepartmentActionsCell: FC<Props> = ({department}) => {
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)

  const deleteItem = () =>
    deleteDepartmentById(department.primaryId).then((res) => {
      setShow(false)
      showAlert(res?.message, res?.status)
      navigate('/department')
    })
  const {state, setState} = React.useContext(MyContext)
  //CALL API FOR PAGE ACCESS USING ROLE ID AND MODULE ID
  const {currentUser} = useAuth()
  const [roleAccesses, setRoleAccesses] = useState<any>([])
  useEffect(() => {
    setRoleAccess(currentUser?.rolesId, 2).then((res: any) => {
      var resultRoleAccess = res.data?.data
      setRoleAccesses(resultRoleAccess)
    })
  }, [])

  const handleShow = () => {
    // if (roleAccesses?.remove === 1) {
    //   toast.error('You do not have permission to access this page', {
    //     position: 'top-right',
    //     style: {
    //       backgroundColor: '#9b2313',
    //       color: '#c7c7d7',
    //       fontSize: '14px',
    //     },
    //     closeButton: false,
    //   })
    // } else {
    setShow(true)
    // }
  }

  return (
    <>
      <ToastContainer />
      <div className='d-flex justify-content-end flex-shrink-0'>
        {/* {roleAccesses?.edit === 1 ? ( */}
        <a
          onClick={() => {
            console.log('handle', department, state)
            setState({
              ...state,
              primaryId: `${department.primaryId}`,
              name: `${department.name}`,
              editclicked: true,
            })
          }}
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a>
        {/* ) : (
          ''
        )} */}
        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleShow}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete "{department.name}"?</Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
    </>
  )
}

export {DepartmentActionsCell}
