/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik, FormikProps} from 'formik'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {deleteFinancialyearById, updateFinancialyear} from '../../core/_requests'
import {Financialyear, UpdateFinancialyear} from '../../core/_models'
import {useNavigate, useParams} from 'react-router-dom'
import {Modal, Button, Form} from 'react-bootstrap'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
import {useAuth} from '../../../../../auth'

const FinancialYearDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  startDate: Yup.string().required('Financial Year Start is required'),
  endDate: Yup.string().required('Financial Year End is required'),
})
type Props = {
  financialyear: Financialyear
}

const FinancialyearActionsCell: FC<Props> = ({financialyear}) => {
  console.log('financialyear', financialyear)
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false)
  const [editshowModal, seteditShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleEditClose = () => seteditShow(false)
  const [data, setData] = useState<UpdateFinancialyear>(financialyear)
  const {id} = useParams()
  const [loading, setLoading] = useState(false)

  //get type
  const {currentUser} = useAuth()
  const formik: FormikProps<UpdateFinancialyear> = useFormik<UpdateFinancialyear>({
    initialValues: financialyear,
    validationSchema: FinancialYearDetailsSchema,
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedBy = currentUser?.userId
      const updatedData = {...data, ...values, updatedBy}
      updateFinancialyear(updatedData, financialyear.primaryId).then((res) => {
        setData(financialyear)
        navigate('/financial-year', {replace: true})
        showAlert(res?.message, res?.status)
        handleEditClose() // Close the modal after submission
      })
    },
  })

  const saveChanges = () => {
    formik.handleSubmit() // Trigger the Formik form submission
  }

  const editFinancialyearDetail = () => {
    seteditShow(true)
  }
  const deleteItem = () =>
    deleteFinancialyearById(financialyear.primaryId).then((res) => {
      console.log('delete', res)
      setShow(false)
      showAlert(res?.message, res?.status)
      navigate('/financial-year')
    })
  const handleShow = () => {
    setShow(true)
  }
  return (
    <>
      {/* Edit Button */}
      <div className='d-flex justify-content-end flex-shrink-0'>
        <a
          onClick={editFinancialyearDetail}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a>

        {/* Delete Button */}
        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleShow}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>
      <Modal show={editshowModal} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Financial Year</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            id='kt_modal_add_financialyear_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            {/* begin::Scroll */}
            <div
              className='d-flex flex-column scroll-y me-n7 pe-7'
              id='kt_modal_add_financialyear_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_financialyear_header'
              data-kt-scroll-wrappers='#kt_modal_add_financialyear_scroll'
              data-kt-scroll-offset='300px'
            >
              <div className='row mb-6'>
                <div className='row'>
                  <div className='col-lg- fv-row'>
                    <label className='col-form-label required fw-bold fs-6'>Year</label>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Enter Year'
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <label className='col-form-label required fw-bold fs-6'>Start Date</label>
                    <input
                      type='date'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Enter Start Date'
                      {...formik.getFieldProps('startDate')}
                    />
                    {formik.touched.startDate && formik.errors.startDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.startDate}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-6 fv-row'>
                    <label className='col-form-label required fw-bold fs-6'>End Date</label>
                    <input
                      type='date'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Enter Year End'
                      {...formik.getFieldProps('endDate')}
                    />
                    {formik.touched.endDate && formik.errors.endDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.endDate}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* end::Scroll */}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleEditClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={saveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete "{financialyear.primaryId}"?</Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
    </>
  )
}

export {FinancialyearActionsCell}
