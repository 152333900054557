import {useListView} from '../../core/ListViewProvider'
import {DepartmentListSearchComponent} from './DepartmentListSearchComponent'

const DepartmentListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      {/* Search bar */}
      <DepartmentListSearchComponent />
    </div>
  )
}

export {DepartmentListHeader}
