import {ID, Response} from '../../../../../../_metronic/helpers'
export type Attendance = {
  id?: ID
  primaryId?: number
  employeeId?: string
  date?: any
  checkIn?: any
  checkout?: any
  otHours?: any

  message?: string
  status?: string
  createdBy?: number
}

export type AttendanceQueryResponse = Response<Array<Attendance>>

export const initialAttendance: Attendance = {
  employeeId: '',
  date: '',
  checkIn: '',
  checkout: '',

  otHours: '',
  createdBy: 0,
}
export type UpdateAttendance = {
  id?: ID
  primaryId?: number
  employeeId?: string
  date?: any
  checkIn?: any
  checkout?: any
  otHours?: any

  message?: string
  status?: string
  updatedBy?: number
}

export type UpdateAttendanceQueryResponse = Response<Array<UpdateAttendance>>

export const initialUpdateAttendance: UpdateAttendance = {
  employeeId: '',
  date: '',
  checkIn: '',
  checkout: '',

  otHours: '',
}
