import React, {useState, useContext, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createBankreconciliation} from '../bank-reconciliation-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {useQueryResponse} from '../bank-reconciliation-list/core/QueryResponseProvider'
// import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
// import {useQueryResponse} from '../Bankreconciliation-list/core/QueryResponseProvider'
// import {getExCRUD} from '../../Bankreconciliation -access/Bankreconciliation -setting-list/core/_requests'

export interface IProfileDetails {
  cashCode: string
  bankCode: string
  advance: string
  fixedAsset: string
  purchaseCode: string
  salesCode: string
  serviceCode: string
  customerCode: string
  supplierCode: string
  costsOfGoodSolds: string
  vat: string
  tax: string
  inventoryCode: string
  cplCode: string
  lplCode: string
  salaryCode: string
  empNpfContribution: string
  emprNpfContribution: string
  empIcfContribution: string
  emprIcfContribution: string
  provStateTax: string
  stateTax: string
  provNpfcode: string

  createdBy: string | undefined
}

export let initialValues: IProfileDetails = {
  cashCode: '',
  bankCode: '',
  advance: '',
  fixedAsset: '',
  purchaseCode: '',
  salesCode: '',
  serviceCode: '',
  customerCode: '',
  supplierCode: '',
  costsOfGoodSolds: '',
  vat: '',
  tax: '',
  inventoryCode: '',
  cplCode: '',
  lplCode: '',
  salaryCode: '',
  empNpfContribution: '',
  emprNpfContribution: '',
  empIcfContribution: '',
  emprIcfContribution: '',
  provStateTax: '',
  stateTax: '',
  provNpfcode: '',

  createdBy: '',
}
const bankreconciliationDetailsSchema = Yup.object().shape({
  cashCode: Yup.string().required('Cash Code is required'),
  bankCode: Yup.string().required('Bank Code is required'),
  advance: Yup.string().required('Advance is required'),
  fixedAsset: Yup.string().required('Fixed Asset is required'),
  purchaseCode: Yup.string().required('Purchase Code is required'),
  salesCode: Yup.string().required('Sales Code is required'),
  serviceCode: Yup.string().required(' Service Code is required'),
  customerCode: Yup.string().required('Customer Code is required'),
  supplierCode: Yup.string().required('Supplier Code is required'),
  costsOfGoodSolds: Yup.string().required('Costs Of Good Solds is required'),
  vat: Yup.string().required('Vat is required'),
  tax: Yup.string().required('Tax is required'),
  inventoryCode: Yup.string().required('Inventory Code is required'),
  cplCode: Yup.string().required('Cpl Code is required'),
  lplCode: Yup.string().required('LPL Code is required'),
  salaryCode: Yup.string().required(' Salary Code is required'),
  empNpfContribution: Yup.string().required('Emp Npf Contribution is required'),
  emprNpfContribution: Yup.string().required('Empr Npf Contribution is required'),
  empIcfContribution: Yup.string().required('Emp Icf Contribution is required'),
  emprIcfContribution: Yup.string().required('Empr Icf Contribution is required'),
  provStateTax: Yup.string().required('Prov State Tax is required'),
  stateTax: Yup.string().required('State Tax is required'),
  provNpfcode: Yup.string().required('Prov Npf Code is required'),
})

const BankreconciliationDetails: React.FC = () => {
  // const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const navigate = useNavigate()

  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: bankreconciliationDetailsSchema,
    onSubmit: (values, {resetForm}) => {
      setLoading(true)

      const updatedData = Object.assign(data, values)

      //updatedData.createdBy = currentUser?.userId
      // createBankreconciliation (updatedData).then((res: any) => {
      //   console.log('Alertres', res)
      //   initialValues = {
      //     name: '',

      //     createdBy: '',
      //   }
      //   resetForm({
      //     ...initialValues,
      //     values: {...initialValues},
      //   })
      //   setData(initialValues)
      //   navigate('/Bank-reconciliation', {replace: true})
      //   refetch()
      //   setLoading(false)
      //   showAlert(res?.Message, res?.Code)
      // })
    },
    enableReinitialize: true,
  })
  // console.log(data?.profileImage+"prof");
  const [bankreconciliationaccess, setbankreconciliationaccess] = useState<any>([])
  // useEffect(() => {
  //   getExCRUD(currentUser?.BankreconciliationId, 2).then((res: any) => {
  //     var crudres = res.data?.Data[0]
  //     setBankreconciliationaccess(crudres)
  //   })
  // }, [])
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Edit Bankreconciliation</h3>
        </div>
      </div>
      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Cash Code</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Cash Code'
                    {...formik.getFieldProps('cashCode')}
                  >
                    <option value=''>Select a Cash Code</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.cashCode && formik.errors.cashCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.cashCode}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Bank Code</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Bank Code'
                    {...formik.getFieldProps('bankCode')}
                  >
                    <option value=''>Select a Bank Code</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.bankCode && formik.errors.bankCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.bankCode}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Advance</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Advance'
                    {...formik.getFieldProps('advance')}
                  >
                    <option value=''>Select a Advance</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.advance && formik.errors.advance && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.advance}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Fixed Asset</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Fixed Asset'
                    {...formik.getFieldProps('fixedAsset')}
                  >
                    <option value=''>Select a Fixed Asset</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.fixedAsset && formik.errors.fixedAsset && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.fixedAsset}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Purchase Code</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Purchase Code'
                    {...formik.getFieldProps('purchaseCode')}
                  >
                    <option value=''>Select a Purchase Code</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.purchaseCode && formik.errors.purchaseCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.purchaseCode}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Sales Code</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Sales Code'
                    {...formik.getFieldProps('salesCode')}
                  >
                    <option value=''>Select a Sales Code</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.salesCode && formik.errors.salesCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.salesCode}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Service Code</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Service Code'
                    {...formik.getFieldProps('Service Code')}
                  >
                    <option value=''>Select a Service Code</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.serviceCode && formik.errors.serviceCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.serviceCode}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Customer Code</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Customer Code'
                    {...formik.getFieldProps('customerCode')}
                  >
                    <option value=''>Select a Customer Code</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.customerCode && formik.errors.customerCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.customerCode}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'> Supplier Code</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' Supplier Code'
                    {...formik.getFieldProps('supplierCode')}
                  >
                    <option value=''>Select a Supplier Code</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.supplierCode && formik.errors.supplierCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.supplierCode}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>
                    Costs Of Good Solds
                  </label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Costs Of Good Solds'
                    {...formik.getFieldProps('costsOfGoodSolds')}
                  >
                    <option value=''>Select a Costs Of Good Solds</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.costsOfGoodSolds && formik.errors.costsOfGoodSolds && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.costsOfGoodSolds}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Vat</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Vat'
                    {...formik.getFieldProps('vat')}
                  >
                    <option value=''>Select a vat</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.vat && formik.errors.vat && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.vat}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Tax</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Tax'
                    {...formik.getFieldProps('tax')}
                  >
                    <option value=''>Select a Tex</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.tax && formik.errors.tax && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.tax}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Inventory Code</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Inventory Code'
                    {...formik.getFieldProps('inventorycode')}
                  >
                    <option value=''>Select a Inventory Code</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.inventoryCode && formik.errors.inventoryCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.inventoryCode}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>CPL Code</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='CPL Code'
                    {...formik.getFieldProps('cplCode')}
                  >
                    <option value=''>Select a CPL Code</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.cplCode && formik.errors.cplCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.cplCode}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>LPL Code</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='LPL Code'
                    {...formik.getFieldProps('lplCode')}
                  >
                    <option value=''>Select a LPL Code</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.lplCode && formik.errors.lplCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.lplCode}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Salary Code</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Salary Code'
                    {...formik.getFieldProps('salaryCode')}
                  >
                    <option value=''>Select a Salary Code</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.salaryCode && formik.errors.salaryCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.salaryCode}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>
                    Emp Npf Contribution
                  </label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Emp Npf Contribution'
                    {...formik.getFieldProps('empNpfContribution')}
                  >
                    <option value=''>Select a Emp Npf Contribution</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.empNpfContribution && formik.errors.empNpfContribution && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.empNpfContribution}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>
                    Empr Npf Contribution
                  </label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Empr Npf Contribution'
                    {...formik.getFieldProps('emprNpfContribution')}
                  >
                    <option value=''>Select a Empr Npf Contribution</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.emprNpfContribution && formik.errors.emprNpfContribution && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.emprNpfContribution}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>empIcfContribution</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='empIcfContribution'
                    {...formik.getFieldProps('empIcfContribution')}
                  >
                    <option value=''>Select a empIcfContribution</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.empIcfContribution && formik.errors.empIcfContribution && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.empIcfContribution}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>
                    empr Icf Contribution
                  </label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='emprIcfContribution'
                    {...formik.getFieldProps('emprIcfContribution')}
                  >
                    <option value=''>Select a empr Icf Contribution</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.emprIcfContribution && formik.errors.emprIcfContribution && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.emprIcfContribution}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'> Prov State Tax</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Prov State Tax'
                    {...formik.getFieldProps('provStateTax')}
                  >
                    <option value=''>Select a Prov State Tax</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.provStateTax && formik.errors.provStateTax && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.provStateTax}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>state Tax</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='State Tax'
                    {...formik.getFieldProps('stateTax')}
                  >
                    <option value=''>Select a State Tax</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.stateTax && formik.errors.stateTax && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.stateTax}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Prov Npf Code</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='prov Npf code'
                    {...formik.getFieldProps('provNpfcode')}
                  >
                    <option value=''>Select a prov Npf code</option>
                    {/* {cashCode.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })} */}
                  </select>
                  {formik.touched.provNpfcode && formik.errors.provNpfcode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.provNpfcode}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
      //{' '}
    </div>
  )
}

export default BankreconciliationDetails
