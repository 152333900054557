import axios, {AxiosResponse} from 'axios'

import {getToken} from '../../../auth'

const API_URL = process.env.REACT_APP_API_URLS

var AuthVerify = getToken.call(this)

const skuDropdown = async () => {
  const res = await axios.get(`${API_URL}custom/get-sku`, getToken())
  console.log('res-sku-drop-api', res)
  return res
}


const getReport = async (data: any) => {
  console.log('data-api', data)
  const res = await axios.post(`${API_URL}reports/stock`, data, getToken())
  console.log('res-api', res)
  return res
}
export {skuDropdown, getReport}
