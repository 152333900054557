// @ts-nocheck
import {Column} from 'react-table'
import {SalesInfoCell} from './SalesInfoCell'
import {SalesTwoStepsCell} from './SalesTwoStepsCell'
import {SalesActionsCell} from './SalesActionsCell'
import {SalesCustomHeader} from './SalesCustomHeader'
import {Sales} from '../../core/_models'

const SalesColumns: ReadonlyArray<Column<Sales>> = [
  {
    Header: (props) => (
      <SalesCustomHeader tableProps={props} title='S.No' className='min-w-55px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <SalesCustomHeader tableProps={props} title='	Customer Name' className='min-w-125px' />
    ),
    accessor: 'customer.customerName',
  },
  {
    Header: (props) => (
      <SalesCustomHeader tableProps={props} title='Sales Date' className='min-w-85px' />
    ),
    accessor: 'salesDate',
  },
  {
    Header: (props) => (
      <SalesCustomHeader tableProps={props} title='Amount' className='min-w-75px' />
    ),
    accessor: 'amount',
  },
  {
    Header: (props) => (
      <SalesCustomHeader tableProps={props} title='Quantity' className='min-w-75px' />
    ),
    accessor: 'quantity',
  },
  {
    Header: (props) => <SalesCustomHeader tableProps={props} title='Tax' className='min-w-75px' />,
    accessor: 'tax',
  },
  {
    Header: (props) => (
      <SalesCustomHeader tableProps={props} title='Grand Total' className='min-w-75px' />
    ),
    accessor: 'grandTotal',
  },
  {
    Header: (props) => (
      <SalesCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <SalesActionsCell sales={props.data[props.row.index]} />,
  },
]

export {SalesColumns}
