// @ts-nocheck
import {Column} from 'react-table'
import {SalarygenerateInfoCell} from './SalarygenerateInfoCell'
import {SalarygenerateActionsCell} from './SalarygenerateActionsCell'
import {SalarygenerateCustomHeader} from './SalarygenerateCustomHeader'
import {Salarygenerate} from '../../core/_models'

const SalarygenerateColumns: ReadonlyArray<Column<Salarygenerate>> = [
  {
    Header: (props) => (
      <SalarygenerateCustomHeader tableProps={props} title='S.No' className='min-w-55px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <SalarygenerateCustomHeader tableProps={props} title='Salary Month' className='min-w-95px' />
    ),
    accessor: 'month',
  },
  {
    Header: (props) => (
      <SalarygenerateCustomHeader
        tableProps={props}
        title='Generated Date'
        className='min-w-95px'
      />
    ),
    accessor: 'generatedDate',
  },
  {
    Header: (props) => (
      <SalarygenerateCustomHeader
        tableProps={props}
        title='Status'
        className='min-w-105px'
      />
    ),
    accessor: 'approvedStatus',
    Cell: ({value}) => (
      <button
        style={{
          backgroundColor: value === 0 ? 'lightcoral' : 'lightgreen',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        {value === 0 ? 'Waiting' : 'Approved'}
      </button>
    ),
  },

  {
    Header: (props) => (
      <SalarygenerateCustomHeader
        tableProps={props}
        title='Approved Date'
        className='min-w-105px'
      />
    ),
    accessor: 'approvedDate',
  },

  {
    Header: (props) => (
      <SalarygenerateCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <SalarygenerateActionsCell salarygenerate={props.data[props.row.index]} />
    ),
  },
]

export {SalarygenerateColumns}
