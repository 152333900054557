import React, {useEffect, useRef, useState} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useDownloadExcel} from 'react-export-table-to-excel'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {getReport} from '../_requests'
import {useAuth} from '../../../../auth'

const ReportContent = () => {
  const tableRef = useRef(null)
  const [report, setReport] = useState<ReportData[]>([])
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null])
  const [btnExcelDisable, setbtnExcelDisable] = useState(true)
  const [btnClearDisable, setbtnClearDisable] = useState(true)
  const [OCDate, setOCDate] = useState('')
  const [loading, setLoading] = useState(false)

  const tableRefDebit = useRef(null)

  const {mode} = useThemeMode()

  interface ReportData {
    coaId: number
    debit: string
    credit: string
    coa: {
      headName: string
      headCode: string
      parentHeadName: string
      headLevel: number
    }
  }

  const TrialSheetReport = () => {
    setLoading(true)
    const Daterange = (document.getElementById('Daterange') as HTMLInputElement).value
    const dateRange = Daterange.split(' - ')
    const fromDate = dateRange[0].split('/').reverse().join('-')
    const toDate = dateRange[1].split('/').reverse().join('-')
    const data = {fromDate, toDate}

    console.log('Sending API request with data:', data)

    getReport(data).then((resRep: any) => {
      console.log('Received API response:', resRep)

      const {Income, Expenses, Assets, Liabilities} = resRep.data.data

      // Combine "Debit" and "Credit" data and set the report state
      setReport([...Income, ...Expenses, ...Assets, ...Liabilities])

      // Set the OCDate based on the selected date range
      setOCDate(`${fromDate} - ${toDate}`)

      setLoading(false)
      // Enable or disable the buttons as needed based on the new data.
      setbtnExcelDisable(Object.keys(resRep.data.data).length === 0)
      setbtnClearDisable(Object.keys(resRep.data.data).length === 0)
    })
  }

  const calculateTotalDebit = () => {
    return report.reduce((total, item) => {
      return total + parseFloat(item.debit || '0')
    }, 0)
  }

  const calculateTotalCredit = () => {
    return report.reduce((total, item) => {
      return total + parseFloat(item.credit || '0')
    }, 0)
  }
  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()

  const clearReport = () => {
    setReport([])
    setDateRange([null, null])
    setOCDate('')
    setbtnExcelDisable(true)
    setbtnClearDisable(true)
  }
  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `TrialBalance_Report_${fileName}`,
    sheet: 'Users',
  })

  return (
    <>
      <div className='card'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Trial Balance Report</h3>
          </div>
          <div className='col-lg-2 pt-5'>
            <button
              className='btn btn-danger col-lg-12'
              disabled={btnExcelDisable}
              onClick={onDownload}
            >
              Export
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='row mb-12'>
            <div className='col-lg-4'></div>

            <div className='col-lg-4'>
              <label className='col-form-label fw-bold fs-6'>Filter By Date Range</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid'
                id='Daterange'
                selectsRange={true}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                onChange={(update) => {
                  setDateRange(update as [Date | null, Date | null])
                }}
                isClearable={true}
                dateFormat='dd/MM/yyyy'
              />
            </div>

            <div className='col-lg-2 pt-12'>
              <button onClick={TrialSheetReport} className='btn btn-primary col-lg-12'>
                Search
              </button>
            </div>
            <div className='col-lg-2 pt-12'>
              <button
                onClick={clearReport}
                className='btn btn-info col-lg-12'
                disabled={btnClearDisable}
              >
                Clear
              </button>
            </div>
          </div>

          <div className='table-responsive'>
            <div className='row'>
              {/* Debit and Credit Table */}
              <div className='col-md-12'>
                <table
                  className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
                  ref={tableRef}
                >
                  <thead>
                    <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                      <th className='min-w-120px' colSpan={5}>
                        DATE: {OCDate}
                      </th>
                    </tr>
                    <tr>
                      <td colSpan={11}></td>{' '}
                    </tr>
                    <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                      <th className='min-w-50px'>S. No</th>
                      <th className='min-w-120px'>Particulars</th>
                      <th className='min-w-120px'>Debit</th>
                      <th className='min-w-120px'>Credit</th>
                    </tr>
                    <tr className='fw-bold text-muted' style={{borderBottom: 'none'}}>
                      <th colSpan={2} className='min-w-120px'></th>
                    </tr>
                  </thead>

                  <tbody>
                    {report.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.coa.headName}</td>
                        <td>{item.debit}</td>
                        <td>{item.credit}</td>
                      </tr>
                    ))}
                    <br />
                    <tr>
                      <td></td>

                      <td>
                        <h5>Totals : </h5>
                      </td>
                      <td>{calculateTotalDebit()}</td>
                      <td>{calculateTotalCredit()}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ReportContent}
