import {KTCard} from '../../../../../_metronic/helpers'
import { ReportContent } from './table/BalanceSheetReportContent'


const Reports = () => {
  return (
    <>
      <KTCard>
        <ReportContent />
      </KTCard>
    </>
  )
}

const ReportWrapper = () => <Reports />

export {ReportWrapper}
