import axios, {AxiosResponse} from 'axios'
import {Material, MaterialQueryResponse} from './_models'
import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_MATERIAL_URL = `${API_URL}material/add`
const GET_MATERIAL_URL = `${API_URL}material/query`
const VIEW_MATERIAL_URL = `${API_URL}material/view`
const UPDATE_MATERIAL_URL = `${API_URL}material/update`
const DELETE_MATERIAL_URL = `${API_URL}material/delete`
const GET_UNIT_URL = `${API_URL}custom/get-unit`
const GET_TAX_URL = `${API_URL}custom/get-tax`

//AuthVerify
var AuthVerify = getToken.call(this)

//Material LIST
const getMaterial = (query: string): Promise<MaterialQueryResponse> => {
  return axios
    .post(`${GET_MATERIAL_URL}?${query}`, AuthVerify)
    .then((response: AxiosResponse<MaterialQueryResponse>) => {
      console.log('getMaterial==>', response.data)
      return response.data
    })
}

//Material ADD
const createMaterial = (data: any): Promise<Material | undefined> => {
  return axios.post(CREATE_MATERIAL_URL, data, getToken()).then((response: any) => response.data)
}

//Material VEIW
const viewMaterial = (id: any) => {
  return axios.get(`${VIEW_MATERIAL_URL}/${id}`, getToken()).then((response) => {
    console.log('view-Material', response.data)
    return response.data
  })
}

//Material UPDATE
const updateMaterial = (data: any, id: any) => {
  console.log('Material=update', data)
  return axios.put(`${UPDATE_MATERIAL_URL}/${id}`, data, getToken()).then((response) => {
    console.log('update-Material', response.data)
    return response.data
  })
}

//Material DELETE
const deleteMaterialById = (id: any) => {
  return axios.post(`${DELETE_MATERIAL_URL}/${id}`, AuthVerify).then((response) => {
    return response.data
  })
}
//GET unit
const getUnit = async () => {
  const res = await axios.get(`${GET_UNIT_URL}`, getToken())
  console.log('UNIT', res)
  return res
}
//GET tax
const getTax = async () => {
  const res = await axios.get(`${GET_TAX_URL}`, getToken())
  console.log('Tax', res)
  return res
}

export {getMaterial, createMaterial, viewMaterial, updateMaterial, deleteMaterialById, getUnit, getTax}
