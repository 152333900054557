// @ts-nocheck
import {Column} from 'react-table'
import {SalaryadvanceInfoCell} from './SalaryadvanceInfoCell'
import {SalaryadvanceTwoStepsCell} from './SalaryadvanceTwoStepsCell'
import {SalaryadvanceActionsCell} from './SalaryadvanceActionsCell'
import {SalaryadvanceCustomHeader} from './SalaryadvanceCustomHeader'
import {Salaryadvance} from '../../core/_models'

const SalaryadvanceColumns: ReadonlyArray<Column<Salaryadvance>> = [
  {
    Header: (props) => (
      <SalaryadvanceCustomHeader tableProps={props} title='S.No' className='min-w-55px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <SalaryadvanceCustomHeader tableProps={props} title='Employee Id' className='min-w-95px' />
    ),
    accessor: 'employee.employeeId',
  },
  {
    Header: (props) => (
      <SalaryadvanceCustomHeader tableProps={props} title='Employee Name' className='min-w-125px' />
    ),
    accessor: 'employee.employeeName',
  },
  {
    Header: (props) => (
      <SalaryadvanceCustomHeader tableProps={props} title='Release Date' className='min-w-95px' />
    ),
    accessor: 'releaseDate',
  },
  {
    Header: (props) => (
      <SalaryadvanceCustomHeader tableProps={props} title='Amount' className='min-w-75px' />
    ),
    accessor: 'amount',
  },
  {
    Header: (props) => (
      <SalaryadvanceCustomHeader tableProps={props} title='Salary Month' className='min-w-85px' />
    ),
    accessor: 'salaryMonth',
  },
  {
    Header: (props) => (
      <SalaryadvanceCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <SalaryadvanceActionsCell Salaryadvance={props.data[props.row.index]} />,
  },
]

export {SalaryadvanceColumns}
