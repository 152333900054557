// @ts-nocheck
import {Column} from 'react-table'
import {MaterialInfoCell} from './MaterialInfoCell'
import {MaterialTwoStepsCell} from './MaterialTwoStepsCell'
import {MaterialActionsCell} from './MaterialActionsCell'
import {MaterialCustomHeader} from './MaterialCustomHeader'
import {Material} from '../../core/_models'

const MaterialColumns: ReadonlyArray<Column<Material>> = [
  {
    Header: (props) => (
      <MaterialCustomHeader tableProps={props} title='S.No' className='min-w-55px' />
    ),
    accessor: 'serialNo',
  },

  {
    Header: (props) => (
      <MaterialCustomHeader tableProps={props} title='Material Name' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <MaterialCustomHeader tableProps={props} title='Code' className='min-w-85px' />
    ),
    accessor: 'code',
  },
  {
    Header: (props) => (
      <MaterialCustomHeader tableProps={props} title='Unit' className='min-w-105px' />
    ),
    accessor: 'unit.unitName',
  },
  {
    Header: (props) => (
      <MaterialCustomHeader tableProps={props} title='Price' className='min-w-75px' />
    ),
    accessor: 'price',
  },
  {
    Header: (props) => (
      <MaterialCustomHeader tableProps={props} title='Tax' className='min-w-85px' />
    ),
    accessor: 'tax.taxName',
  },
  {
    Header: (props) => (
      <MaterialCustomHeader tableProps={props} title='Percentage' className='min-w-75px' />
    ),
    accessor: 'tax.percentage',
  },
  {
    Header: (props) => (
      <MaterialCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <MaterialActionsCell material={props.data[props.row.index]} />,
  },
]

export {MaterialColumns}
