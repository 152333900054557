import {BankreconciliationListHeader} from './components/header/BankreconciliationListHeader'
import {BankreconciliationTable} from './table/BankreconciliationTable'
import {KTCard} from '../../../../../_metronic/helpers'
import {AddBankreconciliation, EditBankreconciliation} from '../bank-reconciliation-form'
import {useContext} from 'react'
import {MyContext} from './ContextFile'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ContextProvider} from '../../role/role-list/ContextProvider'

// import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
// import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
const BankreconciliationAdd = () => {
  const {state, setState} = useContext(MyContext)

  return (
    <>
      <KTCard>
        {state.editclicked === true ? <EditBankreconciliation /> : <AddBankreconciliation />}
      </KTCard>
    </>
  )
}

const BankreconciliationList = () => {
  return (
    <>
      <KTCard>
        <BankreconciliationListHeader />
        <BankreconciliationTable />
      </KTCard>
    </>
  )
}

const BankreconciliationMain = () => {
  // const {show, message, type} = useContext(AlertContext)
  return (
    <div className='row'>
      {/* {show && <AlertBox type={type === '1' ? 'success' : 'error'} message={message} />} */}
      <div className='col-lg-12'>
        <BankreconciliationAdd />
      </div>
      {/* <div className='col-lg-7'>
        <BankreconciliationList />
      </div> */}
    </div>
  )
}

const BankreconciliationListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ContextProvider>
          <BankreconciliationMain />
        </ContextProvider>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {BankreconciliationListWrapper}
